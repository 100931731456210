import React, {memo} from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

function TimerBidding(props) {
    const biddingData = props.biddingData;
    const formatTime = ( remainingTime ) => {
        const hours = Math.floor(remainingTime / 3600)
        const minutes = Math.floor((remainingTime % 3600) / 60)
        const seconds = remainingTime % 60
        let hourFormatted = hours.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });
        let minFormatted = minutes.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });
        let secFormatted = seconds.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });

        if(hours === 0){
            return `${minFormatted}:${secFormatted}`
        }
        return `${hourFormatted}:${minFormatted}:${secFormatted}`
    }
    return (
    <CountdownCircleTimer
        isPlaying={biddingData.biddingStarted}
        key={biddingData.keyTimer}
        duration={biddingData.timer}
        onComplete={() => props.onComplete()}
        size={97}
        strokeWidth={7}
        colors={[
        ['#00CA45', 0.33],
        ['#3D7EE2', 0.33],
        ['#DC1F18', 0.33],
        ]}
        >
        {({ remainingTime }) => formatTime(remainingTime)}
    </CountdownCircleTimer>
    )
}
function isTimerStateChanged(prevProps, nextProps) {
    return prevProps.biddingData.biddingStarted === nextProps.biddingData.biddingStarted
      && prevProps.onComplete === nextProps.onComplete;
}
export default memo(TimerBidding, isTimerStateChanged);