import React, { useEffect, useRef, useState, useLayoutEffect } from 'react'
import { Popover } from '@reactour/popover'
import moment from 'moment'
import RadioGroup from '@material-ui/core/RadioGroup';
import RadioWithLabel from './../RadioWithLabel';
import closeIcon from './../../assets/images/icons/closeCircle.svg';
import divider from './../../assets/images/icons/divider.svg';
import helpIcon from './../../assets/images/icons/helpIcon.svg';
import chevronDown from './../../assets/images/icons/dropIcon.svg';
import infoIconWhite from './../../assets/images/icons/infoIconWhite.svg';
import bankGrey from './../../assets/images/icons/bankGrey.svg';
import addSquare from './../../assets/images/icons/addSquare.svg';
import DP from './../../assets/images/DP.png';

function ViewRecommend(props) {
    const dropIconRef = useRef();
    const dropRef = useRef();
    const planExplainer = useRef();
    const dropIconExplainerRef = useRef();
    const contentRef = useRef();
    const expandContent = useRef();
    const [isOpen, setIsOpen] = useState(false)
    const [isListOpen, setIsListOpen] = useState(false)
    const [planUserListOpened, setPlanUserListOpened] = useState(false)
    const [bankSelected, setBankSelected] = useState(0)
    const [isPlanExplainerOpen, setIsPlanExplainerOpen] = useState(false)
    const styles = {
        popover: (base, state) => {
          return {
            ...base,
            borderRadius: 10,
          }
        },
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    });

    useLayoutEffect(() => {
        if(!isPlanExplainerOpen && props.viewBottom){
            contentRef.current.scrollTop = contentRef.current.scrollHeight;
        }
      }, [isPlanExplainerOpen, props.viewBottom])
    const handleClickOutside = (event) => {
        if(planUserListOpened && event.target.className !== "list"){
            setPlanUserListOpened(false)
        }
        if(isOpen){
            setIsOpen(false)
        }
    }

    const openUserList = () => {
        setPlanUserListOpened(true)
    }
    const toggleList = () => {
        if(isListOpen){
            dropRef.current.style.display = "none";
            dropIconRef.current.style.transform = 'rotate(0deg)'

        } else {
            dropRef.current.style.display = "block";
            dropIconRef.current.style.transform = 'rotate(180deg)'
        }
        setIsListOpen(!isListOpen)
    }
    const togglePlanExplainer = () => {
        if(isPlanExplainerOpen){
            expandContent.current.style.display = "none"
            dropIconExplainerRef.current.style.transform = 'rotate(0deg)'
          

        } else {
            expandContent.current.style.display = "block";
            dropIconExplainerRef.current.style.transform = 'rotate(180deg)'
        }
        setIsPlanExplainerOpen(!isPlanExplainerOpen)
    }
    return (
        <div className="modal viewRecommend">
            <div className="modal-content">
                <div className="header">
                    <div className='title'>{props.details.plan.title}</div>
                    <span onClick={() => props.closeModal()} className="close">
                        <img src={closeIcon} alt="icon" />
                    </span>
                </div>
                
                <div className="content" ref={contentRef}>
                    {!isPlanExplainerOpen && <>
                        <img className='divider' src={divider} alt="icon" />
                        <div className="gridContent">
                            <span className="title">Amount to contribute at every Month or week</span>
                            <span className="value">${props.details.plan.amount_to_contribute.substring(0, props.details.plan.amount_to_contribute.length - 3)}</span>
                            <span className="title">Total pooled amount for Auction at every month or week</span>
                            <span className="value">${props.details.plan.total_amount.substring(0, props.details.plan.total_amount.length - 3)}</span>
                            <span className="title">Total members in the plan</span>
                            <span className="value">{props.details.plan.no_of_members}</span>
                            <span className="title">Plan Period</span>
                            <span className="value">
                                {props.details.plan.no_of_auctions}&nbsp;
                                {props.details.plan.type.interval_period === 1 && 'Days'}
                                {props.details.plan.type.interval_period === 7 && 'Weeks'}
                                {props.details.plan.type.interval_period === 30 && 'Months'}
                            </span>
                            <span className="title">Total no of Auctions<br />(Monthly or weekly once)</span>
                            <span className="value">{props.details.plan.no_of_auctions}</span>
                            <span className="title">Predicted average amount paid after the auction at every Month or week. (PAPA)</span>
                            <span className="value">${props.details.plan.papb.substring(0, props.details.plan.papb.length - 3)}</span>
                            <span className="title">Predicted total amount paid at the end of the plan (PTAP)</span>
                            <span className="value">${props.details.plan.ptap.substring(0, props.details.plan.ptap.length - 3)}</span>
                            <span className="title">Slots left</span>
                            <span className="value">{props.details.plan.no_of_members - props.details.planDetails.members.length}</span>
                            <span className="title">Time zone for auction Standard auction Timing</span>
                            <span className="value timeZoneValue">8am - 8pm</span>
                            {props.details.plan.expected_starting_date && <span className="title">Expected starting Date</span>}
                            {props.details.plan.expected_starting_date && <span className="value">{moment(props.details.plan.expected_starting_date).format("DD/MM/YYYY")}</span>}
                            <span className="title">Members Joined</span>
                            <span className="value">{props.details.planDetails.members.length}</span>
                        </div>
                        {props.details.planDetails.members.length > 0 && <div className='friends'>
                            <div className='title'>Members</div>
                            <div className="imageContainer" onClick={() => openUserList()}>
                                {props.details.planDetails && props.details.planDetails.members && props.details.planDetails.members.length > 0 && props.details.planDetails.members.map((member, index) => {
                                    if(index <= 3){
                                        return <img key={member.id} className="displayedImage" src={DP} alt="icon"/>
                                    } else return null
                                    
                                })}
                                {props.details.planDetails.members.length > 4 && <div className="planUserCount">+{props.details.planDetails.members.length - 4}</div>}
                                {planUserListOpened && <div className="planUserList">
                                    <div className='heading'>Members</div>
                                    <ul>
                                        {props.details.planDetails && props.details.planDetails.members && props.details.planDetails.members.length > 0 && props.details.planDetails.members.map((member) => {
                                            return <li key={member.id} className='list'>
                                                    <img src={DP} alt="icon"/>
                                                    <span>{member.first_name}&nbsp;{member.last_name}</span>
                                                </li>
                                        })}
                                    </ul>
                                    {/* <div className='heading'>Other Members</div> */}
                                </div>}
                            </div>
                        </div>}
                    </>}
                    <div className='planExplainer' ref={planExplainer}>
                        <div className='topContent' onClick={() => togglePlanExplainer()}>
                            <img src={infoIconWhite} alt="icon" />
                            <span>Plan Explainer</span>
                            <img ref={dropIconExplainerRef} src={chevronDown} alt="icon" />
                        </div>
                        <div className='expand' ref={expandContent}>
                            <img src={divider} alt="icon" />
                            <div className='explainerContent'>
                                {props.details.planDetails.plan_explainer}
                            </div>
                        </div>
                    </div>
                    <div className='actionRequired'>
                        <div className='row'>
                            <span className='text'>Action Required</span>
                            <div onClick={() => setIsOpen(o => !o)} className='help'>
                                <img src={helpIcon} alt="icon" />
                                {isOpen ? (
                                    <Popover className='helpTooltip' sizes={{  top: 0, right: 0}} styles={styles}>
                                    <p>
                                    At each installments, the amount that
                                    you what to contributed to the plan will
                                    be deducted from your bank account automatically and members can easily withdraw their funds from their wheez
                                    wallet to the linked bank accounts
                                    </p>
                                    </Popover>
                                ) : null}
                            </div>
                        </div>
                        <div className='selectBank' onClick={() => toggleList()}>
                            <img src={bankGrey} alt="icon" />
                            <span className='addText'>Select Bank Account</span>
                            <img ref={dropIconRef} src={chevronDown} alt="icon" />
                        </div>
                        <div className="dropdown" ref={dropRef}>
                                <div className="dropContent">
                                    <RadioGroup onChange={e => { setBankSelected(e.target.value) }} className='radioGroup' aria-label="plantype" name="customized-radios">
                                        <RadioWithLabel value="1" label="Wallet" />
                                    </RadioGroup>
                                </div>
                            </div>
                        <div className='addAccount'>
                            <img src={addSquare} alt="icon" />
                            <span className='addText'>Add Bank Account</span>
                        </div>

                    </div>
                </div>
                <button style={{opacity : bankSelected === '1' ? 1 : 0.5}} onClick={() => { 
                    if(bankSelected === '1'){
                        props.closeModal(); props.joinPlan(props.details.id); 
                    }
                    }}>Join Now!</button>
            </div>
        </div>
    )
}

export default ViewRecommend