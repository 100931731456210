import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import { 
    linkGoogleAccount,
} from './../../redux/actions/contactActions';
import divider from './../../assets/images/icons/divider.svg';
import planOptions from './../../assets/images/icons/planOptions.svg';
import deletePlan from './../../assets/images/icons/deletePlan.svg';
import DP from './../../assets/images/DP.png';
import infoPlan from './../../assets/images/icons/infoPlan.svg';
const BASE_URL = `http://${process.env.REACT_APP_WHEEZ_SERVER_URL}`;

export default function RecommendCard(props) {
    const dispatch = useDispatch();
    const {recommendedPlan} = props;
    const contactsState = useSelector((state) => state.contacts);
    const { userAvatars } = contactsState;

    const [planUserListOpened, setPlanUserListOpened] = useState(false)
    const [currentListPlanId, setCurrentListPlanId] = useState(0)
    const [isExpandedOpen, setIsExpandedOpen] = useState(false)
    const [friendsHeading , setFriendsHeading] = useState(false)
    const [otherFriendsHeading , setOtherFriendsHeading] = useState(false)

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        if(recommendedPlan.planDetails && recommendedPlan.planDetails.members && recommendedPlan.planDetails.members.length > 0){
            recommendedPlan.planDetails.members.map((member) => {
                if(member.details && member.details.is_contact){
                    setFriendsHeading(true)
                }
                if(member.details && !member.details.is_contact){
                    setOtherFriendsHeading(true)
                }
                return null;
            })
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    });

    const handleClickOutside = (event) => {
        if(planUserListOpened && event.target.className !== "list" && event.target.className !== "googleConnect"){
            setPlanUserListOpened(false)
        }
        if(isExpandedOpen && event.target.className !== "notInterestedText"){
            setIsExpandedOpen(false)
        }
    }
    const openUserList = (planId) => {
        setPlanUserListOpened(true)
        setCurrentListPlanId(planId)
    }
    const openUserInfo = (member) => {
        setPlanUserListOpened(false)
        props.openUserInfo(member);
    }
    const toggleExpanded = () => {
        setIsExpandedOpen(!isExpandedOpen)
    }
    const handleGoogleLink = () => {
        dispatch(linkGoogleAccount()).then((url)=> {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        });
    }
 
    return (
        <section key={recommendedPlan.plan.id} className={`${recommendedPlan.planDetails.members.length === recommendedPlan.plan.no_of_members ? 'planCard inActiveRecommend' : 'planCard'}`}>
            {recommendedPlan.planDetails.members.length === recommendedPlan.plan.no_of_members && <div className='inactiveLayer'></div>}
            <div className='dummyContainer'>
                <div className='planTypeInfo'>
                    <span className='planText'>
                        {recommendedPlan.planDetails.type.title}
                    </span>
                </div>
                <div className='highlyRecommended'>
                    <span>Highly Recommended</span>
                </div>
            </div>
            <div className="header">
                <div className='memberCount'>{recommendedPlan.planDetails.no_of_members}</div>
                <div className='content'>
                    <div className='planType'>{recommendedPlan.planDetails.title}</div>
                    <div className='duration'>
                        x${recommendedPlan.planDetails.amount_to_contribute.substring(0, recommendedPlan.planDetails.amount_to_contribute.length - 3)}&nbsp;
                        Per&nbsp;
                        {recommendedPlan.planDetails.type.interval_period === 1 && 'Day'}
                        {recommendedPlan.planDetails.type.interval_period === 7 && 'Week'}
                        {recommendedPlan.planDetails.type.interval_period === 30 && 'Month'}&nbsp;
                        For <span className='month'>{recommendedPlan.planDetails.no_of_auctions}&nbsp;
                        {recommendedPlan.planDetails.type.interval_period === 1 && 'Day'}
                        {recommendedPlan.planDetails.type.interval_period === 7 && 'Week'}
                        {recommendedPlan.planDetails.type.interval_period === 30 && 'Month'}s</span>
                    </div>
                </div>
            </div>
            <div className='statusProgress'>
                <div className='text'>
                    <span>Total Members</span>
                    <span>{recommendedPlan.planDetails.no_of_members} Members</span>
                </div>
                <div className='progress'>
                    <div style={{width: `${(recommendedPlan.planDetails.members.length/recommendedPlan.planDetails.no_of_members)*100 - 2}%`}} className='percentage'></div>
                </div>
                <div className='upcoming'>
                    {recommendedPlan.planDetails.expected_starting_date && (recommendedPlan.planDetails.members.length < recommendedPlan.plan.no_of_members) ? <span>Expected Starting Date: {moment(recommendedPlan.planDetails.expected_starting_date).format("DD/MM/YYYY")}</span> : <span></span>}
                    {recommendedPlan.planDetails.members.length === recommendedPlan.plan.no_of_members && <span className='slotsFilledUpcoming'>Seats are filled</span>}
                    <span>{recommendedPlan.planDetails.no_of_members - recommendedPlan.planDetails.members.length} seats left</span>
                </div>
            </div>
            <div className="buttonRow">
                <button className='active' onClick={() => {
                    // props.joinPlan(recommendedPlan.id)}
                    props.openRecommendView(recommendedPlan, false)}
                } 
                >Join Now!</button>
                <button onClick={() => props.openRecommendView(recommendedPlan, true)}>View More</button>
            </div>
            <img className='plansDivider' src={divider} alt="icon" />
            <div className="bottomRow">
                <div className="imageContainer" onClick={() => openUserList(recommendedPlan.plan)}>
                    {recommendedPlan.planDetails && recommendedPlan.planDetails.members && recommendedPlan.planDetails.members.length > 0 && recommendedPlan.planDetails.members.map((member, index) => {
                        if(index <= 3){
                            let avatar = null
                            if(member.avatar){
                                avatar = userAvatars.filter(u => u.id === member.avatar)
                            }
                            if(avatar) return <img key={member.id} className="displayedImage" src={`${BASE_URL}${avatar[0].image}`} alt="icon"/>
                            else if(member.profile_image) return <img key={member.id} className="displayedImage" src={`${BASE_URL}${member.profile_image}`} alt="icon"/>
                            else return <img key={member.id} className="displayedImage" src={DP} alt="icon"/>
                        } else return null
                        
                    })}
                    {recommendedPlan.planDetails.members.length > 4 && <div className="planUserCount">+{recommendedPlan.planDetails.members.length - 4}</div>}
                    {planUserListOpened && currentListPlanId === recommendedPlan.plan && <div className="planUserList">
                        <div className='heading'>Friends</div>
                        {recommendedPlan.planDetails && recommendedPlan.planDetails.members && recommendedPlan.planDetails.members.length > 0 && 
                            <ul>
                                {recommendedPlan.planDetails.members.map((member) => {
                                    if(member.details && member.details.is_contact){ 
                                        let avatar = null
                                        if(member.avatar){
                                            avatar = userAvatars.filter(u => u.id === member.avatar)
                                        }
                                        return <li onClick={() => openUserInfo(member)} key={member.id} className='list'>
                                            {avatar ? 
                                                <img src={`${BASE_URL}${avatar[0].image}`} alt="icon" /> : 
                                                (member.profile_image ? <img src={`${BASE_URL}${member.profile_image}`} alt="icon"/> : <img src={DP} alt="icon"/>)
                                            }
                                            <span>{member.first_name}&nbsp;{member.last_name}</span>
                                        </li>
                                    } else return null
                                    
                                })}
                            </ul>
                        }
                        {!friendsHeading && (
                                <div className='connectGoogle'>
                                    <div className='description'>Connect your google account to view friends</div>
                                    <button className='googleConnect' onClick={() => handleGoogleLink()}>Connect</button>
                                </div>
                            )
                        }
                        {otherFriendsHeading && <div className='heading' style={{marginTop: friendsHeading ? '10px' : 0}}>Other Members</div>}
                        <ul>
                            {recommendedPlan.planDetails && recommendedPlan.planDetails.members && recommendedPlan.planDetails.members.length > 0 && recommendedPlan.planDetails.members.map((member) => {
                                if(member.details && !member.details.is_contact){
                                    let avatar = null
                                    if(member.avatar){
                                        avatar = userAvatars.filter(u => u.id === member.avatar)
                                    }
                                    return (
                                        <li onClick={() => openUserInfo(member)} key={member.id} className='list'>
                                            {avatar ? 
                                                <img src={`${BASE_URL}${avatar[0].image}`} alt="icon" /> : 
                                                (member.profile_image ? <img src={`${BASE_URL}${member.profile_image}`} alt="icon"/> : <img src={DP} alt="icon"/>)
                                            }
                                            <span>{member.first_name}&nbsp;{member.last_name}</span>
                                        </li>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </ul>
                        
                    </div>}
                </div>
                
                <div className='planActions'>
                    {recommendedPlan.planDetails.members.length === recommendedPlan.plan.no_of_members && <img onClick={() => props.deleteRecommendedPlan(recommendedPlan)} className='deletePlan' src={deletePlan} alt="icon" />}
                    <img className='infoPlan' src={infoPlan} alt="icon" />
                    <div className='planOptions'>
                        <img onClick={toggleExpanded} src={planOptions} alt="icon" />
                        {isExpandedOpen && (
                            <div className="notInterested">
                                <span className='notInterestedText' onClick={() => props.openNotInterested(recommendedPlan)}>Not Interested</span>    
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}
