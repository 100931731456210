import React from 'react'
import RecommendCard from './Plans/RecommendCard';
import TrialPlan from './Plans/TrialPlan';
import applyPlanNow from './../assets/images/applyPlanNow.svg';
import beforeMCL from './../assets/images/beforeMCL.svg';
export default function RecommendSection(props) {
  return (
    <>
       {props.plans.recommendedPlans && props.plans.recommendedPlans.length === 0 && props.notifications.planApplicationThere && props.plansWithFirstAuction && <div className='messageInfo'>
            <img src={applyPlanNow} alt="icon" />
            <div className='title'>Applied Successfully</div>
            <div className='info'>We'll give you the right plans soon</div>
            <div className='info'>If you think you want to re-apply again please apply again here</div>
            <button onClick={() => props.openApplication()}>Apply Again</button>
        </div>}

       {props.plans.recommendedPlans && props.plans.recommendedPlans.length === 0 && props.notifications.planApplicationThere && !props.plansWithFirstAuction && <div className='messageInfo'>
            <img src={applyPlanNow} alt="icon" />
            <div className='title'>Applied Successfully</div>
            <div className='info'> we’re sorry You cannot apply for a plan now, because your previous plan has not started yet.</div>
        </div>}
        
        {!props.appliedSuccessSection && props.plans.recommendedPlans && props.plans.recommendedPlans.length > 0 &&
            props.plans.recommendedPlans.map((recommendedPlan) => 
                <RecommendCard 
                    key={recommendedPlan.id} 
                    openRecommendView={props.openRecommendView}
                    deleteRecommendedPlan={props.deleteRecommendedPlan}
                    joinPlan={props.joinPlan}
                    openNotInterested={props.openNotInterested}
                    openUserInfo={props.openUserInfo} 
                    recommendedPlan={recommendedPlan} 
                />
            )
        }
        {(props.plans.demoPlans.length === 0 && props.user.userProfile && props.user.userProfile.level === 0) ? <>
                <TrialPlan />
                <div className='fixedButton'>
                    <button onClick={() => props.openApplication()}>Apply Now</button>
                </div>
            </>
            :
            props.plans.recommendedPlans && props.plans.recommendedPlans.length === 0 && (
                props.user.userProfile.limit > 0 && !props.notifications.planApplicationThere ? <div className='messageInfo'>
                <img src={applyPlanNow} alt="icon" />
                <div className='title'>You are available to apply for a plan</div>
                <button onClick={() => props.openApplication()}>Apply Now</button>
            </div> : <div className='messageInfo'>
                            <img src={beforeMCL} alt="icon" ></img>
                            <div className='title'>Monthly Contribution Limit</div>
                            <div className='info'>Get a Monthly contribution limit to join in a plan.</div>
                            <button onClick={() => props.limitPage()}>Get A MC Limit</button>
                            <div className='demoInfo'></div>
                        </div>

            )
        } 
    </>
  )
}
