import React, { useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'

function CountrySelector(props) {
  const countryOptions = useMemo(() => countryList().getData(), [])
  const colourStyles = {
    control: styles => ({ ...styles,
      boxSizing: 'border-box',
      fontSize: '18px',
      outline: 'none',
      width: '100%',
      border: '2px solid rgba(255,255,255,0.8)',
      padding: '10px 20px 10px 20px',
      color: 'rgba(255,255,255,0.8)',
      background: 'transparent',
      borderRadius: '10px',
      display: 'flex',
    }),
    placeholder:styles => ({ ...styles, textTransform: 'capitalize',marginLeft:'0'}),
    valueContainer:styles => ({ ...styles,paddingLeft:'0'}),
    container: styles => ({ ...styles, width: '100%',}),
    input: styles => ({ ...styles, color: 'white',}),
    singleValue: styles => ({ ...styles,  color: 'rgba(255,255,255,0.8)' }),
    indicatorSeparator: styles => ({ ...styles, display: 'none' }),
  };
  return <Select 
    options={countryOptions} 
    value={props.value && props.value.value === '' ? null : props.value} 
    placeholder="Country"
    onChange={props.countrySelect} 
    styles={colourStyles}
    />
}

export default CountrySelector