import axios from 'axios';
import store from '../store';

import { 
    LOADING_UI,
    CLEAR_ERRORS,
    PLAN_RECOMMENDATION,
    AUCTION_STARTS,
    DEPOSIT_SUCCESSFUL,
    DEPOSIT_UNSUCCESSFUL,
    CONTACT_JOINS_PLAN,
    PERSON_JOINS_PLAN,
    AUCTION_ABOUT_TO_START,
    AUCTION_ABOUT_TO_END,
    AUCTION_ENDS,
    WINNING_AMOUNT_ADDED,
    SURPLUS_AMOUNT_ADDED,
    DELETE_NOTIFICATION,
    CLEAR_ALL_NOTIFICATIONS,
    TOTAL_NOTIFICATIONS,
    CONTACT_REQUEST,
    FIRST_TIME_LOGIN,
    PLAN_SCHEDULED,
    CONTACT_REMOVED,
    UNREAD_NOTIFICATIONS,
    APPLIED_FOR_MCL,
    PLAN_APPLICATION_SUBMITTED,
    PLAN_APPLICATION_THERE
} from '../types';

const BASE_URL = process.env.REACT_APP_WHEEZ_SERVER_URL;
let socketUrl;

export const notificationSocket = () => (dispatch) => {
    let token = localStorage.accessToken;
    socketUrl = new WebSocket(`ws://${BASE_URL}/ws/notifications/?token=${token}`)
    socketUrl.onmessage = evt => {
        const message = JSON.parse(evt.data);
        if(message.message_type === "notification"){
            dispatch({
                type: TOTAL_NOTIFICATIONS,
                payload: store.getState().notifications.totalNotifications + 1
            })
            dispatch({
                type: UNREAD_NOTIFICATIONS,
                payload: store.getState().notifications.unreadNotifications + 1
            })
            if(message.data && message.data.user_notification && message.data.user_notification.type === "PLAN_RECOMMENDATION"){
                dispatch({
                    type: PLAN_RECOMMENDATION,
                    payload: message.data.user_notification
                })
            }
            if(message.data && message.data.user_notification && message.data.user_notification.type === "DEPOSIT_SUCCESSFUL"){
                dispatch({
                    type: DEPOSIT_SUCCESSFUL,
                    payload: message.data.user_notification
                })
            }
            if(message.data && message.data.user_notification && message.data.user_notification.type === "DEPOSIT_UNSUCCESSFUL"){
                dispatch({
                    type: DEPOSIT_UNSUCCESSFUL,
                    payload: message.data.user_notification
                })
            }
            if(message.data && message.data.user_notification && message.data.user_notification.type === "CONTACT_JOINS_PLAN"){
                dispatch({
                    type: CONTACT_JOINS_PLAN,
                    payload: message.data.user_notification
                })
            }
            if(message.data && message.data.user_notification && message.data.user_notification.type === "PERSON_JOINS_PLAN"){
                dispatch({
                    type: PERSON_JOINS_PLAN,
                    payload: message.data.user_notification
                })
            }
            if(message.data && message.data.user_notification && message.data.user_notification.type === "AUCTION_ABOUT_TO_START"){
                dispatch({
                    type: AUCTION_ABOUT_TO_START,
                    payload: message.data.user_notification
                })
            }
            if(message.data && message.data.user_notification && message.data.user_notification.type === "AUCTION_STARTS"){
                dispatch({
                    type: AUCTION_STARTS,
                    payload: message.data.user_notification
                })
            }
            if(message.data && message.data.user_notification && message.data.user_notification.type === "AUCTION_ABOUT_TO_END"){
                dispatch({
                    type: AUCTION_ABOUT_TO_END,
                    payload: message.data.user_notification
                })
            }
            if(message.data && message.data.user_notification && message.data.user_notification.type === "AUCTION_ENDS"){
                dispatch({
                    type: AUCTION_ENDS,
                    payload: message.data.user_notification
                })
            }
            if(message.data && message.data.user_notification && message.data.user_notification.type === "WINNING_AMOUNT_ADDED"){
                dispatch({
                    type: WINNING_AMOUNT_ADDED,
                    payload: message.data.user_notification
                })
            }
            if(message.data && message.data.user_notification && message.data.user_notification.type === "SURPLUS_AMOUNT_ADDED"){
                dispatch({
                    type: SURPLUS_AMOUNT_ADDED,
                    payload: message.data.user_notification
                })
            }
            if(message.data && message.data.user_notification && message.data.user_notification.type === "CONTACT_REQUEST"){
                dispatch({
                    type: CONTACT_REQUEST,
                    payload: message.data.user_notification
                })
            }
            if(message.data && message.data.user_notification && message.data.user_notification.type === "FIRST_TIME_LOGIN"){
                dispatch({
                    type: FIRST_TIME_LOGIN,
                    payload: message.data.user_notification
                })
            }
            if(message.data && message.data.user_notification && message.data.user_notification.type === "CONTACT_REMOVED"){
                dispatch({
                    type: CONTACT_REMOVED,
                    payload: message.data.user_notification
                })
            }
            if(message.data && message.data.user_notification && message.data.user_notification.type === "PLAN_SCHEDULED"){
                dispatch({
                    type: PLAN_SCHEDULED,
                    payload: message.data.user_notification
                })
            }
            if(message.data && message.data.user_notification && message.data.user_notification.type === "APPLIED_FOR_MCL"){
                dispatch({
                    type: APPLIED_FOR_MCL,
                    payload: message.data.user_notification
                })
            }
            if(message.data && message.data.user_notification && message.data.user_notification.type === "PLAN_APPLICATION_SUBMITTED"){
                dispatch({
                    type: PLAN_APPLICATION_SUBMITTED,
                    payload: message.data.user_notification
                })
            }
        }
    }
}

export const viewNotificationAction = (id) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.put(`/api/notifications/read/${id}/`, {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            dispatch({type: CLEAR_ERRORS})
            return dispatch(getAllNotifications());
        }
    })
    .catch((err)=>{
        console.log("unable to update")
    })
}

export const readAllNotification = (list) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.put('/api/notifications/read/', list)
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            dispatch({type: CLEAR_ERRORS})
            return dispatch(getAllNotifications());
        }
    })
    .catch((err)=>{
        console.log("unable to update")
    })
}

export const deleteNotification = (notification) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.put(`/api/notifications/clear/${notification.id}/`, {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            dispatch({type: CLEAR_ERRORS})
            dispatch({
                type: DELETE_NOTIFICATION,
                payload: {category: notification.type, id: notification.id, readStatus: notification.wn_read}
            })
        }
    })
    .catch((err)=>{
        console.log("unable to delete")
    })
}

export const closeConnection = () => () => {
    if(socketUrl){
        socketUrl.close();
    }
}

export const getAllNotifications = () => (dispatch) => {
    let unreadNotifications = 0;
    let allNotifications = 0;
    let planApplicationNotCount = 0
    return axios.get(`/api/notifications/list/`, {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            dispatch({type: CLEAR_ALL_NOTIFICATIONS});
            if(res.data.results.length > 0){
                res.data.results.forEach(result => {
                    if(result.type === "PLAN_RECOMMENDATION"){
                        dispatch({
                            type: PLAN_RECOMMENDATION,
                            payload: result
                        })
                    }
                    if(result.type === "DEPOSIT_SUCCESSFUL"){
                        dispatch({
                            type: DEPOSIT_SUCCESSFUL,
                            payload: result
                        })
                    }
                    if(result.type === "DEPOSIT_UNSUCCESSFUL"){
                        dispatch({
                            type: DEPOSIT_UNSUCCESSFUL,
                            payload: result
                        })
                    }
                    if(result.type === "CONTACT_JOINS_PLAN"){
                        dispatch({
                            type: CONTACT_JOINS_PLAN,
                            payload: result
                        })
                    }
                    if(result.type === "PERSON_JOINS_PLAN"){
                        dispatch({
                            type: PERSON_JOINS_PLAN,
                            payload: result
                        })
                    }
                    if(result.type === "AUCTION_ABOUT_TO_START"){
                        dispatch({
                            type: AUCTION_ABOUT_TO_START,
                            payload: result
                        })
                    }
                    if(result.type === "AUCTION_STARTS"){
                        dispatch({
                            type: AUCTION_STARTS,
                            payload: result
                        })
                    }
                    if(result.type === "AUCTION_ABOUT_TO_END"){
                        dispatch({
                            type: AUCTION_ABOUT_TO_END,
                            payload: result
                        })
                    }
                    if(result.type === "AUCTION_ENDS"){
                        dispatch({
                            type: AUCTION_ENDS,
                            payload: result
                        })
                    }
                    if(result.type === "WINNING_AMOUNT_ADDED"){
                        dispatch({
                            type: WINNING_AMOUNT_ADDED,
                            payload: result
                        })
                    }
                    if(result.type === "SURPLUS_AMOUNT_ADDED"){
                        dispatch({
                            type: SURPLUS_AMOUNT_ADDED,
                            payload: result
                        })
                    }
                    if( result.type === "CONTACT_REQUEST"){
                        dispatch({
                            type: CONTACT_REQUEST,
                            payload: result
                        })
                    }
                    if( result.type === "FIRST_TIME_LOGIN"){
                        dispatch({
                            type: FIRST_TIME_LOGIN,
                            payload: result
                        })
                    }
                    if( result.type === "CONTACT_REMOVED"){
                        dispatch({
                            type: CONTACT_REMOVED,
                            payload: result
                        })
                    }
                    if( result.type === "PLAN_SCHEDULED"){
                        dispatch({
                            type: PLAN_SCHEDULED,
                            payload: result
                        })
                    }
                    if( result.type === "APPLIED_FOR_MCL"){
                        dispatch({
                            type: APPLIED_FOR_MCL,
                            payload: result
                        })
                    }
                    if( result.type === "PLAN_APPLICATION_SUBMITTED"){
                        dispatch({
                            type: PLAN_APPLICATION_SUBMITTED,
                            payload: result
                        })
                        planApplicationNotCount++
                       
                    }
                    if(!result.wn_read){
                        unreadNotifications++;
                    }
                    allNotifications++;
                })
                if(planApplicationNotCount > 0){
                    dispatch({
                        type: PLAN_APPLICATION_THERE,
                        payload: true
                    })
                } else {
                    dispatch({
                        type: PLAN_APPLICATION_THERE,
                        payload: false
                    })
                }
                dispatch({
                    type: TOTAL_NOTIFICATIONS,
                    payload: allNotifications
                })
                dispatch({
                    type: UNREAD_NOTIFICATIONS,
                    payload: unreadNotifications
                })
            }
        }
    })
    .catch((err)=>{
        console.log("unable to fetch")
    })
}

