import React, { useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { emailSubscription } from '../../redux/actions/userActions';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

function GetStarted(props) {
    const dispatch = useDispatch();
    const { onClose, open } = props;
    const emailRef = useRef()
    const [emailStatus, setEmailStatus] = useState("")
    const [headerTitle, setHeaderTitle] = useState("Provide Your Info")
  
    const handleClose = () => {
        onClose(false);
        setEmailStatus("")
        setHeaderTitle("Provide Your Info")
    };

    const handleSubscription = () => {
        dispatch(emailSubscription({
            email: emailRef.current.value
        })).then((res)=> {
            if(res.status === "sent"){
                setEmailStatus("sent")
                setHeaderTitle("Submitted Successfully!")
            } else {
                if(res.status.length > 0 && res.status[0]){
                    setEmailStatus(res.status[0])
                } else {
                    setEmailStatus("failed")
                }
            }
        })
    }

    return (
      <Dialog  PaperProps={{ style: { backgroundColor: '#414147'}}} onClose={handleClose} aria-labelledby="get-started" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {headerTitle}
        </DialogTitle>
        <Box pl={"15px"} pr={"15px"}>
            <Divider style={{backgroundColor: "#ffffff1a"}}/>
        </Box>

        {emailStatus !== "sent" && <div className="get-started-content">
            <div className="name-flex">
              <input type="text" placeholder="Enter First Name"></input>
              <input type="text" placeholder="Enter Last Name"></input>
            </div>
            <input ref={emailRef} type="email" placeholder="Enter Email Address"></input>
            {emailStatus && emailStatus !== "sent" && <div className="result">
                {emailStatus}
            </div>}
            <input style={{marginTop: "20px"}} type="text" placeholder="Enter Phone No: (Optional)"></input>
            <Button className="button" onClick={() => handleSubscription()}>Submit</Button>
        </div>}
        {emailStatus === "sent" && <div className="get-started-content">
            <div className="text">
                Thank you for showing your interest in joining wheez.
            </div>
            <div className="text">
                We’ll invite you when we go live.
            </div>
            <Button className="button" onClick={() => handleClose()}>Okay!</Button>
        </div>}
      </Dialog>
    );
  }

  export default GetStarted