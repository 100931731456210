import React, { useEffect, useState } from "react";
import { Container, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Pagination } from "swiper";
import { Filter, Filters } from "../../pages/landingPage";
import stepsIcon1 from "../../assets/images/landingPage/home/Group 1049.png";
import stepsIcon2 from "../../assets/images/landingPage/home/Group 1050.png";
import preImg from "./../../assets/images/landingPage/home/steps/Group 56.png";
import nextImg from "./../../assets/images/landingPage/home/steps/Group 57.png";
import stepFiveOne from "./../../assets/images/landingPage/home/steps/stepFiveOne.svg";
import stepFiveThree from "./../../assets/images/landingPage/home/steps/stepFiveThree.png";
import stepFiveTwo from "./../../assets/images/landingPage/home/steps/stepFiveTwo.png";
import stepFourOne from "./../../assets/images/landingPage/home/steps/stepFourOne.png";
import stepFourTwo from "./../../assets/images/landingPage/home/steps/stepFourTwo.png";
import arrow from "./../../assets/images/landingPage/home/steps/arrow.png";
import contibution from "./../../assets/images/landingPage/home/steps/contibution.png";
import linkBank from "./../../assets/images/landingPage/home/steps/linkBank.png";
import stepFourLineFull from "./../../assets/images/landingPage/home/steps/stepFourLineFull.svg";
import stepsLine from "./../../assets/images/landingPage/home/steps/stepsLine.svg";
import stepThreeOne from "./../../assets/images/landingPage/home/steps/stepThreeOne.png";
import stepThreeT from "./../../assets/images/landingPage/home/steps/stepThreeT.png";
import stepThreeTwo from "./../../assets/images/landingPage/home/steps/stepThreeTwo.png";
import "swiper/swiper.min.css";
import style from "../../styles/landingPage/steps.module.scss";
gsap.registerPlugin(ScrollTrigger);

const Steps = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    if (value < newValue) {
      gsap.utils.toArray(".slideOne").forEach((panel, i) => {
        gsap.fromTo(
          [panel],
          { x: 300 },
          {
            ease: "power1.out",
            duration: 1.15,
            x: 0,
          }
        );
      });
    } else {
      gsap.utils.toArray(".slideOne").forEach((panel, i) => {
        gsap.fromTo(
          [panel],
          { x: -300 },
          {
            ease: "power1.out",
            duration: 1.15,
            x: 0,
          }
        );
      });
    }
    setValue(newValue);
  };
  const matches = useMediaQuery("(max-width:1139px)");

  useEffect(() => {
    gsap.fromTo(
      ["#stepsOneId"],
      { x: -200, y: 200 },
      {
        ease: "power1.out",
        duration: 1.15,
        x: 0,
        // delay: 1,
        y: 0,
        // scrollTrigger: {
        //   trigger: "#panelOne",
        //   start: "center-=10 center+=200",
        // },
      }
    );
    gsap.fromTo(
      ["#stepsTwoId"],
      { x: 200, y: -200 },
      {
        ease: "power1.out",
        duration: 1.15,
        // delay: 1,
        x: 0,
        y: 0,
      }
    );

    gsap.fromTo(
      ["#stepsOneId"],
      { opacity: 0, x: -200, y: 200 },
      {
        opacity: 1,
        ease: "power1.out",
        duration: 1.15,
        x: 0,
        // delay: 1,
        y: 0,
        scrollTrigger: {
          trigger: "#panelOne",
          start: "center-=10 center+=200",
        },
      }
    );
    gsap.fromTo(
      ["#stepsTwoId"],
      { opacity: 0, x: 200, y: -200 },
      {
        opacity: 1,
        ease: "power1.out",
        duration: 1.15,
        x: 0,
        y: 0,
        scrollTrigger: {
          trigger: "#panelOne",
          start: "center-=10 center+=200",
        },
      }
    );
    gsap.fromTo(
      ["#stepsTwoId1"],
      { opacity: 0 },
      {
        ease: "power1.inOut",
        duration: 1.15,
        opacity: 1,
        delay: 0.03,
      }
    );
    gsap.fromTo(
      ["#stepsTwoId2"],
      { opacity: 0 },
      { ease: "power1.inOut", duration: 1.15, opacity: 1, delay: 0.5 }
    );
    gsap.fromTo(
      ["#stepsTwoId3"],
      { opacity: 0 },
      { ease: "power1.inOut", duration: 1.15, opacity: 1, delay: 0.8 }
    );
    gsap.fromTo(
      ["#stepsTwoId4"],
      { opacity: 0 },
      { ease: "power1.inOut", duration: 1.15, opacity: 1, delay: 1.2 }
    );
    gsap.fromTo(
      ["#stepsTwoId5"],
      { opacity: 0 },
      { ease: "power1.inOut", duration: 1.15, opacity: 1, delay: 1.6 }
    );
    gsap.fromTo(
      ["#stepsThreeId1", "#stepsFiveId2"],
      { opacity: 0, x: -200 },
      {
        opacity: 1,
        delay: 1,
        ease: "power1.out",
        duration: 1.15,
        x: 0,
      }
    );
    gsap.fromTo(
      ["#stepsThreeId2", "#stepsFiveId1"],
      { opacity: 0, x: 200 },
      {
        opacity: 1,
        delay: 1,
        ease: "power1.out",
        duration: 1.15,
        x: 0,
      }
    );
    gsap.fromTo(
      ["#stepsFourId1"],
      { opacity: 0.6, y: -200, x: -79 },
      {
        opacity: 1,
        ease: "power1.out",
        delay: 1,
        duration: 2,
        y: 0,
        x: 0,
      }
    );
    gsap.fromTo(
      ["#stepsFourId2"],
      { opacity: 0.6, y: 150, x: 0 },
      {
        opacity: 1,
        delay: 1,
        ease: "power1.out",
        duration: 2,
        y: 0,
        x: 0,
      }
    );
    gsap.fromTo(
      ["#stepsFourId2", "#stepsFourId3"],
      { opacity: 0 },
      {
        opacity: 1,
        ease: "power1.out",
        duration: 2,
      }
    );
    // { x: -200, y: 200 },
    // {
    //   ease: "power1.out",
    //   duration: 1.15,
    //   x: 0,
    //   y: 0,
    // }
  }, [value]);
  return (
    <div className={style["steps-main"]} id={"how-it-work"}>
      <Container
        sx={{
          maxWidth: {
            sm: "xl",
            md: "xl",
            lg: "lg",
            xl: "xl",
          },
        }}
        className={`${style["container-steps"]}`}>
        <Typography
          variant="h4"
          className={style["main-h4"]}
        >
          Here is a quick
          <Box component="span" color="#fff" paddingLeft={"8px"}>
            5-step guide
          </Box>{" "}
          on how it works
        </Typography>
       
        {!matches ? (
         <Grid
         container
         direction="row"
         padding={"30px 0px"}
         >
         <>
           <Grid
             order={{ xs: 2, md: 2, lg: 1, xl: 1 }}
             item
             xs={12}
             lg={6}
             md={12}
             sm={12}>
             <Tabs
               orientation="vertical"
               value={value}
               onChange={handleChange}
               TabIndicatorProps={{
                 style: {
                   height:"0px",
                 }
               }}
               sx={{
                 paddingY: "77px",
               }}>
               <Tab
                 label={`${
                   value == 0
                     ? `Sign-up and finish your KYC process and get it started in no time`
                     : "Step 1"
                 }`}
                 style={{
                   width: value == 0 ? "360px" : "50px",
                   borderRadius: '8px',
                   marginBottom: value == 0 ? '20px' : "10px",
                   fontWeight: value == 0 ? "500" : "700",
                   fontSize: value == 0 ? "12px" : "18px",
                   background: value == 0 ? "#414147" : "#262626",
                   lineHeight: '16px',
                   color: 'white',
                   boxShadow: value == 0 ? '5px 5px 0px #6D61C2' : ''
                 }}
                 {...a11yProps(0)}
               />
               <Tab
                 label={`${
                   value == 1
                     ? "Finish account set-up by linking contacts and bank accounts and get an MCL (Monthly Contribution Limit)"
                     : "Step 2"
                 }`}
                 style={{
                   width: value == 1 ? "360px" : "50px",
                   borderRadius: '8px',
                   marginBottom: value == 1 ? '20px' : "10px",
                   fontWeight: value == 1 ? "500" : "700",
                   fontSize: value == 1 ? "12px" : "18px",
                   background: value == 1 ? "#414147" : "#262626",
                   lineHeight: '16px',
                   color: 'white',
                   boxShadow: value == 1 ? '5px 5px 0px #6D61C2' : ''
                 }}
                 {...a11yProps(1)}
               />
               <Tab
                 label={`${
                   value == 2
                     ? "Apply for a plan by choosing your convenient amount, tenure, and goals."
                     : "Step 3"
                 }`}
                 style={{
                   width: value == 2 ? "360px" : "50px",
                   borderRadius: '8px',
                   marginBottom: value == 2 ? '20px' : "10px",
                   fontWeight: value == 2 ? "500" : "700",
                   fontSize: value == 2 ? "12px" : "18px",
                   lineHeight: '16px',
                   background: value == 2 ? "#414147" : "#262626",
                   color: 'white',
                   boxShadow: value == 2 ? '5px 5px 0px #6D61C2' : ''
                 }}
                 {...a11yProps(2)}
               />
               <Tab
                 label={`${
                   value == 3
                     ? "Get personalized recommendations of the most suited plans from Wheez."
                     : "Step 4"
                 }`}
                 style={{
                   width: value == 3 ? "360px" : "50px",
                   borderRadius: '8px',
                   marginBottom: value == 3 ? '20px' : "10px",
                   fontWeight: value == 3 ? "500" : "700",
                   fontSize: value == 3 ? "12px" : "18px",
                   background: value == 3 ? "#414147" : "#262626",
                   lineHeight: '16px',
                   color: 'white',
                   boxShadow: value == 3 ? '5px 5px 0px #6D61C2' : ''
                 }}
                 {...a11yProps(3)}
               />
               <Tab
                 label={`${
                   value == 4
                     ? "Join in your most liked plans from the recommendations and use them based on your needs. "
                     : "Step 5"
                 }`}
                 style={{
                   width: value == 4 ? "360px" : "50px",
                   borderRadius: '8px',
                   marginBottom: value == 4 ? '20px' : "10px",
                   fontWeight: value == 4 ? "500" : "700",
                   fontSize: value == 4 ? "12px" : "18px",
                   background: value == 4 ? "#414147" : "#262626",
                   lineHeight: '16px',
                   color: 'white',
                   boxShadow: value == 4 ? '5px 5px 0px #6D61C2' : ''
                 }}
                 {...a11yProps(4)}
               />
             </Tabs>
             <Box marginTop={"20px"} display={"flex"}>
               <Box
                 marginRight={"10px"}
                 style={{cursor: 'pointer'}}
                 onClick={() => {
                  
         
                   if (value > 0) {
                       setValue(value - 1);
                       gsap.utils.toArray(".slideOne").forEach((panel, i) => {
                         gsap.fromTo(
                           [panel],
                           { x: -300 },
                           {
                             ease: "power1.out",
                             duration: 1.15,
                             x: 0,
                           }
                         );
                       });
                   }
                 }}>
                 <img width='80px' src={preImg} />
               </Box>
               <Box
                 style={{cursor: 'pointer'}}
                 onClick={() => {
                   if (value < 4) {
                     setValue(value + 1);
                     gsap.utils.toArray(".slideOne").forEach((panel, i) => {
                       gsap.fromTo(
                         [panel],
                         { x: 300 },
                         {
                           ease: "power1.out",
                           duration: 1.15,
                           x: 0,
                         }
                       );
                     });
                   }
                  
                 }}>
                 <img width='80px' src={nextImg} />
               </Box>
             </Box>
           </Grid>
           <Grid
              order={{ xs: 1, md: 1, lg: 2, xl: 2 }}
              item
              xs={12}
              lg={6}
              md={12}
              sm={12}
              style={{position: 'relative'}}
              // textAlign={"center"}
              >
              <Box
                position={"absolute"}
                left={"-176px"}
                bottom={"247px"}
              >
                <Filter />
              </Box>
              <Box
                position={"absolute"}
                top={"-129px"}
                right="0px">
                <Filters />
              </Box>
              <Box className={"slideOne"}>
                <TabPanel
                  value={value}
                  index={0}
                  id={"panelOne"}
                  position={"relative"}>
                  <Box position={"relative"} zIndex="2" id={"panelOne"}>
                    <Box
                      className={style["box-content"]}
                    >
                      <Typography
                        variant="h5"
                        className={style["content-h5"]}>
                        Steps To Create Your Account
                      </Typography>
                      <Box padding={"30px 0px 15px 0"}>
                        <Box
                          display={"flex"}
                          alignItems="center"
                        >
                          <Box
                            padding={"3px 12px"}
                            border={"1px solid #fff"}
                            borderRadius="8px"
                            className={style["tabs-one-radius"]}>
                            1
                          </Box>
                          <Box marginLeft={"20px"}>
                            Enter Your Personal Info
                          </Box>
                        </Box>
                        <Box
                          height={"30px"}
                          width="2px"
                          marginLeft={"16px"}
                          bgcolor={"#ccc"}
                          marginY={"0px"}
                          style={{ opacity: "0.3" }}
                        ></Box>
                        <Box
                          display={"flex"}
                          alignItems="center"
                        >
                          <Box
                            padding={"3px 10px"}
                            border={"1px solid #fff"}
                            borderRadius="8px"
                            className={style["tabs-one-radius"]}>
                            2
                          </Box>
                          <Box marginLeft={"20px"}>
                            Give Your Contact Info
                          </Box>
                        </Box>
                        <Box
                          height={"30px"}
                          width="2px"
                          marginLeft={"16px"}
                          bgcolor={"#ccc"}
                          marginY={"0px"}
                          style={{ opacity: "0.3" }}
                        ></Box>
                        <Box
                          display={"flex"}
                          alignItems="center"
                        >
                          <Box
                          padding={"3px 10px"}
                            border={"1px solid #fff"}
                            borderRadius="8px"
                            className={style["tabs-one-radius"]}>
                            3
                          </Box>
                          <Box marginLeft={"20px"}>
                            Go Through Verification
                          </Box>
                        </Box>
                        <Box
                          height={"30px"}
                          width="2px"
                          marginLeft={"16px"}
                          bgcolor={"#ccc"}
                          marginY={"0px"}
                          style={{ opacity: "0.3" }}
                        ></Box>
                        <Box
                          display={"flex"}
                          alignItems="center"
                        >
                          <Box
                            padding={"3px 10px"}
                            border={"1px solid #fff"}
                            borderRadius="8px"
                            className={style["tabs-one-radius"]}>
                            4
                          </Box>
                          <Box marginLeft={"20px"}>
                            Connect Your Bank Account
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        position={"absolute"}
                        zIndex="1"
                        top={"-20px"}
                        right={"-40px"}
                        id={"stepsOneId"}>
                        <img width='60%' src={stepsIcon2} />
                      </Box>
                      <Box
                        position={"absolute"}
                        zIndex="1"
                        bottom={"-30px"}
                        left={"-20px"}
                        id={"stepsTwoId"}>
                        <img width='60%' src={stepsIcon1} />
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>
              </Box>
              <Box className={"slideOne"}>
                <TabPanel position={"relative"} value={value} index={1}>
                  <Box position={"relative"} zIndex="2">
                    <Box
                      display="inline-block"
                      borderRadius="30px"
                      position={"relative"}
                      zIndex={"5"}>
                      <Box id={"stepsTwoId1"}>
                        <img width='80%' src={linkBank} />
                      </Box>
                      <Box
                        position={"absolute"}
                        id={"stepsTwoId2"}
                        top={"82px"}
                        left={"-27px"}>
                        <img width='88%' src={stepsLine}  />
                      </Box>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent="center"
                        bgcolor={"#fff"}
                        color="#6D61C2"
                        borderRadius="10px"
                        width='80%'
                        padding="10px 20px"
                        fontWeight={600}
                        marginTop={"32px"}
                        id={"stepsTwoId3"}>
                        <Box>Connect</Box>
                      </Box>
                      <Box
                        id={"stepsTwoId4"}
                        marginY={"0px"}
                        marginLeft="-72px"
                        textAlign={"center"}>
                        <img width='15px' src={arrow} />
                      </Box>
                      <Box id={"stepsTwoId5"}>
                        <img width='80%' src={contibution} />
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>
              </Box>
              <Box className={"slideOne"}>
                <TabPanel position={"relative"} value={value} index={2}>
                  <Box
                    position={"relative"}
                    zIndex="2"
                  >
                    <Box
                      display="inline-block"
                      borderRadius="30px"
                      position={"relative"}
                      zIndex={"5"}>
                      <Box>
                        <img width='70%' src={stepThreeOne} />
                      </Box>
                      <Box
                        position={"absolute"}
                        id={"stepsThreeId1"}
                        bottom={"-29px"}
                        left={"-60px"}>
                        <img width='70%' src={stepThreeTwo} />
                      </Box>
                      <Box
                        position={"absolute"}
                        top={"51px"}
                        id={"stepsThreeId2"}
                        right={"-34px"}>
                        <img width='70%' src={stepThreeT} />
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>
              </Box>
              <Box className={"slideOne"}>
                <TabPanel value={value} index={3} position={"relative"}>
                  <Box position={"relative"} zIndex={2}>
                    <Box
                      display="inline-block"
                      borderRadius="30px"
                      position={"relative"}
                      zIndex={"5"}>
                      <Box id={"stepsFourId2"}>
                        <img width='50%' src={stepFourOne} />
                      </Box>
                      <Box
                        position={"absolute"}
                        top={"5px"}
                        left={"16px"}
                        zIndex={-1}>
                        <img width='60%' src={stepFourLineFull} />
                      </Box>

                      <Box marginLeft={"70px"} id={"stepsFourId1"}>
                        <img width='60%' src={stepFourTwo} />
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>
              </Box>
              <Box className={"slideOne"}>
                <TabPanel value={value} index={4} position={"relative"}>
                  <Box
                    position={"relative"}
                    zIndex="2"
                  >
                    <Box
                      display="inline-block"
                      padding="30px"
                      borderRadius="30px"
                      position={"relative"}
                      zIndex={"5"}>
                      <Box>
                        <img width='70%' src={stepFiveOne} />
                      </Box>
                      <Box
                        position={"absolute"}
                        id={"stepsFiveId1"}
                        top={"45px"}
                        right={"0px"}>
                        <img width='70%' src={stepFiveThree} />
                      </Box>
                      <Box
                        position={"absolute"}
                        bottom={"50px"}
                        id={"stepsFiveId2"}
                        left={"-30px"}>
                        <img width='70%' src={stepFiveTwo} />
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>
              </Box>
            </Grid>
         </>
         </Grid>
        ) : (
          <Swiper
            pagination={{
              clickable: true,
            }}
            slidesPerView={1}
            spaceBetween={10}
            onSlideChange={(e) => {
              if (e.activeIndex == 1) {
                gsap.fromTo(
                  ["#stepsTwoId"],
                  { opacity: 0, x: 200, y: -200 },
                  {
                    opacity: 1,
                    ease: "power1.out",
                    duration: 1.15,
                    x: 0,
                    y: 0,
                    scrollTrigger: {
                      trigger: "#panelOne",
                      start: "center-=10 center+=200",
                    },
                  }
                );
                gsap.fromTo(
                  ["#stepsTwoId1"],
                  { opacity: 0 },
                  {
                    ease: "power1.inOut",
                    duration: 1.15,
                    opacity: 1,
                    delay: 0.03,
                  }
                );
                gsap.fromTo(
                  ["#stepsTwoId2"],
                  { opacity: 0 },
                  {
                    ease: "power1.inOut",
                    duration: 1.15,
                    opacity: 1,
                    delay: 0.5,
                  }
                );
                gsap.fromTo(
                  ["#stepsTwoId3"],
                  { opacity: 0 },
                  {
                    ease: "power1.inOut",
                    duration: 1.15,
                    opacity: 1,
                    delay: 0.8,
                  }
                );
                gsap.fromTo(
                  ["#stepsTwoId4"],
                  { opacity: 0 },
                  {
                    ease: "power1.inOut",
                    duration: 1.15,
                    opacity: 1,
                    delay: 1.2,
                  }
                );
                gsap.fromTo(
                  ["#stepsTwoId5"],
                  { opacity: 0 },
                  {
                    ease: "power1.inOut",
                    duration: 1.15,
                    opacity: 1,
                    delay: 1.6,
                  }
                );
              } else if (e.activeIndex == 2) {
                gsap.fromTo(
                  ["#stepsThreeId1", "#stepsFiveId2"],
                  { opacity: 0, x: -200 },
                  {
                    opacity: 1,
                    delay: 1,
                    ease: "power1.out",
                    duration: 1.15,
                    x: 0,
                  }
                );
                gsap.fromTo(
                  ["#stepsThreeId2", "#stepsFiveId1"],
                  { opacity: 0, x: 200 },
                  {
                    opacity: 1,
                    delay: 1,
                    ease: "power1.out",
                    duration: 1.15,
                    x: 0,
                  }
                );
              } else if (e.activeIndex == 3) {
                gsap.fromTo(
                  ["#stepsFourId1"],
                  { opacity: 0.6, y: -200, x: -79 },
                  {
                    opacity: 1,
                    ease: "power1.out",
                    delay: 1,
                    duration: 2,
                    y: 0,
                    x: 0,
                  }
                );
                gsap.fromTo(
                  ["#stepsFourId2"],
                  { opacity: 0.6, y: 150, x: 0 },
                  {
                    opacity: 1,
                    delay: 1,
                    ease: "power1.out",
                    duration: 2,
                    y: 0,
                    x: 0,
                  }
                );
                gsap.fromTo(
                  ["#stepsFourId2", "#stepsFourId3"],
                  { opacity: 0 },
                  {
                    opacity: 1,
                    ease: "power1.out",
                    duration: 2,
                  }
                );
              } else if (e.activeIndex == 0) {
                gsap.fromTo(
                  ["#stepsOneId"],
                  { x: -200, y: 200 },
                  {
                    ease: "power1.out",
                    duration: 1.15,
                    x: 0,
                    // delay: 1,
                    y: 0,
                  }
                );
                gsap.fromTo(
                  ["#stepsTwoId"],
                  { x: 200, y: -200 },
                  {
                    ease: "power1.out",
                    duration: 1.15,
                    // delay: 1,
                    x: 0,
                    y: 0,
                  }
                );
                gsap.fromTo(
                  ["#stepsOneId"],
                  { opacity: 0, x: -200, y: 200 },
                  {
                    opacity: 1,
                    ease: "power1.out",
                    duration: 1.15,
                    x: 0,
                    // delay: 1,
                    y: 0,
                    scrollTrigger: {
                      trigger: "#panelOne",
                      start: "center-=10 center+=200",
                    },
                  }
                );
              } else if (e.activeIndex == 4) {
                gsap.fromTo(
                  ["#stepsThreeId1", "#stepsFiveId2"],
                  { opacity: 0, x: -200 },
                  {
                    opacity: 1,
                    delay: 1,
                    ease: "power1.out",
                    duration: 1.15,
                    x: 0,
                  }
                );
                gsap.fromTo(
                  ["#stepsThreeId2", "#stepsFiveId1"],
                  { opacity: 0, x: 200 },
                  {
                    opacity: 1,
                    delay: 1,
                    ease: "power1.out",
                    duration: 1.15,
                    x: 0,
                  }
                );
              }
            }}
            modules={[Pagination]}
            className={`swiper-my mySwiper `}
            style={{ alignItems: "center", paddingY: "50px" }}>
            <SwiperSlide>
              <Box
                justifyContent={"center"}
                marginTop="0px"
                display="flex"
                className={style["steps_one_main"]}>
                <Box position={"relative"} zIndex="2" id={"panelOne"}>
                  <Box
                    bgcolor={"#383838"}
                    display="inline-block"
                    padding="30px 25px"
                    borderRadius="20px"
                    position={"relative"}
                    color={"white"}
                  >
                    <Typography variant="h5" style={{fontSize: '20px', fontWeight: '600'}} fontWeight={"600"}>
                      Steps To Create Your Account
                    </Typography>
                    <Box padding={"30px 0px 15px 0"}>
                      <Box
                        display={"flex"}
                        alignItems="center"
                      >
                        <Box
                          padding={"3px 12px"}
                          border={"1px solid #fff"}
                          borderRadius="8px"
                          className={style["tabs-one-radius"]}>
                          1
                        </Box>
                        <Box marginLeft={"20px"}>Enter Your Personal Info</Box>
                      </Box>
                      <Box
                        height={"30px"}
                        width="2px"
                        marginLeft={"16px"}
                        bgcolor={"#ccc"}
                        marginY={"0px"}
                        style={{ opacity: "0.3" }}
                      ></Box>
                      <Box
                        display={"flex"}
                        alignItems="center"
                      >
                        <Box
                          padding={"3px 10px"}
                          border={"1px solid #fff"}
                          borderRadius="8px"
                          // bgcolor={"#6055AD"}
                          className={style["tabs-one-radius"]}>
                          2
                        </Box>
                        <Box marginLeft={"20px"}>Give Your Contact Info</Box>
                      </Box>
                      <Box
                        height={"30px"}
                        width="2px"
                        marginLeft={"16px"}
                        bgcolor={"#ccc"}
                        marginY={"0px"}
                        style={{ opacity: "0.3" }}

                        // o="0.3"
                      ></Box>
                      <Box
                        display={"flex"}
                        alignItems="center"
                        // justifyContent={"space-evenly"}
                      >
                        <Box
                          padding={"3px 10px"}
                          border={"1px solid #fff"}
                          borderRadius="8px"
                          className={style["tabs-one-radius"]}>
                          3
                        </Box>
                        <Box marginLeft={"20px"}>Go Through Verification</Box>
                      </Box>
                      <Box
                        height={"30px"}
                        width="2px"
                        marginLeft={"16px"}
                        bgcolor={"#ccc"}
                        marginY={"0px"}
                        style={{ opacity: "0.3" }}
                      ></Box>
                      <Box
                        display={"flex"}
                        alignItems="center"
                      >
                        <Box
                          padding={"3px 10px"}
                          border={"1px solid #fff"}
                          borderRadius="8px"
                          className={style["tabs-one-radius"]}>
                          4
                        </Box>
                        <Box marginLeft={"20px"}>Connect Your Bank Account</Box>
                      </Box>
                    </Box>
                    <Box
                      position={"absolute"}
                      zIndex="1"
                      top={"-20px"}
                      right={"-40px"}
                      id={"stepsOneId"}>
                      <img width='60%' src={stepsIcon2} />
                    </Box>
                    <Box
                      position={"absolute"}
                      zIndex="1"
                      bottom={"-30px"}
                      left={"-20px"}
                      id={"stepsTwoId"}>
                      <img width='60%' src={stepsIcon1} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box justifyContent={"center"} marginTop="0px" display="flex">
                <Tabs
                  orientation="vertical"
                  TabIndicatorProps={{
                    style: {
                      height:"0px",
                    }
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  style={{overflow: 'visible'}}
                  sx={{ borderRight: 1, borderColor: "divider"}}>
                  <Tab
                    label={`
                        Sign-up and finish your KYC process and get it started in no time.
                    `}
                    style={{
                      width: "360px",
                      background: "#262626",
                      borderRadius: '8px',
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: '16px',
                      color: 'white',
                    }}
                    {...a11yProps(0)}
                  />
                </Tabs>
              </Box>
            </SwiperSlide>
            <SwiperSlide style={{ paddingBottom: "50px" }}>
              <Box justifyContent={"center"} display="flex">
                <Box position={"relative"} zIndex="2">
                  <Box
                    display="inline-block"
                    padding="0 30px"
                    borderRadius="30px"
                    position={"relative"}
                    zIndex={"5"}>
                    <Box id={"stepsTwoId1"} className={style["link_div"]}>
                      <img src={linkBank} className={style["link_bank"]} />
                    </Box>
                    <Box
                      position={"absolute"}
                      id={"stepsTwoId2"}
                      top={"102px"}
                      left={"-7px"}
                      className={style["steps_two_line_div"]}>
                      <img
                        src={stepsLine}
                        width={"454px"}
                        className={style["steps_two_line"]}
                      />
                    </Box>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent="center"
                      bgcolor={"#fff"}
                      color="#6D61C2"
                      borderRadius="10px"
                      width={"340px"}
                      padding="10px 20px"
                      marginTop={"60px"}
                      id={"stepsTwoId3"}
                      className={style["stpes_two_connect"]}>
                      <Box>Connect</Box>
                    </Box>
                    <Box
                      width={"370px"}
                      id={"stepsTwoId4"}
                      marginY={"3px"}
                      textAlign={"center"}>
                      <img src={arrow} />
                    </Box>
                    <Box id={"stepsTwoId5"} className={style["link_div"]}>
                      <img src={contibution} className={style["link_bank"]} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box justifyContent={"center"} marginTop="10px" display="flex">
                <Tabs
                  orientation="vertical"
                  TabIndicatorProps={{
                    style: {
                      height:"0px",
                    }
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: "divider" }}>
                  <Tab
                    label={`
                        Finish account set-up by linking contacts and bank accounts and get an MCL (Monthly Contribution Limit).
                    `}
                    style={{
                      width: "360px",
                      background: "#262626",
                      borderRadius: '8px',
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: '16px',
                      color: 'white',
                    }}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box justifyContent={"center"} display="flex" marginTop='0px' >
                <Box
                  position={"relative"}
                  zIndex="2"
                >
                  <Box
                    display="inline-block"
                    padding="30px"
                    borderRadius="30px"
                    position={"relative"}
                    zIndex={"5"}
                    className={style["step_three_div"]}>
                    <Box>
                      <img
                        src={stepThreeOne}
                        className={style["step_three_one"]}
                      />
                    </Box>
                    <Box
                      position={"absolute"}
                      id={"stepsThreeId1"}
                      bottom={"-29px"}
                      left={"-76px"}
                      className={style["step_three_two_div"]}>
                      <img
                        src={stepThreeTwo}
                        className={style["step_three_two"]}
                      />
                    </Box>
                    <Box
                      position={"absolute"}
                      top={"91px"}
                      id={"stepsThreeId2"}
                      right={"-74px"}
                      className={style["step_three_one_div"]}>
                      <img src={stepThreeT} className={style["step_three_T"]} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box justifyContent={"center"} marginTop="10px" display="flex">
                <Tabs
                  orientation="vertical"
                  value={value}
                  TabIndicatorProps={{
                    style: {
                      height:"0px",
                    }
                  }}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: "divider" }}>
                  <Tab
                    label={`Apply for a plan by choosing your convenient amount, tenure, and goals.`}
                    style={{
                      width: "360px",
                      background: "#262626",
                      borderRadius: '8px',
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: '16px',
                      color: 'white',
                    }}
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box justifyContent={"center"} display="flex" marginTop='0px'>
                <Box position={"relative"} textAlign={"center"} zIndex={2}>
                  <Box
                    display="inline-block"
                    padding="30px"
                    borderRadius="30px"
                    position={"relative"}
                    zIndex={"5"}>
                    <Box width={"100%"} id={"stepsFourId2"}>
                      <img width={"206px"} src={stepFourOne} />
                    </Box>
                    {/* <Box
                      position={"absolute"}
                      top={"27px"}
                      left={"56px"}
                      zIndex={-1}>
                      <img width={"343px"} src={stepFourLineFull} />
                    </Box> */}

                    <Box marginLeft={"0"} id={"stepsFourId1"}>
                      <img width={"206px"} src={stepFourTwo} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box justifyContent={"center"} marginTop="10px" display="flex">
                <Tabs
                  orientation="vertical"
                  TabIndicatorProps={{
                    style: {
                      height:"0px",
                    }
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: "divider" }}>
                  <Tab
                    label={`Get personalized recommendations of the most suited plans from Wheez.
                    `}
                    style={{
                      width: "360px",
                      background: "#262626",
                      borderRadius: '8px',
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: '16px',
                      color: 'white',
                    }}
                    {...a11yProps(3)}
                  />
                </Tabs>
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box justifyContent={"center"} marginTop='0px' display="flex">
                <Box
                  position={"relative"}
                  zIndex="2"
                >
                  <Box
                    display="inline-block"
                    padding="30px"
                    borderRadius="30px"
                    position={"relative"}
                    zIndex={"5"}>
                    <Box className={`${style["setep_five_div"]}`}>
                      <img
                        src={stepFiveOne}
                        className={`${style["step_five_one"]}`}
                      />
                    </Box>
                    <Box
                      position={"absolute"}
                      id={"stepsFiveId1"}
                      top={"45px"}
                      right={"-90px"}
                      className={`${style["step_five_three_div"]}`}>
                      <img
                        src={stepFiveThree}
                        className={`${style["step_five_three"]}`}
                      />
                    </Box>
                    <Box
                      position={"absolute"}
                      bottom={"60px"}
                      id={"stepsFiveId2"}
                      left={"-60px"}
                      className={`${style["step_five_two_div"]}`}>
                      <img
                        src={stepFiveTwo}
                        className={`${style["step_five_two"]}`}
                      />
                    </Box>
                  </Box>
                </Box>
                {/* </TabPanel> */}
              </Box>
              <Box justifyContent={"center"} marginTop="10px" display="flex">
                <Tabs
                  orientation="vertical"
                  TabIndicatorProps={{
                    style: {
                      height:"0px",
                    }
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: "divider" }}>
                  <Tab
                    label={`Join in your most liked plans from the recommendations and use them based on your needs.`}
                        style={{
                          width: "360px",
                          background: "#262626",
                          borderRadius: '8px',
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: '16px',
                          color: 'white',
                        }}
                    {...a11yProps(4)}
                  />
                </Tabs>
              </Box>
            </SwiperSlide>
          </Swiper>
        )}
      </Container>
    </div>
  );
};

export default Steps;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div style={{color: 'white'}}>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
