import {
  PLAN_RECOMMENDATION,
  AUCTION_STARTS,
  DEPOSIT_UNSUCCESSFUL,
  CONTACT_JOINS_PLAN,
  PERSON_JOINS_PLAN,
  AUCTION_ABOUT_TO_START,
  AUCTION_ABOUT_TO_END,
  AUCTION_ENDS,
  DEPOSIT_SUCCESSFUL,
  WINNING_AMOUNT_ADDED,
  SURPLUS_AMOUNT_ADDED,
  DELETE_NOTIFICATION,
  TOTAL_NOTIFICATIONS,
  CONTACT_REQUEST,
  CLEAR_ALL_NOTIFICATIONS,
  UNREAD_NOTIFICATIONS,
  FIRST_TIME_LOGIN,
  CONTACT_REMOVED,
  PLAN_SCHEDULED,
  PLAN_APPLICATION_SUBMITTED,
  PLAN_APPLICATION_THERE,
  APPLIED_FOR_MCL
} from '../types';
  
const initialState = {
  notificationList: [],
  totalNotifications: 0,
  unreadNotifications: 0,
  planApplicationThere: false
};
function compare( a, b ) {
    if ( a.created_at > b.created_at ){
      return -1;
    }
    if ( a.created_at < b.created_at ){
      return 1;
    }
    return 0;
}
  export default function notificationReducer(state = initialState, action) {
    switch (action.type) {
        case PLAN_RECOMMENDATION:
          let sortedList1 = [...state.notificationList, action.payload].sort(compare);
          return {
            ...state,
            notificationList: sortedList1,
          }
        case CONTACT_REQUEST:
          let sortedList2 = [...state.notificationList, action.payload].sort(compare);
          return {
            ...state,
            notificationList: sortedList2,
          }
        case PLAN_APPLICATION_THERE:
          return {
            ...state,
            planApplicationThere: action.payload
          }
        case AUCTION_ABOUT_TO_START:
          let sortedList3 = [...state.notificationList, action.payload].sort(compare);
          return {
            ...state,
            notificationList: sortedList3,
          }
        case AUCTION_STARTS:
          let sortedList4 = [...state.notificationList, action.payload].sort(compare);
          return {
            ...state,
            notificationList: sortedList4,
          }
        case AUCTION_ABOUT_TO_END:
          let sortedList5 = [...state.notificationList, action.payload].sort(compare);
          return {
            ...state,
            notificationList: sortedList5,
          }
        case AUCTION_ENDS:
          let sortedList6 = [...state.notificationList, action.payload].sort(compare);
          return {
            ...state,
            notificationList: sortedList6,
          }
        case DEPOSIT_SUCCESSFUL:
          let sortedList7 = [...state.notificationList, action.payload].sort(compare);
          return {
            ...state,
            notificationList: sortedList7,
          }
        case DEPOSIT_UNSUCCESSFUL:
          let sortedList8 = [...state.notificationList, action.payload].sort(compare);
          return {
            ...state,
            notificationList: sortedList8,
          }
        case PERSON_JOINS_PLAN:
          let sortedList9 = [...state.notificationList, action.payload].sort(compare);
          return {
            ...state,
            notificationList: sortedList9,
          }
        case CONTACT_JOINS_PLAN:
          let sortedList10 = [...state.notificationList, action.payload].sort(compare);
          return {
            ...state,
            notificationList: sortedList10,
          }
        case WINNING_AMOUNT_ADDED:
          let sortedList11 = [...state.notificationList, action.payload].sort(compare);
          return {
            ...state,
            notificationList: sortedList11,
          }
        case SURPLUS_AMOUNT_ADDED: 
          let sortedList12 = [...state.notificationList, action.payload].sort(compare);
          return {
            ...state,
            notificationList: sortedList12,
          }
        case FIRST_TIME_LOGIN:
          let sortedList13 = [...state.notificationList, action.payload].sort(compare);
          return {
            ...state,
            notificationList: sortedList13,
          }
        case CONTACT_REMOVED:
          let sortedList14 = [...state.notificationList, action.payload].sort(compare);
          return {
            ...state,
            notificationList: sortedList14,
          }
        case PLAN_SCHEDULED:
          let sortedList15 = [...state.notificationList, action.payload].sort(compare);
          return {
            ...state,
            notificationList: sortedList15,
          }
        case APPLIED_FOR_MCL:
          let sortedList16 = [...state.notificationList, action.payload].sort(compare);
          return {
            ...state,
            notificationList: sortedList16,
          }
        case PLAN_APPLICATION_SUBMITTED:
          let sortedList17 = [...state.notificationList, action.payload].sort(compare);
          return {
            ...state,
            notificationList: sortedList17,
          }
        case TOTAL_NOTIFICATIONS: 
          return{
            ...state,
            totalNotifications: action.payload
          }
        case UNREAD_NOTIFICATIONS: 
          return{
            ...state,
            unreadNotifications: action.payload
          }
        case CLEAR_ALL_NOTIFICATIONS:
          return initialState

        case DELETE_NOTIFICATION:
          let filteredNotification;
          filteredNotification = state.notificationList.filter((notification) => {
            return !(notification.id === action.payload.id)
          })
          return {
            ...state,
            notificationList: filteredNotification,
            totalNotifications: state.totalNotifications > 1 ? state.totalNotifications - 1 : 0,
            unreadNotifications: !action.payload.readStatus ? state.unreadNotifications - 1 : state.unreadNotifications
          }
          
        default:
            return state;
    }
  }