import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useTour } from '@reactour/tour'
import { Swiper, SwiperSlide } from 'swiper/react';
import TimerBidding from './TimerBidding';
import BidMessage from './BidMessage';
import { placeBidEnd, placeABid, triggerScrollEnd, triggerScroll } from '../../redux/actions/biddingActions';
import dropWhite from './../../assets/images/icons/dropIcon.svg';
import backIcon from './../../assets/images/icons/backIcon.svg';
import divider from './../../assets/images/icons/divider.svg';
import ellipsisOutlineBox from './../../assets/images/icons/ellipsisOutlineBox.svg';
import 'swiper/swiper.scss';

const dummyBidLog = [
    {
        "id": 4,
        "timestamp": 1631797465458,
        "bidAmount": 9500,
        "initialValue": 10000,
        "deductedValue": 500,
        "name": "Cathy"
    },
    {
        "id": 1,
        "timestamp": 1631797514000,
        "bidAmount": 9400,
        "initialValue": 9500,
        "deductedValue": 100,
        "name": "Vicky"
    },
    {
        "id": 3,
        "timestamp": 1631797574000,
        "bidAmount": 9200,
        "initialValue": 9400,
        "deductedValue": 200,
        "name": "Rahul"
    },
    {
        "id": 2,
        "timestamp": 1631797674000,
        "bidAmount": 8700,
        "initialValue": 9200,
        "deductedValue": 500,
        "name": "John"
    }
]

const dummyBiddingData = {
    userId : 1
}
function BiddingView(props) {
    const dispatch = useDispatch();
    const biddingData = useSelector((state) => state.practiceBidding);
    const messageContentRef = useRef();
    const { isOpen, setIsOpen, currentStep, setCurrentStep } = useTour()

    const [isExpandedOpen, setIsExpandedOpen] = useState(false);
    const [isListOpenTask, setIsListOpenTask] = useState(false);
    const [taskId, setTaskId] = useState(0);
    const [forfeitMembersCount, setForfeitMembersCount] = useState(0);
    
    useEffect(() => {
        if(isExpandedOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
                return () => {
                    document.removeEventListener('mousedown', handleClickOutside);
                }
        }
    });
    useEffect(() => {
        if(biddingData.scrollTrigger) {
            messageContentRef.current.scrollTop = messageContentRef.current.scrollHeight;
        } 
        return () => {
            dispatch(triggerScrollEnd());
        }
    }, [dispatch, biddingData.scrollTrigger]);
    useEffect(() => {
        if(biddingData.bidLog.length > 0){
            biddingData.bidLog.map((bid) => {
                if(bid.type === "self_forfeit"){
                    setForfeitMembersCount(1);
                }
                return null;
            })
        }
    }, [biddingData.bidLog]);
    useEffect(() => {
        if(props.guideStep === 10 && props.isGuide){
            setIsExpandedOpen(true);
        }
        if(props.guideStep === 0 && props.isGuide){
            setIsExpandedOpen(false);
        }
    }, [props.guideStep, props.isGuide])

    useEffect(() => {
        if(biddingData.biddingStarted && biddingData.currentBidUser) {
            var interval = setInterval(() => {
                if(biddingData.biddingStarted){
                    let biddableAmount = [5, 10, 25, 50, 75];
                    let minId = Math.ceil(2);
                    let maxId = Math.floor(5);
                    let randomId = Math.floor(Math.random() * (maxId - minId + 1)) + minId;
                    let randomBidAmount = Math.floor(Math.random() * 5);
                    dispatch(placeABid(randomId, biddingData.currentBidUser.bidAmount, biddableAmount[randomBidAmount]));
                    dispatch(triggerScroll());
                }
            }, 20000);
        }

        return () => {
            clearInterval(interval);
        }
    }, [dispatch, biddingData.biddingStarted, biddingData.currentBidUser])

    useEffect(() => {
        if(biddingData.biddingStarted) {
            var found = false;
            var checkUserInteraction = setTimeout(() => {
                biddingData.bidLog.forEach(bid => {
                    if(bid.id === 1){
                        found = true;
                    }
                })
                if(!found){
                    if(taskId === 1){
                        props.promptUserToBid();
                    }
                    if(taskId === 2){
                        props.promptUserToForfeit();
                    }
                }
            }, 10000);
        }
        return () => {
            clearInterval(checkUserInteraction);

        }
    }, [biddingData.biddingStarted, biddingData.bidLog, taskId, props])

    useEffect(() => {
        if(biddingData.biddingStarted && biddingData.keyTimer > 0) {
            var timer1 = setTimeout(() => {
                dispatch(placeABid(4, 2000, 100));
            }, 1000);
            var timer2 = setTimeout(() => {
                dispatch(placeABid(2, 1900, 50));
            }, 2500);
            var timer3 = setTimeout(() => {
                dispatch(placeABid(5, 1850, 50));
            }, 4000);
        }

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
            clearTimeout(timer3);
        }
    }, [dispatch, biddingData.biddingStarted, biddingData.keyTimer])

    const toggleExpanded = () => {
        setIsExpandedOpen(!isExpandedOpen);
    }
    const selectMenuItem = (id) => {
        setIsExpandedOpen(false);
        if(id === 3){
            props.openConfirmExit()
        }
    }
    const selectTaskItem = (id) => {
        setIsListOpenTask(false);
        if(biddingData.biddingStarted){
            props.openConfirmFinish(id);
        } else {
            setTaskId(id);
            props.openStartBidModal(id);
            props.setPlaceBid(true);
        }
    }
 
    const finishTask = useCallback(() => {
        props.setPlaceBid(false);
        dispatch(placeBidEnd());
        props.openPlanEndModal();
        props.disableLockStatus();
    },[dispatch, props])
    
    const toggleListTask = () => {
        setIsListOpenTask(!isListOpenTask);
    }
 
    const handleClickOutside = (event) => {
        if(isExpandedOpen && event.target.className !== "menuDrop" && event.target.className !== "menu-item") {
            if(!(props.isGuide && props.guideStep === 10)){
                setIsExpandedOpen(false);
            }
        }
        if(isListOpenTask && event.target.className !== "planDropdown" && event.target.className !== "taskDropItem") {
            setIsListOpenTask(false);
        }
    }
    const openTour = (id) => {
        if(id === 3 && props.isTourOpenTask){
            props.setCurrentTaskTourId(1)
        } else {
            setIsOpen(true);
            setCurrentStep(id)
        }
    }

    return (
        <section className="practiceBidding">
            <div className="fixedHeader">
                <div className="heading">
                    Practice Bid
                </div>
                <div onClick={() => props.openConfirmExit()} className="backMenu">
                    <img src={backIcon} alt="icon"/>
                </div>
                <div className="expandedMenu">
                    <div className="dropdown" data-tut="context">
                        <div onClick={toggleExpanded} className="menuDrop">
                            <img src={ellipsisOutlineBox} alt="icon"/>
                        </div>
                        {isExpandedOpen && (
                            <div role="list" className="dropdown-content-menu">
                                <span className="menu-item" onClick={() => selectMenuItem(1)}>Rules</span>
                                <span className="menu-item" onClick={() => { props.startTour(); selectMenuItem(2)}}>Guide</span>
                                <span className="menu-item" onClick={() => selectMenuItem(3)}>Exit</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="timerBarContainer">
                    <div className="timerBar">
                        <div className="left">
                            <div className="dropdown" onClick={()=> toggleListTask()} data-tut="task">
                                <div className="planDropdown">
                                    <span style={{margin: '0 auto', fontSize:'16px'}}>Task</span>
                                    <img src={dropWhite} alt="icon"/>
                                </div>
                                {isListOpenTask && (
                                    <div role="list" className="dropdown-content-task">
                                        <span className="taskDropItem" onClick={() => selectTaskItem(1)}>Place a Bid</span>
                                        <span className="taskDropItem" onClick={() => selectTaskItem(2)}>Show Forgo</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div data-tut="timer" className="timer" onClick={() => openTour(0)}>
                            <TimerBidding onComplete={finishTask} biddingData={biddingData} /> 
                        </div>
                       
                        <div className="right">
                            <div data-tut="finish" style={{minHeight: '32px'}} className="dropdown">
                                {(props.placeBid || props.currentTaskId > 0 || (isOpen && currentStep === 10))  && <div onClick={() => finishTask()} className="planDropdown dropRight">
                                    <span style={{margin: '0 auto', fontSize:'16px'}}>Finish</span>
                                </div>}
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="planTags">
                     <Swiper  
                        spaceBetween={8}
                        direction={"horizontal"}
                        slidesPerView= "auto"
                        className="mySwiper">
                        <SwiperSlide>
                            <div className="tag" data-tut="online" onClick={() => openTour(2)}>
                                <span className="title">Online:</span>
                                <span className="value">6</span>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="tag" data-tut="ptap" onClick={() => openTour(3)}>
                                <span className="title">PTAP:</span>
                                <span className="value">$4000</span>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="tag" data-tut="flagged" onClick={() => openTour(4)}>
                                <span className="title">Forwent Members:</span>
                                <span className="value">{forfeitMembersCount}</span>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="tag" data-tut="available" onClick={() => openTour(5)}>
                                <span className="title">Available Members:</span>
                                <span className="value">10</span>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <img src={divider} alt="icon" className="divider"></img>
            </div>
            <div ref={messageContentRef} className="bidThreadsContainer">
                {biddingData.biddingStarted && biddingData.bidLog && biddingData.bidLog.map((bidData, index) => {
                        if(bidData.type === "self_forfeit")
                            return  <div key={index} className="forfeitThread">You Forwent</div>
                        else return  <BidMessage openUserInfoModal={props.openUserInfoModal} key={index} bidData={bidData} biddingData={biddingData} />
                    }
                )}
                {!biddingData.biddingStarted && dummyBidLog.map((bidData, index) => 
                    <BidMessage openUserInfoModal={props.openUserInfoModal} key={index} bidData={bidData} biddingData={dummyBiddingData} />
                )}
            </div>
        </section>
    )
}

export default BiddingView