import { Box, Grid, Typography, Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { gsap } from "gsap";
import circle1 from "../../assets/images/landingPage/home/easyPayment/circle1.svg";
import element1 from "../../assets/images/landingPage/home/easyPayment/element1.svg";
import cross1 from "../../assets/images/landingPage/home/easyPayment/cross1.svg";

const EasyPayment = (props) => {
  useEffect(() => {
    if(!props.isMobile){
      gsap.utils.toArray(".easy-col-1").forEach((panel, i) => {
        gsap.fromTo(
          [panel],
          { opacity: 0, x: -200 },
          {
            opacity: 1,
            ease: "power1.out",
            duration: 1.15,
            x: 0,
            scrollTrigger: {
              trigger: panel,
  
              start: "center-=150 center+=150",
            },
          }
        );
      });
      gsap.utils.toArray(".img-left").forEach((panel, i) => {
        gsap.fromTo(
          [panel],
          { opacity: 0, x: 200, y: 20, rotation: 360 },
          {
            opacity: 1,
            rotation: 0,
            ease: "power1.out",
            duration: 1.15,
            x: 0,
            y: 0,
            scrollTrigger: {
              trigger: panel,
  
              start: "center-=10 center+=200",
            },
          }
        );
      });
  
      gsap.utils.toArray(".img-right").forEach((panel, i) => {
        gsap.fromTo(
          [panel],
          { opacity: 0, x: -200, y: 10, rotation: 360 },
          {
            opacity: 1,
            rotation: 0,
            ease: "power1.out",
            duration: 1.15,
            x: 0,
            y: 0,
            scrollTrigger: {
              trigger: panel,
  
              start: "center-=10 center+=200",
            },
          }
        );
      });
  
      gsap.utils.toArray(".easy-col-2").forEach((panel, i) => {
        gsap.fromTo(
          [panel],
          { opacity: 0, x: 200 },
          {
            opacity: 1,
            ease: "power1.out",
            duration: 1.15,
            x: 0,
            scrollTrigger: {
              trigger: panel,
  
              start: "center-=150 center+=150",
            },
          }
        );
      });
    }
  }, []);
  return (
    <div>
      <Box bgcolor={"#2E2E2E"} className="easy-pay" position={"relative"}>
        {!props.isMobile && <>
          <Box
            position={"absolute"}
            left={"5%"}
            className={"img-left"}
            top={"10%"}>
            <img src={circle1} alt="circle1" />
          </Box>
          <Box position={"absolute"} className={"c"} right={"5%"} top={"10%"}>
            <img src={circle1} alt="circle1" />
          </Box>
          <Box
            position={"absolute"}
            className={"img-right"}
            right={"5%"}
            bottom={"10%"}>
            <img src={circle1} alt="circle1" height={"20px"} />
          </Box>

          <Box
            position={"absolute"}
            left={"-10px"}
            className={"img-left"}
            bottom={"10%"}>
            <img src={element1} alt="element1" />
          </Box>
          <Box
            position={"absolute"}
            left={"8%"}
            className={"img-left"}
            bottom={"50%"}>
            <img src={element1} alt="element1" />
          </Box>
          <Box
            position={"absolute"}
            className={"img-right"}
            right={"30%"}
            top={"10%"}>
            <img src={element1} alt="element1" />
          </Box>
          <Box
            position={"absolute"}
            left={"8%"}
            className={"img-left"}
            bottom={"10%"}>
            <img src={cross1} alt="cross1" />
          </Box>
          <Box
            position={"absolute"}
            left={"4%"}
            className={"img-left"}
            bottom={"30%"}>
            <img src={cross1} alt="cross1" />
          </Box>
          <Box
            position={"absolute"}
            right={"4%"}
            className={"img-right"}
            bottom={"30%"}>
            <img src={cross1} alt="cross1" />
          </Box>
        </>}
        <Grid
          container
          className="easy-pay-main"
          style={{padding: "30px 24px"}}
          sx={{
            maxWidth: {
              sm: "sm",
              md: "md",
              lg: "xl",
              xl: "xl",
            },
          }}
          margin={"auto"}>
          {!props.isMobile && <Grid item xs={12} md={6} className={"easy-col-1"}>
              <Box display={"flex"} justifyContent={"center"}>
                <img src={props.img} alt="easyPayment" />
              </Box>
          </Grid>}
          {props.isMobile && <img src={props.img} className="main-image" alt="easyPayment" />}
          <Grid item xs={12} md={6} className={"easy-col-2"}>
            <Box
              className="box"
            >
              <Typography
                className="label"
               >
                {props.label}
              </Typography>
              <Typography
                className="description"
               >
                {props.description}
              </Typography>
              <Button
                id={"getStartBtn"}
                className="button"
                onClick={() => props.getStarted()}
              >
                {props.Btext}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default EasyPayment;
