import {
    SET_USER,
    SET_AUTHENTICATED,
    SET_UNAUTHENTICATED,
    SET_TWO_FACTOR_AUTH,
    CLEAR_TWO_FACTOR_AUTH,
    SET_OTP_SENT,
    CLEAR_OTP_SENT,
    LOADING_USER,
    SET_REGISTRATION,
    SET_CHANGED_SUCCESS,
    SET_REGISTER_SUCCESS,
    USER_PROFILE_DATA,
    SELF_PROFILE_IMAGE,
    COUNTRY_STATES,
    STATE_CITIES,
    NOTIFICATION_SETTINGS
  } from '../types';
  
  const initialState = {
    authenticated: false,
    userId: '',
    signUpOtpStatus: "",
    registration: {},
    twoFactor: {},
    userProfile: {},
    countryStates: [],
    stateCities: [],
    notificationSettings: {},
    selfProfileImage: ""
  };
  
  export default function userReducer(state = initialState, action) {
    switch (action.type) {
      case SET_AUTHENTICATED:
        return {
          ...state,
          authenticated: true
        };
      case NOTIFICATION_SETTINGS:
        return {
          ...state,
          notificationSettings: action.payload
        }
      case SET_UNAUTHENTICATED:
        return initialState;
      case SET_USER:
        return {
          authenticated: true,
        };
      case SET_REGISTRATION:
        return {
          ...state,
          registration: action.payload
        };
      case COUNTRY_STATES:
        return {
          ...state,
          countryStates: action.payload
        }
      case STATE_CITIES:
        return {
          ...state,
          stateCities: action.payload
        }
      case SET_TWO_FACTOR_AUTH:
        return {
          ...state,
          twoFactor: action.payload
        };
      case CLEAR_TWO_FACTOR_AUTH:
        return {
          ...state,
          authenticated: true,
          twoFactor: {}
        };
      case SET_REGISTER_SUCCESS:
        return {
          ...state,
          registration: {},
        };
      case SET_CHANGED_SUCCESS:
        return {
          ...state,
        };
      case SET_OTP_SENT:
        return {
          ...state,
          signUpOtpStatus : "verified"
        };
      case CLEAR_OTP_SENT:
        return {
          ...state,
          signUpOtpStatus : ""
        };
      case LOADING_USER:
        return {
          ...state,
          // loading: true
        };
      case USER_PROFILE_DATA:
        return {
          ...state,
          userProfile: action.payload,
        }
      case SELF_PROFILE_IMAGE:
        return {
          ...state,
          selfProfileImage: action.payload,
        }
      default:
        return state;
    }
  }