import {
    INCOMING_MESSAGE,
    CHAT_SCROLL_TRIGGER_START,
    CHAT_SCROLL_TRIGGER_END,
    CLEAR_CHAT,
    DAY_INDICATOR,
    DELETE_MESSAGE
    } from '../types';

const initialState = {
    chatMessages: [],
    scrollTrigger: false
};
 
export default function chatReducer(state = initialState, action) {
    switch (action.type) {   
        case INCOMING_MESSAGE: 
            return {
                ...state,
                chatMessages: [...state.chatMessages, action.payload]
            }
        case CLEAR_CHAT:
            return{
                chatMessages: [],
            }
        case DAY_INDICATOR:
            return {
                ...state,
                chatMessages: [...state.chatMessages, {
                    "type": "Time",
                    "value": action.payload.date.toUpperCase(),
                    "key": action.payload.key
                }]

            }
        case DELETE_MESSAGE:
            return {
                ...state,
                chatMessages: state.chatMessages.filter((message) => message.id !== action.payload )
            }
        case CHAT_SCROLL_TRIGGER_START:
            return  {
                ...state,
                scrollTrigger: true
            }
        case CHAT_SCROLL_TRIGGER_END:
            return  {
                ...state,
                scrollTrigger: false
            }
        default:
            return state;
    }
}