import axios from 'axios';
import { closeConnection } from './notificationAction';

import { 
    SET_USER, 
    SET_ERRORS, 
    CLEAR_ERRORS, 
    LOADING_UI, 
    SET_UNAUTHENTICATED,
    SET_REGISTRATION,
    CLEAR_TWO_FACTOR_AUTH,
    SET_TWO_FACTOR_AUTH,
    SET_OTP_SENT,
    CLEAR_OTP_SENT,
    SET_REGISTER_SUCCESS,
    SET_CHANGED_SUCCESS,
    CLEAR_CONTACT_DATA,
    USER_PROFILE_DATA,
    COUNTRY_STATES,
    STATE_CITIES,
    SELF_PROFILE_IMAGE,
    NOTIFICATION_SETTINGS
} from '../types';

const setAuthorizationHeader = (token) => {
    localStorage.setItem('accessToken', token.access_token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token.access_token}`;

    localStorage.setItem('refreshToken', token.refresh_token);
    let expiryTime = Date.now() + token.expires_in*1000;
    localStorage.setItem('expiryTime', expiryTime);
}

export const setUserId = (userData) => (dispatch) => {
    dispatch({type: SET_USER});
    localStorage.setItem('userId', userData);
}

export const getProfileInfo = () => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.get('/api/users/profile/', {})
    .then((res)=>{
        dispatch({type: CLEAR_ERRORS});
        dispatch({
            type: USER_PROFILE_DATA,
            payload: res.data
        });
        dispatch({
            type: SELF_PROFILE_IMAGE,
            payload: res.data.profile_image
        });
        return res.data
    })
    .catch((err)=>{
       
    })
}

export const getStates = () => (dispatch) => {
    return axios.get('/api/utils/states/?country_code=US', {})
    .then((res)=>{
        dispatch({
            type: COUNTRY_STATES,
            payload: res.data
        });
    })
    .catch((err)=>{
    })
}

export const getCities = (id) => (dispatch) => {
    return axios.get(`/api/utils/cities/?state_id=${id}`, {})
    .then((res)=>{
        dispatch({
            type: STATE_CITIES,
            payload: res.data
        });
    })
    .catch((err)=>{
    })
}

export const getNotificationSettings = (id) => (dispatch) => {
    return axios.get(`/api/notifications/settings/`, {})
    .then((res)=>{
        dispatch({
            type: NOTIFICATION_SETTINGS,
            payload: res.data
        });
    })
    .catch((err)=>{
    })
}


export const updateNotificationSetting = (data) => (dispatch) => {
    return axios.post(`/api/notifications/settings/`, data)
    .then((res)=>{
        dispatch({
            type: NOTIFICATION_SETTINGS,
            payload: res.data
        });
    })
    .catch((err)=>{
    })
}

export const saveProfileInfo = (userData) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.put('/api/users/profile/', userData)
    .then((res)=>{
        dispatch({type: CLEAR_ERRORS});
        if(res.status === 200) {
            return {
                "status": "done"
            }
        }
    })
    .catch((err)=>{
        dispatch({type: CLEAR_ERRORS});
        if(err.response.status === 422 && err.response.data){
            let errRes = Object.keys(err.response.data).map((e) => e)
            return errRes
        }
        return {
            "status": "failed"
        }
    })
}

export const loginUser = (userData, history) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.post('/api/users/login/', userData)
    .then((res)=>{
        if(res.data && res.data.otp_session === null && res.data.two_factor_enabled === false && res.data.token) {
            let token = res.data.token;
            setAuthorizationHeader(token);
            dispatch(setUserId(res.data.user_id));
            dispatch({type: CLEAR_ERRORS});
            history.push('/dashboard');
        } else if(res.data && res.data.otp_session && res.data.two_factor_enabled && res.data.token === null) {
            dispatch({type: CLEAR_ERRORS});
            return dispatch({
                type: SET_TWO_FACTOR_AUTH,
                payload: res.data
            });
        }
    })
    .catch((err)=>{
        if(err && err.response && err.response.status === 401){
            dispatch({
                type: SET_ERRORS,
                payload: {
                    status: 401
                }
            })
          
        } else if(err && err.response && err.response.data ){
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data 
            })
        } else {
            dispatch({
                type: SET_ERRORS,
                payload: null
            })
        }
    })
}


export const signupUser = (newUserData) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.post('/api/users/signup/', newUserData)
        .then((res) => {
            dispatch({type: CLEAR_ERRORS});
            if(res.status === 201) {
                return dispatch({
                    type: SET_REGISTRATION,
                    payload: res.data.otp_session
                });
            }
        })
        .catch((err)=>{
            if(err && err.response && err.response.data){
                dispatch({
                    type: SET_ERRORS,
                    payload: err.response.data 
                })
            } else {
                dispatch({
                    type: SET_ERRORS,
                    payload: null
                })
            }
        })
}

export const basicSignUp = (userData) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.post('/api/users/signup/', userData)
    .then((res) => {
        if(res.status === 201) {
            dispatch({type: CLEAR_ERRORS});
            return {
                message: "success",
                error: ""
            }
        }
    })
    .catch((err)=>{
        if(err.response && err.response.status === 422){
            dispatch({type: CLEAR_ERRORS});
            if(err.response.data.email){
                return {
                    message: "failed",
                    error: "user already exists"
                }
            } else {
                return {
                    message: "failed",
                    error: "Server error"
                }
            }
           
        } else {
            dispatch({type: CLEAR_ERRORS});
            return {
                message: "failed",
                error: "Server error"
            }
        }
    })
    
}

export const requestOTP = (userData) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.post('/api/users/signup/add-phone/', userData)
        .then((res) => {
            dispatch({type: CLEAR_ERRORS});
            if(res.status === 200) {
                dispatch({type: SET_OTP_SENT});
            }
        })
        .catch((err)=>{
            if(err.response.status){
                return dispatch({
                    type: SET_ERRORS,
                    payload: err.response.data
                })
            } else {
                return dispatch({
                    type: SET_ERRORS,
                    payload: null
                })
            }
        })
}

export const resendOTP = (userData) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.post('/api/users/resend-otp/', userData)
        .then((res) => {
            dispatch({type: CLEAR_ERRORS});
            // if(res.status === 200) {
            //     dispatch({type: SET_OTP_SENT});
            // }
        })
        .catch((err)=>{
            let error = "Unable to request";
            if(err && err.response && err.response.data){
                return dispatch({type: SET_ERRORS, payload: {
                    errors: error
                }});
            } else {
                return dispatch({type: SET_ERRORS, payload: {
                    errors: error
                }});
            }
        })
}

export const verifyOTPLogin = (userData,history) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.post('/api/users/2fa/verify/', userData)
    .then((res)=>{
       if(res.data && res.data.token && res.data.token.access_token) {
            let token = res.data.token;
            setAuthorizationHeader(token);
            dispatch(setUserId(res.data.user_id));
            dispatch({type: CLEAR_ERRORS});
            dispatch({type: CLEAR_TWO_FACTOR_AUTH});
            history.push('/dashboard');
          
       }
    })
    .catch((err)=>{
        let error = "Invalid OTP";
        if(err && err.response && err.response.data){
            return dispatch({type: SET_ERRORS, payload: {
                errors: error
            }});
        } else {
            return dispatch({type: SET_ERRORS, payload: {
                errors: error
            }});
        }
    })
}

export const verifyOTPSignup = (userData,history) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.post('/api/users/signup/verify-phone/', userData)
    .then((res)=>{
       if(res && res.status && res.data && res.status === 200) {
            dispatch({type: CLEAR_ERRORS});          
       }
    })
    .catch((err)=>{
        if(err.response.status){
            return dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        } else {
            return dispatch({
                type: SET_ERRORS,
                payload: null
            })
        }
    })
}

export const logoutUser = (userData) => (dispatch) => {
    dispatch({type: LOADING_UI});
    localStorage.clear();
    dispatch({type: SET_UNAUTHENTICATED});
    dispatch({type: CLEAR_CONTACT_DATA});
    axios.post('/api/users/logout/', userData)
    .then((res)=>{
        dispatch({type: CLEAR_ERRORS}); 
        dispatch(closeConnection());
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS
        })
    })
}

export const clearOTPStatus = () => (dispatch) => {
    dispatch({type: CLEAR_OTP_SENT});
}


export const passwordResetRequest = (userData) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.post('/api/users/password_reset/', userData)
    .then((res)=>{
       if(res && res.status && res.status === 200) {
            return dispatch({
                type: CLEAR_ERRORS,
                payload: {
                    email: true
                }
            })
       }
    })
    .catch((err)=>{
        return dispatch({
            type: SET_ERRORS,
            payload: {
                email: false
            }
        })
    })
}

export const submitRenewPassword = (userData, history) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return validatedTokenConfirmPassword(userData.token).then((res)=>{
        if(res){
            return axios.post('/api/users/password_reset/confirm/', userData)
            .then((res)=>{
               if(res && res.status && res.status === 200) {
                    dispatch({ type: CLEAR_ERRORS });
                    return dispatch({
                        type: SET_REGISTER_SUCCESS,
                        payload: {
                            register: true
                        }
                    })
               }
            })
            .catch((err)=>{
                if(err && err.response && err.response.status){
                    if(err.response.data.password){
                        return dispatch({
                            type: SET_ERRORS,
                            payload: {
                                register: false,
                                errorDescription: err.response.data.password
                            }
                        })
                    } else if(err.response.data.detail){
                        return dispatch({
                            type: SET_ERRORS,
                            payload: {
                                register: false,
                                errorDescription: err.response.data.detail
                            }
                        })
                    }
                    
                } else {
                    return dispatch({
                        type: SET_ERRORS,
                        payload: {
                            register: false,
                            errorDescription: "Error in sending data"
                        }
                    })
                }
                
            })
        } else {
            return dispatch({
                type: SET_ERRORS,
                payload: {
                    register: false,
                    errorDescription: "User link has expired or invalid"
                }
            })
        }
    })    
}


export const submitChangePassword = (userData, history) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.post('/api/users/change-password/', userData)
    .then((res)=>{
        if(res && res.status && res.status === 201) {
            dispatch({ type: CLEAR_ERRORS });
            return dispatch({
                type: SET_CHANGED_SUCCESS,
                payload: {
                    changedPassword: true
                }
            })
        }
    })
    .catch((err)=>{
        if(err && err.response && err.response.status){
            if(err.response.data.password){
                return dispatch({
                    type: SET_ERRORS,
                    payload: {
                        changedPassword: false,
                        errorDescription: err.response.data.password
                    }
                })
            } else if(err.response.data.detail){
                return dispatch({
                    type: SET_ERRORS,
                    payload: {
                        changedPassword: false,
                        errorDescription: err.response.data.detail
                    }
                })
            }
        } else {
            return dispatch({
                type: SET_ERRORS,
                payload: {
                    changedPassword: false,
                    errorDescription: "Error in sending data"
                }
            })
        }
        
    })
        
}



const validatedTokenConfirmPassword = (token) => {
    return axios.post('/api/users/password_reset/validate_token/', {
        "token": token
    })
    .then((res)=>{
        if(res && res.status === 200){
            return true
        }
    })
    .catch(()=>{
        return false;
    })
}

export const addIdProof = (formData) => (dispatch) => {
    dispatch({type: LOADING_UI});
    let accToken = localStorage.accessToken;
    return axios.post('/api/users/verification',  formData, {
        headers: {
            'Content-Type':  `multipart/form-data`,
            'Authorization': `Bearer ${accToken}` ,
        }
    })
    .then((res)=>{
       if(res && res.status && res.status === 200) {
            return dispatch({ type: CLEAR_ERRORS, result: "uploaded" });
       } 
    })
    .catch((err)=>{
        if(err.response.status === 400) {
            return dispatch({
                type: SET_ERRORS,
                result: "tooBig"
            })
       } else {
            return dispatch({
                type: SET_ERRORS,
                result: "failed"
            })
       }
    })
}


export const emailSubscription = (userData) => (dispatch) => {
    return axios.post('/api/email_subscription/subscribers/', userData)
    .then((res)=>{
       if(res && res.status && res.status === 201) {
           return {
               status: "sent"
           }
       }
    })
    .catch((err)=>{
        if(err && err.response && err.response.data && err.response.data.email){
            return {
                status: err.response.data.email
            }
        } else {
            return {
                status: "failed"
            }
        }
        
    })
}