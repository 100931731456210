import React, { useState, useEffect, useMemo} from 'react';
import { useHistory } from "react-router";
import Select from 'react-select'
import { useDispatch, useSelector } from "react-redux";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import RadioWithLabel from '../components/RadioWithLabel';
import Loading from '../components/Loading';
import CheckboxWithLabelPlans from '../components/CheckboxWithLabelPlans';
import PlanApplicationNotification from '../components/PlanApplicationNotification';
import { 
    getApplicationChoices,
    submitPlanApplication,
    clearErrors
} 
from './../redux/actions/planActions';
import backIcon from './../assets/images/icons/backIcon.svg';
import joinedSuccess from './../assets/images/joinedSuccess.svg';
import shareWhite from './../assets/images/icons/shareWhite.svg';
import closeIconWhite from './../assets/images/icons/closeCircle.svg';
import './../styles/application.scss';

const Application = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    
    const planState = useSelector((state) => state.plans);
    const uiState = useSelector((state) => state.UI);
    const { loading } = uiState
    const { planTypes, contributionAmountRanges, periodRanges, planGoals } = planState

    const amountContributionOptions = useMemo(() => {
        if(contributionAmountRanges && contributionAmountRanges.length > 0){
          return contributionAmountRanges.map((c) => {
                let parseData = JSON.parse(c.range)
                return {
                    lower: parseData.lower, 
                    upper: parseData.upper, 
                    name: `$ ${parseData.lower} - $${parseData.upper}`,
                    label: `$ ${parseData.lower} - $${parseData.upper}`,
                    id: c.id
                }
            })
        }
    } , [contributionAmountRanges])

    const periodRangesOptions = useMemo(() => {
        if(periodRanges && periodRanges.length > 0){
          return periodRanges.map((c) => {
                let parseData = JSON.parse(c.range)
                return {
                    lower: parseData.lower, 
                    upper: parseData.upper, 
                    name: `${parseData.lower} - ${parseData.upper}`,
                    label: `${parseData.lower} - ${parseData.upper}`,
                    id: c.id
                }
            })
        }
    } , [periodRanges])

    const [sectionId, setSectionId] = useState(1);
    const [understandingValue, setUnderstandingValue] = useState(null)
    const [understandingNext, setUnderstandingNext] = useState(false)
    const [planTypeValues, setPlanTypeValues] = useState([])
    const [goalTypeValues, setGoalTypeValues] = useState([])
    const [amountContributed, setAmountContributed] = useState(null)
    const [periodSelected, setPeriodSelected] = useState(null)
    const [expectedTotal, setExpectedTotal] = useState('')
    // const [timeZone, setTimeZone] = useState('America/Los_Angeles')

    const colourStyles = {
        control: styles => ({ ...styles,
          boxSizing: 'border-box',
          fontSize: '15px',
          outline: 'none',
          width: '100%',
          border: 'none',
          padding: '7px 5px 7px 12px',
          color: 'white',
          background: '#66656F',
          borderRadius: '10px',
          display: 'flex'
        }),
        placeholder:styles => ({ ...styles, color: 'rgba(255,255,255,0.6)', textTransform: 'capitalize',marginLeft:'0'}),
        valueContainer:styles => ({ ...styles,paddingLeft:'0'}),
        container: styles => ({ ...styles, width: '100%',}),
        input: styles => ({ ...styles, color: 'white',}),
        singleValue: styles => ({ ...styles,  color: 'rgba(255,255,255,0.8)' }),
        indicatorSeparator: styles => ({ ...styles, display: 'none' }),
    };

    useEffect(() => {
        dispatch(getApplicationChoices())
    },[dispatch])

    useEffect(() => {
        if(periodSelected && amountContributed){
            setExpectedTotal(`$${amountContributed.lower * periodSelected.lower} - $${amountContributed.upper * periodSelected.upper}`)
        } else {
            setExpectedTotal('')
        }
    },[periodSelected, amountContributed])
  
    const openNext = (id) => {
        if(id === 2 && understandingNext){
            setSectionId(id)
        }
        if(id === 3){
            setSectionId(id)
        }
        if(id === 4){
            setSectionId(id)
        }
    }
    const goBack = () => {
        if(sectionId === 1){
            history.goBack();
        } else if(sectionId > 1){
            setSectionId(sectionId -1);
        }
    }
   
    const inviteFriends = () => {
        history.push("/friends/2")
    }
    const shareLink = () => {
    }
    const closeApplication = () => {
        history.push("/dashboard")
    }
    const checkedUnderstandingAction = (event) => {
        setUnderstandingNext(true)
        setUnderstandingValue(parseInt(event.target.value))
    }
    const planTypeCheck = (event, id) => {
        if(event.target.checked){
            setPlanTypeValues([...planTypeValues, {id}])
        } else {
            setPlanTypeValues(planTypeValues.filter(p => p.id !== id))
        }
    }
    const goalTypeCheck = (event, id) => {
        if(event.target.checked){
            setGoalTypeValues([...goalTypeValues, {id}])
        } else {
            setGoalTypeValues(goalTypeValues.filter(p => p.id !== id))
        }
    }
    const periodSelect = (data) => {
        setPeriodSelected(data)
    }
    const contributionSelect = (data) => {
        setAmountContributed(data)
    }

    const submitApplication = () => {
        if(understandingValue && planTypeValues.length > 0 && amountContributed && periodSelected && goalTypeValues.length > 0){
            let planTypes = planTypeValues.map((p) => p.id)
            let goalTypes = goalTypeValues.map((g) => g.id)
            const postData = {
                "plan_understanding_level": understandingValue,
                "plan_types": planTypes,
                "contribution_amount":amountContributed.id,
                "period": periodSelected.id,
                "preferred_time_zone": "America/Los_Angeles",
                "plan_goals": goalTypes
            }
            dispatch(submitPlanApplication(postData)).then(res => {
                if(res === "success"){
                    dispatch(clearErrors())
                }
            })
        }
    }
    
    return (
        <div className="applicationPage">
            {loading ? <Loading></Loading> : <>
                <header>
                    {sectionId < 5 && <img onClick={() => goBack()} src={backIcon} alt="icon" />}
                    <div className='title'>Application</div>
                    <img className='closeIcon' src={closeIconWhite} alt="icon" onClick={() => closeApplication()} />
                </header>
                {sectionId === 1 && <div className='greyContainer tellAboutYourSelf'>
                    <div className='header'>How much understanding you have about wheez plans</div>
                    <FormControl component="fieldset">
                        <RadioGroup className='radioGroup' row aria-label="goals" value={understandingValue} onChange={checkedUnderstandingAction} name="customized-radios">
                            <RadioWithLabel value={2} label="I Got It, I Know What I'm Doing" />
                            <RadioWithLabel value={1} label="I Have Understanding Somewhat" />
                            <RadioWithLabel value={0} label="I Don't Understand" />
                        </RadioGroup>
                    </FormControl>
                    <button className={`button ${!understandingNext && 'disabled'}`} disabled={!understandingNext} onClick={() => openNext(2)}>Next</button>
                </div>}
                {sectionId === 2 && <div className='greyContainer planPreference'>
                    <div className='header'>Plan preference</div>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" className="subHeader">Plan Type</FormLabel>
                        <RadioGroup className='radioGroup' row aria-label="plantype" name="customized-radios">
                            {planTypes.length > 0 && planTypes.map((p) => 
                                <CheckboxWithLabelPlans id={p.id} key={p.id} value={p.title} label={p.title} planTypeCheck={planTypeCheck}/>
                            )}
                        </RadioGroup>
                    </FormControl>
                    <div className='subHeader' style={{marginTop: '15px'}}>Amount willing to contribute</div>
                    <div className='hintInfo'>
                        <span>Hint:</span>
                        <span className='description'>A sweet spot for monthly plans are 6%-10% of your monthly income.</span>
                    </div>
                    <Select 
                        options={amountContributionOptions} 
                        placeholder="Select Range"
                        styles={colourStyles}
                        onChange={contributionSelect} 
                    />
                    <div className='info' style={{marginBottom: '15px', marginTop: '5px'}}>Enter in range eg: $400 - $600</div>
                    
                    <div className='subHeader' style={{marginTop: '15px'}}>Period</div>
                    <Select 
                        options={periodRangesOptions} 
                        placeholder="Period"
                        onChange={periodSelect} 
                        styles={colourStyles}
                    />

                    <div className='subHeader' style={{marginTop: '15px'}}>Expected Total Amount</div>
                    <div className='expectedTotal'>
                        For the selected period and range, you can expect plans around <strong>{expectedTotal.length > 0 ? expectedTotal : '$0'}</strong>
                    </div>
                    {/* <div className='inputWithIcon period disabled'>
                        <span style={{minHeight: '20px'}}>{expectedTotal.length > 0 ? expectedTotal : '$0'}</span>
                    </div> */}
                    <div className='subHeader' style={{marginTop: '15px'}}>Preferred Time Zone</div>
                    <Select 
                        options={[]} 
                        placeholder="America/Los_Angeles"
                        styles={colourStyles}
                    />
                    <div className='hintInfo'>
                        <span>Recommendation note:</span>
                        <span className='description'>Wheez plans are to help every member in the plans to achieve their financial goals. Wheez will analyze your financial capabilities, experience, and goals before recommending plans. If you have applied for a plan above your limit, we can’t provide that plan to you, but we’ll give you the right plans which will help your finances, and at the same time, it’ll also not affect your budget.</span>
                    </div> 
                    <button className={`button ${!(planTypeValues.length > 0 && periodSelected && amountContributed) && 'disabled'}`} 
                        disabled={!(planTypeValues.length > 0 && periodSelected && amountContributed)} 
                        onClick={() => openNext(3)}>Next</button>
                </div>}
                {sectionId === 3 && <div className='greyContainer goals'>
                    <div className='header'>Choose A Goal</div>
                    <div className='mainInfo'>What is your goal for this plan? How do you think you're going to use it?</div>
                    <FormGroup component="fieldset">
                        <RadioGroup className='radioGroup' row aria-label="goalType" name="customized-radios">
                            {planGoals.length > 0 && planGoals.map((p) => 
                                <CheckboxWithLabelPlans type="goal" id={p.id} key={p.id} value={p.title} label={p.title} planTypeCheck={goalTypeCheck}/>
                            )}
                        </RadioGroup>
                    </FormGroup>
                    <div style={{marginTop: '15px'}}><strong className='subInfoNote'>Note:</strong><span className='subInfo'> By choosing a goal we can recommend you the right plan, but that doesn't mean you have to use it as you choose. Wheez plans are meant to provide financial freedom and achieve your goals</span></div>
                    <button className={`button ${!(goalTypeValues.length > 0) && 'disabled'}`} 
                        disabled={!(goalTypeValues.length > 0)}  onClick={() => openNext(4)}>Next</button>
                </div>}
                {sectionId === 4 && <div className='greyContainer rules'>
                    <div className='header'>Rules & Regulations</div>
                    <p className='description'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a augue a est rutrum luctus quis eu leo. Duis massa mauris, porta eget efficitur sed, bibendum vel ante.
                        <br /><br />
                        varsayılan mıgır metinler olarak Lorem Ipsum kullanmaktadır. Ayrıca arama motorlarında 'lorem ipsum' anahtar sözcükleri ile arama yapıldığında henüz tasarım aşamasında olan çok sayıda site listelenir. Yıllar içinde, bazen kazara, bazen bilinçli olarak (örneğin mizah katılarak), çeşitli sürümleri geliştirilmiştir.
                        <br /><br />
                        varsayılan mıgır metinler olarak Lorem Ipsum kullanmaktadır. Ayrıca arama motorlarında 'lorem ipsum' anahtar sözcükleri ile arama yapıldığında henüz tasarım aşamasında olan çok sayıda site listelenir. Yıllar içinde, bazen kazara, bazen bilinçli olarak (örneğin mizah katılarak), çeşitli sürümleri geliştirilmiştir.
                    </p>
                    <button className='button' onClick={() => submitApplication()}>Accept & Apply</button>
                </div>}
                {sectionId === 5 && <div className='greyContainer joinedSuccess'>
                    <img className='image' src={joinedSuccess} alt="icon" />
                    <div className='header'>Joined Successfully</div>
                    <p className='description'>
                        You’ve joined in a wheez plan successfully. The right choice make your financial life better.
                    </p>
                    <button className='button' onClick={() => inviteFriends()}>Invite your friends</button>
                    <div className='shareFlex'>
                        <img src={shareWhite} alt="icon" />
                        <span onClick={() => shareLink()}>Share it</span>
                    </div>
                </div>}
            </>}
            <PlanApplicationNotification setSectionId={setSectionId} /> 
        </div>
    )
}

export default Application