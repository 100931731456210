import { Grid, Container ,Typography, Box, Button } from "@material-ui/core";
import {createTheme, ThemeProvider } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { isMobile } from "react-device-detect";
import CSSRulePlugin from "gsap/CSSRulePlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import Lottie from "lottie-web"
import Footer from "../components/LandingPage/Footer";
import Header from "../components/LandingPage/Header";
import InviteFriend from "../components/LandingPage/InviteFriend";
import Arrow from "../assets/images/landingPage/Arrow.svg";
import Benefits from "../components/LandingPage/Benefits";
import Confused from "../components/LandingPage/Confused";
import GetStarted from "../components/LandingPage/GetStarted";
import EasyPayment from "../components/LandingPage/EasyPayment";
import Security from "../components/LandingPage/Security";
import PanelSwipers from "../components/LandingPage/PanelSwipers";
import Steps from "../components/LandingPage/Steps";
import banner from "../assets/images/landingPage/Group 3.png";
import EllipseOne from "../assets/images/landingPage/Ellipse 3.png";
import EllipseTwo from "../assets/images/landingPage/Ellipse 4.png";
import icon4 from "../assets/images/landingPage/Group 4.png";
import icon1 from "../assets/images/landingPage/Group 5.png";
import icon2 from "../assets/images/landingPage/Group 6.png";
import icon3 from "../assets/images/landingPage/Group 7.png";
import aboutWheezRightImg from "../assets/images/landingPage/home/aboutWheez/rightImg.svg";
import line from "../assets/images/landingPage/home/line.svg";
import inviteFreindCTwo from "../assets/images/landingPage/home/invitefriend/Frame 1302.png";
import inviteFreindCOne from "../assets/images/landingPage/home/invitefriend/invitefriend.svg";
import inviteFreindCThree from "../assets/images/landingPage/home/invitefriend/Visual data-pana 1.png";
import boxOne from "../assets/images/landingPage/home/Ellipse 1879.png";
import boxTwo from "../assets/images/landingPage/home/Ellipse 1881.png";
import easyPaymentCOne from "../assets/images/landingPage/home/easyPayment/easyPayment.svg";
import easyPaymentCTwo from "../assets/images/landingPage/home/easyPayment/Frame 1305.png";
import easyPaymentCThree from "../assets/images/landingPage/home/easyPayment/Top up credit-pana 1.png";
import "../styles/landingPage/globals.scss";
import style from "../styles/landingPage/home.module.scss";
import data from "../assets/animatedSVG/animatedArrow.json"
gsap.registerPlugin(ScrollTrigger, CSSRulePlugin);

export const Filter = () => {
  return (
    <Box width={"398px"} height={"129px"}>
      <img src={boxOne} className={style["filters_one"]} />
    </Box>
  );
};

export const Filters = () => {
  return (
    <Box width={"398px"} height={"129px"}>
      <img src={boxTwo} className={style["filters_one"]} />
    </Box>
  );
};

const LandingPage = () => {
  let circleImgOne = useRef(null);
  let circleImgTwo = useRef(null);
  const [isGetStarted, setIsGetStarted] = useState(false)

  useEffect(() => {
    const time = gsap.timeline({
      repeat: -1,
      delay: 1,
      repeatDelay: 1,
      yoyo: true,
    });

    // time.fromTo(
    //   ".Lineeeee",
    //   { drawSVG: "0", opacity: 0 },
    //   { opacity: 1, drawSVG: "100%", duration: 1, ease: "power1.out" }
    // );

    gsap.to(circleImgOne, {
      opacity: 1,

      x: 175,
      ease: "power1.out",
      duration: 2,
    });
    gsap.to("#Icons", {
      opacity: 1,

      y: -50,
      ease: "power3.out",
      duration: 2,
    });
    gsap.to(circleImgTwo, {
      opacity: 1,
      x: -175,
      ease: "power1.out",
      duration: 2,
    });

    // gsap.fromTo(
    //   ["#section-two", "#section-three"],
    //   { opacity: 0, y: 100 },
    //   {
    //     opacity: 1,
    //     ease: "power1.out",
    //     duration: 1.15,
    //     y: 0,
    //     scrollTrigger: {
    //       trigger: "#quick",

    //       start: "center-=200 center+=200",
    //     },
    //   }
    // );
    gsap.fromTo(
      ["#see-section-one"],
      { opacity: 0, x: 200 },
      {
        opacity: 1,
        ease: "power1.out",
        duration: 1.15,
        x: 0,
        scrollTrigger: {
          trigger: ".see-main",

          start: "center-=200 center+=200",
        },
      }
    );
    gsap.fromTo(
      ["#see-section-Two"],
      { opacity: 0, x: -200 },
      {
        opacity: 1,
        ease: "power1.out",
        duration: 1.15,
        x: 0,
        scrollTrigger: {
          trigger: ".see-main",

          start: "center-=200 center+=200",
        },
      }
    );
    gsap.fromTo(
      ["#quickId1"],
      { opacity: 0, x: -200 },
      {
        opacity: 1,
        ease: "power1.out",
        duration: 1.15,
        x: 0,
        scrollTrigger: {
          trigger: "#quickId1",
          start: "center-=10 center+=300",
        },
      }
    );
    gsap.fromTo(
      ["#quickId3"],
      { opacity: 0, x: -200 },
      {
        opacity: 1,
        ease: "power1.out",
        duration: 1.15,
        x: 0,
        scrollTrigger: {
          trigger: "#quickId3",
          start: "center-=10 center+=350",
        },
      }
    );
    gsap.fromTo(
      ["#quickId2"],
      { opacity: 0, x: -200 },
      {
        opacity: 1,
        ease: "power1.out",
        duration: 1.15,
        x: 0,
        scrollTrigger: {
          trigger: "#quickId2",
          start: "center-=10 center+=300",
        },
      }
    );
    gsap.fromTo(
      ["#quickId4"],
      { opacity: 0, x: -200 },
      {
        opacity: 1,
        ease: "power1.out",
        duration: 1.15,
        x: 0,
        scrollTrigger: {
          trigger: "#quickId4",
          start: "center-=10 center+=350",
        },
      }
    );

    gsap.fromTo(
      ".get-starte",
      { rotation: 360 },
      {
        ease: "power1.out",
        duration: 3,
        rotation: 0,
        scrollTrigger: {
          trigger: ".get-starte",

          toggleActions: "restart restart restart restart",
        },
      }
    );
  }, []);

  React.useEffect(() => {
      
      Lottie.loadAnimation({
        container: document.querySelector("#react-logo"),
        animationData: data,
        loop: true,
        autoplay: true,
      });
  }, []);

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1140,
        xl: 1440,
      },
    },
    typography: {
      fontFamily: "Gilroy",
      allVariants: {
        textTransform: "none",
        color: "#F4F4F4",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          text: {
            textTransform: "none",
          },
          root: {
            "&:hover": {
              backgroundColor: "transparent",
              color: "#72E2BD",
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            background: "orange"
          }
        }
      }
    },
    palette: {
      mode: "dark",
      primary: {
        main: "#4283FF",
        secound: "#6055AD",
        third: "#3B346D",
      },
      secondary: {
        main: "#5056EE",
        secound: "#363CD4",
        third: "#E9D5CA",
      },
      grey: {
        50: "#191920",
      },
    },
  });

  const openGetStarted = () => {
    setIsGetStarted(true)
  }
  const closeGetStarted = () => {
    setIsGetStarted(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <div >
        <GetStarted  open={isGetStarted} onClose={closeGetStarted} />
        <Header getStarted={openGetStarted} />
        <div className={style["section-two-main"]} id={"home"} >
          <Box
            position={"relative"}
            zIndex={1}
            height={"100%"}
            overflow="hidden">
           
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              padding={"100px 0px"}>
              <Grid item style={{marginTop: '4%'}}>
                <Box>
                  {!isMobile ? <div className="wrapper">
                    <div className="static-txt">The New</div>
                    <ul className="dynamic-txts">
                      <li>
                        <span style={{lineHeight: '92px'}} className="dynamic-txts1">Efficient</span>
                      </li>
                      <li>
                        <span style={{lineHeight: '81px'}} className="dynamic-txts1">Socialized</span>
                      </li>
                      <li>
                        <span style={{lineHeight: '82px'}} className="dynamic-txts1">Alternative</span>
                      </li>
                    </ul>
                  </div>
                  :
                  <div className="wrapper">
                    <div style={{lineHeight: '50px'}} className="static-txt">The New</div>
                    <ul style={{lineHeight: '50px'}} className="dynamic-txts">
                      <li>
                        <span style={{lineHeight: '50px'}} className="dynamic-txts1">Efficient</span>
                      </li>
                      <li>
                        <span style={{lineHeight: '108px'}} className="dynamic-txts1">Socialized</span>
                      </li>
                      <li>
                        <span style={{lineHeight: '75px'}} className="dynamic-txts1">Alternative</span>
                      </li>
                    </ul>
                  </div>
                  }
                  {!isMobile ? <div className="wrapper">
                    <div className="static-txt">Way To</div>
                    <ul className="dynamic-txts">
                      <li>
                        <span style={{lineHeight: '92px'}} className="dynamic-txts2">Save</span>
                      </li>
                      <li>
                        <span style={{lineHeight: '85px'}} className="dynamic-txts2">Borrow</span>
                      </li>
                      <li>
                        <span style={{lineHeight: '80px'}} className="dynamic-txts2">Earn</span>
                      </li>
                    </ul>
                  </div>
                  :
                  <div className="wrapper">
                    <div style={{lineHeight: '40px'}} className="static-txt">Way To</div>
                    <ul className="dynamic-txts">
                      <li>
                        <span style={{lineHeight: '65px'}} className="dynamic-txts2">Save</span>
                      </li>
                      <li>
                        <span style={{lineHeight: '75px'}} className="dynamic-txts2">Borrow</span>
                      </li>
                      <li>
                        <span style={{lineHeight: '75px'}} className="dynamic-txts2">Earn</span>
                      </li>
                    </ul>
                  </div>
                  }
                  <Typography
                    id="resp-main-desc"
                    >
                    The democratized financial plan to improve your financial
                    lives.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "13vh 0px 0px 0px",
                    }}>
                    <Box className={`${style["box-shadow"]} box-shadow`}>
                      <Box
                        sx={{
                          background:
                            "linear-gradient(#407BFF, #2223268f, #407BFF)",
                          height: { sm: "150px", xs: "22vh" },
                          width: { sm: "150px", xs: "22vh" },
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                        }}>

                        <Button 
                          onClick={() => openGetStarted()}
                          id="resp-circle-getStarted">Get Started
                        </Button>
                      </Box>
                      <Box className={"get-starte"}></Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box
              position={"absolute"}
              height={"100px"}
              bottom={"31px"}
              width={"100%"}
              zIndex={2}
              className={style["buttom-color"]}>
              <Box
                position={"absolute"}
                zIndex={2}
                top={{ sm: "-50px", xs: "-60px" }}
                left={{ sm: "25%", xs: "-20%" }}>
                <Filter />
              </Box>
              <Box
                position={"absolute"}
                zIndex={2}
                top={{ sm: "-50px", xs: "-60px" }}
                left={{ sm: "45%", xs: "20%" }}>
                <Filters />
              </Box>
            </Box>

            <Box
              position={"absolute"}
              top="-23px"
              zIndex="-1"
              width={"100%"}
              height={"100%"}>
              <img src={banner} width="100%" height={"100%"} />
            </Box>


            <Box
              position={"absolute"}
              ref={(el) => (circleImgOne = el)}
              left={{ sm: "-175px", xs: "-475px" }}
              top={{ sm: "50px", xs: "100px" }}
              zIndex={"-1"}>
              <img src={EllipseOne} />
            </Box>
            <Box
              position={"absolute"}
              ref={(el) => (circleImgTwo = el)}
              right={{ sm: "-175px", xs: "-475px" }}
              bottom={{ sm: "100px", xs: "100px" }}
              zIndex={"-1"}>
              <img src={EllipseTwo} />
            </Box>
            {/* icone */}
            <Box
              position={"absolute"}
              id={"Icons"}
              bottom={{ sm: "200px", xs: "25%" }}
              left={{ sm: "90px", xs: "5%" }}
              zIndex={3}
              opacity={"1"}>
              <img src={icon1} />
            </Box>

            <Box
              position={"absolute"}
              id={"Icons"}
              bottom={{ sm: "5%", xs: "4%" }}
              left={{ sm: "20%", xs: "15%" }}
              zIndex={3}
              opacity={"1"}>
              <img src={icon3} />
            </Box>
            <Box
              position={"absolute"}
              right={{ sm: "100px", xs: "20px" }}
              bottom={{ sm: "390px", xs: "350px" }}
              id={"Icons"}
              zIndex={-1}
              opacity={"1"}>
              <img src={icon2} />
            </Box>
            <Box
              position={"absolute"}
              right={{ sm: "17%", xs: "10%" }}
              id={"Icons"}
              bottom={{ sm: "120px", xs: "100px" }}
              zIndex={3}
              opacity={"1"}>
              <img src={icon4} />
            </Box>
          </Box>
        </div>
        <div>
          <Box
            bgcolor={"#545457"}
            height={"61px"}
            display={"flex"}
            fontWeight={"600"}
            justifyContent={"center"}
            alignItems={"center"}
            paddingX={"15px"}
            fontSize={{ sm: "18px", xs: "14px" }}
            borderBottom={"2px solid black"}
            color={"#fff"}>
            <Box
              width={"100%"}
              textAlign={"center"}
              sx={{
                maxWidth: {
                  sm: "sm",
                  md: "md",
                  lg: "xl",
                  xl: "xl",
                }
              }}>
              Yes You Read It Right. “Demoratize” That Means Your Money Your
              Control. Woohoo.
            </Box>
          </Box>
          <Box
            className={"aboutWheezBg"}
            overflow={"hidden"}
            position={"relative"}
            zIndex={3}
            id={"quick"}>
            <Container
              sx={{
                maxWidth: {
                  sm: "md",
                  md: "xl",
                  lg: "lg",
                  xl: "xl",
                },
                padding: "50px 0px",
                display: "flex",
              }}>
              <Grid container className={style["section-grid-main-2"]}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={6}
                  className={style["grid-item-1"]}
                 >
                  <Box width={{ md: "80%", sm: "100%" }} id={"section-two"}>
                    <Typography
                      className={style["t1"]}
                     >
                      Quick Intro
                      <span
                        style={{color: "#9094E7", marginLeft: '8px'}}
                        >
                        About Wheez
                      </span>
                    </Typography>
                    <Typography
                      style={{color: "#ffffffb3", fontSize: "15px", lineHeight: '25px', textTransform: "capitalize", marginBottom: "31px"}}
                      >
                      Wheez is a unique financial platform, where you can join in
                      a democratized (P2P) Installment plans with your friends or
                      other peers on the platforms to use and maintain
                      efficiently. And we call it as “Wheez Plans”. Wheez plans is
                      an Alternative financial model to use your money
                      efficiently, Either Save, borrow or Earn, Etc... By having a
                      wheez plan you can diversify your financial portfolio and
                      improves your financial lives.
                    </Typography>
                    <Box
                      display={"flex"}
                      style={{gap: '8px'}}
                      alignItems={"center"}
                      mb={"33px"}>
                      <Typography
                        style={{
                          color: '#ffffffb3',
                          fontSize: '15px',
                          textTransform: 'capitalize'
                        }}
                        >
                        And it’s litterally for every one out there.
                      </Typography>
                      <Box
                        display={"inline-block"}
                        padding={"6px 9px"}
                        color={"#3A53E2"}
                        fontWeight={"600"}
                        fontSize={"15px"}
                        bgcolor={"#fff"}
                        borderRadius={"4px"}
                        minWidth={"87px"}>
                        Zero Fees
                      </Box>
                    </Box>
                    <Box
                      className={style["section-button-flex"]}
                      style={{display: 'flex', gap: '20px'}}>
                      <Button
                        className={style["button-1"]}
                        >
                        Learn more
                      </Button>
                      <Button
                        onClick={() => openGetStarted()}
                        className={style["button-2"]}
                      >
                        Sign Up Now
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={7}
                  lg={6}
                  className={style["grid-item-2"]}
                  id={"section-three"}>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    height={"535px"}
                    position={"relative"}>
                    <Box display={"inline-block"}>
                      <img style={{height: '480px'}} src={aboutWheezRightImg} alt="" />
                    </Box>
                    <Box
                      className={style["anim-friendly"]}
                      id={"quickId3"}>
                      Friendly
                    </Box>
                    <Box
                      className={style["anim-trendy"]}
                      id={"quickId4"}>
                      Trendy
                    </Box>
                    <Box
                      className={style["anim-reliable"]}
                      id={"quickId1"}>
                        Reliable
                    </Box>
                    <Box
                      className={style["anim-trust"]}
                      id={"quickId2"}>
                      Trustworthy
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <Box position={"absolute"} top={"0"} left={"0"} zIndex={"-1"}>
              <img src={line} />
            </Box>
          </Box>
        </div>
        <Steps />
        <Box bgcolor="#000000" className={"see-main"}>
          <Container
            sx={{
              maxWidth: {
                sm: "sm",
                md: "xl",
                lg: "lg",
                xl: "xl",
              },
            }}>
            <Grid container className={style["see-grid-main"]}>
              {!isMobile && <>
                <Grid item xs={12} md={6} id={"see-section-one"}>
                  <Typography
                    className={style["see-grid-t1"]}
                    >
                    See It’s
                    <span style={{display: "inline"}} ml={"20px"} >
                      <img src={Arrow} />
                    </span>
                    <br />
                    That
                    <span
                      className={style["see-grid-t2"]}
                    >
                      Simple
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} id={"see-section-Two"}>
                  <Typography
                    variant="subtitle2"
                    className={style["see-subtitle2"]}>
                    In wheez, people are the ones who choose when to take and how
                    much to take, there are no feds that decide how much you pay
                    or earn it's based on you and you alone. Go at your own pace,
                    and take different plans with different goals.
                  </Typography>
                  <Typography
                    className={style["see-subtitle3"]}
                    variant="subtitle2">
                    Start from $50 a month and take it to the moon. Make your
                    finances better on the go, right in the app.
                  </Typography>
                  <Button
                    display={"inline-block"}
                    onClick={() => openGetStarted()}
                    className={style["see-get-started"]}
                    id={"getStartBtn"}>
                    Get Started
                  </Button>
                </Grid>
              </>}
              {isMobile && <>
                <Grid item xs={12} md={6} >
                  <Typography
                    className={style["see-grid-t1"]}
                    >
                    See It’s That
                    <br/>
                    <span style={{display: "flex", alignItems: "center"}}>
                      <span
                        className={style["see-grid-t2"]}
                      >
                        Simple
                      </span>
                      <span className={style["see-image"]} style={{display: "inline", marginLeft: '20px'}} >
                        <img src={Arrow} />
                      </span>
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} >
                  <Typography
                    variant="subtitle2"
                    className={style["see-subtitle2"]}>
                    In wheez, people are the ones who choose when to take and how
                    much to take, there are no feds that decide how much you pay
                    or earn it's based on you and you alone. Go at your own pace,
                    and take different plans with different goals.
                  </Typography>
                  <Typography
                    className={style["see-subtitle3"]}
                    variant="subtitle2">
                    Start from $50 a month and take it to the moon. Make your
                    finances better on the go, right in the app.
                  </Typography>
                  <Button
                    display={"inline-block"}
                    onClick={() => openGetStarted()}
                    className={style["see-get-started"]}
                    id={"getStartBtn"}>
                    Get Started
                  </Button>
                </Grid>
              </>}
            </Grid>
          </Container>
        </Box>
        {isMobile ? <PanelSwipers getStarted={openGetStarted}/> :
          <>
            <section className="panel">
              <InviteFriend
                getStarted={openGetStarted}
                type="pane1"
                img={inviteFreindCOne}
                label="Invite Friends & Family"
                description="Invite friends and family into the platform and plans to help and benefit from each other or make friends from your existing plans."
                Btext="Get Started"
                header={true}
              />
            </section>
            <section className="panel">
            <EasyPayment
              img={easyPaymentCOne}
              getStarted={openGetStarted}
              label="Get Rewarded"
              description="Finish specific tasks and win rewards "
              Btext="Get Started"
            />
            </section>
            <section className="panel">
            <InviteFriend
              img={inviteFreindCTwo}
              getStarted={openGetStarted}
              label="Get upgraded and unlock high tenure plans"
              description="Get upgraded and unlock high tenure plans based on your needs and make use of wheez to its fullest "
              Btext="Get Started"
              header={false}
            />
            </section>
            <section className="panel">
            <EasyPayment
              getStarted={openGetStarted}
              img={easyPaymentCTwo}
              label="Get personalized announcements & tips"
              description="Get personalized announcements and tips from Wheezy( The host) and use the plans wisely and engagingly"
              Btext="Get Started"
            />
            </section>
            <section className="panel">
            <InviteFriend
              img={inviteFreindCThree}
              label="Stay On Track"
              getStarted={openGetStarted}
              description="Get clear analytics reminders, and notifications to keep track of your plans."
              Btext="Get Started"
              header={false}
            />
            </section>
            <section className="panel">
            <EasyPayment
              getStarted={openGetStarted}
              img={easyPaymentCThree}
              label="Hustle Free Withdrawals & Easy payments"
              description="Easy and automatic withdrawal of funds to your linked bank accounts and literally Zero fees. "
              Btext="Get Started"
            />
            </section>
          </>
        }
        <Benefits />
        <Security />
        <Box className="resp-confused-box" bgcolor={"#000"}>
          <Confused getStarted={openGetStarted}/>
        </Box>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default LandingPage;
