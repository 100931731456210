import React from 'react'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
    icon:{
        borderRadius: 6,
        width: 20,
        height: 20,
        backgroundColor:'#898989',
    },
    checkedIcon: {
        backgroundColor: '#4283FF',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#4283FF',
        },
    },
});



function BpCheckbox(props) {
    const classes = useStyles();

    return (
        <Checkbox
            sx={{
                '&:hover': { bgcolor: 'transparent' },
            }}
            disableRipple
            onChange={(e) => props.handleChecked(e)}
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            size={props.size}
            defaultChecked={props.defaultChecked}
        />
    );
}
export default function RememberMe(props) {
    return (
        <BpCheckbox handleChecked={props.handleChecked} size={props.size} defaultChecked={props.defaultChecked} />
    )
}
