import React from 'react'

const DesktopWrapper = (App) => {
    const Wrapper = () => {
        // let currentRoute = window.location.pathname;
        // return 
        // currentRoute === "/" || currentRoute === "/home" ? 
        //    return <Provider store={store}>
        //         <Router>
        //             <Switch>
        //                 <Route path="/" component={Home2}/> 
        //             </Switch>
        //         </Router>
        //     </Provider> 
            // : 
            // <PageNotFoundDesktop />
        //  return  <div style={{
        //         maxWidth: '414px',
        //         width: '100%',
        //         margin: '0 auto',
        //         minHeight: '100vh',
        //         position: 'relative',
        //         overflow: 'hidden'
        //     }}><App/></div>
        return  <App />
    }
    return Wrapper
}

export default DesktopWrapper