import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ChatRoomComponent from '../components/ChatRoomComponent';
import Loading from '../components/Loading';
import { chatSocket, getAllChats, closeSocket } from '../redux/actions/chatActions';
import { getPlanDetails} from '../redux/actions/planActions';
import { sendRequest, getAllAvatars } from '../redux/actions/contactActions';
import tickIcon from './../assets/images/icons/checkIcon.svg';
// import ellipsisBlue from './../assets/images/icons/blueEllipsisBox.svg';
import DP from './../assets/images/DP.png';
import divider from './../assets/images/icons/divider.svg';
import ellipsisHorizontal from './../assets/images/icons/greyDropdown.svg';
import backIcon from './../assets/images/icons/backIcon.svg';
import './../styles/chatRoom.scss';
const BASE_URL = `http://${process.env.REACT_APP_WHEEZ_SERVER_URL}`;

const ChatRoom = (props) => {
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const chatState = useSelector((state) => state.chat);
    const planState = useSelector((state) => state.plans);
    const uiState = useSelector((state) => state.UI);
    const contactsState = useSelector((state) => state.contacts);
    const { userAvatars } = contactsState;

    const planId = id;
    const [chatRoomOpened, setChatRoomOpened] = useState(true);
    const [addNowText, setAddNowText] = useState([]);
    const [isExpandedOpen, setIsExpandedOpen] = useState(false);

 
    useEffect(() => {
        if(isExpandedOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    });
  
    useEffect(() => {
        dispatch(getAllChats(planId));
        dispatch(getAllAvatars());
        setTimeout(() => {
            dispatch(chatSocket(planId));
        }, 1500);
        dispatch(getPlanDetails(planId));
        return function () {
            dispatch(closeSocket());
        }
    }, [dispatch, planId])


    useEffect(() => {
        if(planState.planDetails && planState.planDetails.members && planState.planDetails.members.length > 0){
            planState.planDetails.members.map((member) => {
                let array = addNowText;
                if(array[member.id] && array[member.id] === 'sent'){

                } else {
                    array[member.id] = "add now!"
                    setAddNowText(array)
                }
                return null
            })
        }
    }, [planState.planDetails, addNowText])

    const toggleExpanded = () => {
        setIsExpandedOpen(!isExpandedOpen);
    }
    const selectMenuItem = (id) => {
        setIsExpandedOpen(false);
        if(id === 3) {
            setChatRoomOpened(false);
        }
    }
    const handleClickOutside = (event) => {
        if(isExpandedOpen && event.target.className !== "menuDrop" && event.target.className !== "menu-item") {
            if(!(props.isGuide && props.guideStep === 10)){
                setIsExpandedOpen(false);
            }
        }
    }
    const backAction = () =>{
        if(chatRoomOpened){
            history.goBack();
        } else {
            setChatRoomOpened(true);
        }
    }
    const handleRedirection = () => {
        // dispatch(closeSocket());
        history.push(`/bidding/${planId}`);
    }
    const linkToPlanDetails = () => {
        // dispatch(closeSocket());
        history.push(`/planDetails/${planId}`);
    }
    const sendInvite = (id) => {
        let userData = {
            "value": id,
            "value_type": "id"
        }
        dispatch(sendRequest(userData)).then((res=> {
            let array = addNowText;
            array[id] = "sent"
            setAddNowText(array)
        }))
    }
    return (
        uiState.loading ? <Loading /> :
        <div className="chatRoomContainer">
            <div className="chatRoomHeader">
                <div className='left'>
                    <img onClick={() => backAction()} src={backIcon} alt="icon" />
                    <div className='onlineCount' onClick={() => linkToPlanDetails()}>{planState.planDetails && planState.planDetails.no_of_members ? planState.planDetails.no_of_members : 0}</div>
                    <div onClick={() => linkToPlanDetails()}>
                        <span className="title">Chat room</span>
                        <div className="planInfo">
                            {planState.planDetails.title}
                        </div>
                    </div>
                </div>
                <div className="chatButtons">
                    <div onClick={() => {  handleRedirection() }} className="bidIcon">Auction Room</div>
                    <div className="expandedMenu">
                        <div className="dropdown">
                            <img  onClick={toggleExpanded}  src={ellipsisHorizontal} alt="icon"/>
                            {isExpandedOpen && (
                                <div role="list" className="dropdown-content-menu">
                                    <span className="menu-item" onClick={() => linkToPlanDetails()}>Details</span>
                                    <span className="menu-item">Report</span>
                                    <span className="menu-item" onClick={() => selectMenuItem(3)}>People</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            { chatRoomOpened && <ChatRoomComponent  messages={chatState.chatMessages}/>}
            {!chatRoomOpened && <div style={{paddingTop: '85px'}}>
                <div className="memberSection">
                    <div className="title">Members</div>
                    {planState.planDetails && planState.planDetails.members && <div className='members'>
                        {planState.planDetails && planState.planDetails.members && planState.planDetails.members.length > 0 &&
                            planState.planDetails.members.map((member, index)=> 
                            {
                                let avatar = userAvatars.filter(u => u.id === member.avatar)
                                return (
                                    <div key={member.id} className="listItem">
                                        {avatar.length>0 ? <img className='profileImage' src={`${BASE_URL}${avatar[0].image}`} alt="icon" /> 
                                        : ( member.profile_image ? 
                                            <img className='profileImage' src={`${BASE_URL}${member.profile_image}`} alt="icon" /> 
                                            :
                                            <img className='profileImage' src={DP} alt="icon"/>
                                        )}
                                        <div className="content">
                                            <div className="name">{member.first_name} {member.last_name}</div>
                                            {member.details && <div className="location">{member.details.country && member.details.country}</div>}
                                        </div>
                                        {member.details && member.details.is_contact && <div className='info'>Friends</div>}
                                        {member.details && !member.details.is_contact && member.id !== localStorage.getItem('userId') && 
                                            <div onClick={() => sendInvite(member.id)} className='info'>
                                                {addNowText[member.id] === 'sent' && <img src={tickIcon} alt="icon" />}
                                                <span>{addNowText[member.id]}</span>
                                            </div>
                                        }

                                        {/* <img className='option' src={ellipsisBlue} alt="icon" /> */}
                                        {index !== planState.planDetails.members.length-1 && <img className='divider' src={divider} alt="icon" />}
                                    </div>
                                )
                            }
                            )
                        }
                    </div>
                    }
                </div>
            </div>}
        </div>
    )
}

export default ChatRoom