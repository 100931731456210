import React, {useEffect, useState} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Loading from './../components/Loading';
import { sendRequest, getPublicProfileInfo, getAllAvatars } from '../redux/actions/contactActions';
import backIcon from './../assets/images/icons/backIcon.svg';
import settingsWhite from './../assets/images/icons/settingsWhite.svg';
import DP from './../assets/images/DP.png';
import locIcon from './../assets/images/icons/locIcon.svg';
import divider from './../assets/images/icons/divider.svg';
import tickSquareWhite from './../assets/images/icons/tickSquareWhite.svg';
import './../styles/profile.scss';

const BASE_URL = `http://${process.env.REACT_APP_WHEEZ_SERVER_URL}`;

const Profile = () => {
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const contactsState = useSelector((state) => state.contacts);
    const userState = useSelector((state) => state.user);

    const uiState = useSelector((state) => state.UI);
    const {
        userProfile,
        userAvatars
      } = contactsState;
    const {
        selfProfileImage
      } = userState;
    const [avatar, setAvatar] = useState(null)

    useEffect(() => {
        dispatch(getPublicProfileInfo(id));
        dispatch(getAllAvatars());
    }, [dispatch, id])
    useEffect(() => {
        if(userProfile.avatar){
            const userAvatar = userAvatars.filter(u => u.id === userProfile.avatar)
            if(userAvatar.length > 0){
                setAvatar(userAvatar[0])
            }
        } else {
            setAvatar(null)
        }
    },[userProfile, userAvatars])
    
    const addFriend = () => {
        if(!userProfile.is_contact && userProfile.friend_request_status.status !== 'Pending'){
            let userData = {
                "value": userProfile.id,
                "value_type": "id"
            }
            dispatch(sendRequest(userData)).then(res => {
                if(res){
                    dispatch(getPublicProfileInfo(id));
                }
            })
        }
    }
    let selfProfile = false;
    let selfId = localStorage.getItem("userId");
    if(id === selfId){
        selfProfile = true;
    }
    return (
        uiState.loading ? <Loading /> :
        <div className="profilePage">
            <div className='greyArea'>
                <div className="header">
                    <img onClick={() => history.goBack()} className='backIcon' src={backIcon} alt="icon" />
                    <div>Profile</div>
                    {selfProfile ? <Link to="/settings/0"><img className='settingsIcon'  src={settingsWhite} alt="icon" /></Link> : <span style={{width: '24px'}}></span>}
                </div>
                <div className='userInfo'>
                    <div className='mainContent'>
                        {avatar ? 
                            <img src={`${BASE_URL}${avatar.image}`} alt="icon" /> 
                            : (
                                selfProfile && selfProfileImage ? <img src={`${BASE_URL}${selfProfileImage}`} alt="icon" /> :
                                (
                                    userProfile && userProfile.profile_image ? <img src={`${userProfile.profile_image}`} alt="icon" /> 
                                    :
                                    <img src={DP} alt="icon" /> 
                                ) 
                            )
                        }
                        <div className='content'>
                            <div className='row'>
                                <span className='name'>{userProfile.first_name} {userProfile.last_name}</span>
                                {userProfile.username && <span className='username'>@{userProfile.username}</span>}
                            </div>
                            {userProfile.occupation_custom && <div className='profession'>
                                {userProfile.occupation_custom}
                            </div>}
                            {userProfile.country && <div className='locInfo'>
                                <img src={locIcon} alt="icon" />
                                <div className='text'>{userProfile.country}</div>
                            </div>}
                        </div>
                    </div>
                    <div className='rowFlex'>
                        <div className='countContacts'>
                            <span className='count'>{userProfile.no_of_contacts}</span>
                            <span className='text'>Wheez Friends</span>
                        </div> 
                        {!selfProfile && userProfile.friend_request_status && 
                        <div 
                            className={`friendType ${userProfile.friend_request_status.status === 'Pending' && 'disabled'}`} 
                            onClick={() => addFriend()}
                        >
                            {userProfile.is_contact && <>
                                <img src={tickSquareWhite} alt="icon" />
                                <span>Friends</span>
                            </>}
                            {userProfile.friend_request_status && userProfile.friend_request_status.status === "Pending" &&
                                <span>Sent</span>
                            }
                            {!userProfile.is_contact && userProfile.friend_request_status.status !== "Pending" &&
                                <span>Add Now</span>
                            }
                        </div>}
                    </div>
                     
                </div>  
            </div>
            {userProfile.no_of_plans && userProfile.no_of_plans > 0 &&
                <div className='planCount'>
                    Total Plans: {userProfile.plans.filter(p => p.status === 'Active' || p.status === 'Running').length}
                </div>
            }
            <div className='planInfo'>
                
                {!userProfile.privacy && userProfile.plans && userProfile.plans.length > 0 && <div className='info'>Active Plans</div>}
                {
                    !userProfile.privacy && userProfile.plans && userProfile.plans.map((plan) => {
                        if(plan.status === "Active" || plan.status === "Running"){
                            return (
                                <div key={plan.id} className='planCard'>
                                    <span className='memberCount'>{plan.no_of_members}</span>
                                    <div className='planDetails'>
                                        <div className='planName'>
                                            {plan.title}
                                        </div>
                                        <div className='planType'>
                                            x${plan.amount_to_contribute.substring(0, plan.amount_to_contribute.length - 3)}&nbsp; 
                                            Per&nbsp;
                                            {plan.type.interval_period === 1 && 'Day'}
                                            {plan.type.interval_period === 7 && 'Week'}
                                            {plan.type.interval_period === 30 && 'Month'}&nbsp;
                                            For {plan.no_of_auctions}&nbsp;
                                            {plan.type.interval_period === 1 && 'Day'}
                                            {plan.type.interval_period === 7 && 'Week'}
                                            {plan.type.interval_period === 30 && 'Month'}s
                                        </div>
                                    </div>
                                </div>
                                )
                        }
                        return null
                    })
                }
            </div>
            {userProfile.no_of_mutual_contacts > 0 && !selfProfile && <>
                <img className='divider' src={divider} alt="icon" />
                <div className='friendsInfo'>
                    <span className='count'>{userProfile.no_of_mutual_contacts}</span>
                    <span className='text'>Mutual Contacts</span>
                </div>
            </>}
        </div>
    )
}



export default Profile