import {
    BIDDING_CONFIG,
    BIDDING_DATA,
    AUCTION_ID,
    PLAN_DETAILS,
    BIDDING_DATA_RECEIVE,
    RESET,
    AUCTION_WINNER,
    CLEAR_AUCTION,
    TRIGGER_FEEDBACK,
    CLEAR_ERROR_400,
    ERROR_400_FROM_SERVER,
    FLAG_ENABLED_FROM_NETWORK,
    AUCTION_STATUS,
    SOCKET_STATUS
} from '../types';
    
const initialState = {
    biddingConfig: {},
    bidData: [],
    currentBid: {},
    auctionId: "",
    planDetails: {},
    error400Message: "",
    auctionWinner: {},
    triggerFeedback: false,
    auctionFinished:null,
    auctionStatus: '',
    socketRunning: false,
    flagEnabledFromNetwork: false
};

export default function practiceBiddingReducer(state = initialState, action) {
    switch (action.type) {
    case BIDDING_CONFIG:
        return {
            ...state,
            biddingConfig: action.payload
        }
    case RESET:
        return{
            ...state,
            auctionFinished:true,
            triggerFeedback: false
        }
    case CLEAR_AUCTION:
        return{
            ...state,
            biddingConfig: {},
            planDetails: {},
            auctionWinner: {},
            error400Message: "",
            auctionId: "",
            bidData: [],
            currentBid: {},
            
        }
    case ERROR_400_FROM_SERVER:
        return {
            ...state,
            error400Message: action.payload
        }
    case CLEAR_ERROR_400:
        return {
            ...state,
            error400Message: ""
        }
        
    case SOCKET_STATUS:
        return {
            ...state,
            socketRunning: action.payload
        }
    case AUCTION_STATUS:
        return {
            ...state,
            auctionStatus: action.payload
        }
    case TRIGGER_FEEDBACK: 
        return {
            ...state,
            triggerFeedback: true
        }
    case BIDDING_DATA:
        let sortedData = action.payload;
        if(action.payload){
            sortedData = sortedData.sort((a,b) => {
                return new Date(a.bid_at) - new Date(b.bid_at)
            })
            let currentEvent = sortedData.filter((event) => {
                return event.event_type.toLowerCase() === "bid"
            })
            return {
                ...state,
                bidData: action.payload,
                currentBid: currentEvent.length > 0 ? currentEvent[currentEvent.length-1] : {}
            }
            
        } else {
            return {
                ...state,
                bidData: [],
                currentBid: {},
            }
        }
    case FLAG_ENABLED_FROM_NETWORK:
        return {
            ...state,
            flagEnabledFromNetwork: action.payload
        }
    case BIDDING_DATA_RECEIVE:
        if(action.payload.event_type.toLowerCase() === "bid"){
            return {
                ...state,
                bidData: [...state.bidData, action.payload],
                currentBid: action.payload,
            }
        } else if(action.payload.event_type.toLowerCase() === "flag") {
            return {
                ...state,
                bidData: [...state.bidData, action.payload],
                currentBid: state.currentBid
            }
        } else {
            return {
                ...state,
                bidData: [...state.bidData, action.payload],
                currentBid: state.currentBid
            }
        }
    case PLAN_DETAILS:
        return {
            ...state,
            planDetails: action.payload,
            auctionFinished:null

        }
    case AUCTION_ID:
        return {
            ...state,
            auctionId: action.payload
        }
    case AUCTION_WINNER:
        return {
            ...state,
            auctionWinner: action.payload
        }
        
    default:
        return state;
    }
}