import React, { useMemo, useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { 
  getCities
} from '../redux/actions/userActions';

function StateSelector(props) {
  const dispatch = useDispatch()
  const countryStates = useSelector((state) => state.user.countryStates)
  const userProfile = useSelector((state) => state.user.userProfile)
  const stateOptions = useMemo(() => countryStates.map((c) => {return {name: c.short_code, label: c.title, id: c.id}}), [countryStates])
  const colourStyles = {
    control: styles => ({ ...styles,
      boxSizing: 'border-box',
      fontSize: '18px',
      outline: 'none',
      width: '100%',
      border: '2px solid rgba(255,255,255,0.8)',
      padding: '10px 20px 10px 20px',
      color: 'rgba(255,255,255,0.8)',
      background: 'transparent',
      borderRadius: '10px',
      display: 'flex'
    }),
    placeholder:styles => ({ ...styles, textTransform: 'capitalize',marginLeft:'0'}),
    valueContainer:styles => ({ ...styles,paddingLeft:'0'}),
    container: styles => ({ ...styles, width: '100%',}),
    input: styles => ({ ...styles, color: 'white',}),
    singleValue: styles => ({ ...styles,  color: 'rgba(255,255,255,0.8)' }),
    indicatorSeparator: styles => ({ ...styles, display: 'none' }),
  };
  useEffect(() => {
    if(userProfile && userProfile.state && userProfile.state.id){
      dispatch(getCities(userProfile.state.id))
    }
  },[userProfile, dispatch])
  return <Select 
    options={stateOptions} 
    value={props.value && props.value.value === '' ? null : props.value} 
    placeholder="State"
    onChange={props.stateSelect} 
    styles={colourStyles}
    />
}

export default StateSelector