import React, { useRef } from 'react';
import { BrowserRouter as Router, Route  } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import { isMobile } from "react-device-detect";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import DesktopWrapper from './components/DesktopWrapper';
import GATracking from './components/GATracking';
import ScrollToTop from './components/ScrollToTop';
import Navbar from './components/Navbar';
import login from './pages/login';
import signup from './pages/signup';
import notifications from './pages/notifications';
import feedback from './pages/feedback';
import PageNotFound from './components/PageNotFound';
import dashboard from './pages/dashboard';
import wallet from './pages/wallet';
import application from './pages/application';
import settings from './pages/settings';
import profile from './pages/profile';
import bidding from './pages/bidding';
import biddingDemo from './pages/biddingDemo';
import chatRoom from './pages/chatRoom';
import practiceBidding from './pages/practiceBidding';
import friends from './pages/friends';
import planDetails from './pages/planDetails';
import planDetailsDemo from './pages/planDetailsDemo';
import plans from './pages/plans';
import expiredSession from './pages/expiredSession';
// import forgotPassword from './pages/forgotPassword';
// import registerSuccess from './pages/registerSuccess';
// import accountLinkedSuccess from './pages/accountLinkedSuccess';
// import Home2 from './pages/home2';
// import Home from './pages/home';
// import learn from './pages/learn';
// import changePassword from './pages/changePassword';
// import confirmNewPassword from './pages/confirmNewPassword';
import store from './redux/store';
import { logoutUser } from './redux/actions/userActions';
import AuthRoute from './util/AuthRoute';
import { SET_AUTHENTICATED } from './redux/types';
import LandingPage from './pages/landingPage';

const App = () => {
  const container = useRef();
  const theme = createTheme({
    typography: {
      fontFamily: "GilRoy"
    },
    palette: {
      dark: '#090909',
    },
  });
  const token = localStorage.accessToken;
  // if(token) {
  //   const expiryTime = localStorage.expiryTime;
  //   if(expiryTime < Date.now()){
  //       store.dispatch(logoutUser());
  //       window.location.href = '/expiredSession';
  //   } else {
  //     store.dispatch({type: SET_AUTHENTICATED});
  //   }
  // }
  const  removeContent = () => {
    setTimeout(() => {
      container.current.style.display = "none";
    }, 500);
  }
  const addContent = () => {
    container.current.style.display = "block";
  }
  return (
    <Provider store={store}>
        <Router>
          {/* {isMobile && <Navbar addContent={addContent} removeContent={removeContent} ></Navbar>} */}
          <div style={{paddingTop: '68px'}} ref={container}>
            <ScrollToTop />
            <ThemeProvider theme={theme}>
              <AnimatedSwitch
              atEnter={{ opacity: 0 }}
              atLeave={{ opacity: 0 }}
              atActive={{ opacity: 1 }}
              className="switch-wrapper"
              >
                <GATracking>
                  {/* <Route path="/registerSuccess" exact component={registerSuccess}></Route> */}
                  {/* <Route path="/forgotPassword"  exact component={forgotPassword}></Route> */}
                  {/* <Route path="/confirmNewPassword" exact component={confirmNewPassword}/>  */}
                  {/* <Route path="/" exact component={isMobile? Home : Home2}/>  */}
                  {/* <Route path="/home" exact component={isMobile? Home : Home2}/>  */}
                  {/* <Route path="/learn" exact component={learn}/>  */}
                  {/* <AuthRoute exact path="/accountLinkedSuccess" component={accountLinkedSuccess}></AuthRoute> */}
                  {/* <AuthRoute exact path="/changePassword" component={changePassword}></AuthRoute> */}
                  {/* <Route path="/" exact component={login}/>  */}
                  {/* <Route exact path="/login" component={login}></Route> */}
                  {/* <Route path="/signup" exact component={signup}></Route> */}
                  {/* <Route path="/signup/:id" exact component={signup}></Route> */}
                  {/* <Route path="/expiredSession" component={expiredSession}/> */}
                  <Route path="/" component={LandingPage}/> 
                  <Route path="/home" component={LandingPage}/> 
            

                  {/* <AuthRoute path="/" exact component={dashboard}></AuthRoute> */}
                  {/* <AuthRoute exact path="/dashboard" component={dashboard}></AuthRoute> */}
                  {/* <AuthRoute exact path="/feedback" component={feedback}></AuthRoute> */}

                  {/* <AuthRoute path="/settings/:id" exact component={settings}></AuthRoute> */}
                  {/* <AuthRoute path="/notifications" exact component={notifications}></AuthRoute> */}
                  {/* <AuthRoute exact path="/friends/:id" component={friends}></AuthRoute> */}
                  {/* <AuthRoute exact path="/wallet/:id" component={wallet}></AuthRoute> */}

                  {/* <AuthRoute exact path="/profile/:id" component={profile}></AuthRoute> */}
                  {/* <AuthRoute exact path="/application" component={application}></AuthRoute>                 */}
                    
                  {/* <AuthRoute path="/bidding/:id" exact component={bidding}></AuthRoute> */}
                  {/* <AuthRoute path="/biddingDemo/:id" exact component={biddingDemo}></AuthRoute> */}
                  {/* <AuthRoute path="/practiceBidding" exact component={practiceBidding}></AuthRoute> */}
                  {/* <AuthRoute path="/chatRoom/:id" exact component={chatRoom}></AuthRoute> */}

                  {/* <AuthRoute exact path="/plans/:id" component={plans}></AuthRoute> */}
                  {/* <AuthRoute exact path="/planDetailsDemo/:id" component={planDetailsDemo}></AuthRoute> */}
                  {/* <AuthRoute exact path="/planDetails/:id" component={planDetails}></AuthRoute> */}
                  {/* <Route component={PageNotFound} /> */}
                </GATracking>
              </AnimatedSwitch>
            </ThemeProvider>
          </div>
        </Router>
    </Provider>
  );
}
export default DesktopWrapper(React.memo(App));