import axios from 'axios';
import store from '../store';
import { 
    BIDDING_CONFIG,
    BIDDING_DATA,
    SCROLL_TRIGGER_START,
    AUCTION_ID,
    PLAN_DETAILS,
    BIDDING_LOADING_UI,
    FLAG_ENABLED_FROM_NETWORK,
    BIDDING_DATA_RECEIVE,
    RESET,
    CLEAR_AUCTION,
    AUCTION_WINNER,
    CLEAR_ERROR_400,
    TRIGGER_FEEDBACK,
    AUCTION_STATUS,
    ERROR_400_FROM_SERVER,
    SOCKET_STATUS,
    START_LOADING
} from '../types';

const BASE_URL = process.env.REACT_APP_WHEEZ_SERVER_URL;
let socketUrl;

export const getPlanDetailsBidding = (planId) => (dispatch) => {
    // dispatch({type: BIDDING_LOADING_UI});
    return axios.get(`/api/plans/${planId}`, {})
    .then((res)=>{
        if(res.data) {
            // dispatch({type: CLEAR_ERRORS});
            dispatch({
                type: PLAN_DETAILS,
                payload: res.data
            });
        }
    })
    .catch((err)=>{
        console.log(err)
    })
}

export const getPlanDetailsBiddingDemo = (planId) => (dispatch) => {
    // dispatch({type: BIDDING_LOADING_UI});
    return axios.get(`/api/demo_plans/${planId}`, {})
    .then((res)=>{
        if(res.data) {
            // dispatch({type: CLEAR_ERRORS});
            dispatch({
                type: PLAN_DETAILS,
                payload: res.data
            });
        }
    })
    .catch((err)=>{
        console.log(err)
    })
}

export const startUI = () => (dispatch) => {
   dispatch({type: START_LOADING});
}

export const getAuctionDetails = (id) => (dispatch) => {
    // dispatch({type: BIDDING_LOADING_UI});
    dispatch({
        type: AUCTION_ID,
        payload: id
    });
    return axios.get(`/api/plans/auctions/${id}`, {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            // dispatch({type: CLEAR_ERRORS});
            if(res.data && res.data.auction_events && res.data.auction_events.length > 0){
                dispatch({
                    type: BIDDING_CONFIG,
                    payload: {}
                });
                dispatch({
                    type: AUCTION_STATUS,
                    payload: res.data.status.toLowerCase()
                })
                dispatch({
                    type: BIDDING_DATA,
                    payload: res.data.auction_events
                });
                dispatch({
                    type: SCROLL_TRIGGER_START
                });
                res.data.auction_events.map((event) => {
                    if(event.event_type.toLowerCase() === "winner"){
                        dispatch({
                            type: AUCTION_WINNER,
                            payload: event.auction_winner,
                        });
                    }
                    return null
                })
            } else {
                dispatch({
                    type: BIDDING_DATA,
                    payload: null
                });
            }
        }
    })
    .catch((err)=>{
        console.log("unable to fetch")
    })
}

export const auctionStatusRunningOrScheduled = () => (dispatch) => {
    dispatch({type: AUCTION_STATUS, payload: 'other'});
}


export const setFirstAuctionId = (auctionId) => (dispatch) => {
    dispatch({
        type: AUCTION_ID,
        payload: auctionId
    });
}

export const socketConnectDemo = (auctionId) => (dispatch) => {
    let token = localStorage.accessToken;
    dispatch({type: BIDDING_LOADING_UI});
    dispatch({type: SOCKET_STATUS, payload: true});
    socketUrl = new WebSocket(`ws://${BASE_URL}/ws/auction/${auctionId}/?token=${token}`)
    dispatch({
        type: AUCTION_ID,
        payload: auctionId
    });
    socketUrl.onopen = () => {
        if(socketUrl.readyState === 1){
            socketUrl.send(
                JSON.stringify({
                    "message_type": "auction_events",
                    "data": null
                })
            )
        }
        
    }
    // socketUrl.onerror = () => {
    //     window.location.reload()
    // }
    socketUrl.onmessage = evt => {
        const message = JSON.parse(evt.data);
        if(message.message_type === "initial"){
            dispatch({
                type: BIDDING_CONFIG,
                payload: message.data
            });
            if(message.data.flag_enabled){
                dispatch({
                    type: FLAG_ENABLED_FROM_NETWORK,
                    payload: true
                });
            }
        }
        if(message.status_code === 400){
            dispatch({
                type: ERROR_400_FROM_SERVER,
                payload: message.message
            });
        }
        if(message.message_type === "auction_events"){
            if(message.data && message.data.auction_events && message.data.auction_events.length > 0 ){
                dispatch({
                    type: BIDDING_DATA,
                    payload: message.data.auction_events
                });
                dispatch({
                    type: SCROLL_TRIGGER_START
                });
            } else {
                dispatch({
                    type: BIDDING_DATA,
                    payload: null
                });
            }
        }
        if(message.message_type === "bid" || message.message_type === "flag"){
            dispatch({
                type: BIDDING_DATA_RECEIVE,
                payload: message.data.auction_event
            });
            dispatch({
                type: SCROLL_TRIGGER_START
            });
        }
        if(message.message_type === "flag_enable_status"){
            if(message.data && message.data.flag_enabled){
                dispatch({
                    type: FLAG_ENABLED_FROM_NETWORK,
                    payload: true
                });
            }
        }
        if(message.message_type==="flag" && message.data.auction_event.user_flag.user.id===localStorage.userId){
            socketUrl.close();
            dispatch(socketConnect(auctionId))
        }
        if(message.message_type === "auction_event"
         || ( message.data && message.data.auction_event && message.data.auction_event.event_type && message.data.auction_event.event_type === "system" )
         || ( message.data && message.data.auction_event && message.data.auction_event.message && message.data.auction_event.message.toLowerCase() === "auction ended")){
            dispatch({
                type: BIDDING_DATA_RECEIVE,
                payload: message.data.auction_event
            });
            dispatch({
                type: TRIGGER_FEEDBACK
            })
            dispatch({
                type: SCROLL_TRIGGER_START
            });
        }
        if(message.message_type === "winner_info" && message.data && message.data.auction_event && message.data.auction_event.auction_winner){
            dispatch({
                type: AUCTION_WINNER,
                payload: message.data.auction_event.auction_winner
            });
            setTimeout(() => {
                socketUrl.close();
                let auctionId = store.getState().liveBidding.auctionId;
                dispatch({
                    type:RESET
                })
                dispatch(getAuctionDetails(auctionId));
            }, 2000);
          
        }
    }
}

export const socketConnect = (auctionId) => (dispatch) => {
    let token = localStorage.accessToken;
    dispatch({type: BIDDING_LOADING_UI});
    dispatch({type: SOCKET_STATUS, payload: true});
    socketUrl = new WebSocket(`ws://${BASE_URL}/ws/auction/${auctionId}/?token=${token}`)
    dispatch({
        type: AUCTION_ID,
        payload: auctionId
    });
    socketUrl.onopen = () => {
        if(socketUrl.readyState === 1){
            socketUrl.send(
                JSON.stringify({
                    "message_type": "auction_events",
                    "data": null
                })
            )
        }
        
    }
    // socketUrl.onerror = () => {
    //     window.location.reload()
    // }
    socketUrl.onmessage = evt => {
        const message = JSON.parse(evt.data);
        if(message.message_type === "initial"){
            dispatch({
                type: BIDDING_CONFIG,
                payload: message.data
            });
            if(message.data.flag_enabled){
                dispatch({
                    type: FLAG_ENABLED_FROM_NETWORK,
                    payload: true
                });
            }
        }
        if(message.status_code === 400){
            dispatch({
                type: ERROR_400_FROM_SERVER,
                payload: message.message
            });
        }
        if(message.message_type === "auction_events"){
            if(message.data && message.data.auction_events && message.data.auction_events.length > 0 ){
                dispatch({
                    type: BIDDING_DATA,
                    payload: message.data.auction_events
                });
                dispatch({
                    type: SCROLL_TRIGGER_START
                });
            } else {
                dispatch({
                    type: BIDDING_DATA,
                    payload: null
                });
            }
        }
        if(message.message_type === "bid" || message.message_type === "flag"){
            dispatch({
                type: BIDDING_DATA_RECEIVE,
                payload: message.data.auction_event
            });
            dispatch({
                type: SCROLL_TRIGGER_START
            });
        }
        if(message.message_type === "flag_enable_status"){
            if(message.data && message.data.flag_enabled){
                dispatch({
                    type: FLAG_ENABLED_FROM_NETWORK,
                    payload: true
                });
            }
        }
        if(message.message_type==="flag" && message.data.auction_event.user_flag.user.id===localStorage.userId){
            socketUrl.close();
            dispatch(socketConnect(auctionId))
        }
        if(message.message_type === "auction_event"
         || ( message.data && message.data.auction_event && message.data.auction_event.event_type && message.data.auction_event.event_type === "system" )
         || ( message.data && message.data.auction_event && message.data.auction_event.message && message.data.auction_event.message.toLowerCase() === "auction ended")){
            dispatch({
                type: BIDDING_DATA_RECEIVE,
                payload: message.data.auction_event
            });
            dispatch({
                type: TRIGGER_FEEDBACK
            })
            dispatch({
                type: SCROLL_TRIGGER_START
            });
        }
        if(message.message_type === "winner_info" && message.data && message.data.auction_event && message.data.auction_event.auction_winner){
            dispatch({
                type: AUCTION_WINNER,
                payload: message.data.auction_event.auction_winner
            });
            setTimeout(() => {
                socketUrl.close();
                let auctionId = store.getState().liveBidding.auctionId;
                dispatch({
                    type:RESET
                })
                dispatch(getAuctionDetails(auctionId));
            }, 2000);
          
        }
    }
}

const isEmpty = (obj) => {
    for(var prop in obj) {
      if(Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
}

export const forgoBid = () => (dispatch) => {
    let currentBid = store.getState().liveBidding.currentBid;
    if(!isEmpty(currentBid)){
        socketUrl.send(
            JSON.stringify({
                "message_type": "flag",
                "data": null
            })
        )
    }
}

export const placeBid = (decuctedValue) => (dispatch) => {
    let currentBid = store.getState().liveBidding.currentBid;
    if(!isEmpty(currentBid)){
        socketUrl.send(
            JSON.stringify({
                "message_type": "bid",
                "data": {
                    "deducted_amount": decuctedValue,
                    "previous_bid_id": currentBid.bid.id 
                }
            })
        )
        socketUrl.send(
            JSON.stringify({
                "message_type": "auction_events",
                "data": null
            })
        )
    } else {
        socketUrl.send(
            JSON.stringify({
                "message_type": "bid",
                "data": {
                    "deducted_amount": decuctedValue,
                    "previous_bid_id": null
                }
            })
        )
        socketUrl.send(
            JSON.stringify({
                "message_type": "auction_events",
                "data": null
            })
        )
    }
    
}

export const closeConnection = () => (dispatch) => {
    if(socketUrl && socketUrl.readyState === 1){
        dispatch({type: SOCKET_STATUS, payload: false});
        return socketUrl.close();
    }
}

export const clearAuction = (id) => (dispatch) => {
    dispatch({
        type: CLEAR_AUCTION
    });
    dispatch({
        type: AUCTION_ID,
        payload: id
    });
}

export const exitAuction = () => (dispatch) => {
    dispatch({
        type: CLEAR_AUCTION
    });
}

export const clear400Error = () => (dispatch) => {
    dispatch({
        type: CLEAR_ERROR_400
    });
}
