import React, {useState, useRef, useEffect} from 'react'
import { useSelector } from "react-redux";
import { useTable } from 'react-table'
import moment from 'moment'
import planDetailsUser from './../../assets/images/icons/planDetailsUser.svg';
import chevronDown from './../../assets/images/icons/dropIcon.svg';
import './../../styles/planAuctionTable.scss';
var ordinal = require('ordinal')

export default function PlanAuctionTable({ data, nextAuctionDate }) {
    const planState = useSelector((state) => state.plans);
    const [moreAuctions, setMoreAuctions] = useState(false)
    const [nextDate, setNextDate] = useState("");
    const [upcomingAuctions, setUpcomingAuctions] = useState([]);
    const chevronDownRef = useRef();
    const {planDetails} = planState;

    useEffect(() => {
      if(planDetails.auctions){
        if(nextAuctionDate){
          let temp1Upcoming = [];
          if(!nextAuctionDate.is_last_auction){
            setNextDate(nextAuctionDate.starts_at)
            for(var j=0; j<planDetails.auctions.length;j++){
              if(j > nextAuctionDate.serial_no - 1){
                temp1Upcoming.push({
                  ordinal: planDetails.auctions[j].serial_no,
                  starts_at: planDetails.auctions[j].starts_at
                })
              }
            }
            setUpcomingAuctions(temp1Upcoming)
          } else {
            setNextDate("")
            setUpcomingAuctions(temp1Upcoming)
          }
        } else {
            let tempUpcoming = [];
            for(var i=0; i<planDetails.auctions.length;i++){
              if(i === 0){
                setNextDate(planDetails.auctions[i].starts_at)
              }
              if(i > 0){
                tempUpcoming.push({
                  ordinal: planDetails.auctions[i].serial_no,
                  starts_at: planDetails.auctions[i].starts_at
                })
              }
            }
            setUpcomingAuctions(tempUpcoming)
        }
      }
    }, [planDetails.auctions, nextAuctionDate])
    

    const columns = React.useMemo(
        () => [
          {
            Header: 'Date & Auction',
            accessor: 'dateAuction',
          },
          {
            Header: `Amount Taken From $${planDetails.total_amount ? planDetails.total_amount.substring(0, planDetails.total_amount.length - 3) : 0}`,
            columns: [
              {
                Header: 'By',
                accessor: 'by',
              },
              {
                Header: 'Value',
                accessor: 'value',
              },
            ],
          },
          {
            Header: 'Surplus',
            columns: [
              {
                Header: 'Total',
                accessor: 'totalSurplus',
              },
              {
                Header: 'Individual',
                accessor: 'individualSurplus',
              },
            ],
          },
          {
            Header: 'Amount Contributed By Each After The Auction',
            accessor: 'amountContributed',
          },
        ],
        [planDetails]
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({columns, data})
    const openNextAuctions = () => {
      setMoreAuctions(!moreAuctions)
      if(upcomingAuctions.length > 0){
        if(!moreAuctions){
          chevronDownRef.current.style.transform = 'rotateZ(180deg)'
        } else {
          chevronDownRef.current.style.transform = 'rotateZ(0deg)'
        }
      }
    }
    return (
        (nextAuctionDate || nextDate) ?
        <div className='tableRelative'>
            <div className='tableScroller'>
                <table className='planTable' {...getTableProps()}>
                    <thead>
                        {headerGroups.map(group => ( 
                            <tr {...group.getHeaderGroupProps()}>
                                {group.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                if(cell.column.id === "by"){
                                    return <td {...cell.getCellProps()}>
                                            <img src={planDetailsUser} alt="icon" />
                                            {cell.render('Cell')}
                                        </td>
                                } else {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                }
                            })}
                            </tr>
                        )
                        })}
                    </tbody>
                </table>
            </div>
            <div className='tfoot'>
                <div className='upcomingContent' onClick={() => openNextAuctions()}>
                  {nextDate && <>
                    <span>Next Auction: </span>
                    <span className='dated'>{moment(nextDate).format('D MMMM YYYY')}</span>
                    {upcomingAuctions.length > 0 && <img className='chevronDown' ref={chevronDownRef} src={chevronDown} alt="icon" />}
                  </>}
                </div>
                {moreAuctions && upcomingAuctions.length > 0 && <div className='moreAuctions'>
                    {
                      upcomingAuctions.map((auction, index) => (
                        <div key={index} className='upcomingContentMore'>
                            <span>{ordinal(auction.ordinal)} Auction: </span>
                            <span className='dated'>{moment(auction.starts_at).format('D MMMM YYYY')}</span>
                        </div>
                      ))
                    }
                </div>}
            </div>
        </div>: <></>
    )
}
