import axios from 'axios';
import {getPublicProfileInfo} from './contactActions'
import { 
    RECOMMENDED_PLANS,
    USER_PLANS,
    CURRENT_SELECTED_PLAN,
    LOADING_UI,
    BASIC_PLAN_STATUS,
    CLEAR_ERRORS,
    RESET_PLAN_DATA,
    PLAN_DATA,
    SIMPLE_USER_PLANS,
    REMOVE_RECOMMEND_PLAN,
    AUCTION_WINNING_INFO,
    CURRENT_PLAN_PORTFOLIO,
    CLEAR_ALL_TYPE_PLANS,
    INACTIVE_PLANS,
    PLAN_SUMMARY,
    CLEAR_PLANS_RECOMMENDS,
    ALL_TYPE_PLANS,
    RECOMMENDED_PLANS_THERE,
    USER_PLANS_THERE,
    PLAN_TYPES,
    CONTRIBUTION_AMOUNT_RANGES,
    PERIOD_RANGES,
    PLAN_GOALS,
    DEMO_PLANS
} from '../types';


export const getPlanDetails = (planId) => (dispatch) => {
    return axios.get(`/api/plans/${planId}`, {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            let addedData = res.data;
            if(addedData.members.length > 0){
                addedData.members.map((member, index) => {
                    dispatch(getPublicProfileInfo(member.id)).then(det => {
                        addedData.members[index].details = det;
                        return dispatch({
                            type: PLAN_DATA,
                            payload: addedData
                        })
                    })
                    return null
                })
            }
        }
    })
    .catch((err)=>{
        console.log("unable to fetch")
    })
}

export const getPlanDetailsDemo = (planId) => (dispatch) => {
    return axios.get(`/api/demo_plans/${planId}`, {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            let addedData = res.data;
            if(addedData.members.length > 0){
                addedData.members.map((member, index) => {
                    dispatch(getPublicProfileInfo(member.id)).then(det => {
                        addedData.members[index].details = det;
                        return dispatch({
                            type: PLAN_DATA,
                            payload: addedData
                        })
                    })
                    return null
                })
            }
        }
    })
    .catch((err)=>{
        console.log("unable to fetch")
    })
}

export const getPlanSummary = (planId) => (dispatch) => {
    return axios.get(`/api/plans/${planId}/summary/`, {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            dispatch({
                type: PLAN_SUMMARY,
                payload: res.data
            })
            
        }
    })
    .catch((err)=>{
        console.log("unable to fetch")
    })
}

export const getPlanSummaryDemo = (planId) => (dispatch) => {
    return axios.get(`/api/demo_plans/${planId}/summary/`, {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            dispatch({
                type: PLAN_SUMMARY,
                payload: res.data
            })
            
        }
    })
    .catch((err)=>{
        console.log("unable to fetch")
    })
}

export const getPlanAuctionWinningInfo = (planId) => (dispatch) => {
    return axios.get(`/api/plans/${planId}/auction-calculations/`, {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            dispatch({
                type: CLEAR_ERRORS
            })
            return dispatch({
                type: AUCTION_WINNING_INFO,
                payload: res.data
            })
        }
    })
    .catch((err)=>{
        console.log("unable to fetch")
    })
}


export const sendFeedback = (userData) => (dispatch) => {
    return axios.post('/api/users/feedback/', userData)
    .then((res)=>{
        if(res && res.status && res.status === 201) {
            
        }
    })
    .catch((err)=>{
        console.log("unable to fetch")
       
    })
}

export const getApplicationChoices = () => (dispatch) => {
    return axios.get('/api/plans/plan-application/choice-field-values/', {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            dispatch({
                type: PLAN_TYPES,
                payload: res.data.plan_types
            })
            dispatch({
                type: CONTRIBUTION_AMOUNT_RANGES,
                payload: res.data.contribution_amount_ranges
            })
            dispatch({
                type: PERIOD_RANGES,
                payload: res.data.period_ranges
            })
            dispatch({
                type: PLAN_GOALS,
                payload: res.data.plan_goals
            })
        }
    })
    .catch((err)=>{
        console.log("unable to fetch")
    })
}

export const submitPlanApplication = (data) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.post('/api/plans/plan-application/', data)
    .then((res)=>{
        if(res && res.status && res.status === 201) {
            return {
                message: "success"
            }
        }
    })
    .catch((err)=>{
        console.log("unable to fetch")
    })
}



export const getInactivePlans = () => (dispatch) => {
    // dispatch({type: LOADING_UI});
    return axios.get('/api/plans/?status=Completed', {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            // dispatch({type: CLEAR_ERRORS});
            let userPlansWithDetails = [];
            let firstScheduled;
            if(res.data && res.data.results && res.data.results.length > 0){
                res.data.results.map((result, index) => {
                    if(result) {
                        userPlansWithDetails[index] = result;
                        axios.get(`/api/plans/${result.id}`, {})
                        .then((planDetail)=>{
                            if(planDetail && planDetail.status && planDetail.status === 200) {
                                let addedData = planDetail.data;
                                if(planDetail.data.members){
                                    planDetail.data.members.map((member, i) => {
                                        dispatch(getPublicProfileInfo(member.id)).then(det => {
                                            addedData.members[i].details = det;
                                            userPlansWithDetails[index].planDetails = addedData;
                                        })
                                        return null
                                    })
                                }

                                for(var i = 0; i < planDetail.data.auctions.length; i++){
                                    if(planDetail.data.auctions[i].status.toLowerCase() === "scheduled"){
                                        firstScheduled = planDetail.data.auctions[i].serial_no
                                        break;
                                    }
                                }
                                userPlansWithDetails[index].planDetails = planDetail.data;
                                if(firstScheduled){
                                    if(planDetail.data.auctions.length > 0 && planDetail.data.auctions[firstScheduled - 1]){
                                        userPlansWithDetails[index].nextAuctionDate = planDetail.data.auctions[firstScheduled - 1].starts_at;
                                        userPlansWithDetails[index].nextAuctionScheduled = firstScheduled - 1;
                                    }
                                }
                                // dispatch({type: CLEAR_ERRORS});
                                dispatch({
                                    type: INACTIVE_PLANS,
                                    payload: userPlansWithDetails[index]
                                })
                            }
                           
                        })
                    }
                    return null;
                })
            }
        }
    })
    .catch((err)=>{
        dispatch({type: CLEAR_ERRORS});
        console.log("unable to fetch")
    })
}

export const clearErrors = () => (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}


export const simpleGetUserPlans = () => (dispatch) => {
    dispatch({
        type: LOADING_UI,
    })
    return axios.get('/api/plans/?status=Running,Draft,Scheduled', {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            dispatch({
                type: CLEAR_ERRORS,
            })
            return dispatch({
                type: SIMPLE_USER_PLANS,
                payload: res.data.results
            })
        }
    })
    .catch((err)=>{
        dispatch({
            type: CLEAR_ERRORS,
        })
        console.log("unable to fetch")
    })
}

export const simpleUserPlans = () => (dispatch) => {
    return axios.get('/api/plans/?status=Running,Draft,Scheduled', {})
    .then((res)=>{
        if(res && res.status && res.status === 200 && res.data.results && res.data.results.length > 0) {
            dispatch({
                type: USER_PLANS_THERE,
                payload: true
            })
        } else {
            dispatch({
                type: USER_PLANS_THERE,
                payload: false
            })
        }
    })
    .catch((err)=>{
        dispatch({
            type: USER_PLANS_THERE,
            payload: false
        })
        console.log("unable to fetch")
    })
}

export const getAllTypePlans = () => (dispatch) => {
    axios.get('/api/plans/?status=Running,Draft,Scheduled', {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            return dispatch({
                type: ALL_TYPE_PLANS,
                payload: res.data.results
            })
        }
    })
    .catch((err)=>{
        dispatch({
            type: CLEAR_ERRORS,
        })
        console.log("unable to fetch")
    })
    axios.get('/api/plans/?status=Completed', {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            return dispatch({
                type: ALL_TYPE_PLANS,
                payload: res.data.results
            })
        }
    })
    .catch((err)=>{
        dispatch({
            type: CLEAR_ERRORS,
        })
        console.log("unable to fetch")
    })
}

export const clearAllTypePlans = () => (dispatch) => {
    dispatch({
        type: CLEAR_ALL_TYPE_PLANS,
        payload: []
    })
}

export const getUserPlans = () => (dispatch) => {
    dispatch({type: LOADING_UI});
    
    return axios.get('/api/plans/?status=Running,Draft,Scheduled', {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            // dispatch({type: CLEAR_ERRORS});
            let userPlansWithDetails = [];
            let firstScheduled;
            if(res.data && res.data.results && res.data.results.length > 0){
                res.data.results.map((result, index) => {
                    if(result) {
                        userPlansWithDetails[index] = result;
                        axios.get(`/api/plans/${result.id}`, {})
                        .then((planDetail)=>{
                            if(planDetail && planDetail.status && planDetail.status === 200) {
                                let addedData = planDetail.data;
                                if(planDetail.data.members){
                                    planDetail.data.members.map((member, i) => {
                                        dispatch(getPublicProfileInfo(member.id)).then(det => {
                                            addedData.members[i].details = det;
                                            userPlansWithDetails[index].planDetails = addedData;
                                        })
                                        return null
                                    })
                                }

                                for(var i = 0; i < planDetail.data.auctions.length; i++){
                                    if(planDetail.data.auctions[i].status.toLowerCase() === "scheduled"){
                                        firstScheduled = planDetail.data.auctions[i].serial_no
                                        break;
                                    }
                                }
                                userPlansWithDetails[index].planDetails = planDetail.data;
                                if(firstScheduled){
                                    if(planDetail.data.auctions.length > 0 && planDetail.data.auctions[firstScheduled - 1]){
                                        userPlansWithDetails[index].nextAuctionDate = planDetail.data.auctions[firstScheduled - 1].starts_at;
                                        userPlansWithDetails[index].nextAuctionScheduled = firstScheduled - 1;
                                    }
                                }
                                dispatch({type: CLEAR_ERRORS});
                                dispatch({
                                    type: USER_PLANS,
                                    payload: userPlansWithDetails[index]
                                })
                            }
                           
                        })
                    }
                    return null;
                })
            } else {
                dispatch({type: CLEAR_ERRORS});
            }
        }
    })
    .catch((err)=>{
        dispatch({type: CLEAR_ERRORS});
        console.log("unable to fetch")
    })
}

export const getRecommendedPlans = () => (dispatch) => {
    // dispatch({type: LOADING_UI});
    return axios.get(`/api/plans/recommended-plans/`, {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            // dispatch({type: CLEAR_ERRORS});
            if(res.data && res.data.count >= 1){
                let recommendPlansWithDetails = [];
                res.data.results.map((resultRecommend, index) => {
                    recommendPlansWithDetails[index] = resultRecommend;
                    axios.get(`/api/plans/${resultRecommend.plan.id}`, {})
                    .then((recommendedPlanDetail)=>{
                        if(recommendedPlanDetail && recommendedPlanDetail.status && recommendedPlanDetail.status === 200) {
                            let addedData = recommendedPlanDetail.data;
                            if(recommendedPlanDetail.data.members){
                                recommendedPlanDetail.data.members.map((member, i) => {
                                    dispatch(getPublicProfileInfo(member.id)).then(det => {
                                        addedData.members[i].details = det;
                                        recommendPlansWithDetails[index].planDetails = addedData;
                                    })
                                    return null
                                })
                            }
                            recommendPlansWithDetails[index].planDetails = recommendedPlanDetail.data;
                            dispatch({
                                type: RECOMMENDED_PLANS,
                                payload: recommendPlansWithDetails[index]
                            })
                        }
                    })
                    return null;
                })
            }
           
        }
    })
    .catch((err)=>{
        // dispatch({type: CLEAR_ERRORS});
        console.log("unable to fetch")
    })
}


export const simpleGetRecommendedPlans = () => (dispatch) => {
    return axios.get(`/api/plans/recommended-plans/`, {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            if(res.data && res.data.count >= 1){
                dispatch({
                    type: RECOMMENDED_PLANS_THERE,
                    payload: true
                })
            } else {
                dispatch({
                    type: RECOMMENDED_PLANS_THERE,
                    payload: false
                })
            }
        }
    })
    .catch((err)=>{
        console.log("unable to fetch")
    })
}

export const actionOnRecommendedPlans = (actionData) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.put('/api/plans/recommended-plan/', actionData)
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            dispatch({type: CLEAR_ERRORS});
            dispatch({type: CLEAR_PLANS_RECOMMENDS});
            return {
                message: "success"
            }
        }
    })
    .catch((err)=>{
        if(err.response && err.response.status === 422){
            dispatch({type: CLEAR_ERRORS});
            return {
                message: "error",
                text: err.response.data.recommended_plan_id[0]
            }
        } else {
            dispatch({type: CLEAR_ERRORS});
            return {
                message: "error",
                text: err.response.data.message[0]
            }
        }
    })
}



export const removeRecommendFromStore = (id) => (dispatch) => {
    let prom = new Promise((res, rej) => {
        let dummy = true;
        if (dummy) {
            dispatch({
                type: REMOVE_RECOMMEND_PLAN,
                payload: id
            })
          res('Success');
        } else {
          rej('Error');
        }
    })
    return prom.then(() => {
    }).catch(() => {
    }).finally(() => {
    });
}

export const joinDemoPlan = (id) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.put('/api/demo_plans/recommended-plan/', {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            dispatch({type: CLEAR_ERRORS});
            return {
                message: "success"
            }
        }
    })
    .catch((err)=>{
        dispatch({type: CLEAR_ERRORS});
        return {
            message: "error"
        }
    })
}

// export const getDemoPlans = () => (dispatch) => {
//     return axios.get('/api/demo_plans/?status=Active', {})
//     .then((res)=>{
//         if(res && res.status && res.status === 200) {
//             dispatch({
//                 type: DEMO_PLANS,
//                 payload: res.data.results
//             })
//         }
//     })
//     .catch((err)=>{
//     })
// }


export const getDemoPlans = () => (dispatch) => {
    
    return axios.get('/api/demo_plans/?status=Active,Running,Draft,Scheduled', {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            // dispatch({type: CLEAR_ERRORS});
            let userPlansWithDetails = [];
            let firstScheduled;
            if(res.data && res.data.results && res.data.results.length > 0){
                res.data.results.map((result, index) => {
                    if(result) {
                        userPlansWithDetails[index] = result;
                        axios.get(`/api/demo_plans/${result.id}`, {})
                        .then((planDetail)=>{
                            if(planDetail && planDetail.status && planDetail.status === 200) {
                                let addedData = planDetail.data;
                                if(planDetail.data.members){
                                    planDetail.data.members.map((member, i) => {
                                        dispatch(getPublicProfileInfo(member.id)).then(det => {
                                            addedData.members[i].details = det;
                                            userPlansWithDetails[index].planDetails = addedData;
                                        })
                                        return null
                                    })
                                }

                                for(var i = 0; i < planDetail.data.auctions.length; i++){
                                    if(planDetail.data.auctions[i].status.toLowerCase() === "scheduled"){
                                        firstScheduled = planDetail.data.auctions[i].serial_no
                                        break;
                                    }
                                }
                                userPlansWithDetails[index].planDetails = planDetail.data;
                                if(firstScheduled){
                                    if(planDetail.data.auctions.length > 0 && planDetail.data.auctions[firstScheduled - 1]){
                                        userPlansWithDetails[index].nextAuctionDate = planDetail.data.auctions[firstScheduled - 1].starts_at;
                                        userPlansWithDetails[index].nextAuctionScheduled = firstScheduled - 1;
                                    }
                                }
                                dispatch({
                                    type: DEMO_PLANS,
                                    payload: [userPlansWithDetails[index]]
                                })
                            }
                           
                        })
                    }
                    return null;
                })
            } 
        }
    })
    .catch((err)=>{
        console.log("unable to fetch")
    })
}

export const setCurrentPlan = (data) => (dispatch) => {
    if(data){
        localStorage.setItem('currentPlanId', data.id);
        dispatch({
            type: CURRENT_SELECTED_PLAN,
            payload: data
        })
    }
    
}

export const getCurrentAuction = () => (dispatch) => {
    return axios.get('/api/plans/active-auction/', {})
    .then((res)=>{
        if(res ) {
            return res
        }
    })
    .catch((err)=>{
    })
    
}

export const getPrevAuction = (id) => () => {
    return axios.get(`/api/plans/${id}/latest-winner/`, {})
    .then((res)=>{
        if(res ) {
            return res
        }
    })
    .catch((err)=>{
    })
    
}

export const getNextAuctionDate = (id) => () => {
    return axios.get(`/api/plans/${id}/next-auction/`, {})
    .then((res)=>{
        if(res ) {
            return res
        }
    })
    .catch((err)=>{
    })
}

export const getPlanPortfolio = (id) => (dispatch) => {
    return axios.get(`/api/plans/${id}/portfolio/`, {})
    .then((res)=>{
        if(res && res.status === 200) {
            dispatch({
                type: CURRENT_PLAN_PORTFOLIO,
                payload: res.data
            })
        }
    })
    .catch((err)=>{
        console.log(err)
        dispatch({
            type: CURRENT_PLAN_PORTFOLIO,
            payload: {}
        })
    })
    
}

export const sendBasicPlanApplication = () => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.post('/api/plans/basic-plan-application/', {})
    .then((res)=>{
        dispatch({type: CLEAR_ERRORS});
        if(res && res.status && res.status === 201) {
            return 201
        }
    })
    .catch((err)=>{
        dispatch({type: CLEAR_ERRORS});
        if(err && err.response && err.response.status && err.response.status === 422) {
            return 422
        } else {
            console.log("unable to fetch")
        }
    })
}

export const getBasicPlanStatus = () => (dispatch) => {
    return axios.get('/api/plans/basic-plan-application/', {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            if(res.data){
                dispatch({
                    type: BASIC_PLAN_STATUS,
                    payload: res.data.status
                })
            }
        }
    })
    .catch((err)=>{
        console.log("Basic Application not available")
    })
}

export const removePlanData = () => (dispatch) => {
    dispatch({
        type: RESET_PLAN_DATA
    })
}