import React from 'react'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles({
    icon: {
        borderRadius: 6,
        width: 20,
        height: 20,
        backgroundColor:'#898989',
    },
    checkedIcon: {
        backgroundColor: '#4283FF',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#4283FF',
        },
    },
});

function BpCheckbox(props) {
    const classes = useStyles();
    return (
        <Checkbox
            sx={{
                '&:hover': { bgcolor: 'transparent' },
            }}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />
    );
}
export default function CheckboxWithLabel(props) {
    return (
        props.type === "goal" ? 
        <div>
            <FormControlLabel 
                className="formLabel" 
                value={props.value} 
                onChange={(e) => props.planTypeCheck(e, props.id)}
                control={<BpCheckbox size={props.size} defaultChecked={props.defaultChecked} />} 
                label={props.label} 
            />
            <div style={{
                fontSize: '13px',
                lineHeight: '15px',
                textTransform: 'capitalize',
                color: '#FFFFFF',
                opacity: '0.6',
                marginTop: '0px',
                marginBottom: '15px',
            }}>
                {props.label.toLowerCase().includes("saving") && 'Choose this, if you want to save and gather a lump sum of money.'}
                {(props.label.toLowerCase().includes("cash") || props.label.toLowerCase().includes("borrow")) && 'Choose this if you are looking to use the plan to borrow or to provide cash-flow, whenever you are in need.'}
                {props.label.toLowerCase().includes("invest") && 'Choose this if you are looking to use it as an investment and gain some returns in the end.'}

            </div>
        </div> 
        : 
        <FormControlLabel 
            className="formLabel" 
            value={props.value} 
            onChange={(e) => props.planTypeCheck(e, props.id)}
            control={<BpCheckbox size={props.size} defaultChecked={props.defaultChecked} />} 
            label={props.label} 
        />
    )
}
