import React from 'react'
import moment from 'moment'
import userIconWhite from './../../assets/images/icons/userIconWhite.svg';
import userIconBlack from './../../assets/images/icons/userIconBlack.svg';


export default function BidMessage(props) {
    return (
        <div className={props.biddingData.userId === props.bidData.id ? "bidThread bidThreadLeft" : "bidThread bidThreadRight"}>
            <span className={props.biddingData.userId === props.bidData.id ? 'userIcon' : 'userIcon ownUser'} onClick={() => props.openUserInfoModal(props.bidData)}> 
                {props.biddingData.userId === props.bidData.id ?
                <img src={userIconBlack} alt="icon" /> :  <img src={userIconWhite} alt="icon" /> }
            </span>
            <div className="threadContainer">
                {props.bidData.timestamp && <span className="time">{moment(props.bidData.timestamp).format('h:mm:ss A')}</span>}
                <div className="bidText">{props.bidData.name} asks for</div>
                <div className="value">${props.bidData.initialValue} - ${props.bidData.deductedValue}</div>
                <div className="finalValue">${props.bidData.bidAmount}</div>
            </div>
        </div>
    )
}