import { Box, Grid, Typography } from "@material-ui/core";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect } from "react";
import bottomLeft1 from "../../assets/images/landingPage/home/invitefriend/bottomLeft1.svg";
import bottomLeft2 from "../../assets/images/landingPage/home/invitefriend/bottomLeft2.svg";
import bottomRight1 from "../../assets/images/landingPage/home/invitefriend/bottomRight1.svg";
import bottomRight2 from "../../assets/images/landingPage/home/invitefriend/bottomRight2.svg";
import topLeft1 from "../../assets/images/landingPage/home/invitefriend/topLeft1.svg";
import topLeft2 from "../../assets/images/landingPage/home/invitefriend/topLeft2.svg";
import topRight1 from "../../assets/images/landingPage/home/invitefriend/topRight1.svg";
import topRight2 from "../../assets/images/landingPage/home/invitefriend/topRight2.svg";
gsap.registerPlugin(ScrollTrigger);

const InviteFriend = (props) => {
  useEffect(() => {
    if(!props.isMobile){
      gsap.utils.toArray(".invite-col-1").forEach((panel, i) => {
        gsap.fromTo(
          [panel],
          { opacity: 0, x: -200 },
          {
            opacity: 1,
            ease: "power1.out",
            duration: 1,
            delay: 0.5,
  
            x: 0,
            scrollTrigger: {
              trigger: panel,
              start: "center-=150 center+=150",
              
            },
          }
        );
      });
      gsap.utils.toArray(".invite-col-2").forEach((panel, i) => {
        gsap.fromTo(
          [panel],
          { opacity: 0, x: 200 },
          {
            opacity: 1,
            ease: "power1.out",
            duration: 1,
            delay: 0.5,
            x: 0,
            scrollTrigger: {
              trigger: panel,
              start: "center-=150 center+=150",
              
            },
          }
        );
      });
      gsap.utils.toArray(".oneLeft").forEach((panel, i) => {
        gsap.fromTo(
          [panel],
          { x: 500, y: 200, opacity: 0 },
          {
            opacity: 1,
            ease: "power1.out",
            duration: 1,
            x: 0,
            y: 0,
            scrollTrigger: {
              trigger: panel,
              start: "center-=10 center+=200",
              
            },
          }
        );
      });
      gsap.utils.toArray(".threeLeft").forEach((panel, i) => {
        gsap.fromTo(
          [panel],
          { x: 500, y: -200, opacity: 0 },
          {
            opacity: 1,
            ease: "power1.out",
            duration: 1,
            x: 0,
            y: 0,
            scrollTrigger: {
              trigger: panel,
              start: "center-=200 center+=200",
              
            },
          }
        );
      });
      gsap.utils.toArray(".twoRight").forEach((panel, i) => {
        gsap.fromTo(
          [panel],
          { x: -500, y: 200, opacity: 0 },
          {
            opacity: 1,
            ease: "power1.out",
            duration: 1,
            x: 0,
            y: 0,
            scrollTrigger: {
              trigger: panel,
              start: "center-=10 center+=200",
              
            },
          }
        );
      });
      gsap.utils.toArray(".fourRight").forEach((panel, i) => {
        gsap.fromTo(
          [panel],
          { x: -500, y: -200, opacity: 0 },
          {
            opacity: 1,
            ease: "power1.out",
            duration: 1,
            x: 0,
            y: 0,
            scrollTrigger: {
              trigger: panel,
              start: "center-=200 center+=200",
            },
          }
        );
      });
    }
  }, []);
  return (
    <div className="invite-main">
      <Box
        className="aboutWheezBg"
        padding={"30px 0px"}
        position={"relative"}
      >
        {!props.isMobile && <Box className="twoRight" marginTop={"-30px"}>
          <Box position={"absolute"} right={"0"} top={"0"}>
            <img src={topRight1} alt="topRight1" />
          </Box>
          <Box position={"absolute"} right={"0"} top={"70px"}>
            <img src={topRight2} alt="topRight2" />
          </Box>
        </Box>}
        {!props.isMobile && <Box className="oneLeft" marginTop={"-30px"}>
          <Box position={"absolute"} left={"0"} top={"0"}>
            <img src={topLeft1} alt="topLeft1" />
          </Box>
          <Box position={"absolute"} left={"0"} top={"0"}>
            <img src={topLeft2} alt="topLeft2" />
          </Box>
        </Box>}
        {!props.isMobile && <Box className="threeLeft" position={"absolute"} bottom={"-10px"}>
          <Box position={"absolute"} left={"0"} bottom={"0px"}>
            <img src={bottomLeft1} alt="bottomLeft1" />
          </Box>
          <Box
            position={"absolute"}
            left={"0"}
            bottom={"80px"}
          >
            <img src={bottomLeft2} alt="bottomLeft2" />
          </Box>
        </Box>}
        {!props.isMobile && <Box
          className={"fourRight"}
          position={"absolute"}
          bottom={"-10px"}
          right={"0"}
        >
          <Box position={"absolute"} right={"0"} bottom={"0px"}>
            <img src={bottomRight1} alt="bottomRight1" />
          </Box>
          <Box position={"absolute"} right={"0"} bottom={"110px"}>
            <img src={bottomRight2} alt="bottomRight2" />
          </Box>
        </Box>}
        {props.header ? (
          <Typography
            className="invite-main-h1"
          >
            Here Are The&nbsp;
            <span
              className="invite-main-h2"
            >
              Other Things&nbsp;
            </span>
            To Do In Wheez
          </Typography>
        ) : null}
        <Grid
          container
          sx={{
            maxWidth: {
              sm: "sm",
              md: "md",
              lg: "lg",
              xl: "xl",
            },
          }}
          className="main-grid"
        >
          {props.isMobile &&
              <img src={props.img} alt="" className="main-image"/>}
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={"invite-col-1"}
          >
            <Box
              className="content-box"
            >
              <Typography
                className="label"
              >
                {props.label}
              </Typography>
              <Typography
                className="description"
              >
                {props.description}
              </Typography>
              <Box
                className="button"
                display={"inline-block"}
                padding={"15px 47px"}
                bgcolor={"#fff"}
                borderRadius={"12px"}
                fontSize={"16px"}
                fontWeight={"700"}
                color={"#4283FF"}
                mr={"auto"}
                mt={"31px"}
                onClick={() => props.getStarted()}
              >
                {props.Btext}
              </Box>
            </Box>
          </Grid>
          {!props.isMobile && <Grid item xs={12} md={6} className={"invite-col-2"}>
            <Box display={"flex"} justifyContent={"center"}>
              <img src={props.img} alt="" />
            </Box>
          </Grid>}
        </Grid>
      </Box>
    </div>
  );
};

export default InviteFriend;
