import React, {useRef, useState} from 'react';
import { useDispatch } from "react-redux";
import Rating from '@material-ui/lab/Rating';
import { 
    sendFeedback,
} 
from '../../redux/actions/planActions';
import closeIcon from './../../assets/images/icons/closeCircle.svg';
import ratingIcon from './../../assets/images/icons/ratingIcon.svg';
import emptyRating from './../../assets/images/icons/emptyRating.svg';

export default function Feedback(props) {
    const dispatch = useDispatch();
    const descriptionRef = useRef();
    const [ratingValue,setRatingValue] = useState(1);
    const handleSubmit = () => {
        let userData = {
            "rating": ratingValue,
            "message": descriptionRef.current.value,
        }
        dispatch(sendFeedback(userData)).then(() => {
            props.closeFeedback();
        })
    }
    return <div className="modal feedbackModal">
            <div className="modal-content">
                <div className="header">
                    <div>Feedback</div>
                    <img onClick={() => props.closeFeedback()} src={closeIcon} alt="icon" />
                </div>
                <div className="content">
                    <div className='question'>How was your auction experience?</div>
                    <Rating
                        name="simple-controlled"
                        className="ratingClass"
                        icon={<img src={ratingIcon} alt="icon" />}
                        emptyIcon={<img src={emptyRating} alt="icon" />}
                        value={ratingValue}
                        onChange={(event, newValue) => {
                            setRatingValue(newValue);
                        }}
                    />
                    <div className='question'>Share your experience</div>
                    <textarea className='textarea' ref={descriptionRef} />
                    <button onClick={ () => handleSubmit()}>Submit Now</button>
                </div>
            </div>
        </div>
}
