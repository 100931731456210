import axios from 'axios';
import { 
    GOOGLE_CONTACTS,
    WHEEZ_CONTACTS,
    INVITE_LINK,
    LOADING_UI,
    CLEAR_ERRORS,
    CURRENT_REQUESTS,
    PUBLIC_PROFILE_DATA,
    USER_AVATARS
} from '../types';

let accToken = localStorage.accessToken;
axios.defaults.headers.common = {'Authorization': `bearer ${accToken}`};

export const getWheezContacts = () => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.get('/api/contacts/user-contacts/',  {})
    .then((res)=>{
        if(res && res.status) {
            dispatch({type: CLEAR_ERRORS});
            dispatch({
                type: WHEEZ_CONTACTS,
                payload: res.data
            });
        } 
    })
    .catch((err)=>{
        console.log(err)
    })
}

export const getAllAvatars = () => (dispatch) => {
    return axios.get('/api/users/avatars/',  {})
    .then((res)=>{
        if(res && res.status && res.data.length > 0) {
            dispatch({
                type: USER_AVATARS,
                payload: res.data
            });
        } 
    })
    .catch((err)=>{
        if(err && err.response && err.response.status){
            console.log(err)
        }
    })
}

export const updateAvatar = (req) => (dispatch) => {
    var bodyFormData = new FormData();
    bodyFormData.append('avatar_id', req.avatarId);
    return axios.patch('/api/users/profile-image/', bodyFormData)
    .then((res)=>{
        if(res.status === 200){
            return "success"
        } else{
            return "failed"
        }
    })
    .catch((err)=>{
        if(err && err.response && err.response.status){
            console.log(err)
        }
        return "failed"
    })
}

export const uploadPicture = (req) => (dispatch) => {
    var bodyFormData = new FormData();
    bodyFormData.append("image", req.imagefile.files[0]);
    return axios.patch('/api/users/profile-image/', bodyFormData, {
        headers: {
            'Content-Type':  `multipart/form-data`,
        }
    })
    .then((res)=>{
        if(res.status === 200){
            return "success"
        } else{
            return "failed"
        }
    })
    .catch((err)=>{
        if(err && err.response && err.response.status){
            console.log(err)
        }
        return "failed"
    })
}

export const getGoogleContactsAll = () => (dispatch) => {
    dispatch({type: LOADING_UI});
    let googleContacts;
    return axios.get('/api/contacts/user-linked-account/contacts/',  {})
    .then((res)=>{
        if(res && res.status) {
            dispatch({type: CLEAR_ERRORS});
            let result = res.data;
            if(result.results.length > 0){
                result.results.forEach((value,index) => {
                    if(index === 0 && value.contacts){
                        googleContacts = result.results[index].contacts;
                    } else {
                        if(value.contacts){
                            googleContacts = googleContacts.concat(result.results[index].contacts)
                        }
                    }
                    googleContacts.sort(compare);
                    result.contacts = googleContacts;
                })
            }
            dispatch({
                type: GOOGLE_CONTACTS,
                payload: res.data
            });
        } 
    })
    .catch((err)=>{
        dispatch({type: CLEAR_ERRORS});
        if(err && err.response && err.response.status && err.response.status === 404){
            console.log(err)
        }
    })
}

export const getSearchedUser = (query, userList, type) => (dispatch) => {
    dispatch({type: LOADING_UI});
    if(type === '2'){
        let list = userList;
        list.contacts = list.contacts.filter((contact) => {
            return contact.full_name.toLowerCase().includes(query)
        })
        dispatch({
            type: GOOGLE_CONTACTS,
            payload: userList
        });
        if(query.trim() === ''){
            dispatch(getGoogleContactsAll());
        }
    }
    dispatch({type: CLEAR_ERRORS});
  
}

function compare( a, b ) {
    if ( a.full_name.toLowerCase() < b.full_name.toLowerCase() ){
      return -1;
    }
    if ( a.full_name.toLowerCase() > b.full_name.toLowerCase() ){
      return 1;
    }
    return 0;
}

export const linkGoogleAccount = () => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.get('/api/contacts/google-oauth/',  {})
    .then((res)=>{
        if(res && res.status) {
            dispatch({type: CLEAR_ERRORS});
            if(res.data && res.data[0])
                return res.data[0]
        } 
    })
    .catch((err)=>{
        console.log(err)
    })
}

export const getInviteLink = () => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.get('/api/users/invitation-link/',  {})
    .then((res)=>{
        if(res && res.status) {
            dispatch({type: CLEAR_ERRORS});
            dispatch({
                type: INVITE_LINK,
                payload: res.data
            });
        } 
    })
    .catch((err)=>{
        console.log(err)
    })
}

export const inviteGoogleAccount = (userData) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.post('/api/contacts/invite/',  userData)
    .then((res)=>{
        if(res && res.status) {
            dispatch({type: CLEAR_ERRORS});
        } 
    })
    .catch((err)=>{
        console.log(err)
    })
}

export const deleteWheezContact = (id) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.delete(`/api/contacts/delete/${id}/`,  {})
    .then((res)=>{
        if(res && res.status) {
            dispatch({type: CLEAR_ERRORS});
            dispatch(getWheezContacts());
        } 
    })
    .catch((err)=>{
        console.log(err)
    })
}

export const getCurrentRequests = () => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.get('/api/contacts/requests/',  {})
    .then((res)=>{
        if(res && res.status) {
            dispatch({type: CLEAR_ERRORS});
            dispatch({
                type: CURRENT_REQUESTS,
                payload: res.data
            })
        } 
    })
    .catch((err)=>{
        console.log(err)
    })
}


export const handleIncomingRequest = (userData) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.put('/api/contacts/requests/', userData)
    .then((res)=>{
        if(res && res.status === 200) {
            dispatch({type: CLEAR_ERRORS});
            dispatch(getCurrentRequests());
        } 
    })
    .catch((err)=>{
        console.log(err)
    })
}


export const getPublicProfileInfo = (userId) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.get(`/api/users/${userId}/profile/`, {})
    .then((res)=>{
        dispatch({type: CLEAR_ERRORS});
        dispatch({
            type: PUBLIC_PROFILE_DATA,
            payload: res.data
        });
        return res.data
    })
    .catch((err)=>{
        dispatch({type: CLEAR_ERRORS});
        console.log(err)
    })
}


export const sendRequest = (userData) => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.post('/api/contacts/requests/', userData)
    .then((res)=>{
        if(res && res.status === 200) {
            dispatch({type: CLEAR_ERRORS});
            return "success"
        } 
    })
    .catch((err)=>{
        dispatch({type: CLEAR_ERRORS});
        return "failed"
    })
}
