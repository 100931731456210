import React from 'react'
import { useSelector } from "react-redux";
import { useTable } from 'react-table'
import './../../styles/planSummaryTable.scss';

export default function PlanSummaryTable({ data }) {
    const planState = useSelector((state) => state.plans);
    const {planDetails} = planState;

    const columns = React.useMemo(
        () => [
          {
            Header: 'Date & Auction',
            accessor: 'dateAuction',
          },
          {
            Header: `Amount Taken From $${planDetails.total_amount ? planDetails.total_amount.substring(0, planDetails.total_amount.length - 3) : 0}`,
            columns: [
              {
                Header: 'Value',
                accessor: 'value',
              },
            ],
          },
          {
            Header: 'Amount Contributed By Each After The Auction',
            accessor: 'amountContributed',
          },
          {
            Header: 'Extra Interest ATAA - TAP',
            accessor: 'interest',
          },
        ],
        [planDetails]
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({columns, data})
   
    return (
        <div className='tableRelativeSummary'>
            <div className='tableScrollerSummary'>
                <table className='planTable' {...getTableProps()}>
                    <thead>
                        {headerGroups.map(group => ( 
                            <tr {...group.getHeaderGroupProps()}>
                                {group.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                            {row.cells.map(cell => 
                                {
                                    if(cell.column.id === "interest"){
                                        return <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                                &nbsp;(<span style={{color: cell.row.original.status === "Paid" ? '#FF3100' : '#00CA45'}}>{cell.row.original.status}</span>)
                                            </td>
                                    } else {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    }
                                }
                            )}
                            </tr>
                        )
                        })}
                    </tbody>
                </table>
            </div>
            <div className='tfoot'>
                <div className='upcomingContent'>
                </div>
            </div>
        </div>
    )
}
