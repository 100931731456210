import {
    GOOGLE_CONTACTS,
    INVITE_LINK,
    WHEEZ_CONTACTS,
    CURRENT_REQUESTS,
    CLEAR_CONTACT_DATA,
    PUBLIC_PROFILE_DATA,
    USER_AVATARS
  } from '../types';
  
  const initialState = {
    googleContacts: [],
    inviteLink : "",
    incomingRequests: [],
    outgoingRequests: [],
    userProfile: {},
    userAvatars: []
  };
  
  export default function contactReducer(state = initialState, action) {
    switch (action.type) {
        case GOOGLE_CONTACTS:
            return {
                ...state,
                googleContacts: action.payload
            };
        case USER_AVATARS:
            return {
                ...state,
                userAvatars: action.payload
            }
        case WHEEZ_CONTACTS:
            return {
                ...state,
                wheezContacts: action.payload
            };
        case CURRENT_REQUESTS:
            return {
                ...state,
                incomingRequests: action.payload.incoming_requests,
                outgoingRequests: action.payload.outgoing_requests,
            };
        case INVITE_LINK:
            return {
                ...state,
                inviteLink: action.payload
            };
        case PUBLIC_PROFILE_DATA:
            return {
                ...state,
                userProfile: action.payload,
            }
        case CLEAR_CONTACT_DATA:
            return initialState;
        default:
            return state;
    }
  }