import React from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from 'swiper/core';
import { isMobile } from "react-device-detect";
import arrowLeft from "../../assets/images/landingPage/benefits/arrow-right.png";
import leftImg from "../../assets/images/landingPage/benefits/Group 29.png";
import rightImg from "../../assets/images/landingPage/benefits/Group 30.png";
import imgTwo from "../../assets/images/landingPage/benefits/image 1 (1).png";
import imgThree from "../../assets/images/landingPage/benefits/image 1 (2).png";
import imgFour from "../../assets/images/landingPage/benefits/image 1 (3).png";
import imgFive from "../../assets/images/landingPage/benefits/image 1 (4).png";
import imgSix from "../../assets/images/landingPage/benefits/image 1 (5).png";
import imgOne from "../../assets/images/landingPage/benefits/image 1.png";
import style from "../../styles/landingPage/benefits.module.scss";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
SwiperCore.use([Pagination]);


const Benefits = () => {
  const matches = useMediaQuery("(max-width:899px)");

  return (
    <div className={style["benefits-main"]} id={"benefits"}>
      <Container
        sx={{
          maxWidth: {
            sm: "sm",
            md: "xl",
            lg: "lg",
            xl: "xl",
          },
        }}>
        <Box>
          <Typography
            variant="h3"
            className={style["benefits-main-t1"]}>
            Benefits of having{" "}
            {isMobile && <br/>}
            <Box component={"span"} color="#38383A">
              a wheez plan
            </Box>
          </Typography>
          <Typography
            className={style["benefits-main-t2"]}>
            You may be wondering how this plan helps you right? I can hear it
            Hear is Why everyone should have a wheez plan?
          </Typography>
          <Box style={{display: "flex", justifyContent: "center", gap: "30px", zIndex: '6', position: 'relative'}}>
            <img src={leftImg} width="40%" />
            <img src={rightImg} width="40%" />
          </Box>
        </Box>
        <Box className={style["benefits-grid-section"]}>
          <Box
            className={style["benefits-grid-section-box"]}>
            <div className={style["benefits-grid-section-boxes"]}>
              <div className={style["benefits-grid-section-boxes-1"]}></div>
              <div className={style["benefits-grid-section-boxes-2"]}></div>
              <div className={style["benefits-grid-section-boxes-3"]}></div>
            </div>
            {matches ? (
              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                className="mySwiper"
                style={{ height: "467px" }}>
                <SwiperSlide>
                  <Box
                    className={style["flip-card"]}
                    display={"flex"}
                    justifyContent={"center"}>
                    <Box
                      width={"95%"}
                      padding="20px"
                      height={"405px"}
                      borderRadius={"10px"}
                      bgcolor="#38383A"
                      position={"relative"}
                      className={style[("hover", "flip-card-inner")]}>
                      <Box className={style["flip-card-front"]}>
                        <Box marginTop={"20px"}>
                          <img src={imgOne} />
                        </Box>
                        <Typography
                          className={style["flip-card-front-t1"]}>
                          A New Way to Earn
                        </Typography>
                        <Typography className={style["flip-card-front-t2"]}>
                          By having a Wheez plan, you are contributing an amount
                          of money....
                        </Typography>
                        <Box
                          marginTop={"200px"}
                          display={"flex"}
                          justifyContent="end"
                          alignItems={"center"}>
                          <Box
                            padding={"10px"}
                            bgcolor="#000"
                            borderRadius={"50px"}
                            lineHeight="0px"
                            position={"absolute"}
                            right="20px"
                            bottom={"20px"}>
                            <img src={arrowLeft} />
                          </Box>
                        </Box>
                        <div style={{width: '100%', height: '100%', overflow: 'hidden', position: 'absolute', bottom: '0', right: 0, zIndex: '-1'}}>
                          <Box
                              bgcolor="#FFFFFF"
                              width={"120px"}
                              height={"120px"}
                              position={"absolute"}
                              right="-60px"
                              bottom={"-60px"}
                              style={{ opacity: "0.05", transform: "rotateZ(45deg)" }}
                            ></Box>
                        </div>
                      </Box>
                      <Box
                        padding="20px"
                        height={"405px"}
                        borderRadius={"10px"}
                        bgcolor="#000"
                        border="1px solid #fff"
                        position={"absolute"}
                        top="0px"
                        left={"0px"}
                        className={style[("show-hover", "flip-card-back")]}>
                        <Typography className={style["flip-card-front-t3"]} fontSize="16px">
                          By having a Wheez plan, you are contributing an amount
                          of money for a certain period with other members in
                          the plan. That means, members will give and take money
                          from each other, basically the member who waits and
                          contributes to other members will earn for their
                          contributions as like interests, but they have to wait
                          and play accordingly. And also At each installment,
                          based on the auction, every member will get dividends
                          too.
                        </Typography>

                        <Box
                          display={"flex"}
                          alignItems="center"
                          marginTop={"9px"}>
                          <Typography className={style["flip-card-front-t3"]}>
                            Diversification or an alternative way to use your
                            money.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Box
                    className={style["flip-card"]}
                    display={"flex"}
                    justifyContent={"center"}>
                    <Box
                      width={"95%"}
                      padding="20px"
                      height={"405px"}
                      borderRadius={"10px"}
                      bgcolor="#38383A"
                      position={"relative"}
                      className={style[("hover", "flip-card-inner")]}>
                      <Box className={style["flip-card-front"]}>
                        <Box marginTop={"20px"}>
                          <img src={imgTwo} />
                        </Box>
                        <Typography
                          className={style["flip-card-front-t1"]}>
                          A New Way to Save
                        </Typography>
                        <Typography className={style["flip-card-front-t2"]}>
                          By Having a Wheez plan one could improve their saving
                          habits....
                        </Typography>
                        <Box>
                          <Box
                            padding={"10px"}
                            bgcolor="#000"
                            borderRadius={"50px"}
                            lineHeight="0px"
                            position={"absolute"}
                            right="20px"
                            bottom={"20px"}>
                            <img src={arrowLeft} />
                          </Box>
                        </Box>
                        <div style={{width: '100%', height: '100%', overflow: 'hidden', position: 'absolute', bottom: '0', right: 0, zIndex: '-1'}}>
                          <Box
                              bgcolor="#FFFFFF"
                              width={"120px"}
                              height={"120px"}
                              position={"absolute"}
                              right="-60px"
                              bottom={"-60px"}
                              style={{ opacity: "0.05", transform: "rotateZ(45deg)" }}
                            ></Box>
                        </div>
                      </Box>
                      <Box
                        padding="20px"
                        height={"405px"}
                        borderRadius={"10px"}
                        bgcolor="#000"
                        position={"absolute"}
                        border="1px solid #fff"
                        top="0px"
                        left={"0px"}
                        className={style[("show-hover", "flip-card-back")]}>
                        <Typography className={style["flip-card-front-t3"]}>
                          By Having a Wheez plan one could improve their saving
                          habits and don’t need to worry about overspending.
                          Members can join in convenient plans and contribute a
                          small portion of the amount for a pre-determined
                          period based on their budget to take a lump sum of
                          money from the plan whenever they needed. So, by doing
                          this, people can eliminate overspending and at the
                          same time, they manage to save and gather a reasonable
                          sum of money.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Box
                    className={style["flip-card"]}
                    display={"flex"}
                    justifyContent={"center"}>
                    <Box
                      width={"95%"}
                      padding="20px"
                      height={"405px"}
                      borderRadius={"10px"}
                      bgcolor="#38383A"
                      position={"relative"}
                      className={style[("hover", "flip-card-inner")]}>
                      <Box className={style["flip-card-front"]}>
                        <Box marginTop={"20px"}>
                          <img src={imgThree} />
                        </Box>
                        <Typography
                          className={style["flip-card-front-t1"]}
                          >
                          A New Way to Borrow
                        </Typography>
                        <Typography className={style["flip-card-front-t2"]}>
                          By having a wheez plan, members have the accessibility
                          to take a reasonable....
                        </Typography>
                        <Box>
                          <Box
                            padding={"10px"}
                            bgcolor="#000"
                            borderRadius={"50px"}
                            lineHeight="0px"
                            position={"absolute"}
                            right="20px"
                            bottom={"20px"}>
                            <img src={arrowLeft} />
                          </Box>
                        </Box>
                        <div style={{width: '100%', height: '100%', overflow: 'hidden', position: 'absolute', bottom: '0', right: 0, zIndex: '-1'}}>
                          <Box
                              bgcolor="#FFFFFF"
                              width={"120px"}
                              height={"120px"}
                              position={"absolute"}
                              right="-60px"
                              bottom={"-60px"}
                              style={{ opacity: "0.05", transform: "rotateZ(45deg)" }}
                            ></Box>
                        </div>
                      </Box>
                      <Box
                        padding="20px"
                        height={"405px"}
                        borderRadius={"10px"}
                        bgcolor="#000"
                        position={"absolute"}
                        border="1px solid #fff"
                        top="0px"
                        left={"0px"}
                        className={style[("show-hover", "flip-card-back")]}>
                        <Typography className={style["flip-card-front-t3"]}>
                          By having a wheez plan, members have the accessibility
                          to take a reasonable sum of money at any installment
                          from the plan before even contributing such an amount,
                          and members can even take a lump sum of money at a
                          very low-interest rate or even without any interest
                          based on the auction. Members have to look into the
                          auction metrics while bidding and place bids
                          accordingly to borrow or take money accordingly.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Box
                    className={style["flip-card"]}
                    display={"flex"}
                    justifyContent={"center"}>
                    <Box
                      width={"95%"}
                      padding="20px"
                      height={"405px"}
                      borderRadius={"10px"}
                      bgcolor="#38383A"
                      position={"relative"}
                      className={style[("hover", "flip-card-inner")]}>
                      <Box className={style["flip-card-front"]}>
                        <Box marginTop={"20px"}>
                          <img src={imgFour} />
                        </Box>
                        <Typography
                          className={style["flip-card-front-t1"]}
                          >
                          Build Credit While You Save And Earn
                        </Typography>
                        <Typography className={style["flip-card-front-t2"]}>
                          Wheez plans are a concept, which brings people
                          together.....
                        </Typography>
                        <Box>
                          <Box
                            padding={"10px"}
                            bgcolor="#000"
                            borderRadius={"50px"}
                            lineHeight="0px"
                            position={"absolute"}
                            right="20px"
                            bottom={"20px"}>
                            <img src={arrowLeft} />
                          </Box>
                        </Box>
                        <div style={{width: '100%', height: '100%', overflow: 'hidden', position: 'absolute', bottom: '0', right: 0, zIndex: '-1'}}>
                          <Box
                              bgcolor="#FFFFFF"
                              width={"120px"}
                              height={"120px"}
                              position={"absolute"}
                              right="-60px"
                              bottom={"-60px"}
                              style={{ opacity: "0.05", transform: "rotateZ(45deg)" }}
                            ></Box>
                        </div>
                      </Box>
                      <Box
                        padding="20px"
                        height={"405px"}
                        borderRadius={"10px"}
                        bgcolor="#000"
                        border="1px solid #fff"
                        position={"absolute"}
                        top="0px"
                        left={"0px"}
                        className={style[("show-hover", "flip-card-back")]}>
                        <Typography className={style["flip-card-front-t3"]}>
                          Wheez plans are a concept, which brings people
                          together to help and benefit each other without
                          depending on any big financial institutions. Members
                          have total control over their money, and there are no
                          feds that determine the interest rates because this
                          concept lets a group of members in the plan to choose
                          their own Gains or pay, And that makes wheez plans a
                          democratized and socialized financial system.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Box
                    className={style["flip-card"]}
                    display={"flex"}
                    justifyContent={"center"}>
                    <Box
                      width={"95%"}
                      padding="20px"
                      height={"405px"}
                      borderRadius={"10px"}
                      bgcolor="#38383A"
                      position={"relative"}
                      className={style[("hover", "flip-card-inner")]}>
                      <Box className={style["flip-card-front"]}>
                        <Box marginTop={"20px"}>
                          <img src={imgFive} />
                        </Box>
                        <Typography
                          className={style["flip-card-front-t1"]}
                         >
                          An Alternative Way To Use Your Money
                        </Typography>
                        <Typography className={style["flip-card-front-t2"]}>
                          By having a Wheez Plan, One can diversify their
                          financial portfolio....
                        </Typography>
                        <Box>
                          <Box
                            padding={"10px"}
                            bgcolor="#000"
                            borderRadius={"50px"}
                            lineHeight="0px"
                            position={"absolute"}
                            right="20px"
                            bottom={"20px"}>
                            <img src={arrowLeft} />
                          </Box>
                        </Box>
                        <div style={{width: '100%', height: '100%', overflow: 'hidden', position: 'absolute', bottom: '0', right: 0, zIndex: '-1'}}>
                          <Box
                              bgcolor="#FFFFFF"
                              width={"120px"}
                              height={"120px"}
                              position={"absolute"}
                              right="-60px"
                              bottom={"-60px"}
                              style={{ opacity: "0.05", transform: "rotateZ(45deg)" }}
                            ></Box>
                        </div>
                      </Box>
                      <Box
                        padding="20px"
                        height={"405px"}
                        borderRadius={"10px"}
                        bgcolor="#000"
                        border="1px solid #fff"
                        position={"absolute"}
                        top="0px"
                        left={"0px"}
                        className={style[("show-hover", "flip-card-back")]}>
                        <Typography className={style["flip-card-front-t3"]}>
                          By having a Wheez Plan, One can diversify their
                          financial portfolio and make their financial lives
                          better. For over a century we are all following the
                          same financial models when it comes to saving and
                          borrowing, The same old Savings account with fewer
                          interest rates and the high-interest loans provided by
                          big financial institutions. Now there is a better
                          alternative way for people to use their money with
                          control and efficiency.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Box
                    className={style["flip-card"]}
                    display={"flex"}
                    justifyContent={"center"}>
                    <Box
                      width={"95%"}
                      padding="20px"
                      height={"405px"}
                      borderRadius={"10px"}
                      bgcolor="#38383A"
                      position={"relative"}
                      className={style[("hover", "flip-card-inner")]}>
                      <Box className={style["flip-card-front"]}>
                        <Box marginTop={"20px"}>
                          <img src={imgSix} />
                        </Box>
                        <Typography
                          className={style["flip-card-front-t1"]}
                          >
                          Democratized And A Socialized Financial System
                        </Typography>
                        <Typography className={style["flip-card-front-t2"]}>
                          Wheez plans are a concept, which brings people
                          together to help....
                        </Typography>
                        <Box>
                          <Box
                            padding={"10px"}
                            bgcolor="#000"
                            borderRadius={"50px"}
                            lineHeight="0px"
                            position={"absolute"}
                            right="20px"
                            bottom={"20px"}>
                            <img src={arrowLeft} />
                          </Box>
                        </Box>
                        <div style={{width: '100%', height: '100%', overflow: 'hidden', position: 'absolute', bottom: '0', right: 0, zIndex: '-1'}}>
                          <Box
                              bgcolor="#FFFFFF"
                              width={"120px"}
                              height={"120px"}
                              position={"absolute"}
                              right="-60px"
                              bottom={"-60px"}
                              style={{ opacity: "0.05", transform: "rotateZ(45deg)" }}
                            ></Box>
                        </div>
                      </Box>
                      <Box
                        padding="20px"
                        height={"405px"}
                        borderRadius={"10px"}
                        bgcolor="#000"
                        border="1px solid #fff"
                        position={"absolute"}
                        top="0px"
                        left={"0px"}
                        className={style[("show-hover", "flip-card-back")]}>
                        <Typography className={style["flip-card-front-t3"]}>
                          Wheez plans are a concept, which brings people
                          together to help and benefit each other without
                          depending on any big financial institutions. Members
                          have total control over their money, and there are no
                          feds that determine the interest rates because this
                          concept lets a group of members in the plan to choose
                          their own Gains or pay, And that makes wheez plans a
                          democratized and socialized financial system.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </SwiperSlide>
              </Swiper>
            ) : (
              <Grid container style={{flexDirection:"row", gap:"20px 0px", justifyContent: 'center'}}>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  md={6}
                  sm={12}
                  className={style["flip-card"]}>
                  <Box
                    width={"90%"}
                    padding="20px"
                    height={"345px"}
                    borderRadius={"10px"}
                    bgcolor="#38383A"
                    position={"relative"}
                    className={style[("hover", "flip-card-inner")]}>
                    <Box className={style["flip-card-front"]}>
                      <Box marginTop={"20px"}>
                        <img src={imgOne} />
                      </Box>
                      <Typography
                        style={{color: "#ffffff", margin: "5px 0", fontWeight: "600", fontSize: "21px"}}>
                        A New Way to Earn
                      </Typography>
                      <Typography style={{color: "#ffffff", margin: "5px 0", opacity: "0.6"}} >
                        By having a Wheez plan, you are contributing an amount
                        of money....
                      </Typography>
                      <Box
                        marginTop={"200px"}
                        display={"flex"}
                        justifyContent="end"
                        alignItems={"center"}>
                        <Box
                          padding={"10px"}
                          bgcolor="#000"
                          borderRadius={"50px"}
                          lineHeight="0px"
                          position={"absolute"}
                          right="20px"
                          bottom={"20px"}>
                          <img src={arrowLeft} />
                        </Box>
                      
                      </Box>
                      <div style={{width: '100%', height: '100%', overflow: 'hidden', position: 'absolute', bottom: '0', right: 0, zIndex: '-1'}}>
                          <Box
                              bgcolor="#FFFFFF"
                              width={"120px"}
                              height={"120px"}
                              position={"absolute"}
                              right="-60px"
                              bottom={"-60px"}
                              style={{ opacity: "0.05", transform: "rotateZ(45deg)" }}
                            ></Box>
                        </div>
                    </Box>
                    <Box
                      padding="20px"
                      height={"345px"}
                      borderRadius={"10px"}
                      bgcolor="#000"
                      position={"absolute"}
                      border="1px solid #fff"
                      top="0px"
                      left={"0px"}
                      className={style[("show-hover", "flip-card-back")]}>
                      <Typography style={{color: '#fff', fontSize: '13px'}}>
                        By having a Wheez plan, you are contributing an amount
                        of money for a certain period with other members in the
                        plan. That means, members will give and take money from
                        each other, basically the member who waits and
                        contributes to other members will earn for their
                        contributions as like interests, but they have to wait
                        and play accordingly. And also At each installment,
                        based on the auction, every member will get dividends
                        too.
                      </Typography>

                      <Box
                        display={"flex"}
                        alignItems="center"
                        marginTop={"9px"}>
                        <Typography style={{color: '#fff', fontSize: '13px'}} >
                          Diversification or an alternative way to use your
                          money.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  md={6}
                  sm={12}
                  className={style["flip-card"]}>
                  <Box
                    width={"90%"}
                    padding="20px"
                    height={"345px"}
                    borderRadius={"10px"}
                    bgcolor="#38383A"
                    position={"relative"}
                    className={style[("hover", "flip-card-inner")]}>
                    <Box className={style["flip-card-front"]}>
                      <Box marginTop={"20px"}>
                        <img src={imgTwo} />
                      </Box>
                      <Typography
                        style={{color: "#ffffff", margin: "5px 0", fontWeight: "600", fontSize: "21px"}}
                        >
                        A New Way to Save
                      </Typography>
                      <Typography style={{color: "#ffffff", margin: "5px 0", opacity: "0.6"}}>
                        By Having a Wheez plan one could improve their saving
                        habits....
                      </Typography>
                      <Box>
                        <Box
                          padding={"10px"}
                          bgcolor="#000"
                          borderRadius={"50px"}
                          lineHeight="0px"
                          position={"absolute"}
                          right="20px"
                          bottom={"20px"}>
                          <img src={arrowLeft} />
                        </Box>
                      </Box>
                      <div style={{width: '100%', height: '100%', overflow: 'hidden', position: 'absolute', bottom: '0', right: 0, zIndex: '-1'}}>
                          <Box
                              bgcolor="#FFFFFF"
                              width={"120px"}
                              height={"120px"}
                              position={"absolute"}
                              right="-60px"
                              bottom={"-60px"}
                              style={{ opacity: "0.05", transform: "rotateZ(45deg)" }}
                            ></Box>
                      </div>
                    </Box>
                    <Box
                      padding="20px"
                      height={"345px"}
                      borderRadius={"10px"}
                      bgcolor="#000"
                      position={"absolute"}
                      border="1px solid #fff"
                      top="0px"
                      left={"0px"}
                      className={style[("show-hover", "flip-card-back")]}>
                      <Typography style={{color: '#fff', fontSize: '13px'}}>
                        By Having a Wheez plan one could improve their saving
                        habits and don’t need to worry about overspending.
                        Members can join in convenient plans and contribute a
                        small portion of the amount for a pre-determined period
                        based on their budget to take a lump sum of money from
                        the plan whenever they needed. So, by doing this, people
                        can eliminate overspending and at the same time, they
                        manage to save and gather a reasonable sum of money.
                      </Typography>
                      <Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  md={6}
                  sm={12}
                  className={style["flip-card"]}>
                  <Box
                    width={"90%"}
                    padding="20px"
                    height={"345px"}
                    borderRadius={"10px"}
                    bgcolor="#38383A"
                    position={"relative"}
                    className={style[("hover", "flip-card-inner")]}>
                    <Box className={style["flip-card-front"]}>
                      <Box marginTop={"20px"}>
                        <img src={imgThree} />
                      </Box>
                      <Typography
                        style={{color: "#ffffff", margin: "5px 0", fontWeight: "600", fontSize: "21px"}}>
                        A New Way to Borrow
                      </Typography>
                      <Typography style={{color: "#ffffff", margin: "5px 0", opacity: "0.6"}} >
                        By having a wheez plan, members have the accessibility
                        to take a reasonable....
                      </Typography>
                      <Box      marginTop={"200px"}
                        display={"flex"}
                        justifyContent="end"
                        alignItems={"center"}>
                        <Box
                          padding={"10px"}
                          bgcolor="#000"
                          borderRadius={"50px"}
                          lineHeight="0px"
                          position={"absolute"}
                          right="20px"
                          bottom={"20px"}>
                          <img src={arrowLeft} />
                        </Box>
                      </Box>
                      <div style={{width: '100%', height: '100%', overflow: 'hidden', position: 'absolute', bottom: '0', right: 0, zIndex: '-1'}}>
                          <Box
                              bgcolor="#FFFFFF"
                              width={"120px"}
                              height={"120px"}
                              position={"absolute"}
                              right="-60px"
                              bottom={"-60px"}
                              style={{ opacity: "0.05", transform: "rotateZ(45deg)" }}
                            ></Box>
                      </div>
                    </Box>
                    <Box
                      padding="20px"
                      height={"345px"}
                      borderRadius={"10px"}
                      bgcolor="#000"
                      position={"absolute"}
                      border="1px solid #fff"
                      top="0px"
                      left={"0px"}
                      className={style[("show-hover", "flip-card-back")]}>
                      <Typography style={{color: '#fff', fontSize: '13px'}}>
                        By having a wheez plan, members have the accessibility
                        to take a reasonable sum of money at any installment
                        from the plan before even contributing such an amount,
                        and members can even take a lump sum of money at a very
                        low-interest rate or even without any interest based on
                        the auction. Members have to look into the auction
                        metrics while bidding and place bids accordingly to
                        borrow or take money accordingly.
                      </Typography>
                      <Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  md={6}
                  sm={12}
                  className={style["flip-card"]}>
                  <Box
                    width={"90%"}
                    padding="20px"
                    height={"345px"}
                    borderRadius={"10px"}
                    bgcolor="#38383A"
                    position={"relative"}
                    className={style[("hover", "flip-card-inner")]}>
                    <Box className={style["flip-card-front"]}>
                      <Box marginTop={"20px"}>
                        <img src={imgFour} />
                      </Box>
                      <Typography
                          style={{color: "#ffffff", margin: "5px 0", fontWeight: "600", fontSize: "21px",  textTransform: 'capitalize'}}>
                        Build Credit while you Save and Earn
                      </Typography>
                      <Typography style={{color: "#ffffff", margin: "5px 0", opacity: "0.6"}}>
                        Wheez plans are a concept, which brings people
                        together.....
                      </Typography>
                      <Box>
                        <Box
                          padding={"10px"}
                          bgcolor="#000"
                          borderRadius={"50px"}
                          lineHeight="0px"
                          position={"absolute"}
                          right="20px"
                          bottom={"20px"}>
                          <img src={arrowLeft} />
                        </Box>
                      </Box>
                      <div style={{width: '100%', height: '100%', overflow: 'hidden', position: 'absolute', bottom: '0', right: 0, zIndex: '-1'}}>
                          <Box
                              bgcolor="#FFFFFF"
                              width={"120px"}
                              height={"120px"}
                              position={"absolute"}
                              right="-60px"
                              bottom={"-60px"}
                              style={{ opacity: "0.05", transform: "rotateZ(45deg)" }}
                            ></Box>
                      </div>
                    </Box>
                    <Box
                      padding="20px"
                      height={"345px"}
                      borderRadius={"10px"}
                      bgcolor="#000"
                      position={"absolute"}
                      border="1px solid #fff"
                      top="0px"
                      left={"0px"}
                      className={style[("show-hover", "flip-card-back")]}>
                      <Typography style={{color: '#fff', fontSize: '13px'}}>
                        Wheez plans are a concept, which brings people together
                        to help and benefit each other without depending on any
                        big financial institutions. Members have total control
                        over their money, and there are no feds that determine
                        the interest rates because this concept lets a group of
                        members in the plan to choose their own Gains or pay,
                        And that makes wheez plans a democratized and socialized
                        financial system.
                      </Typography>
                      <Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  md={6}
                  sm={12}
                  className={style["flip-card"]}>
                  <Box
                    width={"90%"}
                    padding="20px"
                    height={"345px"}
                    borderRadius={"10px"}
                    bgcolor="#38383A"
                    position={"relative"}
                    className={style[("hover", "flip-card-inner")]}>
                    <Box className={style["flip-card-front"]}>
                      <Box marginTop={"20px"}>
                        <img src={imgFive} />
                      </Box>
                      <Typography
                        style={{color: "#ffffff", margin: "5px 0", fontWeight: "600", fontSize: "21px", textTransform: 'capitalize'}}>
                        an alternative way to use your money
                      </Typography>
                      <Typography style={{color: "#ffffff", margin: "5px 0", opacity: "0.6"}}>
                        By having a Wheez Plan, One can diversify their
                        financial portfolio....
                      </Typography>
                      <Box>
                        <Box
                          padding={"10px"}
                          bgcolor="#000"
                          borderRadius={"50px"}
                          lineHeight="0px"
                          position={"absolute"}
                          right="20px"
                          bottom={"20px"}>
                          <img src={arrowLeft} />
                        </Box>
                      </Box>
                      <div style={{width: '100%', height: '100%', overflow: 'hidden', position: 'absolute', bottom: '0', right: 0, zIndex: '-1'}}>
                          <Box
                              bgcolor="#FFFFFF"
                              width={"120px"}
                              height={"120px"}
                              position={"absolute"}
                              right="-60px"
                              bottom={"-60px"}
                              style={{ opacity: "0.05", transform: "rotateZ(45deg)" }}
                            ></Box>
                      </div>
                    </Box>
                    <Box
                      padding="20px"
                      height={"345px"}
                      borderRadius={"10px"}
                      bgcolor="#000"
                      position={"absolute"}
                      border="1px solid #fff"
                      top="0px"
                      left={"0px"}
                      className={style[("show-hover", "flip-card-back")]}>
                      <Typography style={{color: '#fff', fontSize: '13px'}}>
                        By having a Wheez Plan, One can diversify their
                        financial portfolio and make their financial lives
                        better. For over a century we are all following the same
                        financial models when it comes to saving and borrowing,
                        The same old Savings account with fewer interest rates
                        and the high-interest loans provided by big financial
                        institutions. Now there is a better alternative way for
                        people to use their money with control and efficiency.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  md={6}
                  sm={12}
                  className={style["flip-card"]}>
                  <Box
                    width={"90%"}
                    padding="20px"
                    height={"345px"}
                    borderRadius={"10px"}
                    bgcolor="#38383A"
                    position={"relative"}
                    className={style[("hover", "flip-card-inner")]}>
                    <Box className={style["flip-card-front"]}>
                      <Box marginTop={"20px"}>
                        <img src={imgSix} />
                      </Box>
                      <Typography
                        style={{color: "#ffffff", margin: "5px 0", fontWeight: "600", fontSize: "21px",  textTransform: 'capitalize'}}>
                        Democratized and a socialized financial system
                      </Typography>
                      <Typography style={{color: "#ffffff", margin: "5px 0", opacity: "0.6"}}>
                        Wheez plans are a concept, which brings people together
                        to help....
                      </Typography>
                      <Box>
                        <Box
                          padding={"10px"}
                          bgcolor="#000"
                          borderRadius={"50px"}
                          lineHeight="0px"
                          position={"absolute"}
                          right="20px"
                          bottom={"20px"}>
                          <img src={arrowLeft} />
                        </Box>
                      </Box>
                      <div style={{width: '100%', height: '100%', overflow: 'hidden', position: 'absolute', bottom: '0', right: 0, zIndex: '-1'}}>
                          <Box
                              bgcolor="#FFFFFF"
                              width={"120px"}
                              height={"120px"}
                              position={"absolute"}
                              right="-60px"
                              bottom={"-60px"}
                              style={{ opacity: "0.05", transform: "rotateZ(45deg)" }}
                            ></Box>
                      </div>
                    </Box>
                    <Box
                      padding="20px"
                      height={"345px"}
                      borderRadius={"10px"}
                      bgcolor="#000"
                      position={"absolute"}
                      border="1px solid #fff"
                      top="0px"
                      left={"0px"}
                      className={style[("show-hover", "flip-card-back")]}>
                      <Typography style={{color: '#fff', fontSize: '13px'}}>
                        Wheez plans are a concept, which brings people together
                        to help and benefit each other without depending on any
                        big financial institutions. Members have total control
                        over their money, and there are no feds that determine
                        the interest rates because this concept lets a group of
                        members in the plan to choose their own Gains or pay,
                        And that makes wheez plans a democratized and socialized
                        financial system.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default Benefits;
