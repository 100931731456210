import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from "react-router";
import addUserProgress from './../assets/images/icons/addUserProgress.svg';
import googleProgress from './../assets/images/icons/googleProgress.svg';
import divider from './../assets/images/icons/divider.svg';
import tickSquareGreen from './../assets/images/icons/tickSquareGreen.svg';
import checkProgress from './../assets/images/icons/checkProgress.svg';
import walletProgress from './../assets/images/icons/walletProgress.svg';
import bankProgress from './../assets/images/icons/bankProgress.svg';
import gemIcon from './../assets/images/icons/gemIcon.svg';
import thumbsUpIcon from './../assets/images/icons/thumbsUpIcon.svg';

import './../styles/accountSetup.scss'

export default function AccountSetup () {
    const history = useHistory()
    const user = useSelector((state) => state.user);
    const plan = useSelector((state) => state.plans);

    const { userProfile } = user;
    const [statusCount, setStatusCount] = useState(0) 
    const [googleAdded, setGoogleAdded] = useState(false)
    const [bankAdded, setBankAdded] = useState(false)
    
    const recPlans = false
    const userPlans = plan.userPlansThere

    useEffect(() => {
        let count = 0
        if(userProfile &&  userProfile.added_account){
            setGoogleAdded(true)
            count ++;
        }
        if(userProfile &&  userProfile.limit && parseFloat(userProfile.limit) > 0){
            setBankAdded(true)
            count ++;
        }
        setStatusCount(count)
    }, [userProfile])

    const handleButton = () => {
        if(statusCount === 0){
            history.push('/friends/2')
        }

        if(statusCount <= 1 && !googleAdded){
            history.push('/friends/2')
        }

        if(statusCount === 2){
            if(recPlans){
                history.push('/plans/2')
            } else {
                history.push('/plans/1')
            }
        }
    }
    return (
        <div className='accountProgressContainer'>
            <header>
                {statusCount <= 2 && (
                    !googleAdded ? !googleAdded && <img src={googleProgress} alt="icon" /> : 
                    (!bankAdded ? <img src={walletProgress} alt="icon" /> : <></>)
                )}
                {statusCount <= 2 && googleAdded && bankAdded && (recPlans ? <img src={gemIcon} alt="icon" /> : (userPlans ? <img src={thumbsUpIcon} alt="icon" /> : <img src={checkProgress} alt="icon" />))}
                <div className='content'>
                    <div className='welcomeBack'>Welcome Back, {userProfile && (userProfile.display_name || userProfile.first_name) ? (userProfile.display_name ? userProfile.display_name : userProfile.first_name) : '' }</div>
                    <div className='info'>
                        {statusCount < 2 ? 'Finish Account Setup' : (recPlans ? "Hurray, You've Got Recommendations" : (userPlans ? "You're Up To Date" : 'Your Account Has Been Unlocked'))}
                    </div>
                    {statusCount < 2 && <div className='slotsSlide'>
                        <div className='track'>
                            <div className='amount' style={{width: `${(statusCount/2)*100}%`}}></div>
                        </div>
                        <div className='value'>{statusCount}/2</div>
                    </div>}
                </div>
            </header>
            {statusCount === 2 && !recPlans && !userPlans && <div className='eligble'>You're now eligble to apply for a plan</div>}
            {(!userPlans || statusCount < 2) && <button onClick={() => handleButton()}>
                {statusCount <= 2 && (
                     statusCount <= 1 ? (
                        !googleAdded ? 'Connect Google' : (!bankAdded ? 'Link Account' : '')
                     ) : (recPlans ? 'Join it before it fills out' : 'Apply For A Plan')
                )}
            </button>}
            {recPlans && <button onClick={() => handleButton()}>
                {statusCount === 2 && recPlans && 'Join it before it fills out'}
            </button>}
            {((!recPlans && !userPlans) || statusCount <= 2) && <><div className='statusFlex first'>
                {!googleAdded ? <img src={addUserProgress} alt="icon" /> : <img src={tickSquareGreen} alt="icon" />}
                <span className='info'>
                    {googleAdded ? 'Succcessfully connected google contacts' : 'Connect Your Google Contacts'}</span>
                {!googleAdded && <span className='status'></span>}
            </div>
            <img className='divider' src={divider} alt="icon" />
            <div className='statusFlex'>
                {!bankAdded ? <img src={bankProgress} alt="icon" /> : <img src={tickSquareGreen} alt="icon" />}
                <span className={`info ${statusCount === 0 && 'greyed'}`}>
                    {bankAdded ? 'Linked Account Successfully' : 'Link your bank account and get a limit'}
                    
                </span>
                {!bankAdded && <span className='status'></span>}
            </div></>}
        </div>
    )
}
