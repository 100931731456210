import axios from 'axios';
import moment from 'moment'
import { 
    INCOMING_MESSAGE,
    CHAT_SCROLL_TRIGGER_START,
    CHAT_SCROLL_TRIGGER_END,
    CLEAR_CHAT,
    DAY_INDICATOR,
    DELETE_MESSAGE,
    LOADING_UI,
    CLEAR_ERRORS
} from '../types';
const BASE_URL = process.env.REACT_APP_WHEEZ_SERVER_URL;
let socketUrl;

export const chatSocket = (planId) => (dispatch) => {
    let token = localStorage.accessToken;
    if(!socketUrl || (socketUrl && (socketUrl.readyState === 3 || socketUrl.readyState === 2))){
        socketUrl = new WebSocket(`ws://${BASE_URL}/ws/chat/${planId}/?token=${token}`)
    }

    socketUrl.onmessage = evt => {
        dispatch({
            type: LOADING_UI
        })
        const message = JSON.parse(evt.data);
        if(message.message_type === "chat_message"){
            dispatch({
                type: INCOMING_MESSAGE,
                payload: message.data.chat_message
            })
            dispatch({
                type: CHAT_SCROLL_TRIGGER_START
            })
            
        }
        if(message.message_type === "delete_message"){
            dispatch({
                type: DELETE_MESSAGE,
                payload: message.data.message_id
            })
            dispatch({
                type: CHAT_SCROLL_TRIGGER_START
            })
        }
        dispatch({
            type: CLEAR_ERRORS
        })
    }
}

export const closeSocket = () => (dispatch) => {
    if(socketUrl && socketUrl.readyState === 1){
        socketUrl.close();
    }
}

export const sendMessageSocket = (message) => (dispatch) => {
    socketUrl.send(
        JSON.stringify({
            "message_type": "chat_message",
            "data": {
                "text_message": message,
                "message_replied_to_id": null
            }
        })
    )
}


export const deleteMessage = (message) => (dispatch) => {
    socketUrl.send(
        JSON.stringify({
            "message_type": "delete_message",
            "data": {
                "id": message.id
            }
        })
    )
}

export const replyTo = (message, replyId) => (dispatch) => {
    socketUrl.send(
        JSON.stringify({
            "message_type": "chat_message",
            "data": {
                "text_message": message,
                "message_replied_to_id": replyId
            }
        })
    )
}

function compare( a, b ) {
    if ( a.created_at < b.created_at ){
      return -1;
    }
    if ( a.created_at > b.created_at ){
      return 1;
    }
    return 0;
}

function getMyText(date){
    if( !moment.isMoment(date) ){
      date = moment(date); // ok for js date, milliseconds or string in ISO format
    }
    
    if( date.isSame(moment(), 'day') ){
      return 'today';
    } else if( date.isSame(moment().subtract(1, 'd'), 'day') ){
      return 'yesterday';
    } else if( date.isSame(moment(), 'week') ){
      return date.format('dddd');
    } else {
      return date.format('DD/MM/YYYY');
    }
  }

export const getAllChats = (planId) => (dispatch) => {
    dispatch({
        type: CLEAR_CHAT
    });
    return axios.get(`/api/plans/${planId}/chat-messages/`, {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            let results = res.data.results;
            let yesterday = false;
            let today = false;
            // let before = false;
            results.sort(compare);
            results.map((result, index) => {
                let date = getMyText(results[index].created_at);
                if(date === "yesterday" && !yesterday){
                    yesterday = true;
                    dispatch({
                        type: DAY_INDICATOR,
                        payload: {
                            date: date,
                            key: result.created_at
                        }
                    })
                } else if(date === "today" && !today){
                    today = true;
                    dispatch({
                        type: DAY_INDICATOR,
                        payload: {
                            date: date,
                            key: result.created_at
                        }
                    })
                } else {
                    // before = true;
                    // dispatch({
                    //     type: DAY_INDICATOR,
                    //     payload: {
                    //         date: "before",
                    //         key: result.created_at
                    //     }
                    // })
                }
                if(result.message_type === "MEMBER_MESSAGE"){
                    dispatch({
                        type: INCOMING_MESSAGE,
                        payload: result
                    })
                }
                if(result.message_type === "ADMIN_MESSAGE"){
                    dispatch({
                        type: INCOMING_MESSAGE,
                        payload: result
                    })
                }
                return null
            })
            dispatch({
                type: CHAT_SCROLL_TRIGGER_START
            })
        }
    })
    .catch((err)=>{
        console.log("unable to fetch")
    })
}

export const triggerScroll = () => (dispatch) => {
    dispatch({type: CHAT_SCROLL_TRIGGER_START});
}

export const triggerScrollEnd = () => (dispatch) => {
    dispatch({type: CHAT_SCROLL_TRIGGER_END});
}