import React from "react";
import { Box } from "@material-ui/core";
import { isMobile } from "react-device-detect";

function Footer() {
  return (
    !isMobile ? 
    <Box style={{backgroundColor: '#262626', padding: "30px 100px 30px 100px", color: '#D9DBE1'}} >
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{display: 'flex', gap: '32px'}}>
            <span style={{cursor: 'pointer'}}>Home</span>
            <span style={{cursor: 'pointer'}}>About</span>
            <span style={{cursor: 'pointer'}}>Blog</span>
            <span style={{cursor: 'pointer'}}>Contact</span>
          </div>
          <span>© 2023 Wheez. All rights reserved</span>
        </div>
    </Box>
    :
    <Box style={{backgroundColor: '#262626', padding: "30px", color: '#D9DBE1'}} >
        <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <div style={{display: 'flex', gap: '32px'}}>
            <span style={{cursor: 'pointer'}}>Home</span>
            <span style={{cursor: 'pointer'}}>About</span>
            <span style={{cursor: 'pointer'}}>Blog</span>
            <span style={{cursor: 'pointer'}}>Contact</span>
          </div>
          <span>© 2023 Wheez. All rights reserved</span>
        </div>
    </Box>
  );
}

export default Footer;
