import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import Loading from '../components/Loading';
import SwitchRadio from '../components/SwitchRadio';
import CountrySelector from './../components/CountrySelector';
import StateSelector from './../components/StateSelector';
import CitySelector from './../components/CitySelector';
import { 
    addIdProof,
    getProfileInfo,
    saveProfileInfo,
    getStates,
    getCities,
    getNotificationSettings,
    updateNotificationSetting
} from '../redux/actions/userActions';

import { 
    linkGoogleAccount,
    getGoogleContactsAll,
    getAllAvatars,
    updateAvatar,
    uploadPicture,
} from '../redux/actions/contactActions';
import backIcon from './../assets/images/icons/backIcon.svg';
import profileIcon from './../assets/images/icons/profileIconWhite.svg';
import rightArrowIcon from './../assets/images/icons/rightArrowIcon.svg';
import walletSettings from './../assets/images/icons/walletSettings.svg';
import notificationSettings from './../assets/images/icons/notificationSettings.svg';
import privacyIcon from './../assets/images/icons/privacyIcon.svg';
import lockWithBlue from './../assets/images/icons/lockWithBlue.svg';
import accountIcon from './../assets/images/icons/accountIcon.svg';
import divider from './../assets/images/icons/divider.svg';
import closeIcon from './../assets/images/icons/closeCircle.svg';
import DP from './../assets/images/DP.png';
import editIcon from './../assets/images/icons/editImageIcon.svg';
import infoSquare from './../assets/images/icons/infoSquare.svg';
import plusIcon from './../assets/images/icons/plusIcon.svg';
import citiIcon from './../assets/images/icons/citiIcon.svg';
import boaIcon from './../assets/images/icons/boaIcon.svg';
import gIcon from './../assets/images/icons/googleIcon.png';
import chevronRightGrey from './../assets/images/icons/chevronRightGrey.svg';
import imageSource from './../assets/images/icons/imageSource.svg';
import 'swiper/swiper.scss';
import './../styles/settings.scss';
const BASE_URL = `http://${process.env.REACT_APP_WHEEZ_SERVER_URL}`;
class settings extends Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.listRef = React.createRef();
        this.settingsSection = React.createRef();
        this.firstName = React.createRef();
        this.lastName = React.createRef();
        this.location = React.createRef();
        this.occupation = React.createRef();
        this.jobTitle = React.createRef();
        this.company = React.createRef();
        this.displayName = React.createRef();
        this.username = React.createRef();
        this.phoneNumber = React.createRef();
        this.legalFirstName = React.createRef();
        this.legalLastName = React.createRef();
        this.resAddress = React.createRef()

        this.state = {
            image: null,
            headerTitle: 'settings',
            firstNameValue : '',
            lastNameValue: '',
            locationValue: '',
            occupationValue: '',
            jobTitleValue: '',
            companyValue: '',
            displayNameValue: '',
            userNameValue: '',
            phoneNumberValue: '',
            editFirstName: false,
            setIsAvatarSelectorOpened: false,
            editLastName: false,
            editLocation: false,
            editOccupation: false,
            editJobTitle: false,
            editCompany: false,
            editDisplayName: false,
            editUsername: false,
            editPhoneNumber: false,
            residencialEdit: false,
            avatarSelected: false,
            avatarSelectedId: null,
            privacyEnabled: false,
            editLegalFirstName:false,
            legalFirstNameValue:'',
            legalLastNameValue:'',
            country: '',
            state: '',
            city: '',
            resAddress: '',
            invalidCountry: false,
            zipCode: ''
        }
        // this.onImageChange = this.onImageChange.bind(this);
    }
    getProfile = () => {
        this.props.getProfileInfo().then(res => {
            if(res){
                this.setState({
                    firstNameValue: res.first_name,
                    lastNameValue: res.last_name,
                    locationValue: res.country,
                    occupationValue: res.occupation_custom,
                    jobTitleValue: res.job_title,
                    companyValue: res.company,
                    displayNameValue: res.display_name,
                    userNameValue: res.username,
                    phoneNumberValue: res.secondary_phone_number,
                    legalFirstNameValue: res.legal_first_name,
                    legalLastNameValue: res.legal_last_name,
                    privacyEnabled: res.privacy,
                    country: { value: res.country, label: res.country === "US" ? "United States" : ''},
                    state: res.state && res.state.id ? { value: res.state.short_code, label: res.state.title, id: res.state.id } : null,
                    city: res.city && res.city.id ? { value: res.city.title, label: res.city.title, id: res.city.id } : null,
                    resAddress: res.residential_address,
                    zipCode: res.zip_code
                })
            }
        })
    }
    componentDidMount() {
        this.props.getGoogleContactsAll();
        this.props.getAllAvatars();
        this.getProfile()
        this.props.getStates()
        this.props.getNotificationSettings()
    }
    // addIdProof = () => {
    //     this.fileInput.current.click();
    // }
    // onImageChange = event => {
    //     event.preventDefault();
    //     if (event.target.files && event.target.files[0]) {
    //         let img = event.target.files[0];
    //         this.setState({
    //             image: URL.createObjectURL(img)
    //         });
    //         var formData = new FormData();
    //         formData.append('photo', img); 
    //         this.props.addIdProof(formData).then(res => {
    //             // if(res.result === "uploaded"){
    //             // } 
    //             if(res.result === "tooBig"){
    //                 console.log("too big")
    //             } else {
    //                 console.log("failed")
    //             }
    //         });
    //     }
    // };
    handleGoogleLink = () => {
        this.props.linkGoogleAccount().then((url)=> {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        });
    }
    openSection = (id) => {
        if(id === 1){
            this.setState({
                headerTitle: 'Profile settings',
            })
        } else if(id === 2){
            this.setState({
                headerTitle: 'Account settings',
            })
        } else if(id === 3){
            this.setState({
                headerTitle: 'Privacy settings',
            })
        } else if(id === 4){
            this.setState({
                headerTitle: 'Notifications settings',
            })
        } else if(id === 5){
            this.setState({
                headerTitle: 'Password & Security settings',
            })
        }
        else if(id === 6){
            this.setState({
                headerTitle: 'Personal Info',
            })
        }
        else if(id === 7){
            this.setState({
                headerTitle: 'Residencial Address',
            })
        }
        this.props.history.push(`/settings/${id}`)
    }
    backAction = () => {
        if(this.state.headerTitle.toLowerCase() === "settings"){
            this.props.history.push('/dashboard')
        } else if(this.state.headerTitle.toLowerCase() === "residencial address"){
            this.props.history.push('/settings/6')
            this.setState({
                headerTitle: 'Personal Info'
            })
        } else {
            this.props.history.push('/settings/0')
            this.setState({
                headerTitle: 'settings'
            })
        }
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name] : event.target.value
        })
    }   
    saveData = (id) => {
        if(id === 1){
            this.setState({
                editFirstName: false
            })
            this.props.saveProfileInfo({
                first_name: this.firstName.current.value
            }).then(res => {
                if(res.status === "done"){
                    this.props.getProfileInfo().then(res => {
                        this.setState({
                            firstNameValue: res.first_name,
                        })
                    })
                }
            })
        }
        if(id === 2){
            this.setState({
                editLastName: false
            })
            this.props.saveProfileInfo({
                last_name: this.lastName.current.value
            }).then(res => {
                if(res.status === "done"){
                    this.props.getProfileInfo().then(res => {
                        this.setState({
                            lastNameValue: res.last_name
                        })
                    })
                }
            })
        }
        if(id === 3){
            this.setState({
                editDisplayName: false
            })
            this.props.saveProfileInfo({
                display_name: this.displayName.current.value
            }).then(res => {
                if(res.status === "done"){
                    this.props.getProfileInfo().then(res => {
                        this.setState({
                            displayNameValue: res.display_name
                        })
                    })
                }
            })
        }
        if(id === 4){
            this.setState({
                editLocation: false
            })
            this.props.saveProfileInfo({
                country: this.location.current.value
            }).then(res => {
                if(res.status === "done"){
                    this.props.getProfileInfo().then(res => {
                        this.setState({
                            locationValue: res.country
                        })
                    })
                }
            })
        }
        if(id === 5){
            this.setState({
                editOccupation: false
            })
            this.props.saveProfileInfo({
                occupation_custom: this.occupation.current.value
            }).then(res => {
                if(res.status === "done"){
                    this.props.getProfileInfo().then(res => {
                        this.setState({
                            occupationValue: res.occupation_custom
                        })
                    })
                }
            })
        }
        if(id === 6){
            this.setState({
                editJobTitle: false
            })
            this.props.saveProfileInfo({
                job_title: this.jobTitle.current.value
            }).then(res => {
                if(res.status === "done"){
                    this.props.getProfileInfo().then(res => {
                        this.setState({
                            jobTitleValue: res.job_title
                        })
                    })
                }
            })
        }
        if(id === 7){
            this.setState({
                editCompany: false
            })
            this.props.saveProfileInfo({
                company: this.company.current.value
            }).then(res => {
                if(res.status === "done"){
                    this.props.getProfileInfo().then(res => {
                        this.setState({
                            companyValue: res.company
                        })
                    })
                }
            })
        }
        if(id === 8){
            this.setState({
                editUsername: false
            })
            this.props.saveProfileInfo({
                username: this.username.current.value
            }).then(res => {
                if(res.status === "done"){
                    this.props.getProfileInfo().then(res => {
                        this.setState({
                            userNameValue: res.username
                        })
                    })
                }
            })
        }
        if(id === 9){
            this.setState({
                editPhoneNumber: false
            })
            this.props.saveProfileInfo({
                secondary_phone_number: this.phoneNumber.current.value
            }).then(res => {
                if(res.status === "done"){
                    this.props.getProfileInfo().then(res => {
                        this.setState({
                            phoneNumberValue: res.secondary_phone_number
                        })
                    })
                }
            })
        }
        if(id === 10){
            this.setState({
                editLegalFirstName: false
            })
            this.props.saveProfileInfo({
                legal_first_name: this.legalFirstName.current.value
            }).then(res => {
                if(res.status === "done"){
                    this.props.getProfileInfo().then(res => {
                        this.setState({
                            legalFirstNameValue: res.legal_first_name
                        })
                    })
                }
            })
        }
        if(id === 11){
            this.setState({
                editLegalLastName: false
            })
            this.props.saveProfileInfo({
                legal_last_name: this.legalLastName.current.value
            }).then(res => {
                if(res.status === "done"){
                    this.props.getProfileInfo().then(res => {
                        this.setState({
                            legalLasttNameValue: res.legal_last_name
                        })
                    })
                }
            })
        }
        if(id === 12){
            this.props.saveProfileInfo({
                zip_code: this.state.zipCode
            }).then(res => {
                if(res.status === "done"){
                    this.props.getProfileInfo().then(res => {
                        this.setState({
                            zipCode: res.zip_code
                        })
                    })
                }
            })
        }
    }
    updatePrivacy = (e) => {
        // this.setState({privacyEnabled: e.target.checked}) 
        this.props.saveProfileInfo({
            privacy: e.target.checked
        }).then(res => {
            if(res.status === "done"){
                this.props.getProfileInfo().then(res => {
                    this.setState({
                        privacyEnabled: res.privacy
                    })
                })
            }
        })
    }
    updateAv = () => {
        if(this.state.avatarSelectedId){
            this.props.updateAvatar({
                avatarId: this.state.avatarSelectedId
            }).then((res) => {
                if(res){
                    this.getProfile()
                    this.setState({ isAvatarSelectorOpened : false, avatarSelected: false, avatarSelectedId: null})
                }
            })
        }
    }
    customUpload = () => {
        var imagefile = document.querySelector('#file');
        this.props.uploadPicture({
            imagefile
        }).then((res) => {
            if(res){
                this.getProfile()
                this.setState({ isAvatarSelectorOpened : false, avatarSelected: false, avatarSelectedId: null})
            }
        })

    }
    countrySelect = (value) => {
        this.setState({
            country : value
        })
    }
    stateSelect = (value) => {
        this.setState({
            state : value
        })
        this.props.getCities(value.id)
    }
    citySelect = (value) => {
        this.setState({
            city : value
        })
    }
    updateData = (e) => {
        e.preventDefault()
        this.saveData(12)
        this.props.saveProfileInfo({
            country: this.state.country.value,
            city: this.state.city ? this.state.city.id : '',
            state: this.state.state ? this.state.state.id : '',
            residential_address: this.state.resAddress
        }).then(res => {
            if(res.status === "done"){
                this.props.getProfileInfo().then(res => {
                    this.setState({
                        country: { value: res.country, label: res.country === "US" ? "United States" : ''},
                        city: res.city && res.city.id ? { value: res.city.title, label: res.city.title, id: res.city.id } : null,
                        state: res.state && res.state.id ? { value: res.state.short_code, label: res.state.title, id: res.state.id } : null,
                        resAddress: res.residential_address,
                        invalidCountry: false
                    })
                })
            }
            if(Array.isArray(res)){
                res.map((r) => {
                    if(r === 'country'){
                        this.setState({
                            invalidCountry: true
                        })
                    }
                    return null
                })
            }
        })
    }
    updateNotificationSetting = (event, value) => {
        const data = {...this.props.user.notificationSettings}
        data[value] = event.target.checked
        this.props.updateNotificationSetting(data)
    }

    render() {
        const menuNumber = this.props.match.params.id
        const { UI : { loading} } = this.props;
        const userProfile = this.props.user.userProfile;
        const resAddress = (userProfile.residential_address ? userProfile.residential_address + '<br/>' : '')
        + (userProfile.city && userProfile.city.title ? userProfile.city.title + '<br/>' : '')
        + (userProfile.state && userProfile.state.title ? userProfile.state.title + '<br/>' : '')
        + (userProfile.country ? userProfile.country + '<br/>' : '')
        + (userProfile.zip_code ? userProfile.zip_code : '')

        const notifSettings = this.props.user.notificationSettings

        return (
            loading ? (<Loading /> ) : (
            <div className="settingsContainer">
                <div className='header'>
                    <img onClick={() => this.backAction()} src={backIcon} alt="icon" />
                    <div className='title'>{this.state.headerTitle}</div>
                </div>
                {menuNumber === '0' && <ul ref={this.listRef} className='list'>
                    <li onClick={() => this.openSection(1)}>
                        <img src={profileIcon} className='icon' alt="icon" />
                        <span className='title'>Profile</span>
                        <img src={rightArrowIcon} className='rightArrow' alt="icon" />
                        <img src={divider} className='divider' alt="icon" />
                    </li>
                    <li onClick={() => this.openSection(6)}>
                        <img src={infoSquare} className='icon' alt="icon" />
                        <span className='title'>Personal Info</span>
                        <img src={rightArrowIcon} className='rightArrow' alt="icon" />
                        <img src={divider} className='divider' alt="icon" />
                    </li>
                    <li onClick={() => this.openSection(2)}>
                        <img src={accountIcon} className='icon' alt="icon" />
                        <span className='title'>Account</span>
                        <img src={rightArrowIcon} className='rightArrow' alt="icon" />
                        <img src={divider} className='divider' alt="icon" />
                    </li>
                    <li onClick={() => this.openSection(3)}>
                        <img src={privacyIcon} className='icon' alt="icon" />
                        <span className='title'>Privacy</span>
                        <img src={rightArrowIcon} className='rightArrow' alt="icon" />
                        <img src={divider} className='divider' alt="icon" />
                    </li>
                    <li onClick={() => this.openSection(4)}>
                        <img src={notificationSettings} className='icon' alt="icon" />
                        <span className='title'>Notifications</span>
                        <img src={rightArrowIcon} className='rightArrow' alt="icon" />
                        <img src={divider} className='divider' alt="icon" />
                    </li>
                    <li onClick={() => this.openSection(5)}>
                        <img src={walletSettings} className='icon' alt="icon" />
                        <span className='title'>Password & Security</span>
                        <img src={rightArrowIcon} className='rightArrow' alt="icon" />
                    </li>
                </ul>}
                {this.state.isAvatarSelectorOpened && <div className='avatarSelector'>
                    <div className='topRow'>
                        <span>Change Avatar</span>
                        <img src={closeIcon} alt="icon" onClick={() => this.setState({ isAvatarSelectorOpened : false, avatarSelected: false, avatarSelectedId: null})}/>
                    </div>
                    <label htmlFor="file" className='customUpload'>
                        <img src={imageSource} alt="icon" />
                        <span>Choose From Gallery</span>
                        <img src={chevronRightGrey} alt="icon" />
                        <input onChange={() => this.customUpload()} type="file" id="file" style={{display: "none"}} name="image" accept="image/gif,image/jpeg,image/jpg,image/png" ></input>
                    </label>
                    <div className='orChoose'>Or Choose an Avatar</div>
                    <Swiper  
                        spaceBetween={8}
                        direction={"horizontal"}
                        slidesPerView= "2"
                        className="avatarRow"
                        >
                        {this.props.userAvatars.length > 0 && this.props.userAvatars.map((avatar) => 
                            <SwiperSlide key={avatar.id} className="slide">
                                <img className='avatarContent' onClick={() => this.setState({avatarSelected: true, avatarSelectedId: avatar.id})} src={`${BASE_URL}${avatar.image}`}  alt="icon"></img>
                            </SwiperSlide>
                        )}
                    </Swiper>
                    <div className='buttonRow'>
                        <button onClick={() => this.updateAv()} className={!this.state.avatarSelected ? 'disabled' : ''} disabled={!this.state.avatarSelected}>Update It</button>
                    </div>
                </div>}
                {menuNumber !== '0' && <section ref={this.settingsSection} className="settingsSection">
                    {menuNumber === '1' && 
                        <div className='profile'>
                            <div className='imageChange'>
                                {userProfile && userProfile.avatar ? 
                                    <img className='dp' src={`${BASE_URL}${userProfile.avatar.image}`} alt="icon" /> 
                                    : (
                                        userProfile && userProfile.profile_image ? 
                                        <img className='dp' src={`${BASE_URL}${userProfile.profile_image}`} alt="icon" /> 
                                        :
                                        <img className='dp' src={DP} alt="icon"></img>  
                                    )
                                }
                                <img className='editIcon' src={editIcon} onClick={() => this.setState({ isAvatarSelectorOpened : true})} alt="icon" />
                            </div>
                            <form>
                                <div className='label'>First Name</div>
                                <div className='inputFlex'>
                                    <input name="firstNameValue" type="text" ref={this.firstName} disabled={!this.state.editFirstName} onChange={e => this.handleChange(e)} value={this.state.firstNameValue ? this.state.firstNameValue : ""} />
                                    {!this.state.editFirstName && <span onClick={() => this.setState({editFirstName: true})} className='edit'>Edit</span>}
                                    {this.state.editFirstName && <span onClick={() => this.saveData(1)} className='edit'>Save</span>}
                                </div>
                                <div className='label'>Last Name</div>
                                <div className='inputFlex'>
                                    <input name="lastNameValue" type="text" ref={this.lastName} disabled={!this.state.editLastName} onChange={e => this.handleChange(e)} value={this.state.lastNameValue ? this.state.lastNameValue : ""} />
                                    {!this.state.editLastName && <span onClick={() => this.setState({editLastName: true})} className='edit'>Edit</span>}
                                    {this.state.editLastName && <span onClick={() => this.saveData(2)} className='edit'>Save</span>}
                                </div>
                                <div className='label'>Display Name</div>
                                <div className='inputFlex'>
                                    <input name="displayNameValue" type="text" ref={this.displayName} disabled={!this.state.editDisplayName} onChange={e => this.handleChange(e)} value={this.state.displayNameValue ? this.state.displayNameValue : ""} />
                                    {!this.state.editDisplayName && <span onClick={() => this.setState({editDisplayName: true})} className='edit'>Edit</span>}
                                    {this.state.editDisplayName && <span onClick={() => this.saveData(3)} className='edit'>Save</span>}
                                </div>
                                {/* <div className='label'>Location</div>
                                <div className='inputFlex'>
                                    <input name="locationValue" ref={this.location} type="text" disabled={!this.state.editLocation} onChange={e => this.handleChange(e)} value={this.state.locationValue ? this.state.locationValue : ""} placeholder='Location' />
                                    {!this.state.editLocation && <span onClick={() => this.setState({editLocation: true})} className='edit'>Edit</span>}
                                    {this.state.editLocation && <span onClick={() => this.saveData(4)} className='edit'>Save</span>}
                                </div> */}
                                <div className='label'>Occupation</div>
                                <div className='inputFlex'>
                                    <input name="occupationValue" ref={this.occupation} type="text" disabled={!this.state.editOccupation} onChange={e => this.handleChange(e)} value={this.state.occupationValue ? this.state.occupationValue : ""} placeholder='Occupation' />
                                    {!this.state.editOccupation && <span onClick={() => this.setState({editOccupation: true})} className='edit'>Edit</span>}
                                    {this.state.editOccupation && <span onClick={() => this.saveData(5)} className='edit'>Save</span>}
                                </div>
                                <div className='label'>Job Title</div>
                                <div className='inputFlex'>
                                    <input name="jobTitleValue" ref={this.jobTitle} type="text" disabled={!this.state.editJobTitle} onChange={e => this.handleChange(e)} value={this.state.jobTitleValue ? this.state.jobTitleValue : ""} placeholder='Job Title' />
                                    {!this.state.editJobTitle && <span onClick={() => this.setState({editJobTitle: true})} className='edit'>Edit</span>}
                                    {this.state.editJobTitle && <span onClick={() => this.saveData(6)} className='edit'>Save</span>}
                                </div>
                                <div className='label'>Company</div>
                                <div className='inputFlex'>
                                    <input name="companyValue" ref={this.company} type="text" disabled={!this.state.editCompany} onChange={e => this.handleChange(e)} value={this.state.companyValue ? this.state.companyValue : ""} placeholder='Company' />
                                    {!this.state.editCompany && <span onClick={() => this.setState({editCompany: true})} className='edit'>Edit</span>}
                                    {this.state.editCompany && <span onClick={() => this.saveData(7)} className='edit'>Save</span>}
                                </div>
                            </form>
                        </div>}
                    {menuNumber === '2' &&
                        <div className='account'>
                             <form>
                                <div className='label'>Username</div>
                                <div className='inputFlex userId'>
                                    <input name="userNameValue" ref={this.username} type="text" disabled={!this.state.editUsername} onChange={e => this.handleChange(e)} value={this.state.userNameValue ? this.state.userNameValue : ""} placeholder='username'/>
                                    {!this.state.editUsername && <span onClick={() => this.setState({editUsername: true})} className='edit'>Edit</span>}
                                    {this.state.editUsername && <span onClick={() => this.saveData(8)} className='edit'>Save</span>}
                                </div>
                                <div className='description'>
                                    <span>Note:</span>&nbsp;
                                    <span>After changing your username, your old username becomes available for anyone else to claim.</span>
                                </div>
                                <div className='label pNumber'>Phone Number</div>
                                <div className='inputFlex'>
                                    <input name="phoneNumberValue" ref={this.phoneNumber} type="text" disabled={!this.state.editPhoneNumber} onChange={e => this.handleChange(e)} value={this.state.phoneNumberValue ? this.state.phoneNumberValue : ""} placeholder='+919123456789'/>
                                    {!this.state.editPhoneNumber && <span onClick={() => this.setState({editPhoneNumber: true})} className='edit'>Edit</span>}
                                    {this.state.editPhoneNumber && <span onClick={() => this.saveData(9)} className='edit'>Save</span>}
                                </div>

                                <div className='description'>
                                    <span>Keep your primary phone number up-to-date</span>
                                </div>
                                <div className='label email'>Email Address</div>
                                <div className='inputFlex emailInput'>
                                    <input type="text" disabled defaultValue={userProfile.email} placeholder='Email' />
                                </div>
                                <div className='description'>
                                    <span>Keep your primary email address up-to-date</span>
                                </div>
                            </form>
                            <div className='linkGoogle'>
                                <div className='linkHeader'>
                                    <span className='text'>Link google contacts</span>
                                    {this.props.googleContacts.total_linked_account > 0 && <span className='addMore' onClick={() => this.handleGoogleLink()}>+ Add More</span>}
                                </div>
                                {(this.props.googleContacts.total_linked_account === 0 || this.props.googleContacts.length === 0) && <div className='googleFlex'>
                                    <img src={gIcon} alt="icon" />
                                    <span className='title'>Google</span>
                                    <span className='button' onClick={() => this.handleGoogleLink()}>Connect</span>
                                </div>}
                                {
                                    this.props.googleContacts.total_linked_account > 0 && 
                                    this.props.googleContacts.results.map((googleContact) => 
                                        <div key={googleContact.linked_account_id} className='accountCard'>
                                            <img src={DP} alt="dp" className='dp' />
                                            <span className='email'>{googleContact.account_identifier}</span>
                                        </div>
                                    )
                                }
                            </div>
                            <div className='headerSettings'>Linked Bank Account</div>
                            <div className='bankCard'>
                                <img src={boaIcon} className='bankIcon' alt="icon" />
                                <div className='content'>
                                    <div className='name'>Bank of America</div>
                                    <div className='number'>123 456 678 999</div>
                                </div>
                            </div>
                            <div className='bankCard'>
                                <img src={citiIcon} className='bankIcon' alt="icon" />
                                <div className='content'>
                                    <div className='name'>City Bank</div>
                                    <div className='number'>123 456 678 999</div>
                                </div>
                            </div>
                            <div className='addAccount'>
                                <img src={plusIcon} alt="icon" />
                                <span className='addText'>Add More Account</span>
                            </div>
                            <div className='info'>Note: we use plaid to connect your bank account</div>
                        </div>}
                    {menuNumber === '3' && 
                        <div className='privacy'>
                            <section className='switchSection'>
                                <div className='switchHeader'>Plans Privacy</div>
                                <div className='switchListItem'>
                                    <div className='switchTitle'>Public Profile</div>
                                    <SwitchRadio 
                                        onChange={(e) => this.updatePrivacy(e)} 
                                        checked={this.state.privacyEnabled} 
                                    />
                                </div>
                            </section>
                            {!this.state.privacyEnabled && <div className='info'>Now no one can see your plan details.</div>}
                            {this.state.privacyEnabled && <div className='info'>Others can now see your plans in your profile.</div>}
                            {/* {!this.state.privacyEnabled && <div className='planCard blur'>
                                <span className='memberCount'>10</span>
                                <div className='planDetails'>
                                    <div className='planName'>
                                        $5000 Monthly plan
                                    </div>
                                </div>
                            </div>} */}
                        </div>}
                    {menuNumber === '4' && 
                        <div className='notification'>
                            <div className='switchHeader'>Security Alert</div>
                            <section className='switchMain'>
                                <div className='title'>Security Alerts (Preview)</div>
                                <div className='rowFlex rowFlexCenter'>
                                    <img src={lockWithBlue} alt="icon" />
                                    <span className='message'>Your password has been reset</span>
                                </div>
                                <div className='bottomFlex'>
                                    <span className='time'>17 May 21 / 7:65 AM</span>
                                    <span className='view'>View More</span>
                                </div>
                            </section>
                            <section className='switchSection'>
                                <div className='switchListItem'>
                                    <div className='switchTitle'>Email</div>
                                    <SwitchRadio onChange={(e) => this.updateNotificationSetting(e, "security_alerts_email")} checked={notifSettings.security_alerts_email ? notifSettings.security_alerts_email : false}/>
                                </div>
                                <div className='switchListItem'>
                                    <div className='switchTitle'>SMS</div>
                                    <SwitchRadio onChange={(e) => this.updateNotificationSetting(e, "security_alerts_sms")} checked={notifSettings.security_alerts_sms ? notifSettings.security_alerts_sms : false}/>
                                </div>
                                <div className='switchListItem'>
                                    <div className='switchTitle'>In-App</div>
                                    <SwitchRadio onChange={(e) => this.updateNotificationSetting(e, "security_alerts_wn")} checked={notifSettings.security_alerts_wn ? notifSettings.security_alerts_wn : false}/>
                                </div>
                            </section>
                            <div className='switchHeader'>Account Activity</div>
                            <section className='switchMain'>
                                <div className='title'>Account Activity (Preview)</div>
                                <div className='rowFlex'>
                                    <img src={lockWithBlue} alt="icon" />
                                    <span className='message'>Welcome hruthik ! We are happy to have you in our community, looking for a wonderful journey ahead.</span>
                                </div>
                                <div className='bottomFlex'>
                                    <span className='time'>17 May 21 / 7:65 AM</span>
                                    <span className='view'>View More</span>
                                </div>
                            </section>
                            <section className='switchSection'>
                                <div className='switchListItem'>
                                    <div className='switchTitle'>Email</div>
                                    <SwitchRadio onChange={(e) => this.updateNotificationSetting(e, "account_activity_email")} checked={notifSettings.account_activity_email ? notifSettings.account_activity_email : false}/>
                                </div>
                                <div className='switchListItem'>
                                    <div className='switchTitle'>SMS</div>
                                    <SwitchRadio onChange={(e) => this.updateNotificationSetting(e, "account_activity_sms")} checked={notifSettings.account_activity_sms ? notifSettings.account_activity_sms : false}/>
                                </div>
                                <div className='switchListItem'>
                                    <div className='switchTitle'>In-App</div>
                                    <SwitchRadio onChange={(e) => this.updateNotificationSetting(e, "account_activity_wn")} checked={notifSettings.account_activity_wn ? notifSettings.account_activity_wn : false}/>
                                </div>
                            </section>
                            <div className='switchHeader'>Plan Alerts & Announcements</div>
                            <section className='switchSection'>
                                
                                <div className='switchListItem'>
                                    <div className='switchTitle'>Email</div>
                                    <SwitchRadio onChange={(e) => this.updateNotificationSetting(e, "plan_alerts_and_announcements_email")} checked={notifSettings.plan_alerts_and_announcements_email ? notifSettings.plan_alerts_and_announcements_email : false}/>
                                </div>
                                <div className='switchListItem'>
                                    <div className='switchTitle'>SMS</div>
                                    <SwitchRadio onChange={(e) => this.updateNotificationSetting(e, "plan_alerts_and_announcements_sms")} checked={notifSettings.plan_alerts_and_announcements_sms ? notifSettings.plan_alerts_and_announcements_sms : false}/>
                                </div>
                                <div className='switchListItem'>
                                    <div className='switchTitle'>In-App</div>
                                    <SwitchRadio onChange={(e) => this.updateNotificationSetting(e, "plan_alerts_and_announcements_wn")} checked={notifSettings.plan_alerts_and_announcements_wn ? notifSettings.plan_alerts_and_announcements_wn : false}/>
                                </div>
                            </section>
                            <div className='switchHeader'>Friends</div>
                            <section className='switchSection'>
                                <div className='switchListItem'>
                                    <div className='switchTitle'>Email</div>
                                    <SwitchRadio onChange={(e) => this.updateNotificationSetting(e, "contacts_alert_email")} checked={notifSettings.contacts_alert_email ? notifSettings.contacts_alert_email : false}/>
                                </div>
                                <div className='switchListItem'>
                                    <div className='switchTitle'>SMS</div>
                                    <SwitchRadio onChange={(e) => this.updateNotificationSetting(e, "contacts_alert_sms")} checked={notifSettings.contacts_alert_sms ? notifSettings.contacts_alert_sms : false}/>
                                </div>
                                <div className='switchListItem'>
                                    <div className='switchTitle'>In-App</div>
                                    <SwitchRadio onChange={(e) => this.updateNotificationSetting(e, "contacts_alert_wn")} checked={notifSettings.contacts_alert_wn ? notifSettings.contacts_alert_wn : false}/>
                                </div>
                            </section>
                        </div>}
                    {menuNumber === '5' && 
                        <div className='passwordAndSecurity'>
                            <div className='basic'>
                                <div className='headerSettings'>Change Password</div>
                                <div className='description'>Enable 2-factor authentication in security settings</div>
                                <button>Change Password</button>
                            </div>
                            <div className='basic'>
                                <div className='headerSettings'>Two Factor Authentication</div>
                                <div className='description'>Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log in.</div>
                                <button>Enable Now!</button>
                            </div>
                            <div className='basic'>
                                <div className='headerSettings'>Section</div>
                                <div className='description'>This is a list of devices that have logged into your account. Revoke any sessions that you do not recognize.</div>
                                <div className='textArea'>
                                    <div className='title'>San Francisco City 190.24.335.55</div>
                                    <div className='info'>
                                        <span>Your current session seen in</span>
                                        <span className='value'>United States</span>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    {menuNumber === '6' &&
                        <div className='personalInfo'>
                            <div className='label'>Legal First Name</div>
                            <div className='inputFlex'>
                                <input name="legalFirstNameValue" placeholder='Legal First Name' type="text" ref={this.legalFirstName} disabled={!this.state.editLegalFirstName} onChange={e => this.handleChange(e)} value={this.state.legalFirstNameValue ? this.state.legalFirstNameValue : ""} />
                                {!this.state.editLegalFirstName && <span onClick={() => this.setState({editLegalFirstName: true})} className='edit'>Edit</span>}
                                {this.state.editLegalFirstName && <span onClick={() => this.saveData(10)} className='edit'>Save</span>}
                            </div>
                            <div className='label'>Legal Last Name</div>
                            <div className='inputFlex'>
                                <input name="legalLastNameValue" placeholder='Legal Last Name' type="text" ref={this.legalLastName} disabled={!this.state.editLegalLastName} onChange={e => this.handleChange(e)} value={this.state.legalLastNameValue ? this.state.legalLastNameValue : ""} />
                                {!this.state.editLegalLastName && <span onClick={() => this.setState({editLegalLastName: true})} className='edit'>Edit</span>}
                                {this.state.editLegalLastName && <span onClick={() => this.saveData(11)} className='edit'>Save</span>}
                            </div>
                            <div className='label'>Residencial Address</div>
                            <div className='inputFlexArea'>
                                <div className='resAddress' dangerouslySetInnerHTML={{ __html: resAddress }} />
                                <span className='editResidence' onClick={() => this.openSection(7)}>Edit</span>
                            </div>
                            <div className='label'>Phone Number</div>
                            <div className='inputFlex'>
                                <input name="lastNameValue" type="text" value={"+91 234 5678"} onChange={() => console.log("")}/>
                            </div>
                            <div className='addAccount'>
                                <img src={plusIcon} alt="icon" />
                                <span className='addText'>Add More Phone Number</span>
                            </div>
                            <div className='buttonRow'>
                                <button>Update</button>
                            </div>
                        </div>
                    }
                    {menuNumber === '7' &&
                        <div className='residencialEdit'>
                             <form className="form">
                                <div>
                                    <label className="input">
                                        <CountrySelector value={this.state.country} countrySelect={this.countrySelect}/>
                                        {this.state.invalidCountry && <div className='error'>Invalid country</div>}
                                    </label>
                                    <label className="input">
                                        <StateSelector value={this.state.state} stateSelect={this.stateSelect}/>
                                    </label>
                                    <label className="input">
                                        <CitySelector value={this.state.city} citySelect={this.citySelect}/>
                                    </label>
                                    <label className="input">
                                        <input className='input__field' onChange={(e) => this.setState({zipCode: e.target.value})} value={this.state.zipCode} type="text" placeholder="Zip Code" />
                                        <span className="input__label">Zip Code</span>
                                    </label>
                                    <label className="input">
                                        <input className='input__field' value={this.state.resAddress ? this.state.resAddress : ''} onChange={e => this.setState({resAddress: e.target.value})}  type="text" placeholder="Apartment #, Suite #, etc" />
                                        <span className="input__label">Residencial Address</span>
                                    </label>
                                    <div className="stepButton">
                                        <button type="submit" onClick={(e) => this.updateData(e)}>Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                </section>}
            </div>)
        )
    }
}

settings.propTypes = {
    addIdProof: PropTypes.func.isRequired,
    linkGoogleAccount: PropTypes.func.isRequired,
    getGoogleContactsAll: PropTypes.func.isRequired,
    getAllAvatars: PropTypes.func.isRequired,
    getProfileInfo: PropTypes.func.isRequired,
    saveProfileInfo: PropTypes.func.isRequired,
    updateAvatar: PropTypes.func.isRequired,
    getStates: PropTypes.func.isRequired,
    getCities: PropTypes.func.isRequired,
    getNotificationSettings: PropTypes.func.isRequired,
    uploadPicture: PropTypes.func.isRequired,
    updateNotificationSetting: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    userAvatars: PropTypes.array.isRequired,
    UI: PropTypes.object.isRequired
}
const mapStateToProps = (state) => ({
   user: state.user,
   UI: state.UI,
   googleContacts: state.contacts.googleContacts,
   userAvatars: state.contacts.userAvatars,
});
const mapActionsToProps = {
    addIdProof,
    uploadPicture,
    linkGoogleAccount,
    updateAvatar,
    getProfileInfo,
    getGoogleContactsAll,
    getAllAvatars,
    saveProfileInfo,
    getStates,
    getCities,
    getNotificationSettings,
    updateNotificationSetting
}
export default connect(mapStateToProps, mapActionsToProps)(settings);