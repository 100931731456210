import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import ChatMessage from './ChatMessage';
import AdminChatMessage from './AdminChatMessage';
import { 
    sendMessageSocket,
    replyTo,
    deleteMessage
} 
from '../redux/actions/chatActions';
import sendIcon from './../assets/images/icons/sendIcon.svg';
import replyIconWhite from './../assets/images/icons/replyIconWhite.svg';
import deleteIconWhite from './../assets/images/icons/deleteIconWhite.svg';
import divider from './../assets/images/icons/divider.svg';
import attachIcon from './../assets/images/icons/attachIcon.svg';
import closeIcon from './../assets/images/icons/closeIcon.svg';
import './../styles/chatRoomComponent.scss';

const ChatRoomComponent = (props) => {
    const dispatch = useDispatch();
    const chatState = useSelector((state) => state.chat);
    const planState = useSelector((state) => state.plans);
    const [replyData, setReplyData] = useState({});
    const [lastAuctionDate, setLastAuctionDate] = useState(null)
    const replyingTo = useRef();
    const chatOptions = useRef();
    const chatMessage = useRef();
    const messageRef = useRef();

    useEffect(() => {
        if(planState.planDetails && planState.planDetails.auctions && planState.planDetails.auctions.length > 0 ){
            setLastAuctionDate(planState.planDetails.auctions[planState.planDetails.auctions.length - 1].ends_at);
        }
    }, [planState.planDetails])
    useLayoutEffect(() => {
        chatMessage.current.scrollIntoView({block: "end", inline: "nearest", behavior : "smooth"});
    });

    const closeReplyTo = () => {
        replyingTo.current.style.transform = "translateY(75px)";
        replyingTo.current.style.opacity = "0";
        chatMessage.current.style.paddingBottom = "80px";
    }
    const openReplyTo = () => {
        chatOptions.current.style.display = "none";
        replyingTo.current.style.transform = "translateY(0px)";
        replyingTo.current.style.opacity = "1";
        chatMessage.current.style.paddingBottom = "160px";
    }
    const openOptions = (data) => {
        if(planState.planDetails.available_to > 0){
            setReplyData(data);
            chatOptions.current.style.display = "block";
        }
    }
    const sendMessage = () => {
        if(replyData && replyData.user) {
            dispatch(replyTo(messageRef.current.value, replyData.id))
            closeReplyTo();
        } else {
            dispatch(sendMessageSocket(messageRef.current.value))
        }
        messageRef.current.value = "";
    }
    const deleteChat = () => {
        if(replyData.id){
            dispatch(deleteMessage(replyData))
            chatOptions.current.style.display = "none";
        }
    }
    return (
        <div className={props.fromBidding ? 'chatMessageContainer fromBidding' : 'chatMessageContainer'} >
            <div ref={chatMessage} className="chatMessages">
                {chatState.chatMessages.length > 0 && chatState.chatMessages.map((message) => {
                    if(message && message.type === "Time"){
                        return <div key={message.key} className="timeThread">{message.value}</div>
                    }
                    if(message && message.message && message.message_type === "MEMBER_MESSAGE" && message.message_replied_to === null){
                        return <ChatMessage openOptions={openOptions} key={message.id} data={message}/>
                    }
                    if(message && message.message_type === "MEMBER_MESSAGE" && message.message_replied_to === null){
                        return <ChatMessage openOptions={openOptions} key={message.id} data={message.message}/>
                    }
                    if(message && message.message_type === "MEMBER_MESSAGE" && message.message_replied_to !== null){
                        return <ChatMessage openOptions={openOptions} key={message.id} data={message}/>
                    }
                    if(message && message.message && message.message_type === "MEMBER_MESSAGE" && message.message_replied_to !== null){
                        return <ChatMessage openOptions={openOptions} key={message.id} data={message.message}/>
                    }
                    if(message && message.message && message.message_type === "ADMIN_MESSAGE" && message.message_replied_to === null){
                        return <AdminChatMessage openOptions={openOptions} key={message.id} data={message} />
                    }
                    if(message && message.message && message.message_type === "ADMIN_MESSAGE" && message.message_replied_to !== null){
                        return <ChatMessage openOptions={openOptions} key={message.id} data={message}/>
                    }
                    return null
                })}
            </div>
            <div className="bottomSolid">
                {planState.planDetails.available_to <= 0 && 
                    <div className="planEndInfo">
                        Plan has ended on <span className='dated'>{moment(lastAuctionDate).format('DD.MM.YYYY')}</span>
                    </div>
                }
            </div>

            <div className="replyingTo" ref={replyingTo}>
                {replyData && replyData.user && <div className='box'>
                    <img onClick={() => closeReplyTo()} className='close' src={closeIcon} alt="icon" />
                    <div className='rectBlue'></div>
                    <div className='column'>
                        <span className='to'>Replying To<span>{replyData.user.first_name}</span></span>
                        <span className='message'>{replyData.message}</span>
                    </div>
                </div>}
            </div>
            <div className={replyData && replyData.user && replyData.user.id === localStorage.userId ? "chatOptions" : "chatOptions noDelete"} ref={chatOptions}>
                <div className='rowFlex'>
                    <img onClick={() => chatOptions.current.style.display = "none"} src={closeIcon} alt="icon" />
                </div>
                <div onClick={() => openReplyTo()} className='optionFlex'>
                    <img src={replyIconWhite} alt="icon" />
                    <span>Reply</span>
                </div>
                {replyData && replyData.user && replyData.user.id === localStorage.userId && <>
                    <img src={divider} alt="icon" />
                    <div className='optionFlex' onClick={() => deleteChat()}>
                        <img src={deleteIconWhite} alt="icon" />
                        <span>Delete</span>
                    </div>
                </>}
            </div>
            {planState.planDetails.available_to > 0 && <div className="messageSender">
                <div className='rowFlex'>
                    <div className="inputFlex">
                        <img src={attachIcon} alt="icon" />
                        <input ref={messageRef} type="text" placeholder="Message..."></input>
                    </div>
                    <span className='sendIcon' onClick={() => sendMessage()}>
                        <img src={sendIcon} alt="icon" />
                    </span>
                </div>
            </div> }
        </div>
    )
}

export default ChatRoomComponent