import React, {useState, useEffect} from 'react';
import { useHistory } from "react-router";
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SlidingPanel from 'react-sliding-side-panel';
import { 
    getAllNotifications,
    notificationSocket
} 
from '../redux/actions/notificationAction';
import { getProfileInfo } from '../redux/actions/userActions';
import menuIcon from './../assets/images/icons/hamburger.svg';
import notificationIcon from './../assets/images/icons/notificationIcon.svg';
import closeIconWhite from './../assets/images/icons/closeCircle.svg';
import divider from './../assets/images/icons/divider.svg';
import planWhite from './../assets/images/icons/planWhite.svg';
import logoutWhite from './../assets/images/icons/logoutWhite.svg';
import settingsWhite from './../assets/images/icons/settingsWhite.svg';
import feedbackIcon from './../assets/images/icons/feedbackIcon.svg';
import helpWhite from './../assets/images/icons/helpWhite.svg';
import learnWhite from './../assets/images/icons/learnWhite.svg';
import notificationWhite from './../assets/images/icons/notificationWhite.svg';
import walletWhite from './../assets/images/icons/walletWhite.svg';
import friendsWhite from './../assets/images/icons/friendsWhite.svg';
import dashboardWhite from './../assets/images/icons/dashboardWhite.svg';
import wheezLogo from './../assets/images/wheez-logo-white.png';
import DP from './../assets/images/DP.png';
import { logoutUser } from '../redux/actions/userActions';
import 'react-sliding-side-panel/lib/index.css';
import './../styles/navbar.scss';

const Navbar = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const notificationsState = useSelector((state) => state.notifications);
    const [openPanelMainMenu, setOpenPanelMainMenu] = useState(false);
    // const [openPanelMenu, setOpenPanelMenu] = useState(false);
    const { 
        unreadNotifications
    } = notificationsState;

    const userState = useSelector((state) => state.user);

    const {
        userProfile
    } = userState;

    const BASE_URL = `http://${process.env.REACT_APP_WHEEZ_SERVER_URL}`;

    useEffect(() => {
        if(props.authenticated){
            dispatch(getAllNotifications());
            dispatch(notificationSocket());
            let userId = localStorage.getItem('userId');
            dispatch(getProfileInfo(userId));
        }
    }, [dispatch, props.authenticated]);

    const slideMenu = () => {
        if(authenticated){
            setOpenPanelMainMenu(true)
            props.removeContent();
        } else {
            // setOpenPanelMenu(true)
        }
    }
    
    const closeSlideMenu = () => {
        if(authenticated){
            setOpenPanelMainMenu(false)
        } else {
            // setOpenPanelMenu(false)
        }
        setTimeout(() => {
            props.addContent();
        }, 200);
    }
    
    const handleLogout = () => {
        if(authenticated){
            setOpenPanelMainMenu(false)
        } else {
            // setOpenPanelMenu(false)
        }
        let clientKey = process.env.REACT_APP_SERVER_CLIENT_KEY;
        const token = localStorage.refreshToken;
        let logoutParam = {
            "client_id": clientKey,
            "token": token,
            "token_type_hint": "refresh_token"
        }
        props.logoutUser(logoutParam, history);
    }
    const openMenuLink = (link) => {
        history.push(link)
        setOpenPanelMainMenu(false)
    }
    const { authenticated } = props;
    let userId = localStorage.getItem('userId');
    let profileLink = `/profile/${userId}`
    return (
        <>
            <nav className={authenticated ? 'headerNav normal': 'headerNav'} >
                {authenticated && <button className="menuBtn" onClick={slideMenu} >
                    <img src={menuIcon}  alt="icon"/>
                </button>}
                <img onClick={() => history.push("/")} className="logo" src={wheezLogo} alt="icon"/>
                <div className="flexRight">
                    {authenticated && <Link to="/notifications">
                        <button className="whiteBtn">
                            <img src={notificationIcon} alt="icon"/>
                            {unreadNotifications > 0 && <span className="info">{unreadNotifications}</span>}
                        </button>
                    </Link>}
                </div>
            </nav>
            <SlidingPanel
                type={'top'}
                isOpen={openPanelMainMenu}
                size={100}
                onClosed={()=>{
                    setTimeout(() => {
                        props.addContent();
                    }, 200);
                }}
            >
                <div className="mainMenu" id="main-menu">
                    <nav>
                        <div  className="col1">
                            {userProfile && userProfile.avatar ? 
                                <img src={`${BASE_URL}${userProfile.avatar.image}`} alt="icon" /> 
                                : (
                                    userProfile && userProfile.profile_image ? 
                                    <img src={`${BASE_URL}${userProfile.profile_image}`} alt="icon" /> 
                                    :
                                    <img src={DP} alt="icon"></img>  
                                )
                            }
                            <div className="name">
                                {userProfile && (userProfile.display_name || userProfile.first_name) && 
                                    <span>{(userProfile.display_name !== "" && userProfile.display_name) ? userProfile.display_name : (
                                        userProfile.first_name ? userProfile.first_name : ""
                                    )}</span>}
                            </div>
                        </div>
                        <div className="col2">
                            {userProfile && userProfile.email && <div className="email">{userProfile.email}</div>}
                            <div className='planFlex'>
                                <div onClick={() => openMenuLink(profileLink)} className="planTag">View Profile</div>
                            </div>
                        </div>
                        <button className="closeIcon" onClick={closeSlideMenu}>
                            <img src={closeIconWhite} alt="icon"/>
                        </button>
                        <img className="divider" src={divider} alt="icon" />
                    </nav>
                    <ul>
                        <li onClick={() => openMenuLink("/dashboard")}>
                            <span className="iconBackground">
                                <img src={dashboardWhite} alt="icon"/>  
                            </span>
                            <span className="text">Dashboard</span>
                        </li>
                        <li onClick={() => openMenuLink("/friends/1")}>
                            <span className="iconBackground">
                                <img src={friendsWhite} alt="icon"/>
                            </span>
                            <span className="text">Friends</span>
                        </li>
                        <li onClick={() => openMenuLink("/plans/1")}>
                            <span className="iconBackground">
                                <img src={planWhite} alt="icon"/>
                            </span>
                            <span className="text">Plans</span>
                            <span className="new"></span>
                        </li>
                        <li onClick={() => openMenuLink("/wallet/1")}>
                            <span className="iconBackground">
                                <img src={walletWhite} alt="icon"/>
                            </span>
                            <span className="text">Wallet</span>
                        </li>
                        <li onClick={() => openMenuLink("/notifications")}>
                            <span className="iconBackground">
                                <img src={notificationWhite} alt="icon"/>
                            </span>
                            <span className="text">Notifications</span>
                        </li>
                        <li onClick={() => openMenuLink("/learn")}>
                            <span className="iconBackground">
                                <img src={learnWhite} alt="icon"/>
                            </span>
                            <span className="text">Learn</span>
                        </li>
                        <li onClick={() => openMenuLink("/feedback")}>
                            <span className="iconBackground">
                                <img src={feedbackIcon} alt="icon"/>
                            </span>
                            <span className="text">Feedback</span>
                        </li>
                        <li>
                            <span className="iconBackground">
                                <img src={helpWhite} alt="icon"/>
                            </span>
                            <span className="text">Help</span>
                        </li>
                        <li onClick={() => openMenuLink("/settings/0")}>
                            <span className="iconBackground">
                                <img src={settingsWhite} alt="icon"/>
                            </span>
                            <span className="text">Settings</span>
                        </li>
                        <li onClick={handleLogout}>
                            <span className="iconBackground">
                                <img src={logoutWhite} alt="icon"/>
                            </span>
                            <span className="text">Log Out</span>
                        </li>
                    </ul>
                </div>
            </SlidingPanel>            
        </>
    )
    
}

Navbar.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    logoutUser: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    authenticated: state.user.authenticated
})
const mapActionsToProps = {
    logoutUser
}
export default connect(mapStateToProps, mapActionsToProps)(Navbar);