import React, { useRef, useState, useEffect }  from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useTour } from '@reactour/tour'
import Tour from 'reactour'
import BiddingView from '../components/PracticeBidding/BiddingView';
import BiddingFooter from '../components/PracticeBidding/BiddingFooter';
import TaskModal from '../components/PracticeBidding/TaskModal';
import IntroModal from '../components/PracticeBidding/IntroModal';
import { placeBidReset, placeABid, triggerScroll, placeBidStart, forfeitSelf } from '../redux/actions/biddingActions';
import smallCloseicon from './../assets/images/icons/smallCloseicon.svg';
import checkIcon from './../assets/images/icons/checkIcon.svg';
import userIconWhite from './../assets/images/icons/userIconWhite.svg';
import successfulIllustration from './../assets/images/successfulIllustration.png';
import tryAgain from './../assets/images/icons/tryAgain.svg';
import planEndClose from './../assets/images/icons/closeCircle.svg';

const PracticeBidding = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const biddingData = useSelector((state) => state.practiceBidding);
    const { isOpen, setIsOpen, setCurrentStep } = useTour();
    const [isIntro, setIsIntro] = useState(true);
    const [bidFormula, setBidFormula] = useState('');
    const [initialBidValue, setInitialBidValue] = useState(0);
    const [ bidValue, setBidValue] = useState(0);
    const [forfeitText, setForfeitText] = useState('');
    const [forfeitModal, setForfeitModal ] = useState(false);
    const [showUserInfo, setShowUserInfo ] = useState(false);
    const [userInfoModalName, setUserInfoModalName ] = useState('');
    const [placeBid, setPlaceBid] = useState(false);
    const [startBidModal, setStartBidModal] = useState(false);
    const [showFlagModal, setShowFlagModal] = useState(false);
    const [didUserBidModal, setDidUserBidModal] = useState(false);
    const [lockStatus, setLockStatus] = useState(false);
    const [shouldTourBeOpen, setShouldTourBeOpen] = useState(true);
    const [flagPromptModal, setFlagPromptModal] = useState(false);
    const [currentTaskId, setCurrentTaskId] = useState(0);
    const [isTourOpenTask, setIsTourOpenTask] = useState(false)
    const [currentTaskTourId, setCurrentTaskTourId] = useState(0)
    const planEndModal = useRef();
    const userInfoModal = useRef();
    const taskSuccesRef = useRef();
    const notBidYetAlert = useRef();
    const forfeitRef = useRef();
    const forgoEnabledAlert = useRef();
    const confirmFinishTask = useRef();
    const forfeitTextRef = useRef();
    const bidSelectModal = useRef();
    const gridValuesTask = useRef();
    const confirmExitTask = useRef();
    
    useEffect(() => {
        setBidFormula(`$${initialBidValue} - $${bidValue}`)
    }, [initialBidValue, bidValue]);
    useEffect(() => {
        if(biddingData.currentBidUser && biddingData.currentBidUser.bidAmount) {
            setInitialBidValue(biddingData.currentBidUser.bidAmount);
        }
    }, [biddingData.currentBidUser]);
    
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    });
    useEffect(() => {
        document.body.style.overflowY = "hidden";
        return () => {
            document.body.style.overflowY = "auto";
            dispatch(placeBidReset());
        }
    }, [dispatch]);

    useEffect(() => {
        if(showUserInfo) {
            document.addEventListener('mousedown', handleUserInfoModal);
        }
        return () =>  document.removeEventListener('mousedown', handleUserInfoModal);
    }, [showUserInfo]);

    useEffect(() => {
        if(didUserBidModal && !lockStatus && shouldTourBeOpen && !isOpen) {
            setIsTourOpenTask(true)
        }
        if(flagPromptModal && !isOpen && shouldTourBeOpen){
            // setIsTourOpenTaskForfeit(true)
        }
    }, [didUserBidModal,lockStatus, flagPromptModal, setIsOpen, setCurrentStep, shouldTourBeOpen, isOpen]);


    const handleUserInfoModal = (event) => {
        if(userInfoModal.current.isSameNode(event.target)){
            userInfoModal.current.style.display = "none";
            setShowUserInfo(false);
            setUserInfoModalName('');
            setShouldTourBeOpen(true)
        }
    }
    const handleClickOutside = (event) => {
        if(forfeitModal){
            if(forfeitRef.current.isSameNode(event.target)){
                forfeitRef.current.style.display = "none";
                setForfeitText('');
                forfeitTextRef.current.value = "";
                setForfeitModal(false);
            }
        }
    }
    const openPlanEndModal = () => {
        if(biddingData.biddingStarted) {
            planEndModal.current.style.display = "block";
        }
    }
    const openBidSelectModal = () => {
        setShouldTourBeOpen(false)
        bidSelectModal.current.style.display = "block";
    }
    const openUserInfoModal = (userInfo) => {
        userInfoModal.current.style.display = "block";
        setUserInfoModalName(userInfo.name)
        setShowUserInfo(true);
        setShouldTourBeOpen(false)
    }
    const handleForfeit = () => {
        forfeitRef.current.style.display = "block";
        setForfeitModal(true);
    }

    const forfeitBid = () => {
        if(forfeitTextRef.current.value.toLowerCase() === "forgo") {
            forfeitRef.current.style.display = "none";
            forfeitTextRef.current.value = "";
            setForfeitText('');
            setForfeitModal(false);
            setLockStatus(true);
            dispatch(forfeitSelf(1));
        }
        setForfeitModal(false);
    }
    const closeBidModal = () => {
        bidSelectModal.current.style.display = "none";
        setBidValue(0);
        setShouldTourBeOpen(true)
        setBidFormula('');
    }
    const removeBidFormula = () => {
        setBidFormula('');
        setBidValue(0);
    }
    const resetTask = () => {
        setPlaceBid(false);
        dispatch(placeBidReset());
        disableLockStatus();
    }
    const startTask = (id) => {
        resetTask();
        setShouldTourBeOpen(true);
        if(id === 1){
            setStartBidModal(false);
            dispatch(placeBidStart());
        }
        if(id === 2){
            setShowFlagModal(false);
            dispatch(placeBidStart());
        }
    }
    const closeTaskModal = (id) => {
        if(!biddingData.biddingStarted){
            setPlaceBid(false);
            setCurrentTaskId(0);
        }
        if(id === 1){
            setStartBidModal(false);
        }
        if(id === 2){
            setShowFlagModal(false);
        }
    }
    const closeIntro = () => {
        setIsIntro(false);
        setIsOpen(true)
    }
    const promptUserToBid = () => {
        setDidUserBidModal(true);
    }
    const promptUserToForfeit = () => {
        setFlagPromptModal(true);
    }
    const openStartBidModal = (id) => {
        setCurrentTaskId(id);
        setShouldTourBeOpen(false)
        if(id === 1){
            setStartBidModal(true);
        }
        if(id === 2){
            setShowFlagModal(true);
        }
    }
    const disableLockStatus = () => {
        setLockStatus(false);
        setDidUserBidModal(false);
    }
    const finishTour = () => {
        setIsOpen(false)
    }
    const handlePlaceBid = () => {
        if(bidValue > 0){
            dispatch(placeABid(1, initialBidValue, bidValue));
            dispatch(triggerScroll());
            bidSelectModal.current.style.display = "none";
            setBidValue(0);
            setBidFormula('');
        }
        if(isTourOpenTask){
            setCurrentTaskTourId(0);
            closeTourTask();
        }
     
    }
    const setPlaceBidValue = (value) => {
        setPlaceBid(value)
    }
    const openConfirmFinish = (id) => {
        confirmFinishTask.current.style.display = "block";
        setShouldTourBeOpen(false)
        setCurrentTaskId(id)
    }
    const openConfirmExit = () => {
        if(biddingData.biddingStarted){
            confirmExitTask.current.style.display = "block";
        } else {
            history.goBack()
        }
    }
    const startTour = () => {
        setIsOpen(true)
    }
    const openNextGuide = () => {
        setCurrentTaskTourId(3)
    }
    const closeTourTask = () => {
        setIsTourOpenTask(false)
    }
    const confirmFinish = () => {
        confirmFinishTask.current.style.display = "none"; 
        openStartBidModal(currentTaskId);
        setPlaceBid(true);
    }
    const confirmExit = () => {
        confirmExitTask.current.style.display = "none"; 
        history.goBack()
    }
    const openLockedAlert = () => {
        forgoEnabledAlert.current.style.display = "block"
        setShouldTourBeOpen(false)
    }
    const openAlertNotBid = () => {
        notBidYetAlert.current.style.display = "block"
        setShouldTourBeOpen(false)
    }
    const stepsTask = [
        {
            selector: '[data-tut="ptap"]',
            content: () => <div className='ptapTourTask'>
                <div className='heading'>NOTE</div>
                <div className='description'>Look At PTAP Before Bidding</div>
            </div>
        },
        {
            selector: '[data-tut="placeBidTask"]',
            content: () => <div className='tourTaskDefault'>Click Here</div>
        },
        {
            selector: '[data-tut="bidValueTask"]',
            content: () => <div className='tourTaskDefault bidValueTask'>Click Here</div>
        },
        {
            selector: '[data-tut="placeBidButton"]',
            content: () => <div className='tourTaskDefault placeBidButton'>Click Here</div>
        }
    ]
    return (
        <div className="practiceBiddingContainer">
            <BiddingView 
                openUserInfoModal={openUserInfoModal} 
                placeBid={placeBid}
                setPlaceBid={setPlaceBidValue}
                promptUserToBid={promptUserToBid}
                isTourOpenTask={isTourOpenTask}
                promptUserToForfeit={promptUserToForfeit}
                setCurrentTaskTourId={setCurrentTaskTourId}
                isGuide={true} 
                openStartBidModal={openStartBidModal}
                openConfirmFinish={openConfirmFinish}
                openConfirmExit={openConfirmExit}
                openPlanEndModal={openPlanEndModal} 
                currentTaskId={currentTaskId}
                didUserBidModal={didUserBidModal}
                disableLockStatus={disableLockStatus}
                finishTour={finishTour}
                startTour={startTour}
            />
            <BiddingFooter 
                handleForfeit={handleForfeit} 
                lockStatus={lockStatus}
                isTourOpenTask={isTourOpenTask}
                setCurrentTaskTourId={setCurrentTaskTourId}
                openBidSelectModal={openBidSelectModal} 
                openLockedAlert={openLockedAlert}
                openAlertNotBid={openAlertNotBid}
                finishTour={finishTour}
            />
            {isIntro && <IntroModal closeIntro={closeIntro}/>}
            
            {startBidModal && 
                <TaskModal 
                    id={1} 
                    closeTaskModal={closeTaskModal}
                    heading={"Place a Bid"}
                    startTask={startTask}
                />}

            {showFlagModal && 
                <TaskModal 
                    id={2} 
                    closeTaskModal={closeTaskModal}
                    heading={"Show Forgo"} 
                    startTask={startTask}
                />}
            
            <Tour
                steps={stepsTask}
                isOpen={isTourOpenTask}
                className={"tourTask"}
                closeWithMask={false}
                disableDotsNavigation
                goToStep={currentTaskTourId}
                showButtons={false}
                showCloseButton={false}
                showNavigation={false}
                showNumber={false}
                onRequestClose={closeTourTask} />
            
            <div id="bidSelectModal" ref={bidSelectModal} className="modal">
                <div className="modal-content">
                    <img className="close" onClick={() => closeBidModal()} src={planEndClose} alt="icon" />
                   
                    <div className="heading">
                        <div>Confirmation</div>
                    </div>
                    <div className="bidFormula">
                        {bidValue > 0 && <>
                            <span>{bidFormula}</span>
                            <span onClick={() => removeBidFormula()} id="bidFormulaClose" className="bidFormulaClose">
                                <img src={smallCloseicon} alt="icon" />
                            </span>
                        </>}
                    </div>
                    
                    <div id="bidValue" className="bidValue">
                        ${`${initialBidValue - bidValue}`}
                    </div>
                    <div ref={gridValuesTask} className="gridValues" onClick={() => openNextGuide()} data-tut="bidValueTask">
                        <div className="gridItem">
                            <span className="bidItem" onClick={() => setBidValue(100)}>-$100</span>
                        </div>
                        <div className="gridItem">
                            <span className="bidItem" onClick={() => setBidValue(75)}>-$75</span>
                        </div>
                        <div className="gridItem">
                            <span className="bidItem" onClick={() => setBidValue(50)}>-$50</span>
                        </div>
                        <div className="gridItem">
                            <span className="bidItem" onClick={() => setBidValue(25)}>-$25</span>
                        </div>
                        <div className="gridItem">
                            <span className="bidItem" onClick={() => setBidValue(10)}>-$10</span>
                        </div>
                    </div>
                    <div className="noticeSection">
                        <span className="text">Predicted total amount paid at the end</span>
                        <span className="info">{biddingData.PTAP}</span>
                    </div>
                    <button data-tut="placeBidButton" onClick={() => handlePlaceBid()} style={{opacity: bidValue > 0 ? '1' : 0.6}} type="submit">
                        Place Bid!
                    </button>

                </div>
            </div>

            <div id="forfeitModal" ref={forfeitRef} className="modal">
                <div className="modal-content">
                    <img onClick={() => { forfeitRef.current.style.display = "none"; }} className="close" src={planEndClose} alt="icon" />
                    <div className="heading">Are you sure for forgo?</div>
                    <div className="info">Type FORGO To Confirm.</div>
                    <input defaultValue={forfeitText} ref={forfeitTextRef} type="text" id="inputForfeit" />
                    <p className="description">
                        NOTE: This action can't be redone and you can't able to bid in this particular auction the bid bar will be locked.
                    </p>
                    <button  onClick={() => forfeitBid()}>Confirm</button>

                </div>
            </div>
            <div ref={planEndModal} className="modal">
                <div className="modal-content planEndModal">
                    <img onClick={() => { planEndModal.current.style.display = "none"; taskSuccesRef.current.style.display = "block";}} id="planEndClose" className="close" src={planEndClose} alt="icon" />
                    <div className="heading">
                        Auction Ended
                    </div>
                    <p className="detail">Next bid on July 10th 2021</p>
                    <div className="values">
                        <span className="title">Total amount taken by {biddingData.currentBidWinner && biddingData.currentBidWinner.name && biddingData.currentBidWinner.name}</span>
                        <span className="value">${biddingData.currentBidWinner && biddingData.currentBidWinner.bidAmount && biddingData.currentBidWinner.bidAmount}</span>
                        <span className="title">Total Surplus</span>
                        <span className="value">${biddingData.initialAmount && biddingData.currentBidWinner && biddingData.currentBidWinner.bidAmount && (biddingData.initialAmount - biddingData.currentBidWinner.bidAmount)}</span>
                        <span className="title">Total Amount Paid</span>
                        {biddingData.initialAmount && biddingData.currentBidWinner && biddingData.currentBidWinner.bidAmount && <span className="value">${400 - (biddingData.initialAmount - biddingData.currentBidWinner.bidAmount)/5}</span>}
                    </div>
                    <button onClick={() => { planEndModal.current.style.display = "none"; taskSuccesRef.current.style.display = "block"; }}>View More</button>
                </div>
            </div>
            <div id="taskSuccesModal" ref={taskSuccesRef} className="modal">
                <div className="modal-content taskSuccesModal">
                    <img onClick={() => {  taskSuccesRef.current.style.display = "none"; setCurrentTaskId(0); }} className="close" src={planEndClose} alt="icon" />
                    <img className="illustration" src={successfulIllustration} alt="icon"/>
                    <div className="heading">Congrats!</div>
                    <p className="message">You have succesfully completed!</p>
                    <div 
                        className='tryAgain'
                        onClick={() => {taskSuccesRef.current.style.display = "none"; openStartBidModal(currentTaskId); }}
                        >
                        <img src={tryAgain} alt="icon" />
                        <span>Try Again</span>
                        </div>
                    <button onClick={() => {taskSuccesRef.current.style.display = "none"; setCurrentTaskId(0); }}>Finish Now!</button>
                </div>
            </div>
            <div id="userInfoModal" ref={userInfoModal} className="modal">
                <div className="modal-content userInfoContent">
                    <span className="userIcon"> 
                        <img src={userIconWhite} alt="icon" />
                    </span>
                    <div className="name">{userInfoModalName}</div>
                    <div className="buttons">
                        <button className="viewProfile">View Profile</button>
                        <div className="status">
                            <img src={checkIcon} alt="icon" />
                            <span>Sent</span>
                        </div>
                    </div>  
                </div>
            </div>

            <div ref={confirmFinishTask} className="modal">
                <div className="modal-content confirmFinishTask">
                    <img onClick={() => {  confirmFinishTask.current.style.display = "none";}} className="close" src={planEndClose} alt="icon" />
                    <div className="heading">Confirmation</div>
                    <p className="description">Do you want to<br/> finish this task?</p>
                    <div className='buttonRow'>
                        <button className="no" onClick={() => confirmFinishTask.current.style.display = "none"}>No</button>
                        <button onClick={() => confirmFinish()}>Yes</button>
                    </div>
                </div>
            </div>

            <div ref={confirmExitTask} className="modal">
                <div className="modal-content confirmFinishTask">
                    <img onClick={() => {  confirmExitTask.current.style.display = "none";}} className="close" src={planEndClose} alt="icon" />
                    <div className="heading">Confirmation</div>
                    <p className="description">Do you want to<br/> exit the task?</p>
                    <div className='buttonRow'>
                        <button className="no" onClick={() => confirmExitTask.current.style.display = "none"}>No</button>
                        <button onClick={() => confirmExit()}>Yes</button>
                    </div>
                </div>
            </div>

            <div ref={forgoEnabledAlert} className="modal">
                <div className="modal-content forgoEnabledAlert">
                    <img onClick={() => { forgoEnabledAlert.current.style.display = "none";setShouldTourBeOpen(true);  }} className="close" src={planEndClose} alt="icon" />
                    <div className="name">ALERT!</div>
                    <div className='description'>You have already shown forgo this auction so you couldn't bid for this auction anymore. So can bid in the next auction.</div>
                </div>
            </div>

            <div ref={notBidYetAlert} className="modal">
                <div className="modal-content forgoEnabledAlert">
                    <img onClick={() => { notBidYetAlert.current.style.display = "none";setShouldTourBeOpen(true); }} className="close" src={planEndClose} alt="icon" />
                    <div className="name">ALERT!</div>
                    <div className='description'>You have not bid yet for this auction. So can't forgo yet.</div>
                </div>
            </div>
        </div>
    )
}

export default PracticeBidding