import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router";
import { 
    joinDemoPlan,
    getDemoPlans
} from './../../redux/actions/planActions';
export default function TrialPlan() {
    const dispatch = useDispatch();
    const history = useHistory();
    const joinDemo = () => {
        dispatch(joinDemoPlan()).then(res => {
            if(res && res.message && res.message === "success"){
                dispatch(getDemoPlans())
                history.push("/plans/1")
            }
        })
    }
    
    return (
        <section className='trialCard'>
            <div className='header'>
                <span>Demo Plan</span>
            </div>
            <div className='content'>
                <div className='info'>
                    <div className='planInfo'>$1000 Monthly Plan</div>
                    <div className='details'>x$200 Per Month For <span className='duration'>5 Months</span></div>
                </div>
                <div className='statusProgress'>
                    <div className='text'>
                        <span>Total Members</span>
                        <span>5 members</span>
                    </div>
                    <div className='progress'>
                        <div style={{width: '75%'}} className='percentage'></div>
                    </div>
                    <div className='upcoming'>
                        <span></span>
                        <span>1 seat left</span>
                    </div>
                </div>
                <div className="buttonRow">
                    <button className='active' onClick={() => joinDemo()}>Join Now!</button>
                    <button>View More</button>
                </div>
            </div>
        </section>
    );
}
