import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from 'swiper/core';
import { isMobile } from "react-device-detect";
import InviteFriend from "./InviteFriend";
import EasyPayment from "./EasyPayment";
import easyPaymentCOne from "../../assets/images/landingPage/home/easyPayment/easyPayment.svg";
import easyPaymentCTwo from "../../assets/images/landingPage/home/easyPayment/Frame 1305.png";
import easyPaymentCThree from "../../assets/images/landingPage/home/easyPayment/Top up credit-pana 1.png";
import inviteFreindCOne from "../../assets/images/landingPage/home/invitefriend/invitefriend.svg";
import inviteFreindCThree from "../../assets/images/landingPage/home/invitefriend/Visual data-pana 1.png";
import inviteFreindCTwo from "../../assets/images/landingPage/home/invitefriend/Frame 1302.png";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
SwiperCore.use([Pagination]);
export default function PanelSwipers(props) {
  return (
    <Swiper
        pagination={{
        dynamicBullets: true,
        }}
        className="mySwiper"
        // style={{ height: "calc(100vh - 100px)" }}
        >
        <SwiperSlide>
            <section className="panel">
                <InviteFriend
                    getStarted={props.getStarted}
                    isMobile={isMobile}
                    type="pane1"
                    img={inviteFreindCOne}
                    label="Invite Friends & Family"
                    description="Invite friends and family into the platform and plans to help and benefit from each other or make friends from your existing plans."
                    Btext="Get Started"
                    header={true}
                />
            </section>
        </SwiperSlide>
        <SwiperSlide>
            <section className="panel">
                <EasyPayment
                    getStarted={props.getStarted}
                    isMobile={isMobile}
                    img={easyPaymentCOne}
                    label="Get Rewarded"
                    description="Finish specific tasks and win rewards "
                    Btext="Get Started"
                />
            </section>
        </SwiperSlide>
        <SwiperSlide>
            <section className="panel">
                <InviteFriend
                    getStarted={props.getStarted}
                    isMobile={isMobile}
                    img={inviteFreindCTwo}
                    label="Get upgraded and unlock high tenure plans"
                    description="Get upgraded and unlock high tenure plans based on your needs and make use of wheez to its fullest "
                    Btext="Get Started"
                    header={false}
                />
            </section>
        </SwiperSlide>
        <SwiperSlide>
            <section className="panel">
                <EasyPayment
                    getStarted={props.getStarted}
                    isMobile={isMobile}
                    img={easyPaymentCTwo}
                    label="Get personalized announcements & tips"
                    description="Get personalized announcements and tips from Wheezy( The host) and use the plans wisely and engagingly"
                    Btext="Get Started"
                />
            </section>
        </SwiperSlide>
        <SwiperSlide>
            <section className="panel">
                <InviteFriend
                    getStarted={props.getStarted}
                    isMobile={isMobile}
                    img={inviteFreindCThree}
                    label="Stay On Track"
                    description="Get clear analytics reminders, and notifications to keep track of your plans."
                    Btext="Get Started"
                    header={false}
                />
            </section>
        </SwiperSlide>
        <SwiperSlide>
            <section className="panel">
                <EasyPayment
                    getStarted={props.getStarted}
                    isMobile={isMobile}
                    img={easyPaymentCThree}
                    label="Hustle Free Withdrawals & Easy payments"
                    description="Easy and automatic withdrawal of funds to your linked bank accounts and literally Zero fees. "
                    Btext="Get Started"
                />
            </section>
        </SwiperSlide>
    </Swiper>
  )
}
