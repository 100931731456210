import {
  START_PRACTICE_BIDDING,
  BID_PRACTICE_AMOUNT,
  END_PRACTICE_BIDDING,
  RESET_BIDDING,
  SCROLL_TRIGGER_START,
  SCROLL_TRIGGER_END,
  PRACTICE_BID_WINNER,
  FORFEIT_SELF
  } from '../types';
  
  const initialState = {
    biddingStarted: false,
    timer: 0,
    PTAP: 8000,
    keyTimer: 0,
    initialAmount: 0,
    currentBidUser: null,
    currentBidWinner: null,
    users: [],
    bidLog: [],
    scrollTrigger: false
  };
  
  export default function practiceBiddingReducer(state = initialState, action) {
    switch (action.type) {
      case START_PRACTICE_BIDDING:
        return {
          ...state,
          PTAP: 1600,
          keyTimer: state.keyTimer + 1,
          biddingStarted: true,
          userId: 1,
          initialAmount: 2000,
          currentBidWinner: null,
          timer: 900,
          users: [
            {
              id: 1,
              name: 'Vicky',
            },
            {
              id: 2,
              name: 'John',
            },
            {
              id: 3,
              name: 'Rahul',
            },
            {
              id: 4,
              name: 'Cathy',
            },
            {
              id: 5,
              name: 'Andrew',
            }
          ]

        }
      case SCROLL_TRIGGER_START:
        return {
          ...state,
          scrollTrigger: true
        }
      case SCROLL_TRIGGER_END:
        return {
          ...state,
          scrollTrigger: false
        }
      case BID_PRACTICE_AMOUNT: 
        let bidUserInfo = state.users.filter((user) => user.id === action.payload.id)[0];
        if(bidUserInfo){
          bidUserInfo["bidAmount"] = action.payload.initialValue - action.payload.deductedValue;
          return  {
            ...state,
            currentBidUser: bidUserInfo,
            bidLog: [
              ...state.bidLog, 
             { 
               id: action.payload.id,
               timestamp: Date.now(),
               bidAmount: action.payload.initialValue - action.payload.deductedValue,
               initialValue: action.payload.initialValue,
               deductedValue: action.payload.deductedValue,
               name: bidUserInfo.name
              }
            ]
          }
        }
        return {
          ...state
        }
      case FORFEIT_SELF:
        return {
          ...state,
          bidLog: [
            ...state.bidLog,
            {
              id: action.payload.id,
              timestamp: Date.now(),
              type: "self_forfeit"
            }
          ]
        }
      case END_PRACTICE_BIDDING:
        return {
          ...state,
          PTAP: 8000,
          biddingStarted: false,
          timer: 0,
          currentBidUser: null,
          users: [],
          bidLog: [],
          keyTimer: state.keyTimer + 1,
        }
      case RESET_BIDDING:
        return {
          ...state,
          biddingStarted: false,
          timer: 0,
          currentBidUser: null,
          currentBidWinner: null,
          bidLog: [],
          users: [],
          keyTimer: state.keyTimer + 1,
        }
      case PRACTICE_BID_WINNER:
        let bidWinnerInfo = state.users.filter((user) => user.id === action.payload.id)[0];
        if(bidWinnerInfo){
          return  {
            ...state,
            currentBidWinner: bidWinnerInfo,
          }
        }
        return {
          ...state
        }

        
      default:
        return state;
    }
  }