import React, { useEffect, useState } from 'react'
import ReactGA from "react-ga";
import { useHistory } from "react-router";

export default function GATracking(props) {
    const history = useHistory();
    const [currentPath, setCurrentPath] = useState("");
    const [foundPath, setFoundPath] = useState(false)
    
    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_WHEEZ_GA_TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
    },[history.location.pathname])

    useEffect(() => {
        let initialPath = history.location.pathname.substring(1)
        if(initialPath.includes("/")){
            let indexOf = initialPath.indexOf("/");
            initialPath = initialPath.substring(0, indexOf)
            setCurrentPath(initialPath)
        } else {
            setCurrentPath(initialPath)
        }
        props.children.map((child) => {
            if(child.props.path && child.props.path.includes(initialPath) && initialPath !== ""){
                setFoundPath(true)
            }
            return null
        })
    },[history.location.pathname, props.children])

    return (
        <>
            {
                props.children.map((child) => {
                    if( child.props.path && child.props.path.includes(currentPath)){
                        return child
                    } else if(child.props.path && !child.props.path.includes(currentPath)) {
                        return null
                    } else if(!child.props.path) {
                        if(!foundPath){
                            return child
                        } else {
                            return null
                        }
                    } else {
                        return null
                    }
                })
            }
        </>
    )
}
