import {
  PLAN_DATA,
  RECOMMENDED_PLANS,
  USER_PLANS,
  CURRENT_SELECTED_PLAN,
  BASIC_PLAN_STATUS,
  RESET_PLAN_DATA,
  REMOVE_RECOMMEND_PLAN,
  SIMPLE_USER_PLANS,
  INACTIVE_PLANS,
  AUCTION_WINNING_INFO,
  CURRENT_PLAN_PORTFOLIO,
  PLAN_SUMMARY,
  CLEAR_ALL_TYPE_PLANS,
  ALL_TYPE_PLANS,
  CLEAR_PLANS_RECOMMENDS,
  PLAN_TYPES,
  RECOMMENDED_PLANS_THERE,
  USER_PLANS_THERE,
  CONTRIBUTION_AMOUNT_RANGES,
  PERIOD_RANGES,
  PLAN_GOALS,
  DEMO_PLANS
} from '../types';
  
  const initialState = {
    planDetails: {},
    recommendedPlans: [],
    userPlans: [],
    currentSelectedPlan: {},
    planSummary: {},
    currentPlanPortfolio: {},
    basicPlanStatus: "",
    simpleUserPlans: [],
    inactivePlans: [],
    allTypePlans: [],
    auctionWinningInfo: [],
    recommendedPlansThere: false,
    userPlansThere: false,
    planTypes: [],
    contributionAmountRanges: [],
    periodRanges: [],
    planGoals: [],
    demoPlans: []
  };
  
  export default function planReducer(state = initialState, action) {
    switch (action.type) {
      case PLAN_DATA:
        return {
          ...state,
          planDetails: action.payload
        }
      case CONTRIBUTION_AMOUNT_RANGES:
        return {
          ...state,
          contributionAmountRanges: action.payload
        }
      case PERIOD_RANGES:
        return {
          ...state,
          periodRanges: action.payload
        }
      case PLAN_GOALS:
        return{
          ...state,
          planGoals: action.payload
        }
      case PLAN_TYPES:
        return {
          ...state,
          planTypes: action.payload
        }
      case RECOMMENDED_PLANS:
        return {
          ...state,
          recommendedPlans: [...state.recommendedPlans, action.payload]
        }
      case BASIC_PLAN_STATUS:
        return {
          ...state,
          basicPlanStatus: action.payload
        }
      case CURRENT_PLAN_PORTFOLIO:
        return {
          ...state,
          currentPlanPortfolio: action.payload
        }
      case PLAN_SUMMARY:
        return {
          ...state,
          planSummary: action.payload
        }
      case DEMO_PLANS:
        return {
          ...state,
          demoPlans: action.payload
        }
      case USER_PLANS:
        return {
          ...state,
          userPlans: [...state.userPlans, action.payload]
        }
      case SIMPLE_USER_PLANS:
        return {
          ...state,
          simpleUserPlans: action.payload
        }
      case CLEAR_PLANS_RECOMMENDS: {
        return {
          ...state,
          recommendedPlans: [],
          userPlans: []
        }
      }
      case INACTIVE_PLANS:
        return {
          ...state,
          inactivePlans: [...state.inactivePlans, action.payload]
        }
      case CURRENT_SELECTED_PLAN: 
        return {
          ...state,
          currentSelectedPlan: action.payload
        }
      case REMOVE_RECOMMEND_PLAN:
        return {
          ...state,
          recommendedPlans: state.recommendedPlans.filter((plan) => plan.id !== action.payload )
        }
      case RESET_PLAN_DATA:
        return {
          ...state,
          recommendedPlans: [],
          userPlans: [],
          inactivePlans: []
        }
      case AUCTION_WINNING_INFO:
        return {
          ...state,
          auctionWinningInfo: action.payload
        }
      case ALL_TYPE_PLANS:
        return {
          ...state,
          allTypePlans: [...state.allTypePlans, ...action.payload]
        }
      case CLEAR_ALL_TYPE_PLANS:
        return {
          ...state,
          allTypePlans: []
        }
      case RECOMMENDED_PLANS_THERE: 
        return {
          ...state,
          recommendedPlansThere: action.payload
        }
      case USER_PLANS_THERE:
        return {
          ...state,
          userPlansThere: action.payload
        }
      default:
        return state;
    }
  }