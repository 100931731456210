import React from 'react'
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import ChatRoomComponent from './../ChatRoomComponent';
import smallCloseicon from './../../assets/images/icons/closeSquare.svg';
import './../../styles/chatBox.scss';

export default function ChatBox(props) {
    const chatState = useSelector((state) => state.chat);
    const biddingState = useSelector((state) => state.liveBidding);

    const chatRoomLink = `/chatRoom/${props.planId}`
    return (
        <>
        { props.showDisplay > 0 && 
            <section className="chatBox">
                <div className="modal">
                    <div className="modal-content">
                        <div className="headerChat">
                            <div>
                                <Link to={chatRoomLink}><div className="title">Chat room</div></Link>
                                <div className="info">{biddingState.planDetails.title}</div>
                            </div>
                            <img onClick={() => props.closeChatBox()} src={smallCloseicon} alt="icon" />
                        </div>
                        <ChatRoomComponent messages={chatState.chatMessages} fromBidding={true} />
                    </div>
                </div>
            </section>} 
        </>
    )
}
