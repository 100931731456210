import React, { useEffect, useState } from 'react'
import { useTour } from '@reactour/tour'
import './../../styles/guidedModal.scss';

function GuidedModal({guideStep}) {
    const [ guide, setGuide] = useState({});
    const { setIsOpen, ...tourProps } = useTour()
    const [guideSteps] = useState([
        {
            stepId: 0,
            heading: "Timer",
            description: "The time in this timer I’ll be reduseing from the starting time if it comes to “00:00:00” the biding is closed. Every bidders have to keep an eye on it."
        },
        {
            stepId: 1,
            heading: "Task",
            description: "Here you can choose the tasks like “ place a bid “ or “ Show a flag “ etc…"
        },
        {
            stepId: 2,
            heading: "Users Online",
            description: "This is the people online now"
        },
        {
            stepId: 3,
            heading: "PTAP",
            description: "“PTPA” is the full form of “ Predicted Total amount Paid At the end of the plan”. So, this is the Predicted value you’ll be paid when the plan ends. Before placing the bid this is the key metric to watch."
        },
        {
            stepId: 4,
            heading: "Flagged Members",
            description: "This is flagged members"
        },
        {
            stepId: 5,
            heading: "Available Members",
            description: "This is Available members"
        },
        {
            stepId: 6,
            heading: "Three dots",
            description: "Rules - Here you can see the plans bidding and chatting rules.<br/> Guide me - If you what to see what are all the elements again from first you can click this and see that.<br/> Exit - If you what to exit from the bidding you can exit from here."
        },
        {
            stepId: 7,
            heading: "Place Bid",
            description: "If we tap that button you are able to bid amount."
        },
        {
            stepId: 8,
            heading: "Lock/Unlock",
            description: "The lock button in bottom left is used for lock the screen so that unknowingly the user will not click any button in the screen."
        },
        {
            stepId: 9,
            heading: "Forgo",
            description: "If you want end this bidding you can hit that button to forfeit from bid."
        },
        {
            stepId: 10,
            heading: "Finish",
            description: "If you want to finish a task or the guide you can use this button."
        },
    ]);
    useEffect(() => {
        guideSteps.forEach((guide)=> {
            if(guide.stepId === guideStep){
                setGuide(guide)
            } 
        })
    }, [guideStep, guideSteps])

    const finishTour = () => {
        setIsOpen(false)
        tourProps.setCurrentStep(0)
    }

    return (
        <section className="guidedModal">
            <div className="modalGuide">
                <div className="modal-content">
                    <div className="heading">
                        {guide.heading}
                    </div>
                    <div className="rowFlex">
                        <div className="description" dangerouslySetInnerHTML={{__html: guide.description}}></div>
                    </div>
                    <button className='endTour' onClick={() => finishTour()}>End</button>
                </div>
            </div>
        </section>
    )
}

export default GuidedModal