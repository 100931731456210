import React from 'react'
import copyright from './../assets/images/icons/copyright.svg';
import wheezLogo from './../assets/images/wheez-logo-white.png';
import './../styles/footer.scss';

export default function Footer() {
    return (
        <div className="footerMain">
            <img className="logo" src={wheezLogo} alt="logo"/>
            <div className="copyright">
                <img src={copyright} alt="icon" />
                <span>Copyright 2021</span>
            </div>
        </div>
    )
}
