import React, { useRef, useState, useEffect }  from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment'
import RememberMe from '../components/RememberMe';
import LiveBiddingView from '../components/LiveBidding/LiveBiddingView';
import ChatBox from '../components/LiveBidding/ChatBox';
import {triggerScroll} from '../redux/actions/biddingActions';
import BiddingFooter from '../components/LiveBidding/BiddingFooter';
import RulesModal from '../components/LiveBidding/RulesModal';
import Feedback from '../components/LiveBidding/Feedback';

import { 
    socketConnect, 
    placeBid,
    forgoBid,
    getPlanDetailsBidding,
    // getAuctionDetails,
    clear400Error,
    exitAuction,
    closeConnection
    } 
from '../redux/actions/liveBiddingActions';
import { getPlanDetails, getPlanAuctionWinningInfo} from '../redux/actions/planActions';
import { 
    sendRequest,
    getPublicProfileInfo,
    getAllAvatars
} 
from '../redux/actions/contactActions';
import { 
    chatSocket,
    getAllChats,
    closeSocket,
} 
from '../redux/actions/chatActions';

import planEndClose from './../assets/images/icons/closeCircle.svg';
import smallCloseicon from './../assets/images/icons/smallCloseicon.svg';
import userIconWhite from './../assets/images/icons/userIconWhite.svg';
import './../styles/bidding.scss';

const Bidding = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const liveBiddingState = useSelector((state) => state.liveBidding);
    const planId = id;
    const [bidFormula, setBidFormula] = useState('');
    const [auctionNumbers, setAuctionNumbers] = useState([
        {
            id: 1,
            value: 0 
        },
        {
            id: 2,
            value: 0 
        },
        {
            id: 3,
            value: 0 
        },
        {
            id: 4,
            value: 0 
        },
        {
            id: 5,
            value: 0 
        }, 
    ]);
    const history = useHistory();
    const [ bidValue, setBidValue] = useState(0);
    const [ showChatBox, setShowChatBox] = useState(false);
    const [showUserInfo, setShowUserInfo ] = useState(false);
    const [errorFromServer, setErrorFromServer] = useState("");
    const [isRulesOpened, setIsRulesOpened] = useState(false);
    const [upcomingAuctionDate, setUpcomingAuctionDate ] = useState("");
    const [isFeedback, setIsFeedback] = useState(false);
    const [currentProfile, setCurrentProfile] = useState({});
    const [selectedAuction, setSelectedAuction] = useState({});
    const [lastAuctionEnd, setLastAuctionEnd] = useState({});

    const forfeitRef = useRef();
    const cantBid400 = useRef();
    const lockedModal = useRef();
    const cantBidAfterForwent = useRef();
    const lastAuctionEndModal = useRef();
    const forfeitTextRef = useRef();
    const planEndModal = useRef();
    const sameBidderCantForgo = useRef();
    const bidPTAPAlertModal = useRef();
    const bidSelectModal = useRef();
    const [forfeitText, setForfeitText] = useState('');
    const [PTAPAlertTrigger,  setPTAPAlertTrigger] = useState(true)
    const [PTAPAlert, setPTAPAlert] = useState(true);
    const [forfeitModal, setForfeitModal ] = useState(false);
    const [isContact, setIsContact] = useState(false);
    const [userInfoModalName, setUserInfoModalName ] = useState('');
    const userInfoModal = useRef();
  
    useEffect(() => {
        if(liveBiddingState.currentBid 
            && liveBiddingState.currentBid.bid 
            && liveBiddingState.currentBid.bid.asking_amount 
            && liveBiddingState.currentBid.bid.asking_amount > 0){
            setBidFormula(`$${Math.trunc(liveBiddingState.currentBid.bid.asking_amount)} - $${bidValue}`)
        } else {
            if(liveBiddingState.planDetails && liveBiddingState.planDetails.total_amount){
                setBidFormula(`$${Math.trunc(liveBiddingState.planDetails.total_amount)} - $${bidValue}`)
            }
        }
    }, [bidValue, liveBiddingState.planDetails, liveBiddingState.currentBid]);

    useEffect(() => {
        dispatch(getAllChats(planId));
        setTimeout(() => {
            dispatch(chatSocket(planId));
        }, 1500);
        dispatch(getPlanDetailsBidding(planId));
        dispatch(getPlanDetails(planId));
        return function () {
            dispatch(closeConnection());
            dispatch(closeSocket());
        }
    }, [dispatch, planId])

    useEffect(() => {
        dispatch(getAllAvatars())
        return function () {
            dispatch(exitAuction());
        }
    },[dispatch])

    useEffect(() => {
        if(liveBiddingState.currentBid 
            && liveBiddingState.currentBid.bid 
            && liveBiddingState.currentBid.bid.asking_amount 
            && liveBiddingState.currentBid.bid.asking_amount > 0){
                let currentBidValue = Math.trunc(liveBiddingState.currentBid.bid.asking_amount);
                let tenDigits = Math.pow(10, currentBidValue.toString().length - 1);
                let firstDigit = currentBidValue.toString()[0];
                let factor = tenDigits * firstDigit;
                setAuctionNumbers([
                    {
                        id: 1,
                        value: (1/100) * factor
                    },
                    {
                        id: 2,
                        value: (2/100) * factor
                    },
                    {
                        id: 3,
                        value: (3/100) * factor
                    },
                    {
                        id: 4,
                        value: (4/100) * factor
                    },
                    {
                        id: 5,
                        value: (5/100) * factor
                    },
                ])
        } else {
            if(liveBiddingState.planDetails && liveBiddingState.planDetails.total_amount){
                let currentBidValue = Math.trunc(liveBiddingState.planDetails.total_amount);
                let tenDigits = Math.pow(10, currentBidValue.toString().length - 1);
                let firstDigit = currentBidValue.toString()[0];
                let factor = tenDigits * firstDigit;
                setAuctionNumbers([
                    {
                        id: 1,
                        value: (1/100) * factor
                    },
                    {
                        id: 2,
                        value: (2/100) * factor
                    },
                    {
                        id: 3,
                        value: (3/100) * factor
                    },
                    {
                        id: 4,
                        value: (4/100) * factor
                    },
                    {
                        id: 5,
                        value: (5/100) * factor
                    },
                ])
            }
        }
    }, [liveBiddingState.currentBid, liveBiddingState.planDetails]);

    useEffect(() => {
        if(liveBiddingState.auctionWinner && liveBiddingState.auctionWinner.winner && liveBiddingState.auctionStatus === "completed"){
            if(lastAuctionEnd && liveBiddingState.planDetails.auctions 
                && liveBiddingState.planDetails.auctions.length > 0 
                && liveBiddingState.planDetails.auctions[liveBiddingState.planDetails.auctions.length - 1].id === liveBiddingState.auctionId){
                lastAuctionEndModal.current.style.display = "block";
            } else {
                planEndModal.current.style.display = "block";
            }
        }
    }, [liveBiddingState.auctionWinner,  liveBiddingState.auctionStatus, lastAuctionEnd, liveBiddingState.planDetails, liveBiddingState.auctionId]);

    useEffect(() => {
        if(liveBiddingState.auctionStatus === "completed" 
        && liveBiddingState.planDetails.auctions 
        && liveBiddingState.planDetails.auctions.length > 0 
        && liveBiddingState.planDetails.auctions[liveBiddingState.planDetails.auctions.length - 1].id === liveBiddingState.auctionId ){
            dispatch(getPlanAuctionWinningInfo(planId)).then((res) => {
                let lastAuctionWinning = res.payload.filter((auction) => {
                    return auction.auction.id === liveBiddingState.auctionId
                })[0]
                if(lastAuctionWinning){
                    lastAuctionEndModal.current.style.display = "block";
                    setLastAuctionEnd({
                        total_amount_received: lastAuctionWinning.total_amount_received,
                        shared_surplus: lastAuctionWinning.shared_surplus,
                        individual_surplus: lastAuctionWinning.individual_surplus,
                        amount_contributed: lastAuctionWinning.amount_contributed,
                        winner: {
                            first_name: lastAuctionWinning.winner.first_name
                        }
                    })
                }
                
            });
        }
    }, [liveBiddingState.auctionStatus, liveBiddingState.planDetails, liveBiddingState.auctionId, dispatch, planId]);

    useEffect(() => {
        if(liveBiddingState.triggerFeedback){
            setIsFeedback(true)
        }
    },[liveBiddingState.triggerFeedback])

    useEffect(() => {
        if(liveBiddingState.error400Message){
            setErrorFromServer(liveBiddingState.error400Message)
            cantBid400.current.style.display = "block";
        }
    }, [liveBiddingState.error400Message]);
    
    useEffect(() => {
        if(liveBiddingState.planDetails && liveBiddingState.planDetails.auctions && liveBiddingState.planDetails.auctions.length > 0){
            let currentRunningAuction = liveBiddingState.planDetails.auctions.filter((auction) => {
                return auction.status.toLowerCase() === "running"
            })
            let finishedAuctions = liveBiddingState.planDetails.auctions.filter((auction) => {
                return auction.status.toLowerCase() === "completed"
            })
            let scheduledAuctions = liveBiddingState.planDetails.auctions.filter((auction) => {
                return auction.status.toLowerCase() === "scheduled"
            })
            if(scheduledAuctions.length > 0){
                setUpcomingAuctionDate(scheduledAuctions[0].starts_at)
            }
            if(currentRunningAuction.length > 0){
                currentRunningAuction.map((auction) => {
                    if(auction.id === liveBiddingState.auctionId && !liveBiddingState.socketRunning){
                        dispatch(socketConnect(auction.id));
                    }
                    return null;
                })
            } else if(finishedAuctions.length > 0){
                finishedAuctions.map((auction) => {
                    if(auction.id === liveBiddingState.auctionId && selectedAuction.id !== liveBiddingState.auctionId){
                        // dispatch(getAuctionDetails(finishedAuctions[finishedAuctions.length-1].id));
                    }
                    return null;
                })
            }
        }
    }, [liveBiddingState.planDetails, dispatch, liveBiddingState.auctionId, liveBiddingState.socketRunning, selectedAuction.id]);


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    });
    useEffect(() => {
        if(showUserInfo) {
            document.addEventListener('mousedown',  handleClickUserInfo);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickUserInfo);
        }
    }, [showUserInfo]);
    const handleForfeit = () => {
        forfeitRef.current.style.display = "block";
        setForfeitModal(true);
    }
    const handleClickOutside = (event) => {
        if(forfeitModal){
            if(forfeitRef.current.isSameNode(event.target)){
                forfeitRef.current.style.display = "none";
                setForfeitText('');
                forfeitTextRef.current.value = "";
                setForfeitModal(false);
            }
        }
    }
    const handleClickUserInfo = (event) => {
        if(userInfoModal.current.isSameNode(event.target)){
            userInfoModal.current.style.display = "none";
            setShowUserInfo(false);
            setUserInfoModalName('');
        }
    }
    const openPlanEndModal = () => {
        if(lastAuctionEnd && liveBiddingState.planDetails.auctions 
            && liveBiddingState.planDetails.auctions.length > 0 
            && liveBiddingState.planDetails.auctions[liveBiddingState.planDetails.auctions.length - 1].id === liveBiddingState.auctionId){
            lastAuctionEndModal.current.style.display = "block";
        }
        if(liveBiddingState.auctionWinner && liveBiddingState.auctionWinner.winner && liveBiddingState.auctionStatus === "completed" && (lastAuctionEndModal.current.style.display === "none" || lastAuctionEndModal.current.style.display === "")){
            planEndModal.current.style.display = "block";
        }
    }
    const openBidSelectModal = () => {
        bidSelectModal.current.style.display = "block";
    }

    const forfeitBid = () => {
        if(forfeitTextRef.current.value.toLowerCase() === "forgo") {
            dispatch(forgoBid());
            forfeitRef.current.style.display = "none";
            forfeitTextRef.current.value = "";
            setForfeitText('');
            setForfeitModal(false);
        }
    }
    const closeBidModal = () => {
        bidSelectModal.current.style.display = "none";
        setBidValue(0);
        setBidFormula('');
    }
    const removeBidFormula = () => {
        setBidFormula('');
        setBidValue(0);
    }
    const openChatBox = () => {
        setShowChatBox(true);
    }
    const closeChatBox = () => {
        setShowChatBox(false);
    }
    const openUserInfoModal = (userInfo) => {
        dispatch(getPublicProfileInfo(userInfo.bid.user.id)).then(res => {
            userInfoModal.current.style.display = "block";
            setUserInfoModalName(userInfo.bid.user.first_name +  ' ' + userInfo.bid.user.last_name)
            setShowUserInfo(true);
            setIsContact(res.is_contact ? res.is_contact : false)
            setCurrentProfile(userInfo.bid.user)
        })
    }
    const closeRules = () => {
        setIsRulesOpened(false);
    }
    const cantBidModal = () => {
        lockedModal.current.style.display = "block";
    }

    const handlePlaceBid = () => {
        let currentValue = 0;
        let ptapLimit = liveBiddingState.planDetails.ptap;

        if(liveBiddingState.currentBid && liveBiddingState.currentBid.bid){
            currentValue = liveBiddingState.currentBid.bid.asking_amount - bidValue;
        }
        if(bidValue > 0){
            if(currentValue < ptapLimit && PTAPAlert && liveBiddingState.currentBid.bid){
                bidPTAPAlertModal.current.style.display = "block";
            } else {
                bidSelectModal.current.style.display = "none";
                dispatch(placeBid(bidValue));
                setBidValue(0);
                dispatch(triggerScroll());
            }
        }
    };
    const closeFeedback = () => {
        setIsFeedback(false)
    }

    const currentBidderCantForgoModal = () => {
        sameBidderCantForgo.current.style.display = "block";
    }
    const noBidAfterForwent = () => {
        cantBidAfterForwent.current.style.display = "block";
    }

    const confirmPTAPBid = () => {
        bidPTAPAlertModal.current.style.display = "none";
        setPTAPAlertTrigger(false);
        bidSelectModal.current.style.display = "none";
        dispatch(placeBid(bidValue));
        setBidValue(0);
        dispatch(triggerScroll());
    }

    const handleChecked = (e) => {
        if(e.target.checked){
            setPTAPAlert(false);
        }
    }
    const handleSmallBid = (value) => {
        setBidValue(bidValue + value)
    }
    const  openProfile = () => {
        let profileLink = `/profile/${currentProfile.id}` ;
        history.push(profileLink)
    }
    const  addFriend = () => {
        if(!isContact){
            let userData = {
                "value": currentProfile.id,
                "value_type": "id"
            }
            dispatch(sendRequest(userData));
            setShowUserInfo(false);
            userInfoModal.current.style.display = "none";
        }
    }

    const clearError400 = () => {
        dispatch(clear400Error());
        cantBid400.current.style.display = "none"
    }
    return (
        <div className="practiceBiddingContainer practiceTour">
            <LiveBiddingView 
                openUserInfoModal={openUserInfoModal} 
                openPlanEndModal={openPlanEndModal} 
                openChatBox={openChatBox} 
                setSelectedAuction={setSelectedAuction}
                setIsRulesOpened={setIsRulesOpened}
                isGuide={false}
            />
            
            <BiddingFooter 
                handleForfeit={handleForfeit} 
                noBidAfterForwent={noBidAfterForwent}
                lastAuctionData={lastAuctionEnd}
                cantBidModal={cantBidModal}
                currentBidderCantForgoModal={currentBidderCantForgoModal}
                openPlanEndModal={openPlanEndModal}
                lockStatus={ liveBiddingState.biddingConfig && liveBiddingState.biddingConfig.users 
                    ? liveBiddingState.biddingConfig.flagged || liveBiddingState.biddingConfig.bid_disabled : true} 
                openBidSelectModal={openBidSelectModal}
            />
            
            <ChatBox planId={id} showDisplay={showChatBox} closeChatBox={closeChatBox}/>

            {isRulesOpened && <RulesModal closeRules={closeRules}/>}

            {isFeedback && <Feedback closeFeedback={closeFeedback} />}

            <div id="bidSelectModal" ref={bidSelectModal} className="modal">
                <div className="modal-content">
                    <img className="close" onClick={() => closeBidModal()} src={planEndClose} alt="icon" />
                   
                    <div className="heading">
                        <div>Confirmation</div>
                    </div>
                    <div className="bidFormula">
                        {bidValue > 0 && <>
                            <span>{bidFormula}</span>
                            <span onClick={() => removeBidFormula()} id="bidFormulaClose" className="bidFormulaClose">
                                <img src={smallCloseicon} alt="icon" />
                            </span>
                        </>}
                    </div>
                    {liveBiddingState.currentBid 
                    && liveBiddingState.currentBid.bid
                    && liveBiddingState.currentBid.bid.asking_amount 
                    ? 
                    ( <div id="bidValue" className="bidValue">
                        ${`${liveBiddingState.currentBid.bid.asking_amount - bidValue}`}
                     </div>) : (
                         liveBiddingState.planDetails.total_amount && <div id="bidValue" className="bidValue">
                         ${`${liveBiddingState.planDetails.total_amount - bidValue}`}
                      </div>
                     )}
                    <div className="smallBids">
                        <span style={{opacity: bidValue > 0 ? '1' : 0.6}} onClick={() => {handleSmallBid(2)}}>-$2</span>
                        <span style={{opacity: bidValue > 0 ? '1' : 0.6}} onClick={() => {handleSmallBid(1)}}>-$1</span>
                        <span style={{opacity: bidValue > 0 ? '1' : 0.6}} onClick={() => {handleSmallBid(-1)}}>+$1</span>
                        <span style={{opacity: bidValue > 0 ? '1' : 0.6}} onClick={() => {handleSmallBid(-2)}}>+$2</span>
                    </div>
                    <div className="gridValues">
                        {
                            auctionNumbers.map((bidNumber) => {
                                return (
                                <div key={bidNumber.id} className="gridItem">
                                    <span className="bidItem" onClick={() => setBidValue(bidNumber.value)}>-${Math.round(bidNumber.value)}</span>
                                </div>)
                            })
                        }
                    </div>
                    <div className="noticeSection">
                        <span className="text">Predicted total amount paid at the end</span>
                        <span className="info">{liveBiddingState.biddingConfig && liveBiddingState.biddingConfig.ptap ? liveBiddingState.biddingConfig.ptap : 0}</span>
                    </div>
                    <button onClick={() => handlePlaceBid()} style={{opacity: bidValue > 0 ? '1' : 0.6}} type="submit">
                        Place Bid!
                    </button>
                </div>
            </div>
            
            <div id="ptapConfirmModal" ref={bidPTAPAlertModal} className="modal">
                <div className="modal-content">
                    <img onClick={() => { bidPTAPAlertModal.current.style.display = "none"; }} className="close" src={planEndClose} alt="icon" />
                    <div className="heading">Alert!</div>
                    <div className="info">The PTAP is less then the amount, are you sure you want to bid?</div>
                    {PTAPAlertTrigger && <FormControlLabel className="formLabel" control={<RememberMe handleChecked={handleChecked} size="small" />} label="Dont't Show This Again" />}

                    <button  onClick={() => confirmPTAPBid()}>Place Bid!</button>
                </div>
            </div>

            <div id="forfeitModal" ref={forfeitRef} className="modal">
                <div className="modal-content">
                    <img onClick={() => { forfeitRef.current.style.display = "none"; }} className="close" src={planEndClose} alt="icon" />
                    <div className="heading">Are you sure for forgo?</div>
                    <div className="info">Type FORGO To Confirm.</div>
                    <input defaultValue={forfeitText} ref={forfeitTextRef} type="text" id="inputForfeit" />
                    <p className="description">
                        NOTE: This action can't be redone and you can't able to bid in this particular auction the bid bar will be locked.
                    </p>
                    <button  onClick={() => forfeitBid()}>Confirm</button>
                </div>
            </div>
            
            <div ref={planEndModal} className="modal">
                <div className="modal-content planEndModal">
                    <img onClick={() => { planEndModal.current.style.display = "none"; }} id="planEndClose" className="close" src={planEndClose} alt="icon" />
                    <div className="heading">
                        Auction Ended
                    </div>
                    {upcomingAuctionDate && <p className="detail">Next bid on {moment(upcomingAuctionDate).format("DD/MM/YYYY HH:mm:ss")}</p>}
                    <div className="values" style={{marginTop: !upcomingAuctionDate ? '25px' : '0px'}}>
                        <span className="title">Total amount taken by {liveBiddingState.auctionWinner && liveBiddingState.auctionWinner.winner && liveBiddingState.auctionWinner.winner.first_name}</span>
                        <span className="value">${liveBiddingState.auctionWinner && liveBiddingState.auctionWinner.total_amount_received && liveBiddingState.auctionWinner.total_amount_received}</span>
                        <span className="title">Surplus</span>
                        <span className="value">${liveBiddingState.auctionWinner && liveBiddingState.auctionWinner.shared_surplus && liveBiddingState.auctionWinner.shared_surplus}</span>
                        <span className="title">Total Amount Paid</span>
                        <span className="value">${liveBiddingState.auctionWinner && liveBiddingState.auctionWinner.amount_contributed && liveBiddingState.auctionWinner.amount_contributed}</span>
                    </div>
                    <button onClick={() => { planEndModal.current.style.display = "none";}}>View More</button>
                </div>
            </div>

            <div ref={lastAuctionEndModal} className="modal">
                <div className="modal-content planEndModal">
                    <img onClick={() => { lastAuctionEndModal.current.style.display = "none";}} id="planEndClose" className="close" src={planEndClose} alt="icon" />
                    <div className="heading">
                        Auction Ended
                    </div>
                    <div className="values" style={{marginTop: !upcomingAuctionDate ? '25px' : '0px'}}>
                        {lastAuctionEnd.winner && <span className="title">Total amount taken by {lastAuctionEnd.winner.first_name}</span>}
                        <span className="value">${lastAuctionEnd.total_amount_received}</span>
                        <span className="title">Surplus</span>
                        <span className="value">${lastAuctionEnd.shared_surplus}</span>
                        <span className="title">Total Amount Paid</span>
                        <span className="value">${lastAuctionEnd.amount_contributed}</span>
                    </div>
                    <button onClick={() => { lastAuctionEndModal.current.style.display = "none";}}>View More</button>
                </div>
            </div>

            <div id="userInfoModal" ref={userInfoModal} className="modal">
                <div className="modal-content userInfoContent">
                    <span className="userIcon"> 
                        <img src={userIconWhite} alt="icon" />
                    </span>
                    <div className="name">{userInfoModalName}</div>
                    <div className="buttons">
                        <button className="viewProfile" onClick={() => openProfile()}>View Profile</button>
                        { localStorage.getItem("userId") !== currentProfile.id && !isContact && 
                            <div className='status' onClick={() => addFriend()}>
                                Add now
                            </div>}
                    </div>  
                </div>
            </div>

            <div ref={sameBidderCantForgo} className="modal">
                <div className="modal-content forgoEnabledAlert">
                    <img onClick={() => sameBidderCantForgo.current.style.display = "none"} className="close" src={planEndClose} alt="icon" />
                    <div className="name">ALERT!</div>
                    <div className='description'>You are the current bidder and can't forgo as of now.</div>
                </div>
            </div>
            <div ref={lockedModal} className="modal">
                <div className="modal-content forgoEnabledAlert">
                    <img onClick={() => lockedModal.current.style.display = "none"} className="close" src={planEndClose} alt="icon" />
                    <div className="name">ALERT!</div>
                    <div className='description'>You have already taken the auction amount. So you can't bid now.</div>
                </div>
            </div>
            <div ref={cantBidAfterForwent} className="modal">
                <div className="modal-content forgoEnabledAlert">
                    <img onClick={() => cantBidAfterForwent.current.style.display = "none"} className="close" src={planEndClose} alt="icon" />
                    <div className="name">ALERT!</div>
                    <div className='description'>You have already forwent. So you can't bid now.</div>
                </div>
            </div>
            <div ref={cantBid400} className="modal">
                <div className="modal-content forgoEnabledAlert">
                    <img onClick={() => clearError400()} className="close" src={planEndClose} alt="icon" />
                    <div className="name">ALERT!</div>
                    <div className='description'>{errorFromServer}</div>
                </div>
            </div>
        </div>
    )
}

export default Bidding