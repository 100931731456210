import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import gridThreeImg from "../../assets/images/landingPage/security/Group 1361 (1).png";
import gridTwoImg from "../../assets/images/landingPage/security/Group 1361 (2).png";
import gridOneImg from "../../assets/images/landingPage/security/Group 1361.png";
import icon from "../../assets/images/landingPage/security/Group 1315.png";
import icon2 from "../../assets/images/landingPage/security/Group 1316.png";
import one from "../../assets/images/landingPage/security/one.svg";
import three from "../../assets/images/landingPage/security/tthree.svg";
import two from "../../assets/images/landingPage/security/two.svg";
import style from "../../styles/landingPage/security.module.scss";
const Security = () => {
  return (
    <div className={style["security-main"]}>
      <Box>
        <Typography
          className={style["resp-security-t1"]}
          >
          your security is{" "}
          <Box component={"span"} color="#fff">
            our responsibility
          </Box>
        </Typography>
        <Grid
          container
          className={style["resp-security-main-grid"]}
          >
          <Grid
            item
            xs={12}
            lg={4}
            md={12}
            className={style["resp-security-item1"]}
            >
            <Box
              position={"relative"}
              zIndex={1}
              className={style["resp-security-box-item"]}>
              <Box textAlign={"center"}>
                <img src={gridOneImg} />
                <Typography
                  style={{fontSize: '32px', color: "#fff", textTransform: "capitalize", textAlign: "center", fontWeight: "600", lineHeight: "37px"}}
                 >
                  We take data privacy seriously!
                </Typography>
                <Typography
                  style={{
                    color: "#fff", marginTop: "10px", textTransform: "capitalize", textAlign: "center", fontSize: "16px"
                  }}>
                  Wheez uses secure processes to protect your information and
                  help prevent unauthorized use.{" "}
                </Typography>
              </Box>
              <Box
                position={"absolute"}
                width={"100%"}
                top={"-48px"}
                left={"0px"}
                zIndex={-1}>
                <img
                  src={one}
                  width={"100%"}
                  height={"100%"}
                  style={{ objectFit: "cover" }}
                />
              </Box>
            </Box>
            <Box
              position={"absolute"}
              className={style["resp-security-img-item1"]}
              zIndex={4}>
              <img src={icon} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            md={12}
            className={style["resp-security-item2"]}
            >
            <Box
              overflow="hidden"
              position={"relative"}
              zIndex={1}
              className={style["resp-security-box-item"]}
              >
              <Box textAlign={"center"}>
                <img src={gridThreeImg} />
                <Typography
                  style={{fontSize: '32px', color: "#fff", textTransform: "capitalize", textAlign: "center", fontWeight: "600", lineHeight: "37px"}}
                >
                  We work hard to keep your data safe & secure!
                </Typography>
                <Typography
                  style={{
                    color: "#fff", marginTop: "20px", textTransform: "capitalize", textAlign: "center", fontSize: "16px"
                  }}>
                  Wheez provides multi-factor authentication on all accounts.
                </Typography>
              </Box>
              <Box
                style={{position: "absolute", top:'0px', zIndex: -1, width: '100%', left: '0px'}}
               >
                <img
                  src={two}
                  width={"100%"}
                  style={{ objectFit: "cover" }}
                />
              </Box>
            </Box>
            <Box
                className={style["resp-security-img-item2"]}
                zIndex={4}
              >
              <img src={icon2} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            md={12}
            className={style["resp-security-item3"]}
          >
            <Box textAlign={"center"}  className={style["resp-security-box-item1"]} >
              <img src={gridTwoImg} />
              <Typography
                 style={{fontSize: '32px', color: "#fff", textTransform: "capitalize", textAlign: "center", fontWeight: "600", lineHeight: "37px"}}
              >
                Anytime, anywhere support!
              </Typography>
              <Typography
                 style={{
                  color: "#fff", marginTop: "10px", textTransform: "capitalize", textAlign: "center", fontSize: "16px"
                }}>
                If you need help, Wheez’s support channels are standing by 24/7.
                Reach our friendly team by phone, email, or in the app, or check
                out the Help Center.
              </Typography>
            </Box>
            <Box
              position={"absolute"}
              top={"0px"}
              zIndex={0}
              height={"100%"}
              width={"100%"}
              left={"-5px"}>
              <img
                src={three}
                width={"100%"}
                height={"100%"}
                style={{ objectFit: "cover" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Security;
