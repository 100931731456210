import React, {useState, useEffect} from 'react'
import moment from 'moment'
import { useSelector } from "react-redux";
import iconBlack from './../assets/images/icons/userIconBlack.svg';
import iconWhite from './../assets/images/icons/userIconWhite.svg';
const BASE_URL = `http://${process.env.REACT_APP_WHEEZ_SERVER_URL}`;

export default function ChatMessage(props) {
    const contactsState = useSelector((state) => state.contacts);
    const [avatar, setAvatar] = useState(null)
    const { userAvatars } = contactsState;
    useEffect(() => {
        if(props.data && props.data.user && props.data.user.avatar){
            const userAvatar = userAvatars.filter(u => u.id === props.data.user.avatar)
            if(userAvatar.length > 0){
                setAvatar(userAvatar[0])
            }
        }
    },[props.data, userAvatars])
    return (
        <div onClick={() => props.openOptions(props.data)} className={props.data.user.id !== localStorage.userId ? "chatThread" : "chatThread chatThreadRight"}>
            <div className='firstRow'>
                <span className='userIcon'>
                    {props.data.user.id !== localStorage.userId && (
                        avatar ? <img className='avatar' src={`${BASE_URL}${avatar.image}`} alt="icon" /> 
                        : ( props.data.user && props.data.user.profile_image ? 
                            <img className='avatar' src={`${props.data.user.profile_image}`} alt="icon" /> 
                            :
                            <img src={iconBlack} alt="icon" /> 
                            )
                    )}
                    {props.data.user.id === localStorage.userId && (
                        avatar ? <img className='avatar' src={`${BASE_URL}${avatar.image}`} alt="icon" /> 
                        : ( props.data.user && props.data.user.profile_image ? 
                            <img className='avatar' src={`${props.data.user.profile_image}`} alt="icon" /> 
                            :
                            <img src={iconWhite} alt="icon" /> 
                            )
                    )}
                </span>
                <span className='name'>{props.data.user.id !== localStorage.userId ? `${props.data.user.first_name} ${props.data.user.last_name}` : 'You'}</span>
                <span className='time'>{moment(props.data.created_at).format('HH:mm')}</span>
            </div>
            {props.data.message_replied_to && <div className="repliedTo">
                <div className='box'>
                    <div className='rectBlue'></div>
                    <div className='column'>
                        <span className='to'>{props.data.message_replied_to.user.id !== localStorage.userId ? props.data.message_replied_to.user.first_name : 'You'}</span>
                        <span className='message'>{props.data.message_replied_to.message}</span>
                    </div>
                </div>
            </div>}
            <p className='message'>{props.data.message}</p>
        </div>
    )
}