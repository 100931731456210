import { isMobile } from "react-device-detect";
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import * as React from "react";
import header1 from "../../assets/images/landingPage/header-1.svg";
import header2 from "../../assets/images/landingPage/header-2.svg";
import header3 from "../../assets/images/landingPage/header-3.svg";
import header4 from "../../assets/images/landingPage/header-4.svg";
import header5 from "../../assets/images/landingPage/header-5.svg";
import logo from "../../assets/images/landingPage/White-modified-PNG.png";
import style from "../../styles/landingPage/header.module.scss";
const drawerWidth = 240;

const navItems = [
  { img: header5, label: "Home", id: '#home' },
  { img: header4, label: "How It Work", id: '#how-it-work'},
  { img: header3, label: "Benefits", id: '#benefits' },
  { img: header2, label: "Learn Page", id: "#" },
  { img: header1, label: "FAQs", id: "#" },
];
const Header = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}  >
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon sx={{ textAlign: "center" }}>
              <img src={item?.img} />
              &nbsp;&nbsp;
              <ListItemText primary={item?.label} />
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={style["main-box"]}>
      <AppBar style={{backgroundColor: '#090909', width: "calc(100% - 10px)", left: 0}} >
        <Toolbar style={{ justifyContent: "space-between", alignItems: "center" }}>
          {isMobile && <IconButton
            color="inherit"
            aria-label="menu"
            edge="start"
            onClick={handleDrawerToggle}
            >
            <MenuIcon />
          </IconButton>}
          <Typography variant="h6" component="div" id="resp-logo">
            <img src={logo} />
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "none", md: "flex", }, flex: '1',  justifyContent: 'center' }}>
            {navItems.map((item, index) => (
              <a href={item.id} key={index} style={{textDecoration: 'none'}}>
                <Button key={index} style={{color: "white", margin: "0 10px"}}>
                  <img src={item?.img} />
                  &nbsp;&nbsp;
                  {item?.label}
                </Button>
              </a>
            ))}
          </Box>
          <Box>
            <Box
              sx={{
                borderRadius: "15px",
                background:
                  "linear-gradient(to right bottom, #6D61C2, #534998)",
                padding: "2px 2px",
                display: "inline-block",
              }}>
              <Box>
                  <Button 
                    onClick={() => props.getStarted()}
                    id="resp-getStarted">Get Started
                  </Button>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav" >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          // className="drawer-paper"
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            }
          }}
          >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
};
Header.propTypes = {
  window: PropTypes.func,
};

export default Header;
