import React, {useRef, useState} from 'react'
import { useDispatch } from "react-redux";
import Rating from '@material-ui/lab/Rating';
import { 
    sendFeedback,
} 
from './../redux/actions/planActions';
import ratingIcon from './../assets/images/icons/ratingIcon.svg';
import emptyRating from './../assets/images/icons/emptyRating.svg';
import './../styles/feedback.scss';


export default function Feedback() {
    const dispatch = useDispatch();
    const descriptionRef = useRef();
    const [ratingValue,setRatingValue] = useState(1);
    const handleSubmit = () => {
        let userData = {
            "rating": ratingValue,
            "message": descriptionRef.current.value,
        }
        dispatch(sendFeedback(userData))
    }
    return (
        <div className="feedbackContent">
            <div className="header">
                <div>Feedback</div>
            </div>
            <div className="content">
                <div className='question'>How was your auction experience?</div>
                <Rating
                    name="simple-controlled"
                    className="ratingClass"
                    icon={<img src={ratingIcon} alt="icon" />}
                    emptyIcon={<img src={emptyRating} alt="icon" />}
                    value={ratingValue}
                    onChange={(event, newValue) => {
                        setRatingValue(newValue);
                    }}
                />
                <div className='question'>Share your experience</div>
                <textarea className='textarea' ref={descriptionRef} />
                <button onClick={ () => handleSubmit()}>Submit Now</button>
            </div>
        </div>
    )
}
