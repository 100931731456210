import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { 
    viewNotificationAction
} 
from '../redux/actions/notificationAction';
import planEndClose from './../assets/images/icons/closeCircle.svg';
import walletNotification from './../assets/images/icons/walletNotification.svg';
import './../styles/planNotification.scss'

export default function PlanApplicationNotification(props) {
    const dispatch = useDispatch()
    const history = useHistory()

    const notificationsState = useSelector((state) => state.notifications);
    const [notificationCurrent, setNotificationCurrent] = useState(null)
    const { 
        notificationList
    } = notificationsState;
    const forfeitRef = useRef()
    useEffect(() => {
        forfeitRef.current.style.display = "none"
    },[])

    useEffect(() => {
        let count = 0;
        if(notificationList.length > 0){
            notificationList.map(n => {
                if(n.type === "PLAN_APPLICATION_SUBMITTED" && !n.wn_read){
                    count++;
                    forfeitRef.current.style.display = "block"
                    setNotificationCurrent(n)
                }
                return null
            })
        }
        if(count === 0){
            forfeitRef.current.style.display = "none"
            setNotificationCurrent(null)
        }
    },[notificationList])
      
    const handleClose = () => {
        forfeitRef.current.style.display = "none"
        if(notificationCurrent){
            dispatch(viewNotificationAction(notificationCurrent.id))
            history.push('/dashboard')
        }
    }
    const redirectRec = () => {
        handleClose()
        history.push('/plans/2')
    }
    return (
        <div id="notification" ref={forfeitRef} className="modal">
            <div className="modal-content">
                <span className='headerIcon'>
                    <img src={walletNotification} alt="icon" />
                </span>
                <div className='closeFlex'>
                    <img onClick={() => handleClose()} className="close" src={planEndClose} alt="icon" />
                </div>
                <div className="title">
                    <span>Congratulations!</span>
                </div>
                <div className='info'>Plan applied successfully</div>
                <p className="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum Lorem ipsum dolor sit amet.
                </p>
                <div className='buttonFlex'>
                    <span onClick={() => redirectRec()}>Yay!</span>
                </div>
            </div>
        </div>
    )
}