import axios from 'axios';
import { 
    GET_BALANCE,
    LOADING_UI,
    CLEAR_ERRORS,
    GET_ALL_TRANSACTIONS,
    ADD_TO_TRANSACTIONS,
    DUE_BALANCE,
    SHOULD_FETCH_MORE_TRANSACTIONS
} from '../types';


export const getBalance = () => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.get(`/api/users/balance/`, {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            dispatch({type: CLEAR_ERRORS});
            dispatch({
                type: GET_BALANCE,
                payload: res.data.balance
            })
            dispatch({
                type: DUE_BALANCE,
                payload: res.data.due_balance
            })
        }
    })
    .catch((err)=>{
        dispatch({type: CLEAR_ERRORS});
        console.log("unable to fetch")
    })
}

function compare( a, b ) {
    if ( a.created_at > b.created_at ){
      return -1;
    }
    if ( a.created_at < b.created_at ){
      return 1;
    }
    return 0;
}

export const getAllTransactions = () => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.get('/api/wallet/transactions/?page=1', {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            dispatch({type: CLEAR_ERRORS});
            let results = res.data.results;
            results.sort(compare);
            res.data.results = results;
            if(res.data.next){
                dispatch({
                    type: SHOULD_FETCH_MORE_TRANSACTIONS,
                    payload: true
                })
            } else {
                dispatch({
                    type: SHOULD_FETCH_MORE_TRANSACTIONS,
                    payload: false
                })
            }
            dispatch({
                type: GET_ALL_TRANSACTIONS,
                payload: res.data.results
            })
        }
    })
    .catch((err)=>{
        console.log("unable to fetch")
    })
}

export const getMoreTransactions = (id) => (dispatch) => {
    return axios.get(`/api/wallet/transactions/?page=${id}`, {})
    .then((res)=>{
        if(res && res.status && res.status === 200) {
            let results = res.data.results;
            results.sort(compare);
            res.data.results = results;
            if(res.data.next){
                dispatch({
                    type: SHOULD_FETCH_MORE_TRANSACTIONS,
                    payload: true
                })
            } else {
                dispatch({
                    type: SHOULD_FETCH_MORE_TRANSACTIONS,
                    payload: false
                })
            }
            dispatch({
                type: ADD_TO_TRANSACTIONS,
                payload: res.data.results
            })
        }
    })
    .catch((err)=>{
        console.log("unable to fetch")
    })
}

export const handleRevalidate = () => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.patch(`/api/users/revalidate-limit/`, {})
    .then((res)=>{
        dispatch({type: CLEAR_ERRORS});
        if(res && res.status && res.status === 200) {
            return "success"
        } else {
            return "fail"
        }
    })
    .catch((err)=>{
        dispatch({type: CLEAR_ERRORS});
        return "fail"
    })
}

export const getLimitFromServer = () => (dispatch) => {
    dispatch({type: LOADING_UI});
    return axios.patch(`/api/users/apply-mcl/`, {})
    .then((res)=>{
        dispatch({type: CLEAR_ERRORS});
        if(res && res.status && res.status === 200) {
            return "success"
        } else {
            return "fail"
        }
    })
    .catch((err)=>{
        dispatch({type: CLEAR_ERRORS});
        return "fail"
    })
}

