import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link  } from 'react-router-dom';
import Loading from '../components/Loading';
import { connect } from 'react-redux';
import { 
    signupUser, 
    requestOTP, 
    clearOTPStatus, 
    verifyOTPSignup, 
    resendOTP, 
    basicSignUp 
} from '../redux/actions/userActions';
import backIcon from './../assets/images/icons/backIcon.svg';
import createSuccess from './../assets/images/icons/createSuccess.svg';
import invisibleIcon from './../assets/images/icons/invisibleIcon.svg';
import visibleIcon from './../assets/images/icons/visibleIcon.svg';
// import IntlTelInput from 'react-intl-tel-input';
// import OTPVerify from '../components/OTPVerify';
// import CountrySelector from './../components/CountrySelector';
// import 'react-intl-tel-input/dist/main.css';
import './../styles/signup.scss';
class signup extends Component {
    constructor(props) {
        super(props);
        this.firstNameRef = React.createRef();
        this.lastNameRef = React.createRef();
        this.emailRef = React.createRef();
        this.passwordRef = React.createRef();
        this.confirmPasswordRef = React.createRef();
        // this.firstNameError = React.createRef();
        this.lastNameError = React.createRef();
        this.emailError = React.createRef();
        this.upperCaseCheck = React.createRef();
        this.numberCheck = React.createRef();
        this.countCheck = React.createRef();
        this.confirmPasswordError = React.createRef();
        this.passwordPattern = React.createRef();
        // this.dobRef = React.createRef();
        // this.mobileCharacters = React.createRef();
        this.visibleOFF = React.createRef();
        this.visibleON = React.createRef();
        this.confirmVisibleOFF = React.createRef();
        this.confirmVisibleON = React.createRef();
        // this.otpRef = React.createRef();
        // this.dobError = React.createRef();
        // this.countryError = React.createRef();
        // this.countryAcceptanceError = React.createRef();
        // this.phoneNumberError = React.createRef();
        // this.OTPError = React.createRef();

        this.state = {
            lengthPassword: 0,
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            firstNameError: false,
            lastNameError: false,
            emailError: false,
            serverError: '',
            signupSuccess: false
            // stepId: 1,
            // verifyOTPModal: false,
            // otp: '',
            // dob: '',
            // country: '',
            // phoneNumber:'',
            // serverEmailError: 'Enter a valid email id',
            // otpStatus: ''
        };
    }
    componentDidMount(){
        if(this.props && this.props.user && this.props.user.authenticated){
            this.props.history.push("/dashboard")
        }
    }
    // componentWillUnmount(){
    //     this.mobileCharacters = null;
    // }
    togglePasswordVisibility = () => {
        if (this.passwordRef.current.type === "password") {
            this.visibleOFF.current.style.display = "block";
            this.visibleON.current.style.display = "none";
            this.passwordRef.current.type = "text";
        } else {
            this.visibleOFF.current.style.display = "none";
            this.visibleON.current.style.display = "block";
            this.passwordRef.current.type = "password";
        }
    }
    toggleConfirmPasswordVisibility = () => {
        if (this.confirmPasswordRef.current.type === "password") {
            this.confirmVisibleOFF.current.style.display = "block";
            this.confirmVisibleON.current.style.display = "none";
            this.confirmPasswordRef.current.type = "text";
        } else {
            this.confirmVisibleOFF.current.style.display = "none";
            this.confirmVisibleON.current.style.display = "block";
            this.confirmPasswordRef.current.type = "password";
        }
    }
    // comeBackFromOTP = () => {
    //     this.setState({stepId: 3, verifyOTPModal: false});
    // }
    // slideToNext = (step) => {
    //     switch(step){
    //         case 1:
    //             this.setState({
    //                 stepId: step,
    //                 email: this.emailRef.current.value,
    //                 password: this.passwordRef.current.value,
    //                 confirm_password: this.confirmPasswordRef.current.value,
    //             });        
    //             break;
    //         case 2:
    //             var ck_name = /^[a-z ,.'-]+$/i;
    //             var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    //             let firstNameError = true;
    //             let lastNameError = true;
    //             let dobError = true;
    //             let countryError = true;
    //             let countryAcceptanceError = true;
    
    
    //             this.setState({
    //                 firstName: this.firstNameRef.current.value,
    //                 lastName: this.lastNameRef.current.value,
    //                 dob: this.dobRef.current.value
    //             });
    //             if (!ck_name.test(this.firstNameRef.current.value)) {
    //                 this.firstNameError.current.style.display = "flex";
    //                 firstNameError = true;
    //             } else {
    //                 this.firstNameError.current.style.display = "none";
    //                 firstNameError = false;
    //             }
    //             if (!ck_name.test(this.lastNameRef.current.value)) {
    //                 this.lastNameError.current.style.display = "flex";
    //                 lastNameError = true;
    //             } else {
    //                 this.lastNameError.current.style.display = "none";
    //                 lastNameError = false;
    //             }
    //             if(date_regex.test(this.dobRef.current.value)){
    //                 this.dobError.current.style.display = "none";
    //                 dobError = false;
    //             } else {
    //                 this.dobError.current.style.display = "flex";
    //                 dobError = true;
    //             }
    //             if(!this.state.country.value){
    //                 this.countryError.current.style.display = "flex";
    //                 countryError = true;
    //             } else {
    //                 this.countryError.current.style.display = "none";
    //                 countryError = false;
    //             }
    //             if(this.state.country.value !== 'US' && this.state.country.value){
    //                 this.countryAcceptanceError.current.style.display = "flex";
    //                 countryAcceptanceError = true;
    //             } else {
    //                 this.countryAcceptanceError.current.style.display = "none";
    //                 countryAcceptanceError = false;
    //             }
    //             if(!(firstNameError || lastNameError || dobError || countryError || countryAcceptanceError)){
    //                 this.setState({
    //                     stepId: step
    //                 });
    //             }             
    //             break;
    //         case 3:
    //             if(this.handleSecondForm()){
    //                 let dateArray=this.state.dob.split('/');
    //                 let date = dateArray[2] + '-' + dateArray[0] + '-' + dateArray[1];
    //                 const newUserData = {
    //                     "email": this.emailRef.current.value,
    //                     "password": this.passwordRef.current.value,
    //                     "confirm_password": this.confirmPasswordRef.current.value,
    //                     "first_name": this.state.firstName,
    //                     "last_name": this.state.lastName,
    //                     "country": this.state.country.value,
    //                     "date_of_birth": date,
    //                     "referral_secret_id": "493c352d-76f6-4ed6-97c8-0f09d7e3607c"
    //                 }
    //                 this.setState({
    //                     email: this.emailRef.current.value,
    //                     password: this.passwordRef.current.value,
    //                     confirmPassword: this.confirmPasswordRef.current.value,
    //                 })
    //                 this.props.signupUser(newUserData).then(() => {
    //                     if(this.props.UI && this.props.UI.errors){
    //                         if(this.props.UI.errors.email){
    //                             this.emailError.current.style.display = "flex";
    //                             this.setState({
    //                                 serverEmailError: 'Email already exists'
    //                             })
    //                         } else {
    //                             this.emailError.current.style.display = "none";
    //                             this.setState({
    //                                 serverEmailError: 'Enter a valid email id'
    //                             });
    //                         }
                           
    //                     } else {
    //                         this.emailError.current.style.display = "none";
    //                         this.setState({
    //                             stepId: step,
    //                             serverEmailError: ''
    //                         });
    //                     }
    //                 })
    //             }
    //             break;
    //         case 4:
    //             const requestOTPParam = {
    //                 "session_secret": this.props.user.registration.session_secret,
    //                 "user_id": this.props.user.registration.user_id,
    //                 "phone_number": `+91${this.state.phoneNumber}`
    //             }
    //             this.props.requestOTP(requestOTPParam).then(() => {
    //                 if(this.props && this.props.user && this.props.user.signUpOtpStatus && this.props.user.signUpOtpStatus === "verified"){
    //                     this.setState({
    //                         verifyOTPModal: true
    //                     });
    //                     this.props.clearOTPStatus();
    //                 }
    //             })
    //             break;
    //         default:
    //     }
    // }
    
    // handleSecondForm = () => {
    //     var ck_email = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    //     var upperCaseEntered = false;
    //     var numberEntered = false;
    //     var passowrdLengthEight = false;
    
    //     if (!ck_email.test(this.emailRef.current.value)) {
    //         this.emailError.current.style.display = "flex";
    //     } else {
    //         this.emailError.current.style.display = "none";
    //     }
    //     for (var i = 0; i < this.passwordRef.current.value.length; i++) {
    //         if(this.isCapital(this.passwordRef.current.value.charAt(i))){
    //             upperCaseEntered = true;
    //         }
    //         if(this.isNumber(this.passwordRef.current.value.charAt(i))){
    //             numberEntered = true;
    //         } 
    //     }
    //     if(this.passwordRef.current.value.length >= 8) {
    //         passowrdLengthEight = true;
    //     }
    //     if(this.confirmPasswordRef.current.value === this.passwordRef.current.value) {
    //         this.confirmPasswordError.current.style.display = "none";
    //     } else {
    //         this.confirmPasswordError.current.style.display = "flex";
    //     }
    //     if(upperCaseEntered && passowrdLengthEight && numberEntered && this.confirmPasswordRef.current.value === this.passwordRef.current.value){
    //         this.passwordPattern.current.style.display = "none";
    //         return true;
    //     } else {
    //         if(upperCaseEntered){
    //             this.upperCaseCheck.current.style.color = "#7f8de8";
    //         } else {
    //             this.upperCaseCheck.current.style.color = "#FA686C"; 
    //         }
    //         if(numberEntered){
    //             this.numberCheck.current.style.color = "#7f8de8";
    //         } else {
    //             this.numberCheck.current.style.color = "#FA686C"; 
    //         }
            
    //         if(passowrdLengthEight){
    //             this.countCheck.current.style.color = "#7f8de8";
    //         } else {
    //             this.countCheck.current.style.color = "#FA686C"; 
    //         }
    //         this.passwordPattern.current.style.display = "flex";
    //         this.setState({
    //             lengthPassword : this.passwordRef.current.value.length
    //         });
    //         return false;
    //     }
    // }
    
    // countrySelect = (country) => {
    //     this.setState({
    //         country: country
    //     });
    // }
    // onPhoneNumberChange = (status,value,number) => {
    //     if(isNaN(value[value.length -1])){
    //         this.mobileCharacters.current.setNumber(value.substring(0, value.length - 1))
    //     }
    //     let renewedNumber = value.split('').filter((character)=>{
    //         if(isNaN(character) || character === ' '){
    //             return false
    //         } else {
    //             return true
    //         }
    //     }).join('');
    //     if(renewedNumber.length > 10) {
    //         this.mobileCharacters.current.setNumber(value.substring(0, value.length - 1));
    //         renewedNumber = renewedNumber.substring(0, renewedNumber.length - 1);
    //     }
    //     this.setState({
    //             phoneNumber: renewedNumber
    //     });
    // }
    // OTPInput = (otp) => {
    //     this.setState({ otp });
    // }
    // resendOTP = () => {
    //     let resendParam = {
    //         "session_secret": this.props.user.registration.session_secret,
    //         "user_id":  this.props.user.registration.user_id
    //     }
    //     this.props.resendOTP(resendParam);
    // }
    // submitOTP = () => {
    //     if(this.props && 
    //         this.props.user &&
    //         this.props.user.registration &&
    //         this.props.user.registration.session_secret &&
    //         this.props.user.registration.user_id
    //          ) {
    
    //         let otpVerifyParam = {
    //             "session_secret": this.props.user.registration.session_secret,
    //             "otp": this.state.otp,
    //             "user_id":  this.props.user.registration.user_id
    //         }
    //         this.props.verifyOTPSignup(otpVerifyParam, this.props.history).then((res) => {
    //             if(res && res.payload){
    //                 this.OTPError.current.style.display = "flex";
    //                 this.setState({
    //                     otpStatus : 'invalid'
    //                 })
    //                 for (let i = 0; i < document.getElementsByClassName("form-control-solid").length; i++) {
    //                     document.getElementsByClassName("form-control-solid")[i].style.backgroundColor = "rgba(250,104,108,0.43)";
    //                 }
    
    //             } else {
    //                 this.setState({
    //                     stepId: 4,
    //                     otpStatus : '',
    //                     verifyOTPModal: false
    //                 });
    //             }
    //         });
    //     }
    // }


    handleChange = (event) => {
        this.setState({
            [event.target.name] : event.target.value
        })
    }  
    isCapital = (char) => {
        return char === char.toUpperCase()
    }
    isNumber = (char) => {
        if (isNaN(char)) {
            return false;
        } else {
            return true;
        }
    }
    isLetter(str) {
        return str.length === 1 && str.match(/[a-z]/i);
    }
    isDataValidated = () => {
        let ck_name = /^[a-z ,.'-]+$/i;
        let ck_email = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        var upperCaseEntered = false;
        var numberEntered = false;
        var passowrdLengthEight = false;

        let firstNameError;
        let lastNameError; 
        let emailError;
        let passwordError;
        let confirmPasswordError;

        if (!ck_name.test(this.firstNameRef.current.value)) {
            this.setState({
                firstNameError: true
            })
            firstNameError = true;
        } else {
            this.setState({
                firstNameError: false
            })
            firstNameError = false;
        }
        if (!ck_name.test(this.lastNameRef.current.value)) {
            this.setState({
                lastNameError: true
            })
            lastNameError = true;
        } else {
            this.setState({
                lastNameError: false
            })
            lastNameError = false;
        }
        
        if (!ck_email.test(this.emailRef.current.value)) {
            this.setState({
                emailError: true
            })
            emailError = true;
        } else {
            this.setState({
                emailError: false
            })
            emailError = false;
        }
        for (var i = 0; i < this.passwordRef.current.value.length; i++) {
            if(this.isCapital(this.passwordRef.current.value.charAt(i)) && this.isLetter(this.passwordRef.current.value.charAt(i))){
                upperCaseEntered = true;
            }
            if(this.isNumber(this.passwordRef.current.value.charAt(i))){
                numberEntered = true;
            } 
        }
        if(this.passwordRef.current.value.length >= 8) {
            passowrdLengthEight = true;
        }

        if(upperCaseEntered && passowrdLengthEight && numberEntered){
            this.setState({
                passwordError: false
            })
            passwordError = false;
        
        } else {
            this.setState({
                passwordError: true
            })
            passwordError = true;
        }
        if(this.confirmPasswordRef.current.value === this.passwordRef.current.value && this.confirmPasswordRef.current.value.length > 0) {
            this.setState({
                confirmPasswordError: false
            })
            confirmPasswordError = false;
        } else {
            this.setState({
                confirmPasswordError: true
            })
            confirmPasswordError = true;
        }
        return !firstNameError && !lastNameError && !emailError && !passwordError && !confirmPasswordError
    }
    createAccount = (event) => {
        event.preventDefault();
        let allGood = this.isDataValidated();
        let userData = {
            "email": this.emailRef.current.value,
            "password": this.passwordRef.current.value,
            "confirm_password": this.confirmPasswordRef.current.value,
            "first_name": this.firstNameRef.current.value,
            "last_name": this.lastNameRef.current.value,
            "referral_secret_id": ""
        }
        if(allGood) {
            this.props.basicSignUp(userData).then(res => {
                if(res && res.message){
                    if(res.message === "success"){
                        this.setState({
                            serverError: "",
                            signupSuccess: true
                        })
                    }
                    if(res.message === "failed"){
                        this.setState({
                            emailError: true,
                            serverError: res.error
                        })
                    }
                }
            })
        }
    }
    render() {
        const { UI : { loading} } = this.props;
        // let otpErrorMessage = '';
        // if((this.props && this.props.UI && this.props.UI.errors && this.props.UI.errors.message && this.props.UI.errors.message.length > 0) && this.props.UI.errors.message[0]){
        //     otpErrorMessage =this.props.UI.errors.message[0];
        // } else if((this.props && this.props.UI && this.props.UI.errors && this.props.UI.errors.otp && this.props.UI.errors.otp.length > 0) && this.props.UI.errors.otp[0]){
        //     otpErrorMessage =this.props.UI.errors.otp[0];
        // }
        return (
            loading ? 
            (<Loading />) :
            (<div className="signupContainer">
            {!this.state.signupSuccess ?                 
                <>
                    <div className='heading'>
                        <Link to="/login"><img src={backIcon} alt="icon" /></Link>
                        <span>Create Account</span>
                    </div>
                    <form className="signIn" onSubmit={this.createAccount}>
                        <div>
                            <label className="input">
                                <input className={`${this.state.firstNameError ? 'input__field errorBorder' : 'input__field'}`} name="firstName" defaultValue={this.state.firstName} onChange={e => this.handleChange(e)} ref={this.firstNameRef} type="text" placeholder="First Name" />
                                <span className="input__label">First Name</span>
                                {this.state.firstNameError && <span className="error">
                                    Enter Valid Name
                                </span>}
                            </label>
                            <label className="input">
                                <input className={`${this.state.lastNameError ? 'input__field errorBorder' : 'input__field'}`} name="lastName" defaultValue={this.state.lastName} onChange={e => this.handleChange(e)} ref={this.lastNameRef} type="text" placeholder='Last Name' />
                                <span className="input__label">Last Name</span>
                                {this.state.lastNameError && <span className="error">
                                    Enter Valid Name
                                </span>}
                            </label>
                            <label className="input">
                                <input className={`${this.state.emailError ? 'input__field errorBorder' : 'input__field'}`} name="email" defaultValue={this.state.email} autoComplete="new-password" onChange={e => this.handleChange(e)} ref={this.emailRef} type="email" placeholder='Email' />
                                <span className="input__label">Email</span>
                                {this.state.emailError && this.state.serverError === "" && <span className="error">
                                    Enter Valid Email
                                </span>}
                                {this.state.emailError && this.state.serverError.length > 0 && <span className="error">
                                    {this.state.serverError}
                                </span>}
                            </label>
                            <label className="input">
                                <input className={`${this.state.passwordError ? 'input__field errorBorder' : 'input__field'}`} name="password" defaultValue={this.state.password} autoComplete="new-password" onChange={e => this.handleChange(e)} ref={this.passwordRef} type="password" placeholder='Password' />
                                <span className="input__label">Password</span>
                                <div className='input__info'>Must be at least 8 characters, 1 Uppercase & 1 Number</div>
                                <img alt="icon" onClick={this.togglePasswordVisibility} ref={this.visibleON} className="visibleIcon" src={visibleIcon} />
                                <img alt="icon" onClick={this.togglePasswordVisibility} className="visibleIcon hide" ref={this.visibleOFF} src={invisibleIcon} />
                                {this.state.passwordError && <span className="error">
                                    Enter Valid Password
                                </span>}
                            </label>
                            <label className="input">
                                <input className={`${this.state.confirmPasswordError ? 'input__field errorBorder' : 'input__field'}`} name="confirmPassword" defaultValue={this.state.confirmPassword} autoComplete="new-password" onChange={e => this.handleChange(e)} ref={this.confirmPasswordRef} type="password" placeholder='Confirm Password' />
                                <span className="input__label">Confirm Password</span>
                                <div className='input__info'>Both passwords must match</div>
                                <img alt="icon" onClick={this.toggleConfirmPasswordVisibility} ref={this.confirmVisibleON} className="visibleIcon" src={visibleIcon} />
                                <img alt="icon" onClick={this.toggleConfirmPasswordVisibility} className="visibleIcon hide" ref={this.confirmVisibleOFF} src={invisibleIcon} />
                                {this.state.confirmPasswordError && <span className="error">
                                    Password does not match
                                </span>}
                            </label>
                            {/* <div className="style-input" style={{marginTop: "33px"}}>
                                <div className="icon">
                                    <img src={profileIcon} alt="icon" />
                                </div>
                                <input type="text" defaultValue={this.state.firstName} ref={this.firstNameRef} placeholder="First Name" />
                            </div>
                            <div className="error" id="firstNameError" ref={this.firstNameError} >
                                <img src={warningIcon} alt="icon"/>
                                <span>Enter valid First Name</span>
                            </div>

                            <div className="style-input" style={{marginTop: "30px"}}>
                                <div className="icon">
                                    <img src={profileIcon} alt="icon" />
                                </div>
                                <input defaultValue={this.state.lastName} type="text" ref={this.lastNameRef} placeholder="Last Name" />
                            </div>
                            <div className="error" id="lastNameError" ref={this.lastNameError} >
                                <img src={warningIcon} alt="icon"/>
                                <span>Enter valid Last Name</span>
                            </div>

                            <div className="style-input" style={{marginTop: "30px"}}>
                                <div className="icon">
                                    <img src={dateOfBirth} alt="icon" />
                                </div>
                                <input defaultValue={this.state.dob} type="text" ref={this.dobRef} placeholder="MM/DD/YYYY" />
                            </div>
                            <div className="error" id="dobError" ref={this.dobError} >
                                <img src={warningIcon} alt="icon"/>
                                <span>Enter valid Date of Birth</span>
                            </div> */}

                            {/* <div className="country-input" style={{marginTop: "30px"}}>
                                <div className="icon">
                                    <img src={location} alt="icon" />
                                </div>
                                <CountrySelector value={this.state.country} countrySelect={this.countrySelect}/>
                            </div> */}

                            <div className="stepButton">
                                <button type="submit">Create Account</button>
                            </div>
                        </div>
                        {/* {this.state.stepId === 2 && <div>
                            <div className="header">Create account</div>
                            <div className="style-input" style={{marginTop: "33px"}}>
                                <div className="icon">
                                    <img src={emailIcon} alt="icon" />
                                </div>
                                <input defaultValue={this.state.email} ref={this.emailRef} id="email" type="email" autoComplete="new-password" placeholder="Email" />
                                <img src={editIcon} className="edit" alt="icon"/>
                            </div>
                            <div className="error" id="emailError" ref={this.emailError} >
                                <img src={warningIcon} alt="icon"/>
                                <span>{this.state.serverEmailError}</span>
                            </div>
                            <div className="style-input" style={{marginTop:"33px"}}>
                                <div className="icon">
                                    <img src={passwordIcon} alt="icon"/>
                                </div>
                                <input  defaultValue={this.state.password} ref={this.passwordRef} id="password" type="password"  autoComplete="new-password" placeholder="Password"/>
                                <img alt="icon" onClick={this.togglePasswordVisibility} id="visibleON" ref={this.visibleON} className="edit" src={visibleIcon} />
                                <img alt="icon" onClick={this.togglePasswordVisibility} id="visibleOFF" ref={this.visibleOFF} className="edit"  src={invisibleIcon} />
                            </div>
                            <div className="passwordPattern" ref={this.passwordPattern} id="passwordPattern">
                                <span className="text">At least&nbsp;
                                    <span ref={this.upperCaseCheck}>1 uppercase letter, </span>
                                    <span ref={this.numberCheck}>1 number</span> 
                                </span>
                                <span ref={this.countCheck} className="count"><span>{this.state.lengthPassword}</span>/8</span>
                            </div>

                            <div className="style-input" style={{marginTop:"33px"}}>
                                <div className="icon">
                                    <img alt="icon" src={passwordIcon} />
                                </div>
                                <input  defaultValue={this.state.confirmPassword} ref={this.confirmPasswordRef} id="confirmPassword" autoComplete="new-password" type="password" placeholder="Confirm Password"></input>
                                <img alt="icon" onClick={this.toggleConfirmPasswordVisibility} id="visibleON" ref={this.confirmVisibleON} className="edit" src={visibleIcon} />
                                <img alt="icon" onClick={this.toggleConfirmPasswordVisibility} id="visibleOFF" ref={this.confirmVisibleOFF} className="edit"  src={invisibleIcon} />
                            </div>
                            <div className="error" id="confirmPasswordError" ref={this.confirmPasswordError}>
                                <img alt="icon" src={warningIcon} />
                                <span>Wrong password combination</span>
                            </div>
                            <div className="stepButton">
                                <button onClick={(e)=> {e.preventDefault(); this.slideToNext(3)}} >Continue</button>
                            </div>
                        </div>}
                        {this.state.stepId === 3 && <div className="addPhoneNumber">
                            <div className="header">Add phone number</div>
                            <div className="image">
                                <img src={phonePad} alt="icon"/>
                            </div>
                            <IntlTelInput
                                ref={this.mobileCharacters}
                                allowDropdown={false}
                                format
                                separateDialCode
                                onPhoneNumberChange = {(status, value, countryData, number, id) => this.onPhoneNumberChange(status,value,number)}
                                containerClassName="intl-tel-input"
                                inputClassName="form-control-mobile-number"
                            />
                            {errors && errors.phone_number && <div className="error1" ref={this.phoneNumberError} >
                                <img alt="icon" src={warningIcon} />
                                <span>{errors.phone_number}</span>
                            </div>}
                            <button onClick={(e)=> {e.preventDefault(); this.slideToNext(4)}}>Send OTP</button>
                        </div>}
                        {this.state.stepId === 4 && <div className="emailVerify">
                        <div className="header">Verify your email</div>
                        <img style={{marginTop: '20px'}} src={emailVerifyIcon} alt="icon" />
                        <div className="text">Almost there!<br></br>Please click on the verification link sent to your email to proceed.</div>
                        <div className="link">Resend email</div>
                        </div>} */}
                    </form>
                    {/* {this.state.verifyOTPModal && 
                        <OTPVerify 
                            otp={this.state.otp}
                            OTPError={this.OTPError}
                            errorMessage={otpErrorMessage}
                            otpStatus={this.state.otpStatus}
                            resendOTP={this.resendOTP}
                            OTPInput={this.OTPInput}
                            submitOTP = {this.submitOTP}
                            comeBackFromOTP={this.comeBackFromOTP}
                        />} */}
                    <div className="createAccounSection">
                        <Link to="/login"><span className="text-link">Back to sign in</span></Link>
                    </div>
                </> :
                <div className="createSuccess">
                    <img src={createSuccess} alt="icon" />
                    <div className='heading'>
                        Congratulation!
                    </div>
                    <div className='description'>
                        You have successfully created your account
                    </div>
                    <Link to="/login"><button>Sign In</button></Link>
                </div>}
            </div>)
        )
    }
}

signup.propTypes = {
    signupUser: PropTypes.func.isRequired,
    requestOTP: PropTypes.func.isRequired,
    clearOTPStatus: PropTypes.func.isRequired,
    verifyOTPSignup: PropTypes.func.isRequired,
    basicSignUp: PropTypes.func.isRequired,
    resendOTP: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired
}
const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI
});
const mapActionsToProps = {
    signupUser,
    basicSignUp,
    requestOTP,
    clearOTPStatus,
    verifyOTPSignup,
    resendOTP
}
export default connect(mapStateToProps, mapActionsToProps)(signup);