import React, { } from 'react'
import closeIcon from './../../assets/images/icons/closeCircle.svg';
import './../../styles/guidedModal.scss';

function RulesModal(props) {
    return (
        <div className="modal introModal">
            <div className="modal-content">
                <div className="header">
                    <div>Plan Introduction</div>
                    <img onClick={() => props.closeRules()} src={closeIcon} alt="icon" />
                </div>
                <div className="content">
                    <p className="description">
                        Practice bidding is designed to help you understand how bidding works in real time. This template will be similar to the live bid template, so it’ll be easy for you to place bids in real time.
                        <br></br>
                        <br></br>
                        Explore every feature of bidding one by one, or tap on a feature to see what it does. Furthermore, there are tasks which you can complete to get a better understanding to use the buttons, keys, metrics to watch, etc. while bidding.
                        <br></br>
                        <br></br>
                        Even though it is the same as the real bidding, some features and elements will only be available in the real bidding like chat room. Also the bid timing is 12 hours
                        (8 - 8) in the real bidding, but here the length of bid has been reduced for practice purposes. So, please keep that in mind and feel free to explore it.
                    </p>
                </div>
                <button onClick={() => props.closeRules()}>Continue</button>
            </div>
        </div>
    )
}

export default RulesModal