import React from 'react'
import moment from 'moment'
import iconBlack from './../assets/images/icons/userIconBlack.svg';
export default function AdminChatMessage(props) {
    return (
        <div className='adminMessage' onClick={() => props.openOptions(props.data)}>
            <div className='header'>
                <span className='userIcon'>
                     <img src={iconBlack} alt="icon" />
                </span>
                <span className='name'>{props.data.user.first_name} from Wheez</span>
                <span className='time'>{moment(props.data.created_at).format('HH:mm')}</span>
            </div>
            <p className='message'>{props.data.message}</p>
        </div>
    )
}