import React, { useEffect, useState }  from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import { useHistory } from 'react-router-dom';
import Loading from '../components/Loading';
import { 
    deleteNotification,
    viewNotificationAction,
    readAllNotification
} 
from '../redux/actions/notificationAction';
import{ handleIncomingRequest } from '../redux/actions/contactActions';
import backIcon from './../assets/images/icons/backIcon.svg';
import divider from './../assets/images/icons/divider.svg';
import filterList from './../assets/images/icons/filterListIcon.svg';
import ellipsisHorizontal from './../assets/images/icons/ellipsisHorizontal.svg';

import './../styles/notifications.scss';

const Notifications = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [isListOpen, setIsListOpen] = useState(false)
    const [isMarkAll, setIsMarkAll] = useState(false)
    const [currentNotificationId, setCurrentNotificationId] = useState(0)
    const UI = useSelector((state) => state.UI);
    const notificationsState = useSelector((state) => state.notifications);
    const userState = useSelector((state) => state.user);

    const { 
        totalNotifications,
        notificationList
    } = notificationsState;

    useEffect(() => {
        if(UI.loading){
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [UI.loading]);
    useEffect(() => {
        setTimeout(() => {
            if(isListOpen){
                window.addEventListener('click', closeDropDown)
            }
            else{
                window.removeEventListener('click', closeDropDown)
            }
        }, 0);
    }, [isListOpen]);
    useEffect(() => {
        if(isMarkAll){
            window.addEventListener('click', closeMarkAll)
        }
        return () => {
            window.removeEventListener('click', closeMarkAll)
        }
    }, [isMarkAll]);
    const closeDropDown = () => {
        setIsListOpen(false)
    }
    const closeMarkAll = () => {
        setIsMarkAll(false)
    }

    const toggleList = (event, id) => {
        event.stopPropagation();
        setCurrentNotificationId(id)
        setIsListOpen(!isListOpen);
    }
    const toggleMarkAll = () => {
        setIsMarkAll(!isMarkAll);
    }
    const deleteNot = (event, notification) => {
        event.stopPropagation();
        dispatch(deleteNotification(notification))
    }
    const acceptRequest = (event, notification) => {
        let userData = {
            "contact_request_id": notification.contact_request.id,
            "action": "accept"
        } 
        dispatch(handleIncomingRequest(userData));
        deleteNot(event, notification)
    }
    const viewNotification = (notification) => {
        dispatch(viewNotificationAction(notification.id)).then(() => {
            if(notification.type === "PLAN_RECOMMENDATION"){
                history.push("/plans/2")
            } else if(notification.type === "CONTACT_JOINS_PLAN" || notification.type === "PERSON_JOINS_PLAN") {
                history.push("/plans/1")
            } else if(notification.type === "DEPOSIT_SUCCESSFUL" || notification.type === "DEPOSIT_UNSUCCESSFUL"){
                history.push("/wallet/1")
            } else if(notification.type === "WINNING_AMOUNT_ADDED" || notification.type === "SURPLUS_AMOUNT_ADDED") {
                history.push("/wallet/2")
            } else if(notification.type === "AUCTION_ABOUT_TO_START" || notification.type === "AUCTION_STARTS" || notification.type === "AUCTION_ABOUT_TO_END"  || notification.type === "AUCTION_ENDS"){
                let planId = notification.auction.plan.id
                history.push(`/bidding/${planId}`)
            } else if(notification.type === "CONTACT_REQUEST"){
                history.push("/friends/1")
            } else if(notification.type === "FIRST_TIME_LOGIN"){
                history.push("/dashboard")
            } else if(notification.type === "CONTACT_REMOVED"){
                history.push("/friends/1")
            } else if(notification.type === "PLAN_SCHEDULED"){
                history.push("/plans/1")
            }
        })
    }
    const markRead = (notification) => {
        dispatch(viewNotificationAction(notification.id));
    }
    const markAllAsRead = () => {
        let unreadListIds = [];
        notificationList.map((notification) => {
            if(!notification.wn_read){
                unreadListIds.push({
                    "id": notification.id
                })
            }
            return null
        })
        if(unreadListIds.length > 0){
            dispatch(readAllNotification(unreadListIds));
        }
    }
    return (
        <div className="notifications">
            {loading ? (<Loading /> ) : 
                <section className="unread">
                    <span onClick={() => history.goBack()} className="backIcon">
                        <img src={backIcon} alt="icon" />
                    </span>
                    <span className="filterList" onClick={() => toggleMarkAll()}>
                        <div className='dropdown'>
                            <img src={filterList} alt="icon" />
                            {isMarkAll && (
                                <div role="list" className="dropdown-content">
                                    <div onClick={()=> markAllAsRead()}>Mark All As Read</div>
                                </div>
                            )}
                        </div>
                    </span>
                    <div className="heading">Notifications</div>
                    {totalNotifications === 0 && 
                        <div className="noResult">
                            No new notifications
                        </div>
                    }
                    {notificationList && notificationList.length > 0 && notificationList.map((notification) => {
                        if(notification.type === "APPLIED_FOR_MCL"){
                            return null
                        }
                        if(notification.type === "PLAN_APPLICATION_SUBMITTED"){
                            return null
                        }
                        return <div 
                            // onClick={()=> viewNotification(notification)} 
                            key={notification.id} 
                            className={notification.wn_read ? "threadContainer read" : "threadContainer"} >
                            <div className='fixedMenu'>
                                <div className="dropdown">
                                    <div 
                                        onClick={(event) => toggleList(event, notification.id)}
                                    >
                                        <img src={ellipsisHorizontal} alt="icon"/>
                                    </div>
                                    {isListOpen && currentNotificationId === notification.id && (
                                        <div role="list" className="dropdown-content">
                                            <div onClick={()=> markRead(notification)}>Mark As Read</div>
                                            <div onClick={(event)=> deleteNot(event, notification)}>Delete</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flexRow">
                                {notification.type === "CONTACT_REQUEST" ? 
                                    <div className="profileDummy">
                                        <span className="newIndicator"></span>
                                    </div> :
                                    <div className="userDummy">
                                        <span className="newIndicator"></span>
                                    </div>
                                }
                                <div className="flexCol">
                                    {notification.type === "PLAN_RECOMMENDATION" && <p>YAY you’ve got plan recommendations. Go take action now!</p>}
                                    {(
                                        notification.type === "AUCTION_ABOUT_TO_START"
                                        || notification.type === "AUCTION_STARTS"
                                        || notification.type === "AUCTION_ABOUT_TO_END"
                                        || notification.type === "AUCTION_ENDS"
                                        || notification.type === "CONTACT_JOINS_PLAN"
                                        ) && <p>{notification.title}</p>}
                                    {notification.type === "FIRST_TIME_LOGIN" && <p>Hello {userState.userProfile.first_name}, Welcome to Wheez.<br/> Click here to learn more about Wheez</p>}
                                    {notification.type === "CONTACT_REMOVED" && <p>{notification.other_user.first_name} has removed you from their friends list</p>}
                                    {notification.type === "PLAN_SCHEDULED" && <p>Your {notification.plan.title} is scheduled. Check it out!</p>}
                                    {notification.type === "PERSON_JOINS_PLAN" && <p>{notification.other_user.first_name} has joined {notification.plan.title}</p>}
                                    {notification.type === "WINNING_AMOUNT_ADDED" && <p>You have won the auction with amount ${notification.auction_return.transaction.amount}</p>}
                                    {notification.type === "SURPLUS_AMOUNT_ADDED" && <p>{userState.userProfile.first_name}, Your {notification.auction.title} surplus for {notification.auction.plan.title} is added to your wallet.</p>}
                                    {notification.type === "DEPOSIT_SUCCESSFUL" && <p>Amount is successfully deposited</p>}
                                    {notification.type === "DEPOSIT_UNSUCCESSFUL" && <p>There is not enough funds to start auction</p>}
                                    {notification.type === "CONTACT_REQUEST" && <p>{notification.contact_request.sender.first_name} invited you</p>}
                                    {notification.type === "CONTACT_REQUEST" && <div className="actionRow">
                                        <button className="view" onClick={()=> viewNotification(notification)}>View More</button>
                                        <button className="accept" onClick={(event) => {
                                            acceptRequest(event, notification)
                                            viewNotification(notification)
                                        }}>Accept</button>
                                    </div>}
                                    <div className="bottomRow">
                                        <div className="timeStamp">{moment(notification.created_at).format('LL HH:mm:ss')}</div>
                                        {notification.type !== "CONTACT_REQUEST" && <span className="link" onClick={()=> viewNotification(notification)}>View More</span>}
                                    </div>
                                </div>
                            </div>
                            <img className="divider" src={divider} alt="icon" />
                        </div>
                    }
                    )}
                </section>}

              
        </div>
    )
}

export default Notifications