import React, { } from 'react'
import closeIcon from './../../assets/images/icons/closeCircle.svg';
import placeBidImage from './../../assets/images/placeBidImage.svg';
import showForgoImage from './../../assets/images/showForgoImage.svg';

import './../../styles/guidedModal.scss';

function TaskModal(props) {
    return (
        <div className="modal taskModal">
            <div className="modal-content">
                <div className="header">
                    <div>{props.heading}</div>
                    <img onClick={() => props.closeTaskModal(props.id)} src={closeIcon} alt="icon" />
                </div>
                <div className="illustration">
                    {props.id === 1 &&<img src={placeBidImage} alt="icon" />}
                    {props.id === 2 &&<img src={showForgoImage} alt="icon" />}
                </div>
                <div className="content">
                    <div className="title">Scenario</div>
                    {props.id === 1 && <div className="description">
                        Imagine it is a $200*10 months = $2000 plan<br/><br/>
                        3rd bidding<br/> 
                        And you are willing to place bidding and take an amount. So the PTAP ( Predicted total amount paid at the end of the plan) is - $1600. This is the main metric to watch while bidding.
                    </div>}
                    {props.id === 2 && <div className="description">
                        Imagine it is a $200*10 months = $2000 plan<br/><br/>
                        3rd bidding And you are not willing to take the amount. So you want to show forgo.
                    </div>}
                </div>
                <div className="footer">
                    <div className="footerText">Now place a bid</div>
                    <button onClick={() => props.startTask(props.id)}>Start Bidding!</button>
                </div>
            </div>
        </div>
    )
}

export default TaskModal