import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link, useParams } from 'react-router-dom';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import moment from 'moment'
import jsPDF from 'jspdf';
import PlanAuctionTable from '../components/Plans/PlanAuctionTable';
import PlanSummaryTable from '../components/Plans/PlanSummaryTable';
import Loading from '../components/Loading';
import { 
    getPlanDetailsDemo,
    getPlanSummaryDemo,
    getPlanAuctionWinningInfo
} from '../redux/actions/planActions';
import { sendRequest, getAllAvatars } from '../redux/actions/contactActions';
import divider from './../assets/images/icons/divider.svg';
import backIcon from './../assets/images/icons/backIcon.svg';
import dropIcon from './../assets/images/icons/dropIcon.svg';
import DP from './../assets/images/DP.png';
import tickIcon from './../assets/images/icons/checkIcon.svg';
import chevronDown from './../assets/images/icons/dropIcon.svg';
import groupMembersIcon from './../assets/images/icons/groupMembersIcon.svg';
import downloadIcon from './../assets/images/icons/downloadIcon.svg';
import infoIconBlue from './../assets/images/icons/infoIconBlue.svg';
import './../styles/planDetails.scss';
var ordinal = require('ordinal')
ChartJS.register(ArcElement, Tooltip, Legend);
const BASE_URL = `http://${process.env.REACT_APP_WHEEZ_SERVER_URL}`;

const PlanDetails = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();

    const planState = useSelector((state) => state.plans);
    const uiState = useSelector((state) => state.UI);
    const contactsState = useSelector((state) => state.contacts);
    const { userAvatars } = contactsState;
    const {planDetails, planSummary} = planState;

    const dropIconRef = useRef();
    const dropIconPlanExplainer = useRef();
    const dropRef = useRef();
    
    const [detailsOpened, setDetailsOpened] = useState(false)
    const [addNowText, setAddNowText] = useState([]);
    const [groupMembers, setGroupMembers] = useState(false)
    const [summaryData, setSummaryData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [nextAuctionDate, setNextAuctionDate] = useState('');
    const [nextDate, setNextDate] = useState("");
    const [planExplainerOpened, setPlanExplainerOpened] = useState(false)
    const [currentAuctionChartSelected, setCurrentAuctionChartSelected] = useState(1)
    const [currentAuctionChartData, setCurrentAuctionChartData] = useState({})
    const [isListOpen, setIsListOpen] = useState(false)
    const [planTableOpened, setPlanTableOpened] = useState(true)
    const [chartData, setChartData] = useState({});

    useEffect(() => {
        dispatch(getPlanDetailsDemo(id));
        dispatch(getPlanAuctionWinningInfo(id));
        dispatch(getPlanSummaryDemo(id));
        dispatch(getAllAvatars());
    }, [id, dispatch]);
    useEffect(() => {
        if(planState.planDetails && planState.planDetails.members && planState.planDetails.members.length > 0){
            planState.planDetails.members.map((member) => {
                let array = addNowText;
                if(array[member.id] && array[member.id] === 'sent'){

                } else {
                    array[member.id] = "add now!"
                    setAddNowText(array)
                }
                return null
            })
        }
    }, [planState.planDetails, addNowText])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    });
    const handleClickOutside = (event) => {
        if(isListOpen && event.target.className !== "drop-item"){
            setIsListOpen(false)
            dropRef.current.style.transform = 'rotateZ(0deg)'
        }
    }

    useEffect(() => {
        if(planState.auctionWinningInfo && planState.auctionWinningInfo.length > 0){
            planState.auctionWinningInfo.filter((auction, index) => {
                if(currentAuctionChartSelected - 1 === index){
                    setCurrentAuctionChartData({
                        winnerId: auction.winner.id,
                        amountReceived: auction.winner.id === localStorage.getItem("userId") ? auction.total_amount_received : 0,
                        surplus: auction.individual_surplus,
                        amountPaid: auction.amount_contributed
                    })
                    setChartData({
                        datasets: [
                            {
                                data: [
                                    auction.amount_contributed, 
                                    auction.winner.id === localStorage.getItem("userId") ? auction.total_amount_received : 0, 
                                    auction.individual_surplus   
                                ],
                                borderWidth: 0,
                                cutout: 40,
                                backgroundColor: ['#DC3912', '#3366CC', '#DE8500']        
                            },
                        ]
                    })
                    return true
                } else {
                    return false
                }
            })
        } else {
            setCurrentAuctionChartData({})
            setChartData({})
        }
      
    }, [currentAuctionChartSelected, planState.auctionWinningInfo]);
    
    useEffect(() => {
        if(planState.auctionWinningInfo && planState.auctionWinningInfo.length > 0 && planState.planDetails && planState.planDetails.auctions && planState.planDetails.auctions.length > 0){
            let totalAmountUntilNow = 0;
           
            let dataObject = planState.auctionWinningInfo.map((auctionWinning) => {
                if(auctionWinning.bid && auctionWinning.bid.user && auctionWinning.bid.user.id === localStorage.getItem("userId")){
                    totalAmountUntilNow = totalAmountUntilNow + parseInt(auctionWinning.amount_contributed);
                }
                let currentAuction;
                planState.planDetails.auctions.map((data) => {
                    if(data.id === auctionWinning.auction.id){
                        currentAuction = data;
                    }
                    return null
                })
                let nextAuction;
                planState.planDetails.auctions.map((data, index) => {
                    if(data.id === auctionWinning.auction.id){
                        if(planState.planDetails.auctions[index+1]){
                            nextAuction = planState.planDetails.auctions[index+1]
                        }
                    }
                    return null
                })
                if(nextAuction){
                    setNextAuctionDate(nextAuction);
                }
                return {
                    dateAuction: currentAuction ? `${moment(auctionWinning.auction.starts_at).format('D MMM YY')}, ${ordinal(currentAuction.serial_no)} Auc` : '' ,
                    by: auctionWinning.winner.first_name,
                    value: '$' + auctionWinning.total_amount_received,
                    totalSurplus: '$' + auctionWinning.shared_surplus,
                    individualSurplus: '$' + auctionWinning.individual_surplus,
                    amountContributed: '$' + auctionWinning.amount_contributed
                }
            })
            setTableData(dataObject)
        } else {
            setNextAuctionDate('');
            setTableData([{
                dateAuction: '-' ,
                by: '-',
                value: '-',
                totalSurplus: '-',
                individualSurplus: '-',
                amountContributed: '-',
            }])
        }
      
    }, [planState.planDetails, planState.auctionWinningInfo]);

    useEffect(() => {
        if(planSummary && planSummary.summary){
           
            let dataObject = planSummary.summary.auctions.map((auction) => {
                return {
                    dateAuction: `${moment(auction.auction_starts_at).format('D MMM YY')}, ${ordinal(auction.auction_serial_no)} Auc` ,
                    value: '$' + auction.amount_taken,
                    amountContributed: '$' + auction.amount_contributed,
                    interest: `$${round(auction.amount_taken)} - $${round(planSummary.total_amount_paid_until_now)} = $${roundAbsolute(auction.amount_taken-planSummary.total_amount_paid_until_now)}`,
                    status: auction.amount_taken-planSummary.total_amount_paid_until_now < 0 ? 'Paid' : 'Earn' 
                }
            })
            setSummaryData(dataObject)
        } else {
            setSummaryData([{
                dateAuction: '-' ,
                value: '-',
                amountContributed: '-',
                interest: '-',
                status: '-' 
            }])
        }
      
    }, [planSummary]);

    useEffect(() => {
        if(planState.planDetails.auctions){
          if(nextAuctionDate){
            setNextDate(nextAuctionDate.starts_at)
          } else {
            for(var i=0; i < planState.planDetails.auctions.length; i++){
              if(i === 0){
                setNextDate(planState.planDetails.auctions[i].starts_at)
              }
            }
          }
        }
       
      }, [planState.planDetails.auctions, nextAuctionDate])

    const printDocument = () => {
        var doc = new jsPDF("p","pt","a4");
        let tableScroller = document.getElementsByClassName("tableScroller")[0];
        tableScroller.style.overflowX = "visible";
        doc.html(document.getElementById("divToPrint"), {
            callback: function(pdf){
                pdf.save("mypdf.pdf");
                tableScroller.style.overflowX = "auto";
            }
        })
        
    }

    const round = (x) => {
        return Number.parseFloat(x).toFixed(2);
    }
    const roundAbsolute = (x) => {
        return Math.abs(Number.parseFloat(x).toFixed(2));
    }

    const selectChartAuction = (id) => {
        setCurrentAuctionChartSelected(id)
    }
    const openPlanTable = () => {
        setPlanTableOpened(true)
    }
    const openPlanSummary = () => {
        setPlanTableOpened(false)
    }

    const isEmpty = (obj) => {
        for(var prop in obj) {
          if(Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
          }
        }
        return JSON.stringify(obj) === JSON.stringify({});
    }
    const sendInvite = (id) => {
        let userData = {
            "value": id,
            "value_type": "id"
        }
        dispatch(sendRequest(userData)).then((res=> {
            let array = addNowText;
            array[id] = "sent"
            setAddNowText(array)
        }))
    }
    return (
        uiState.loading  ? <Loading /> : 
        <div className="planDetailsContainer">
            {
                groupMembers && (
                    <div className='groupMembers'>
                         <div className='header'>
                            <img onClick={() => setGroupMembers(false)} src={backIcon} alt="icon" />
                            <span className='title'>Group Members</span>
                        </div>
                        {planState.planDetails && planState.planDetails.members && <div className='members'>
                            {planState.planDetails && planState.planDetails.members && planState.planDetails.members.length > 0 &&
                                planState.planDetails.members.map((member, index)=> 
                                {
                                    let avatar = null
                                    avatar = userAvatars.filter(u => u.id === member.avatar)
                                    return (
                                        <div key={member.id} className="listItem">
                                            {avatar && avatar.length > 0 ? <img className='profileImage' src={`${BASE_URL}${avatar[0].image}`} alt="icon"/> : (
                                                member.profile_image ? <img className='profileImage' src={`${BASE_URL}${member.profile_image}`} alt="icon"/> : <img className='profileImage' src={DP} alt="icon"/>
                                            )}
                                            <div className="content">
                                                <div className="name">{member.first_name} {member.last_name}</div>
                                                {member.details && <div className="location">{member.details.country && member.details.country}</div>}
                                            </div>
                                            {member.details && member.details.is_contact && <div className='info'>Friends</div>}
                                            {member.details && !member.details.is_contact && member.id !== localStorage.getItem('userId') && 
                                                <div onClick={() => sendInvite(member.id)} className='info'>
                                                    {addNowText[member.id] === 'sent' && <img src={tickIcon} alt="icon" />}
                                                    <span>{addNowText[member.id]}</span>
                                                </div>
                                            }

                                            {/* <img className='option' src={ellipsisBlue} alt="icon" /> */}
                                            {index !== planState.planDetails.members.length-1 && <img className='divider' src={divider} alt="icon" />}
                                        </div>
                                    )
                                }
                                )
                            }
                            </div>
                        }
                    </div>
                )

            }
            {!groupMembers && <>
                <div className='mainHeader'>
                    <img onClick={() => history.goBack()} src={backIcon} alt="icon" />
                    <span className='title'>Plan Details</span>
                    <img src={downloadIcon} onClick={() => printDocument()} alt="icon" />
                </div>
                <div className='dropContainer'>
                    <div className='rowDrop' onClick={() => {
                        if(!detailsOpened){
                            dropIconRef.current.style.transform = 'rotateZ(180deg)'
                        } else {
                            dropIconRef.current.style.transform = 'rotateZ(0deg)'
                        }
                        setDetailsOpened(!detailsOpened);
                    }}>
                        <span className='title'>{planDetails.title}</span>
                        <img ref={dropIconRef} src={dropIcon} alt="icon" />
                    </div>
                    {detailsOpened && (
                        <div className='planDetails'>
                            {
                                planDetails.amount_to_contribute && planDetails.total_amount && planDetails.no_of_members && planDetails.no_of_auctions && 
                                <div className='grid'>
                                    <span className='title'>Amount to contribute at every Month, Week</span>
                                    <span className='value'>${planDetails.amount_to_contribute.substring(0, planDetails.amount_to_contribute.length - 3)}</span>
                                    <span className='title'>Total Pooled Amount for auction at every month or Week</span>
                                    <span className='value'>${planDetails.total_amount.substring(0, planDetails.total_amount.length - 3)}</span>
                                    <span className='title'>Total Members In the Plan</span>
                                    <span className='value'>{planDetails.no_of_members}</span>
                                    <span className='title'>Plan Period</span>
                                    <span className='value'>
                                        {planDetails.no_of_auctions}&nbsp;
                                        {planDetails.type.interval_period === 1 && 'Days'}
                                        {planDetails.type.interval_period === 7 && 'Weeks'}
                                        {planDetails.type.interval_period === 30 && 'Months'}
                                    </span>
                                    <span className='title'>Total No of auctions (Monthly or weekly once)</span>
                                    <span className='value'>
                                        {planDetails.no_of_auctions}&nbsp;
                                        {planDetails.type.interval_period === 1 && 'Days'}
                                        {planDetails.type.interval_period === 7 && 'Weeks'}
                                        {planDetails.type.interval_period === 30 && 'Months'}
                                    </span>
                                </div>}
                        </div>
                    )}
                </div>
                {(nextAuctionDate || nextDate) && <div className='toggler'>
                    <span onClick={() => openPlanTable()} className={planTableOpened ? 'option active' : 'option'}>Plan Table</span>
                    {planSummary.summary && <span onClick={() => openPlanSummary()} className={!planTableOpened ? 'option active' : 'option'}>Plan Summary</span>}
                </div>}
                {planTableOpened && <div id="divToPrint">
                    {tableData.length > 0 && <PlanAuctionTable data={tableData} nextAuctionDate={nextAuctionDate} />}
                </div>}
                {!planTableOpened && <div>
                    {planSummary.summary && <PlanSummaryTable data={summaryData}/>}
                </div>}
                {!isEmpty(chartData) && <div className='donutChartContainer'>
                    <div className='subHeader'>
                        <span className='main'>Chart Portfolio</span>
                        <div className="box" onClick={() => { 
                            if(planState.auctionWinningInfo.length > 0){
                                setIsListOpen(!isListOpen);
                                if(!isListOpen){
                                    dropRef.current.style.transform = 'rotateZ(180deg)'
                                } else {
                                    dropRef.current.style.transform = 'rotateZ(0deg)'
                                }
                            }
                            }
                        }>
                            <div className="dropChevron">
                                <span className='value'>{ordinal(currentAuctionChartSelected)} Auc</span>
                                <img className='chevronDown' ref={dropRef} src={chevronDown} alt="icon" />
                            </div>
                            {isListOpen && (
                                <div role="list" className="dropdown-content">
                                    {
                                        planState.auctionWinningInfo.length > 0 && planState.auctionWinningInfo.map((auction, index) => {
                                            return (
                                            <span key={auction.id} className='drop-item' onClick={() => selectChartAuction(index + 1)}>
                                                {ordinal(index + 1)} Auc
                                            </span>)
                                        })
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='planInfo'>
                        <span className='title'>Total Amount Paid Until Now =</span>
                        <span className='value'>${planSummary.total_amount_paid_until_now}</span>
                    </div>
                    <div className='chartRow'>
                        <Doughnut data={chartData} width={120} height={120} options={{ maintainAspectRatio: false }} />
                        <div className='gridContent'>
                            <div className='labelFlex'>
                                <div className='titleFlex'>
                                    <div className='blue block'></div>
                                    <span className='title'>Auc Amount</span>
                                </div>
                                <div className='value'>{currentAuctionChartData.amountReceived ? `$${currentAuctionChartData.amountReceived}` : '-'}</div>
                            </div>
                            <div className='labelFlex'>
                                <div className='titleFlex'>
                                    <div className='red block'></div>
                                    <span className='title'>Amount Paid</span>
                                </div>
                                <div className='value'>{currentAuctionChartData.amountPaid ? `$${currentAuctionChartData.amountPaid}` : '-'}</div>
                            </div>
                            <div className='labelFlex'>
                                <div className='titleFlex'>
                                    <div className='yellow block'></div>
                                    <span className='title'>Surplus</span>
                                </div>
                                <div className='value'>{currentAuctionChartData.surplus ? `$${currentAuctionChartData.surplus}` : '-'}</div>
                            </div>
                        </div>
                    </div>
                </div>}
                <div className='rowDropExplainer' onClick={() => {
                        if(!planExplainerOpened){
                            dropIconPlanExplainer.current.style.transform = 'rotateZ(180deg)'
                        } else {
                            dropIconPlanExplainer.current.style.transform = 'rotateZ(0deg)'
                        }
                        setPlanExplainerOpened(!planExplainerOpened);
                    }}>
                    <div className='row'>
                        <img src={infoIconBlue} alt="icon" />
                        <span className='title'>Plan Explainer</span>
                    </div>
                    <img ref={dropIconPlanExplainer} src={dropIcon} alt="icon" />
                </div>
                {
                    planExplainerOpened && (
                        <div className='planExplainer'>
                            {planDetails.plan_explainer}
                        </div>
                    )
                }
                <div className='groupMembersRow' onClick={() => setGroupMembers(true)}>
                    <div className='row'>
                        <img src={groupMembersIcon} alt="icon" />
                        <span className='title'>Group Members</span>
                    </div>
                </div>
                {planDetails && planDetails.members && planDetails.no_of_members && planDetails.members.length < planDetails.no_of_members && <>
                    <div className='subHeading'>Slots Left</div>
                    <div className='slotsSlide'>
                        <div className='track'>
                            <div className='amount' style={{width: `${(planDetails.members.length/planDetails.no_of_members)*100}%`}}></div>
                        </div>
                        <div className='value'>{planDetails.members.length}/{planDetails.no_of_members}</div>
                    </div>
                    <div className='slotsInfo'>There are only {planDetails.no_of_members - planDetails.members.length} Slots left, Invite your friends to the platform to start the plan sooner</div>
                    <Link to="/friends/2"><button className='inviteFriends'>Invite Friends</button></Link>
                </>}
            </>}
        </div>
    )
}

export default PlanDetails