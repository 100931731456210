import React from "react";
import { TourProvider } from '@reactour/tour'
import App from './App';
import GuidedModal from './components/PracticeBidding/GuidedModal';
import guideLeft from './assets/images/icons/leftArrowGuide.svg';
import guideRight from './assets/images/icons/rightArrowGuide.svg';



const TourProviderHOC = () => {
    const styles = {
        controls: base => ({ ...base, justifyContent: 'center' }),
        dot: base => ({ ...base, width: '4px', height: '4px', background: '#52498E' })
    }
    const steps = [
        {
            selector: '[data-tut="timer"]',
            content: () => (
                <GuidedModal 
                  guideStep={0} 
                />
            )
        },
        {
            selector: '[data-tut="task"]',
            content: () => (
                <GuidedModal 
                    guideStep={1} 
                />
            )
        },
        {
            selector: '[data-tut="online"]',
            content: () => (
                <GuidedModal 
                    guideStep={2} 
                />
            )
        },
        {
            selector: '[data-tut="ptap"]',
            content: () => (
                <GuidedModal 
                    guideStep={3} 
                />
            )
        },
        {
            selector: '[data-tut="flagged"]',
            content: () => (
                <GuidedModal 
                    guideStep={4} 
                />
            )
        },
        {
            selector: '[data-tut="available"]',
            content: () => (
                <GuidedModal 
                    guideStep={5} 
                />
            )
        },
        {
            selector: '[data-tut="context"]',
            content: () => (
                <GuidedModal 
                    guideStep={6} 
                />
            )
        },
        {
            selector: '[data-tut="placeBid"]',
            content: () => (
                <GuidedModal 
                    guideStep={7} 
                />
            )
        },
        {
            selector: '[data-tut="lock"]',
            content: () => (
                <GuidedModal 
                    guideStep={8} 
                />
            )
        },
        {
            selector: '[data-tut="forgo"]',
            content: () => (
                <GuidedModal 
                    guideStep={9} 
                />
            )
        },
        {
            selector: '[data-tut="finish"]',
            content: () => (
                <GuidedModal 
                    guideStep={10} 
                />
            )
        },
    ]

    return  <TourProvider
                steps={steps}
                showBadge={false}
                showCloseButton={false}
                styles={styles}
                highlightedMaskClassName={"tourMask"}
                onClickMask={({setIsOpen}) => setIsOpen(true)}
                className='tourGuide'
                prevButton={({ currentStep,setCurrentStep }) => {
                const first = currentStep === 0
                return (
                    <img alt="prev"  onClick={() => {
                        if (!first) {
                        setCurrentStep(s => s - 1)
                        } 
                    }} 
                    className={first ? 'prevTourButton disabled': 'prevTourButton'} src={guideLeft} />
                )
                }}
                nextButton={({
                currentStep,
                stepsLength,
                setCurrentStep,
                }) => {
                const last = currentStep === stepsLength - 1
                return (
                    <img  alt="next" onClick={() => {
                    if (!last) {
                        setCurrentStep(s => (s === steps.length - 1 ? 0 : s + 1))
                    } 
                    }} 
                className={last ? 'nextTourButton disabled': 'nextTourButton'} src={guideRight} />
                )
                }}
            >
            <App />
        </TourProvider>
}

export default TourProviderHOC