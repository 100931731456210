import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import userReducer from './reducers/userReducer';
import contactReducer from './reducers/contactReducer';
import uiReducer from './reducers/uiReducer';
import practiceBiddingReducer from './reducers/practiceBiddingReducer'
import notificationReducer from './reducers/notificationReducer'
import walletReducer from './reducers/walletReducer'
import liveBiddingReducer from './reducers/liveBiddingReducer'
import planReducer from './reducers/planReducer'
import chatReducer from './reducers/chatReducer'

const initialState = {};

const middleware = [thunk];
const reducers = combineReducers({
    user: userReducer,
    UI: uiReducer,
    contacts: contactReducer,
    practiceBidding: practiceBiddingReducer,
    notifications: notificationReducer,
    liveBidding: liveBiddingReducer,
    wallet: walletReducer,
    chat: chatReducer,
    plans: planReducer
});

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));
const store = createStore(reducers, initialState, enhancer);

export default store;