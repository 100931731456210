// User reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_REGISTRATION = "SET_REGISTRATION";
export const SET_TWO_FACTOR_AUTH = "SET_TWO_FACTOR_AUTH";
export const CLEAR_TWO_FACTOR_AUTH = "CLEAR_TWO_FACTOR_AUTH";
export const SET_OTP_SENT = "SET_OTP_SENT";
export const CLEAR_OTP_SENT = "CLEAR_OTP_SENT";
export const SET_REGISTER_SUCCESS = "SET_REGISTER_SUCCESS";
export const SET_CHANGED_SUCCESS = "SET_CHANGED_SUCCESS";
export const USER_PROFILE_DATA = "USER_PROFILE_DATA";
export const COUNTRY_STATES = "COUNTRY_STATES";
export const STATE_CITIES = "STATE_CITIES";
export const NOTIFICATION_SETTINGS = "NOTIFICATION_SETTINGS";
export const SELF_PROFILE_IMAGE = "SELF_PROFILE_IMAGE";





// Bidding reducer types
export const START_PRACTICE_BIDDING = 'START_PRACTICE_BIDDING';
export const END_PRACTICE_BIDDING = 'END_PRACTICE_BIDDING';
export const BID_PRACTICE_AMOUNT = 'BID_PRACTICE_AMOUNT';
export const SCROLL_TRIGGER_START = 'SCROLL_TRIGGER_START';
export const SCROLL_TRIGGER_END = 'SCROLL_TRIGGER_END';
export const PRACTICE_BID_WINNER = 'PRACTICE_BID_WINNER';
export const FORFEIT_SELF = 'FORFEIT_SELF';
export const RESET_BIDDING = 'RESET_BIDDING';




// Live Bidding reducer types
export const BIDDING_CONFIG = 'BIDDING_CONFIG';
export const BIDDING_DATA = "BIDDING_DATA";
export const AUCTION_ID = "AUCTION_ID";
export const PLAN_DETAILS = "PLAN_DETAILS";
export const BIDDING_DATA_RECEIVE = "BIDDING_DATA_RECEIVE";
export const RESET = "RESET";
export const AUCTION_WINNER = "AUCTION_WINNER";
export const CLEAR_AUCTION = "CLEAR_AUCTION";
export const FLAG_ENABLED_FROM_NETWORK = "FLAG_ENABLED_FROM_NETWORK";
export const TRIGGER_FEEDBACK = "TRIGGER_FEEDBACK";
export const AUCTION_STATUS = "AUCTION_STATUS";
export const SOCKET_STATUS = "SOCKET_STATUS";
export const ERROR_400_FROM_SERVER = "ERROR_400_FROM_SERVER";
export const CLEAR_ERROR_400 = "CLEAR_ERROR_400";







// Notification reducer types

export const PLAN_RECOMMENDATION = 'PLAN_RECOMMENDATION';
export const DEPOSIT_UNSUCCESSFUL = 'DEPOSIT_UNSUCCESSFUL';
export const CONTACT_JOINS_PLAN = 'CONTACT_JOINS_PLAN';
export const PERSON_JOINS_PLAN = 'PERSON_JOINS_PLAN';
export const AUCTION_STARTS = 'AUCTION_STARTS';
export const AUCTION_ABOUT_TO_START = 'AUCTION_ABOUT_TO_START';
export const AUCTION_ABOUT_TO_END = 'AUCTION_ABOUT_TO_END';
export const AUCTION_ENDS = 'AUCTION_ENDS';
export const DEPOSIT_SUCCESSFUL = 'DEPOSIT_SUCCESSFUL';
export const WINNING_AMOUNT_ADDED = 'WINNING_AMOUNT_ADDED';
export const SURPLUS_AMOUNT_ADDED = 'SURPLUS_AMOUNT_ADDED';
export const CONTACT_REQUEST = 'CONTACT_REQUEST';
export const FIRST_TIME_LOGIN = 'FIRST_TIME_LOGIN';
export const CONTACT_REMOVED = "CONTACT_REMOVED";
export const PLAN_SCHEDULED = "PLAN_SCHEDULED";
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const TOTAL_NOTIFICATIONS = 'TOTAL_NOTIFICATIONS';
export const CLEAR_ALL_NOTIFICATIONS = 'CLEAR_ALL_NOTIFICATIONS';
export const UNREAD_NOTIFICATIONS = 'UNREAD_NOTIFICATIONS';
export const APPLIED_FOR_MCL = 'APPLIED_FOR_MCL';
export const PLAN_APPLICATION_SUBMITTED = 'PLAN_APPLICATION_SUBMITTED';
export const PLAN_APPLICATION_THERE = 'PLAN_APPLICATION_THERE';



// Chat reducer types

export const INCOMING_MESSAGE = "INCOMING_MESSAGE";
export const CHAT_SCROLL_TRIGGER_START = "CHAT_SCROLL_TRIGGER_START";
export const CHAT_SCROLL_TRIGGER_END = "CHAT_SCROLL_TRIGGER_END";
export const CLEAR_CHAT = "CLEAR_CHAT";
export const DAY_INDICATOR = "DAY_INDICATOR";
export const DELETE_MESSAGE = "DELETE_MESSAGE";




// Wallet reducer types

export const GET_BALANCE = "GET_BALANCE";
export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";
export const ADD_TO_TRANSACTIONS = "ADD_TO_TRANSACTIONS";
export const SHOULD_FETCH_MORE_TRANSACTIONS = "SHOULD_FETCH_MORE_TRANSACTIONS";
export const DUE_BALANCE = "DUE_BALANCE";








// Plan reducer types

export const PLAN_DATA = "PLAN_DATA";
export const RECOMMENDED_PLANS = "RECOMMENDED_PLANS";
export const USER_PLANS = "USER_PLANS";
export const CURRENT_SELECTED_PLAN = "CURRENT_SELECTED_PLAN";
export const BASIC_PLAN_STATUS = "BASIC_PLAN_STATUS";
export const RESET_PLAN_DATA = "RESET_PLAN_DATA";
export const SIMPLE_USER_PLANS = "SIMPLE_USER_PLANS";
export const REMOVE_RECOMMEND_PLAN = "REMOVE_RECOMMEND_PLAN";
export const INACTIVE_PLANS = "INACTIVE_PLANS";
export const PLAN_SUMMARY = "PLAN_SUMMARY";
export const AUCTION_WINNING_INFO = "AUCTION_WINNING_INFO";
export const CLEAR_PLANS_RECOMMENDS = "CLEAR_PLANS_RECOMMENDS";
export const CURRENT_PLAN_PORTFOLIO = "CURRENT_PLAN_PORTFOLIO";
export const ALL_TYPE_PLANS = "ALL_TYPE_PLANS";
export const CLEAR_ALL_TYPE_PLANS = "CLEAR_ALL_TYPE_PLANS";
export const RECOMMENDED_PLANS_THERE = "RECOMMENDED_PLANS_THERE";
export const USER_PLANS_THERE = "USER_PLANS_THERE";
export const PLAN_TYPES = "PLAN_TYPES";
export const CONTRIBUTION_AMOUNT_RANGES = "CONTRIBUTION_AMOUNT_RANGES";
export const PERIOD_RANGES = "PERIOD_RANGES";
export const PLAN_GOALS = "PLAN_GOALS";
export const DEMO_PLANS = "DEMO_PLANS";
















// Contact reducer types
export const GOOGLE_CONTACTS = 'GOOGLE_CONTACTS';
export const USER_AVATARS = 'USER_AVATARS';
export const INVITE_LINK = 'INVITE_LINK';
export const WHEEZ_CONTACTS = 'WHEEZ_CONTACTS';
export const CURRENT_REQUESTS = 'CURRENT_REQUESTS';
export const CLEAR_CONTACT_DATA = "CLEAR_CONTACT_DATA";
export const PUBLIC_PROFILE_DATA = "PUBLIC_PROFILE_DATA";




export const SET_USER = "SET_USER";
export const LOADING_USER = "LOADING_USER";


// UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const BIDDING_LOADING_UI = 'BIDDING_LOADING_UI';
export const START_LOADING = 'START_LOADING';



