import React from 'react';
import gradientCircleLoader from './../assets/images/icons/gradientCircleLoader.svg';
import mainCircleLoader from './../assets/images/icons/mainCircleLoader.svg';
import './../styles/loading.scss';

function Loading() {
    return (
        <div className="loadingContainer">
             <div className="loader">
                <img src={mainCircleLoader} alt="icon" />
                <img src={gradientCircleLoader} alt="icon" />
            </div>
            <div className="text">Loading...</div>
        
        </div>
    )
}


export default Loading;