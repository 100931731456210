import { 
    START_PRACTICE_BIDDING,
    END_PRACTICE_BIDDING,
    BID_PRACTICE_AMOUNT,
    SCROLL_TRIGGER_START,
    SCROLL_TRIGGER_END,
    PRACTICE_BID_WINNER,
    FORFEIT_SELF,
    RESET_BIDDING
} from '../types';

export const placeBidStart = () => (dispatch) => {
    dispatch({type: START_PRACTICE_BIDDING});
}

export const placeBidReset = () => (dispatch) => {
    dispatch({type: RESET_BIDDING});
}

export const placeBidEnd = () => (dispatch) => {
    dispatch({type: END_PRACTICE_BIDDING});
}

export const triggerScroll = () => (dispatch) => {
    dispatch({type: SCROLL_TRIGGER_START});
}

export const triggerScrollEnd = () => (dispatch) => {
    dispatch({type: SCROLL_TRIGGER_END});
}

export const placeABid = (id, initialValue, deductedValue) => (dispatch) => {
    dispatch({
        type: BID_PRACTICE_AMOUNT,
        payload: {
            id,
            initialValue,
            deductedValue
        }
    });
    dispatch({
        type: PRACTICE_BID_WINNER,
        payload: {
            id
        }
    });
}

export const forfeitSelf = (id) => (dispatch) => {
    dispatch({
        type: FORFEIT_SELF,
        payload: {
            id
        }
    });
  
}