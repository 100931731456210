import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import TourProviderHOC from './TourProviderHOC';
import './index.css';
const BASE_URL = process.env.REACT_APP_WHEEZ_SERVER_URL;
axios.defaults.baseURL = `http://${BASE_URL}`;
ReactDOM.render(
  <TourProviderHOC />,
  document.getElementById('root')
);
