import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Popover } from '@reactour/popover'
import moment from 'moment'
import { 
    linkGoogleAccount,
} from '../../redux/actions/contactActions';
import divider from './../../assets/images/icons/divider.svg';
import DP from './../../assets/images/DP.png';
import sharePlan from './../../assets/images/icons/sharePlan.svg';
import infoPlan from './../../assets/images/icons/infoPlan.svg';
import notTaken from './../../assets/images/icons/notTaken.svg';
import tickSquare from './../../assets/images/icons/tickSquare.svg';
import infoIconPlan from './../../assets/images/icons/infoIconPlan.svg';
import takenTick from './../../assets/images/icons/takenTick.svg';
const BASE_URL = `http://${process.env.REACT_APP_WHEEZ_SERVER_URL}`;


export default function DemoPlanCard(props) {
    const dispatch = useDispatch();
    const contactsState = useSelector((state) => state.contacts);
    const { userAvatars } = contactsState;
    const styles = {
        popover: (base, state) => {
          return {
            ...base,
            borderRadius: 10,
          }
        },
    }
    const {userPlan} = props;
    const [planUserListOpened, setPlanUserListOpened] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [currentListPlanId, setCurrentListPlanId] = useState(0)
    const [friendsHeading , setFriendsHeading] = useState(false)
    const [otherFriendsHeading , setOtherFriendsHeading] = useState(false)


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        if(userPlan.planDetails && userPlan.planDetails.members && userPlan.planDetails.members.length > 0){
            userPlan.planDetails.members.map((member) => {
                if(member.details && member.details.is_contact){
                    setFriendsHeading(true)
                }
                if(member.details && !member.details.is_contact){
                    setOtherFriendsHeading(true)
                }
                return null;
            })
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    });

    const handleClickOutside = (event) => {
        if(planUserListOpened && event.target.className !== "list" && event.target.className !== "googleConnect"){
            setPlanUserListOpened(false)
        }
        if(isOpen){
            setIsOpen(false)
        }
    }
    const openUserList = (planId) => {
        setPlanUserListOpened(true)
        setCurrentListPlanId(planId)
    }
    const openUserInfo = (member) => {
        setPlanUserListOpened(false)
        props.openUserInfo(member);
    }
    const shareLink = () => {}

    const handleGoogleLink = () => {
        dispatch(linkGoogleAccount()).then((url)=> {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        });
    }
    let biddingLink = `/biddingDemo/${userPlan.id}` ;
    let chatRoomLink = `/chatRoom/${userPlan.id}` ;
    let planDetailsLink = `/planDetailsDemo/${userPlan.id}` ;
    return (
        <section className="planCard">
            <div className='topGradient'></div>
            {props.inActive && <div className='inactiveLayer'></div>}
            <div className='dummyContainer'>
                <div className='planTypeInfo'>
                    <span className='planText'>
                        {userPlan.type.title}
                    </span>
                </div>
            </div>
            <div className="header">
                <div className='memberCount'>{userPlan.no_of_members}</div>
                <div className='content'>
                    <div className='planType'>{userPlan.title}</div>
                    <div className='duration'>
                        x${userPlan.amount_to_contribute.substring(0, userPlan.amount_to_contribute.length - 3)}&nbsp;
                        Per&nbsp;
                        {userPlan.type.interval_period === 1 && 'Day'}
                        {userPlan.type.interval_period === 7 && 'Week'}
                        {userPlan.type.interval_period === 30 && 'Month'}&nbsp;
                        For <span className='month'>{userPlan.no_of_auctions}&nbsp;
                        {userPlan.type.interval_period === 1 && 'Day'}
                        {userPlan.type.interval_period === 7 && 'Week'}
                        {userPlan.type.interval_period === 30 && 'Month'}s</span>
                    </div>
                    <div className='membersTaken'>
                        <div className='left'>
                            <img src={tickSquare} alt="icon" />
                            <span className='members'>5 members</span>
                            <div className='help' onClick={() => {setIsOpen(o => !o)} }>
                                <img className='infoIconPlan' src={infoIconPlan} alt="icon" />
                                {isOpen ? (
                                    <Popover className='helpTooltip' sizes={{  top: 0, right: 0}} styles={styles}>
                                    <p>
                                    At each installments, the amount that
                                    you what to contributed to the plan will
                                    be deducted from your bank account automatically and members can easily withdraw their funds from their wheez
                                    wallet to the linked bank accounts
                                    </p>
                                    </Popover>
                                ) : null}
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
             {userPlan.planDetails.members.length >= userPlan.no_of_members && <div className='statusProgress'>
                <div className='text'>
                    {userPlan.planDetails.available_to === 0 ? <span>Finished</span> : 
                    (
                        userPlan.auction_running ? <span>Running</span> :  <span>Scheduled</span>
                    )
                    }
                    {userPlan.nextAuctionScheduled ? 
                        <span>{userPlan.nextAuctionScheduled ? userPlan.nextAuctionScheduled : 0}/{userPlan.no_of_auctions}</span> 
                        : 
                        <span>{userPlan.planDetails.members.length}/{userPlan.no_of_auctions}</span> 
                    }
                </div>
                <div className='progress'>
                    {userPlan.nextAuctionScheduled ? <div style={{width: `${(userPlan.nextAuctionScheduled ? (userPlan.nextAuctionScheduled/userPlan.no_of_auctions)*100 : 2)}%`}} className='percentage'></div> : <></>}
                    {!userPlan.nextAuctionScheduled && <div style={{width: `${((userPlan.planDetails.members.length)/userPlan.no_of_auctions)*100 - 2}%`}} className='percentage'></div>}
                </div>
                {!props.inActive && userPlan.nextAuctionDate && <div className='upcoming'>Upcoming Auction: {moment(userPlan.nextAuctionDate).format("DD/MM/YYYY HH:mm:ss")}</div>}
                {props.inActive && <div className='upcoming'>Expired</div>}
                {!userPlan.nextAuctionDate && !props.inActive && <div className='upcoming'>Expired</div>}
            </div>} 
            <div className="buttonRow">
                <Link to={biddingLink}><button className='active'>Auction Room</button></Link>
                <Link to={planDetailsLink}><button>Details</button></Link>
                <Link to={chatRoomLink}><button className='active'>Chat room</button></Link>
            </div>
            <img className='plansDivider' src={divider} alt="icon" />
            <div className="bottomRow">
                <div className="imageContainer" onClick={() => openUserList(userPlan.id)}>
                    {userPlan.planDetails && userPlan.planDetails.members && userPlan.planDetails.members.length > 0 && userPlan.planDetails.members.map((member, index) => {
                        if(index <= 3){
                            let avatar = null
                            if(member.avatar){
                                avatar = userAvatars.filter(u => u.id === member.avatar)
                            }
                            if(avatar) return <img key={member.id} className="displayedImage" src={`${BASE_URL}${avatar[0].image}`} alt="icon"/>
                            else if(member.profile_image) return <img key={member.id} className="displayedImage" src={`${BASE_URL}${member.profile_image}`} alt="icon"/>
                            else return <img key={member.id} className="displayedImage" src={DP} alt="icon"/>
                        } else return null
                        
                    })}
                    {userPlan.planDetails 
                        && userPlan.planDetails.members 
                        && userPlan.planDetails.members.length > 4 
                        && <div className="planUserCount">+{userPlan.planDetails.members.length - 4}</div>}
                    {planUserListOpened && currentListPlanId === userPlan.id && <div className="planUserList">
                        <div className='heading'>Friends</div>
                        {userPlan.planDetails && userPlan.planDetails.members && userPlan.planDetails.members.length > 0 && 
                        <ul>
                            {userPlan.planDetails.members.map((member) => {
                                if(member.details && member.details.is_contact){ 
                                    let avatar = null
                                    if(member.avatar){
                                        avatar = userAvatars.filter(u => u.id === member.avatar)
                                    }
                                    return <li onClick={() => openUserInfo(member)} key={member.id} className='list'>
                                        {avatar ? 
                                            <img src={`${BASE_URL}${avatar[0].image}`} alt="icon" /> : 
                                            (member.profile_image ? <img src={`${BASE_URL}${member.profile_image}`} alt="icon"/> : <img src={DP} alt="icon"/>)
                                        }
                                        <span>{member.first_name}&nbsp;{member.last_name}</span>
                                    </li>
                                } else return null
                            })}
                        </ul>}
                        {
                            !friendsHeading && (
                                <div className='connectGoogle'>
                                    <div className='description'>Connect your google account to view friends</div>
                                    <button className='googleConnect' onClick={() => handleGoogleLink()}>Connect</button>
                                </div>
                            )
                        }

                        {otherFriendsHeading && <div className='heading' style={{marginTop: friendsHeading ? '10px' : 0}}>Other Members</div>}
                        <ul>
                            {userPlan.planDetails && userPlan.planDetails.members && userPlan.planDetails.members.length > 0 && userPlan.planDetails.members.map((member) => {
                                if(member.details && !member.details.is_contact){
                                    let avatar = null
                                    if(member.avatar){
                                        avatar = userAvatars.filter(u => u.id === member.avatar)
                                    }
                                    return (
                                        <li onClick={() => openUserInfo(member)} key={member.id} className='list'>
                                            {avatar ? 
                                                <img src={`${BASE_URL}${avatar[0].image}`} alt="icon" /> : 
                                                (member.profile_image ? <img src={`${BASE_URL}${member.profile_image}`} alt="icon"/> : <img src={DP} alt="icon"/>)
                                            }
                                            <span>{member.first_name}&nbsp;{member.last_name}</span>
                                        </li>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </ul>
                    </div>}
                </div>
                <div className='planActions'>
                    <img onClick={() => shareLink()} className='sharePlan' src={sharePlan} alt="icon" />
                    <img className='infoPlan' src={infoPlan} alt="icon" />
                </div>
            </div>
        </section>
    );
}
