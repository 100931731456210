import React from 'react';
import { useHistory } from "react-router";
import errorImage from './../assets/images/400Image.svg';
import './../styles/notfound.scss';

function PageNotFound() {
    const history = useHistory();
    const reload = () => {
        history.push('/');
    }
    return (
        <div className="loadingContainer">
            <img src={errorImage} alt="icon" />
            <div className='heading'>Server Not Responding</div>
            <div className='description'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla molestie</div>
            <button onClick={() => reload()}>Try Again</button>
        </div>
    )
}


export default PageNotFound;