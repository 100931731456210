import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Headroom from 'react-headroom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RadioGroup from '@material-ui/core/RadioGroup';
import RadioWithLabel from './../components/RadioWithLabel';
import Loading from '../components/Loading';
import IntroModal from '../components/PracticeBidding/IntroModal';
import PlanCard from '../components/Plans/PlanCard';
import RecommendSection from '../components/RecommendSection';
import DemoPlanCard from '../components/Plans/DemoPlanCard';
import { 
    getRecommendedPlans, 
    getUserPlans,
    sendBasicPlanApplication,
    removePlanData,
    getBasicPlanStatus,
    removeRecommendFromStore,
    actionOnRecommendedPlans,
    getInactivePlans,
    getDemoPlans
} from '../redux/actions/planActions';
import { sendRequest, getPublicProfileInfo, getAllAvatars } from '../redux/actions/contactActions';
import ViewRecommend from '../components/Plans/ViewRecommend';
import dashboardOutline from './../assets/images/icons/dashboardOutline.svg';
import backIcon from './../assets/images/icons/backIcon.svg';
import dp from './../assets/images/DP.png';
import closeIcon from './../assets/images/icons/closeCircle.svg';
import beforeMCL from './../assets/images/beforeMCL.svg';
import afterMCL from './../assets/images/afterMCL.svg';
import planFilled from './../assets/images/icons/planFilled.svg';
import starIcon from './../assets/images/icons/starFilled.svg';
import noHistory from './../assets/images/noHistory.svg';
import applyPlanNow from './../assets/images/applyPlanNow.svg';
import friendOutline from './../assets/images/icons/friendOutline.svg';
import walletOutline from './../assets/images/icons/walletOutline.svg';
import plansOutline from './../assets/images/icons/plansOutline.svg';
import divider from './../assets/images/icons/divider.svg';
import './../styles/plans.scss';
const BASE_URL = `http://${process.env.REACT_APP_WHEEZ_SERVER_URL}`;

class plans extends Component {
    constructor(props) {
        super(props);
        this.slotsFilledModal = React.createRef();
        this.planPageRef = React.createRef();
        this.historyRef = React.createRef();
        this.errorModal = React.createRef();
        this.userInfoModal = React.createRef();
        this.whyNotInterested = React.createRef();

        this.state = {
            isIntro: false,
            isRecommendView: false,
            showUserInfo: false,
            appliedSuccessSection: false,
            currentProfile: {},
            recommendedPlanDetails: {},
            notInterestedId: 0,
            notInterestedRadioId: 0,
            errorModalText: "",
            isContact: false,
            viewBottom: false,
            openAutoRecommend: false,
            applyTypeOpen: false,
            plansWithFirstAuction: false
        }
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.props.getUserPlans();
        this.props.getRecommendedPlans();
        // this.props.getBasicPlanStatus();
        this.props.getInactivePlans();
        this.props.getAllAvatars();
        this.props.getDemoPlans();
        if(localStorage.getItem("plansFirst")){
            this.setState({
                isIntro: false
            })
        } else {
            this.setState({
                isIntro: true
            })
            localStorage.setItem("plansFirst", true)
        }
    }

    componentWillReceiveProps(nextProps) {
        let anyRunningPlans = false
        if(nextProps.plans.userPlans && nextProps.plans.userPlans.length > 0){
            nextProps.plans.userPlans.map((p) => {
                if(p.planDetails.auctions > 0 && p.planDetails.members.length === p.planDetails.no_of_members){
                    p.planDetails.auctions.map(a => {
                        if(a.status.toLowerCase() === "completed" && a.serial_no === 1){
                            anyRunningPlans = true
                        }
                        return null
                    })
                }
                return null
            })
        }
        this.setState({
            plansWithFirstAuction: anyRunningPlans
        })
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        this.props.removePlanData();
    }
    handleClickOutside = (event) => {
        if(this.state.showUserInfo) {
            if(this.userInfoModal.current.isSameNode(event.target)){
                this.userInfoModal.current.style.display = "none";
                this.setState({
                    showUserInfo: false
                })
            }
        }
    }
   
    openRecommendView = (planDetails, viewBottom) => {
        this.setState({
            isRecommendView: true,
            viewBottom: viewBottom
        })
        this.setState({
            recommendedPlanDetails: planDetails
        })
    }
    closeViewRecommend = () => {
        this.setState({
            isRecommendView: false,
            recommendedPlanDetails: {}
        })
    }
    changeDummy = () => {}
  
    closeIntro = () => {
        this.setState({
            isIntro: false
        })
    }
    closeNotInterested = () => {
        this.whyNotInterested.current.style.display = "none";
    }
    selectTab = (tab) => {
        if(tab === 1) {
            this.props.history.push('/plans/1')
        }
        if(tab === 2) {
            this.props.history.push('/plans/2')
        }
    }
    openNotInterested = (plan) => {
        this.whyNotInterested.current.style.display = "block";
        this.setState({
            notInterestedId: plan.id
        })
    }
    openHistory = () => {
        this.planPageRef.current.style.display = "none";
        this.historyRef.current.style.display = "block";
    }
    openApplication = () => {
        this.props.history.push('/application')
    }
    limitPage = () => {
        this.props.history.push('/wallet/3')
    }
    backNavigateHistory = () => {
        this.planPageRef.current.style.display = "block";
        this.historyRef.current.style.display = "none";
    }
    openProptSlotFull = () => {
        this.slotsFilledModal.current.style.display = "block";
    }
    closeSlotFilled = () => {
        this.slotsFilledModal.current.style.display = "none";
    }
    getBasicPlan = () => {
        if(this.props.plans.basicPlanStatus === "PENDING"){

        } else {
            this.props.sendBasicPlanApplication().then(res => {
                if(res === 422){
                    // console.log("already applied for basic")
                } 
                if(res === 201){
                    this.props.getBasicPlanStatus();
                    this.props.history.push('/plans/2')
                    this.setState({
                        appliedSuccessSection: true
                    })
                }
            })
        }
    }
    applyAgain = () => {
        // this.props.sendBasicPlanApplication();
    }
  
    openUserInfo = (member) => {
        this.props.getPublicProfileInfo(member.id).then(res => {
            this.userInfoModal.current.style.display = "block";
            this.setState({
                showUserInfo: true,
                isContact: res.is_contact ? res.is_contact : false,
                currentProfile: member
            })
        })
    }
    deleteRecommendedPlan = (recommendedPlan) => {
        let actionData = {
            "recommended_plan_id": recommendedPlan.id,
            "action": "reject"
        }
        this.props.actionOnRecommendedPlans(actionData).then(res => {
            this.props.removeRecommendFromStore(recommendedPlan.id);
            this.props.history.push('/plans/1')
        })
    }
    submitNotInterested = () => {
        let actionData = {
            "recommended_plan_id": this.state.notInterestedId,
            "action": "not_interested",
            "reason": this.state.notInterestedRadioId
        }
        this.props.actionOnRecommendedPlans(actionData).then(res => {
            this.whyNotInterested.current.style.display = "none";
            this.props.removeRecommendFromStore(this.state.notInterestedId).then(() => {
                this.props.getUserPlans();
                this.props.getRecommendedPlans();
                this.props.history.push('/plans/2');
            });
        })
    }
  
    openProfile = () => {
        let profileLink = `/profile/${this.state.currentProfile.id}` ;
        this.props.history.push(profileLink)
    }
    joinPlan = (id) => {
        let actionData = {
            "recommended_plan_id": id,
            "action": "accept"
        }
        this.props.actionOnRecommendedPlans(actionData).then(res => {
            if(res.message === "success"){
                this.props.getUserPlans();
                this.props.getRecommendedPlans();
                this.props.history.push('/plans/1')
            } else if(res.message === "error") {
                this.errorModal.current.style.display = "block";
                this.setState({
                    errorModalText: res.text
                })
            }
            
        })
    }
    addFriend = () => {
        if(!this.state.isContact){
            let userData = {
                "value": this.state.currentProfile.id,
                "value_type": "id"
            }
            this.props.sendRequest(userData);
        }
    }
    closeErrorModal = () => {
        this.errorModal.current.style.display = "none";
    }
    
    render() {
        const { UI : { loading} } = this.props;
        const tab = this.props.match.params.id;
        return (
            loading ? <Loading /> :
            <div className="plansContainer">
                 <Headroom style={{
                    WebkitTransition: 'all .5s ease-in-out',
                    MozTransition: 'all .5s ease-in-out',
                    OTransition: 'all .5s ease-in-out',
                    transition: 'all .5s ease-in-out',
                    }}
                    disableInlineStyles
                >
                    <div className="stickyMenu">
                         <div className='flex'>
                            <Link to="/dashboard">
                                <div className="icon">
                                    <img src={dashboardOutline} alt="icon" />
                                </div>
                            </Link>
                            <Link to="/friends/1">
                                <div className="icon">
                                    <img src={friendOutline} alt="icon" />
                                </div>
                            </Link>
                            <Link to="/wallet/1">
                                <div className="icon">
                                    <img src={walletOutline} alt="icon" />
                                </div>
                            </Link>
                            <div className="icon active">
                                <img src={plansOutline} alt="icon" />
                            </div>
                         </div>
                        <img className="divider" src={divider} alt="icon" />
                    </div>
                </Headroom>
                {this.state.isIntro && <IntroModal closeIntro={this.closeIntro}/>}
                <div ref={this.userInfoModal} className="modal">
                    <div className="modal-content userInfoContent">
                        <span className="userIcon"> 
                            {this.state.currentProfile.avatar ? 
                                <img className='avatar' src={`${BASE_URL}${this.props.avatars.filter(u => u.id === this.state.currentProfile.avatar)[0].image}`} alt="icon" /> : 
                                (
                                    this.state.currentProfile.profile_image ?  
                                        <img className='avatar' src={`${BASE_URL}${this.state.currentProfile.profile_image}`} alt="icon" /> : 
                                        <img src={dp} alt="icon" />
                                )}
                        </span>
                        <div className="name">{this.state.currentProfile.first_name}&nbsp;{this.state.currentProfile.last_name}</div>
                        <div className="buttons">
                            <button className="viewProfile" onClick={() => this.openProfile()}>View Profile</button>
                            {
                                localStorage.getItem("userId") !== this.state.currentProfile.id && !this.state.isContact && 
                                <div className='status' onClick={() => this.addFriend()}>
                                    Add now
                                </div>
                            }
                        </div>  
                    </div>
                </div>

                <div ref={this.whyNotInterested} className="modal ">
                    <div className="modal-content notInterestedContent">
                        <div className="header">
                            <div>Why You're Not Interested In This Plan?</div>
                            <img onClick={() => this.closeNotInterested()} src={closeIcon} alt="icon" />
                        </div>
                        <div className="content">
                            <RadioGroup onChange={e => this.setState({ notInterestedRadioId: e.target.value})} className='radioGroup' aria-label="plantype" name="customized-radios">
                                <RadioWithLabel value="1" label="This is not the plan I have applied for" />
                                <RadioWithLabel value="2" label="I need a different plan" />
                            </RadioGroup>
                        </div>
                        <button onClick={() => this.submitNotInterested()}>Submit Now</button>
                    </div>
                </div>

                <div ref={this.errorModal} className="modal ">
                    <div className="modal-content errorModal">
                        <div className="header">
                            <div>ALERT!</div>
                            <img onClick={() => this.closeErrorModal()} src={closeIcon} alt="icon" />
                        </div>
                        <div className="content">
                            {this.state.errorModalText}
                        </div>
                    </div>
                </div>

                <div ref={this.slotsFilledModal} className="modal">
                    <div className="modal-content slotFilled">
                        <div className="header">
                            <div>Wheez Message</div>
                            <img onClick={() => this.closeSlotFilled()} src={closeIcon} alt="icon" />
                        </div>
                        <div className="content">
                            We are sorry for not fulfilling your expectations, we have got your data and this won’t happen again. <br /><br /> Also if you want you can also re-apply again
                        </div>
                        <button onClick={() => this.closeSlotFilled()}>Apply Again</button>
                    </div>
                </div>

                <div className="plansPage" ref={this.planPageRef}>
                    <div className="tabSelect">
                        <input onChange={this.changeDummy} checked={tab === '1'} type="radio" name="toggle" id="walletTab" value="wallet" />
                        <label className="toggle toggle-yes contact" onClick={() => this.selectTab(1)}>
                            <img src={planFilled} alt="icon" />
                            <div>Plans</div>
                        </label>
                        <input onChange={this.changeDummy} checked={tab === '2'} type="radio" name="toggle" id="transactionTab" value="transaction" />
                        <label className="toggle toggle-yes" onClick={() => this.selectTab(2)}>
                            <img src={starIcon} alt="icon" />
                            <div className='inviteText'>Recommends</div>
                        </label>
                        <span className='spanner'></span>  
                    </div>
                    {tab === '1' && <div className="plansContainerInside">
                        <div className='planAdditionalNav'>
                            <span onClick={() => this.openHistory()}>History</span>
                            <Link to="/wallet/2"><span>Transactions</span></Link>
                            <span>Learn</span>
                        </div>
                        {this.props.user.userProfile && this.props.user.userProfile.limit && !(this.props.user.userProfile.limit > 0) && this.props.plans.demoPlans.length === 0 && <div className='messageInfo'>
                            <img src={beforeMCL} alt="icon" ></img>
                            <div className='title'>Monthly Contribution Limit</div>
                            <div className='info'>Get a Monthly contribution limit to join in a plan.</div>
                            <button onClick={() => this.limitPage()}>Get A MC Limit</button>
                            <div className='demoInfo'></div>
                        </div>}
                        {this.props.user.userProfile && this.props.user.userProfile.limit && this.props.user.userProfile.limit > 0 && this.props.plans.userPlans.length === 0 && !this.props.notifications.planApplicationThere && <div className='messageInfo'>
                            <img src={afterMCL} alt="icon" ></img>
                            <div className='title'>Apply for A plan</div>
                            <div className='info'>Get a Monthly contribution limit to join in a plan.</div>
                            <button onClick={() => this.openApplication()}>Apply Now</button>
                            <div className='demoInfo'></div>
                        </div>}

                        {this.props.plans.userPlans && this.props.plans.userPlans.length === 0 && this.props.notifications.planApplicationThere && <div style={{marginBottom:'30px'}} className='messageInfo'>
                            <img src={applyPlanNow} alt="icon" />
                            <div className='title'>Applied Successfully</div>
                            <div className='info'>We'll give you the right plans soon</div>
                            <div className='info'>If you think you want to re-apply again please apply again here</div>
                            <button onClick={() => this.openApplication()}>Apply Again</button>
                        </div>}
                        {
                            this.props.plans.userPlans.length > 0 && 
                            this.props.plans.userPlans.map(userPlan => 
                                <PlanCard 
                                    key={userPlan.id} 
                                    openUserInfo={this.openUserInfo} 
                                    userPlan={userPlan} 
                                />)
                        }
                        {
                            this.props.plans.demoPlans.length > 0 && 
                            this.props.plans.demoPlans.map(userPlan => 
                                <DemoPlanCard 
                                    key={userPlan.id} 
                                    openUserInfo={this.openUserInfo} 
                                    userPlan={userPlan} 
                                />)
                        }
                        {this.props.user.userProfile && this.props.user.userProfile.limit && !(this.props.user.userProfile.limit > 0) && this.props.plans.demoPlans.length > 0 && <div style={{marginBottom: "20px"}} className='messageInfo'>
                            <button onClick={() => this.limitPage()}>Get A MC Limit</button>
                        </div>}
                    </div>}
                    
                    {tab === '2' && 
                    <div id="recommendContainer">
                         <div className='mclInfo'>
                            <div className='info'>
                                <span className='title'>AVL Limit:</span>
                                <span className='value'>${this.props.user.userProfile && this.props.user.userProfile.available_limit && this.props.user.userProfile.available_limit}</span>
                            </div>
                        </div>
                        <RecommendSection 
                            plans={this.props.plans}
                            notifications={this.props.notifications} 
                            plansWithFirstAuction={this.state.plansWithFirstAuction} 
                            openApplication={this.openApplication}    
                            appliedSuccessSection={this.state.appliedSuccessSection}
                            openRecommendView={this.openRecommendView}
                            deleteRecommendedPlan={this.deleteRecommendedPlan}
                            joinPlan={this.joinPlan}
                            user={this.props.user}
                            openNotInterested={this.openNotInterested}
                            openUserInfo={this.openUserInfo} 
                            limitPage={this.limitPage}
                        />
                    </div>}
                </div>

                <div className='historyContainer' ref={this.historyRef}>
                    <div className="headerHistory">
                        <img onClick={() => this.backNavigateHistory()} src={backIcon} alt="icon"/>
                        <div className='title'>History</div>
                    </div>
                    {
                        this.props.plans.inactivePlans.length > 0 && 
                        this.props.plans.inactivePlans.map(userPlan => 
                            <PlanCard 
                                inActive={true}
                                key={userPlan.id} 
                                openUserInfo={this.openUserInfo} 
                                userPlan={userPlan} 
                            />
                        )
                    }
                    {
                        this.props.plans.inactivePlans.length === 0 && 
                        <div className='none'>
                            <img src={noHistory} alt="icon" />
                            <span>No Plans</span>
                        </div>
                    }
                </div>
                {this.state.isRecommendView && <ViewRecommend viewBottom={this.state.viewBottom} joinPlan={this.joinPlan} details={this.state.recommendedPlanDetails} closeModal={this.closeViewRecommend} />} 
            </div>
        )
    }
}

plans.propTypes = {
    getRecommendedPlans: PropTypes.func.isRequired,
    getUserPlans: PropTypes.func.isRequired,
    removePlanData: PropTypes.func.isRequired,
    getBasicPlanStatus: PropTypes.func.isRequired,
    removeRecommendFromStore: PropTypes.func.isRequired,
    sendBasicPlanApplication: PropTypes.func.isRequired,
    actionOnRecommendedPlans: PropTypes.func.isRequired,
    getInactivePlans: PropTypes.func.isRequired,
    getPublicProfileInfo: PropTypes.func.isRequired,
    sendRequest: PropTypes.func.isRequired,
    getDemoPlans: PropTypes.func.isRequired,
    getAllAvatars: PropTypes.func.isRequired,
    plans: PropTypes.object.isRequired,
    notifications: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => ({
    user: state.user,
    plans: state.plans,
    avatars: state.contacts.userAvatars,
    UI: state.UI,
    notifications: state.notifications
});
const mapActionsToProps = {
    getRecommendedPlans,
    getUserPlans,
    removePlanData,
    removeRecommendFromStore,
    sendBasicPlanApplication,
    getPublicProfileInfo,
    sendRequest,
    getBasicPlanStatus,
    actionOnRecommendedPlans,
    getInactivePlans,
    getAllAvatars,
    getDemoPlans
}

export default connect(mapStateToProps, mapActionsToProps)(plans);

