import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import OTPVerify from '../components/OTPVerify';
import RememberMe from '../components/RememberMe';
import Loading from '../components/Loading';
import { loginUser } from '../redux/actions/userActions';
import wheezLogin from './../assets/images/wheezLogin.svg';
import invisibleIcon from './../assets/images/icons/invisibleIcon.svg';
import visibleIcon from './../assets/images/icons/visibleIcon.svg';
import './../styles/login.scss';

class login extends Component {
    constructor(props) {
        super(props);
        this.usernameRef = React.createRef();
        this.passwordRef = React.createRef();
        this.visibleOFF = React.createRef();
        this.visibleON = React.createRef();
        this.OTPError = React.createRef();

        this.state = {
            errors: '',
            otp: '',
            username: '',
            password: '',
            verifyOTPModal: false,
            rememberMe: false
        }
    }
    componentDidMount(){
        if(this.props && this.props.user && this.props.user.authenticated){
            this.props.history.push("/dashboard")
        }
        let usernameFromCookie = this.getCookie('wUsername');
        let passwordFromCookie = this.getCookie('wPassword');
        this.setState({
            username : usernameFromCookie,
            password: passwordFromCookie
        })
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.UI.errors){
            this.setState({
                errors: nextProps.UI.errors,
            })
        }
    }

    togglePasswordVisibility = () => {
        if (this.passwordRef.current.type === "password") {
            this.visibleOFF.current.style.display = "block";
            this.visibleON.current.style.display = "none";
            this.passwordRef.current.type = "text";
        } else {
            this.visibleOFF.current.style.display = "none";
            this.visibleON.current.style.display = "block";
            this.passwordRef.current.type = "password";
        }
    }
    getCookie = (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(var i=0;i<ca.length;i++){
            let c = ca[i];
            while(c.charAt(0) === ' '){
                c = c.substring(1);
            }
            if(c.indexOf(name) === 0){
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    handleSubmit = (event) => {
        event.preventDefault();
        let clientKey = process.env.REACT_APP_SERVER_CLIENT_KEY;
        let loginParam = {
            "grant_type": "password",
            "username": this.usernameRef.current.value,
            "password": this.passwordRef.current.value,
            "client_id": clientKey
        };
        if(this.state.rememberMe){
            document.cookie = "wUsername=" + this.usernameRef.current.value + ";path=http://localhost:3000/";
            document.cookie = "wPassword=" + this.passwordRef.current.value + ";path=http://localhost:3000/";
        }

        this.props.loginUser(loginParam, this.props.history).then((res) => {
            if(res && res.payload && res.payload.two_factor_enabled) {
                this.setState({
                    verifyOTPModal: true
                });
            }
            
        });
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name] : event.target.value
        })
    }   
    OTPInput = (otp) => {
        this.setState({ otp });
    }
    comeBackFromOTP = () => {
        this.setState({ verifyOTPModal: false});
    }
    resendOTP = () => {
        let resendParam = {
            "session_secret": this.props.user.twoFactor.otp_session.session_secret,
            "user_id":  this.props.user.twoFactor.otp_session.user_id
        }
        this.props.resendOTP(resendParam);
    }
    submitOTP = () => {
        if(this.props && 
            this.props.user && 
            this.props.user.twoFactor &&
            this.props.user.twoFactor.otp_session &&
            this.props.user.twoFactor.otp_session.session_secret &&
            this.props.user.twoFactor.otp_session.user_id
             ) {
                let otpVerifyParam = {
                    "session_secret": this.props.user.twoFactor.otp_session.session_secret,
                    "otp": this.state.otp,
                    "user_id":  this.props.user.twoFactor.otp_session.user_id
                }
                this.props.verifyOTPLogin(otpVerifyParam, this.props.history).then((res) => {
                   if(res && res.payload && res.payload.errors && res.payload.errors === "Invalid OTP"){
                        this.OTPError.current.style.display = "flex";
                        for (let i = 0; i < document.getElementsByClassName("form-control-solid").length; i++) {
                            document.getElementsByClassName("form-control-solid")[i].style.backgroundColor = "rgba(250,104,108,0.43)";
                        }
                   }
                });

             }
    }
    handleChecked = (e) => {
        this.setState({
            rememberMe: e.target.checked
        })
    }
    render() {
        const { UI : { loading} } = this.props;
        const { errors } = this.state; 
        // let otpErrorMessage = '';
        // if(this.props.UI.errors && this.props.UI.errors.errors ){
        //     otpErrorMessage = this.props.UI.errors.errors;
        // }
        return (
            loading ? (<Loading /> ) : (<div className="loginContainer">
                <img className='wheezLogin' src={wheezLogin} alt="background" />
                <form className="signIn" onSubmit={this.handleSubmit} >
                    <div className='welcomeText'>Welcome Back!!!</div>
                    <div className="heading">Login</div>
                    <label className="input">
                        <input autoFocus className={`${((errors && errors.username) || (errors && errors.status && errors.status === 401)) ? 'input__field errorBorder' : 'input__field'}`} name="username" value={this.state.username} onChange={e => this.handleChange(e)} ref={this.usernameRef} type="text" placeholder="info@email.com" />
                        <span className="input__label">Username/Email</span>
                        {((errors && errors.username) || (errors && errors.status && errors.status === 401))&& <span className="error">
                            Invalid Username
                        </span>}
                    </label>
                
                    <label className="input">
                        <input className={`${((errors && errors.username) || (errors && errors.status && errors.status === 401)) ? 'input__field errorBorder password' : 'input__field password'}`} name="password" value={this.state.password} ref={this.passwordRef} onChange={e => this.handleChange(e)} type="password" placeholder="Password" autoComplete="new-password"/>
                        <img alt="icon" onClick={this.togglePasswordVisibility} ref={this.visibleON} className="visibleIcon" src={visibleIcon} />
                        <img alt="icon" onClick={this.togglePasswordVisibility} className="visibleIcon hide" ref={this.visibleOFF} src={invisibleIcon} />
                        {((errors && errors.username) || (errors && errors.status && errors.status === 401))&& <span className="error">
                            Invalid Password
                        </span>}
                    </label>
                    <FormControlLabel className="formLabel" control={<RememberMe handleChecked={this.handleChecked} size="small" />} label="Remember me" />
                    <button className="loginBtn" type="submit">Login</button>
                    <div className="createAccounSection">
                        <span className="text-default">Don't have an account?</span>
                        <Link to="/signup"><span className="text-link">Register</span></Link>
                    </div>
                </form>               
                {/* {this.state.verifyOTPModal && 
                    <OTPVerify 
                        otp={this.state.otp}
                        errorMessage={otpErrorMessage}
                        OTPError={this.OTPError}
                        OTPInput={this.OTPInput}
                        resendOTP={this.resendOTP}
                        submitOTP = {this.submitOTP}
                        comeBackFromOTP={this.comeBackFromOTP}
                    />}
                <div className="createAccounSection">
                    <div className="text-default">Not Register yet?</div>
                    <Link to="/signup"><div className="text-link">Create your account now</div></Link>
                </div> */}
            </div>)
        )
    }
}

login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    // resendOTP: PropTypes.func.isRequired,
    // verifyOTPLogin: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired
}
const mapStateToProps = (state) => ({
   user: state.user,
   UI: state.UI
});
const mapActionsToProps = {
    loginUser
    // verifyOTPLogin,
    // resendOTP
}

export default connect(mapStateToProps, mapActionsToProps)(login);