import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './../styles/expiredSession.scss';

class expiredSession extends Component {
    componentDidMount(){
        if(this.props && this.props.user && this.props.user.authenticated){
            this.props.history.push("/dashboard")
        }
    }
    render() {
        return (
            <div className="expiredSession">
                <div className='heading'>Session Expired</div>
                <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="43" cy="43" r="42.5" stroke="#4283FF"/>
                    <rect x="31.8889" y="28.5555" width="24.4444" height="28.8889" fill="#FDFDFD"/>
                    <path d="M50.2331 26.3335C55.8831 26.3335 59.6665 30.2985 59.6665 36.1985V49.8152C59.6665 55.7002 55.8831 59.6668 50.2331 59.6668H35.7831C30.1331 59.6668 26.3331 55.7002 26.3331 49.8152V36.1985C26.3331 30.2985 30.1331 26.3335 35.7831 26.3335H50.2331ZM42.4165 34.5318C41.7331 34.5318 41.1665 35.1002 41.1665 35.7818V44.2002C41.1665 44.6335 41.3998 45.0502 41.7831 45.2668L48.3165 49.1668C48.5165 49.3002 48.7498 49.3502 48.9665 49.3502C49.3831 49.3502 49.7998 49.1335 50.0331 48.7335C50.3998 48.1485 50.1998 47.3818 49.5998 47.0168L43.6665 43.4835V35.7818C43.6665 35.1002 43.1165 34.5318 42.4165 34.5318Z" fill="#4283FF"/>
                </svg>
                <div className='description'>
                    Your session expired due to inactivity <br/>No worry, simply login again.
                </div>
                <Link to="/login"><button>Login</button></Link>
            </div>
        )
    }
}

expiredSession.propTypes = {
    user: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => ({
   user: state.user,
   UI: state.UI
});


export default connect(mapStateToProps)(expiredSession);