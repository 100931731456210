import React, { useState, useRef, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { useHistory } from "react-router";
import { Swiper, SwiperSlide } from 'swiper/react';
import moment from 'moment'
import { useDispatch, useSelector } from "react-redux";
import TimerBidding from './TimerBidding';
import Loading from './../Loading';
import BidMessage from './BidMessage';
import { triggerScrollEnd } from '../../redux/actions/biddingActions';
import { 
    socketConnect, 
    getAuctionDetails,
    auctionStatusRunningOrScheduled,
    closeConnection,
    setFirstAuctionId,
    startUI,
    clearAuction,
    getPlanDetailsBidding
    } 
from '../../redux/actions/liveBiddingActions';
import dropWhite from './../../assets/images/icons/dropIcon.svg';
import upcomingAuction from './../../assets/images/icons/upcomingAuction.svg';
import backIcon from './../../assets/images/icons/backIcon.svg';
import divider from './../../assets/images/icons/divider.svg';
import chatIcon from './../../assets/images/icons/chatIcon.svg';
import ellipsisOutlineBox from './../../assets/images/icons/ellipsisOutlineBox.svg';


function LiveBiddingView(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const planId = id;
    const liveBiddingState = useSelector((state) => state.liveBidding);
    const UI = useSelector((state) => state.UI);
    const biddingData = useSelector((state) => state.practiceBidding);
    const messageContentRef = useRef();
    const [isExpandedOpen, setIsExpandedOpen] = useState(false);
    const [planInfoEnd, setPlanInfoEnd] = useState(false);
    const [isListOpenPlan, setIsListOpenPlan] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [isScheduledAuction, setIsScheduledAuction] = useState(false);
    // const [scheduledAuctionInfo, setScheduledAuctionInfo] = useState("");
    const [upcomingText, setUpcomingText] = useState("");
    const [auctions, setAuctions] = useState([]);
    const [forfeitCount, setForfeitCount] = useState(0);
    const [selectedAuction, setSelectedAuction] = useState({});
    const [keyTimer, setKeyTimer] = useState(0);
    const [isSwiper, setIsSwiper] = useState(false)
    const [loading, setLoading] = useState(true)
    const {
        biddingConfig,
        bidData,
        planDetails,
        auctionId,
        auctionFinished,
    } = liveBiddingState;
    useEffect(() => {
        if(isListOpenPlan === false && selectedAuction){
        } else {
            document.addEventListener('mousedown', handleClickOutside);
        }
        if(isExpandedOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    });
    useEffect( () => {
        setIsSwiper(true)
    },[])

    useEffect(() => {
        if(!UI.biddingLoading){
            setLoading(false);
        } 
        // setLoading(false);

    }, [UI.biddingLoading]);
   
    useEffect(() => {
        if(biddingData.scrollTrigger && messageContentRef.current) {
            messageContentRef.current.scrollTop = messageContentRef.current.scrollHeight;
        } 
        return () => {
            dispatch(triggerScrollEnd());
        }
    }, [dispatch, biddingData.scrollTrigger]);

    useEffect(() => {
        let forfeitedCount = 0;
        if(bidData.length > 0 && isRunning){
            bidData.map((data) => {
                if(data.event_type.toLowerCase() === "flag"){
                    forfeitedCount++;
                }
                return null;
            })
        }
        setForfeitCount(forfeitedCount)
    }, [bidData, isRunning]);

    useEffect(() => {
        if(planDetails.available_to === 0 && !planDetails.auction_running && planDetails.auctions.length > 0 && selectedAuction.id === planDetails.auctions[planDetails.auctions.length - 1].id){
            setPlanInfoEnd(true);
        } else {
            setPlanInfoEnd(false);

        }
    }, [planDetails, selectedAuction]);

    useEffect(() => {
        if(planDetails.auctions && planDetails.auctions.length > 0){
            planDetails.auctions.map((auction) => {
                if(auction.status.toLowerCase() === "running" && auction.id === auctionId && biddingConfig.ends_in && biddingConfig.ends_in > 0){
                    setIsRunning(true);
                    dispatch(startUI());
                    setKeyTimer(keyTimer => keyTimer + 1);
                }
                if(auction.status.toLowerCase() === "scheduled" && auction.id === auctionId){
                    setIsRunning(false);
                    setIsScheduledAuction(true);
                    // setScheduledAuctionInfo(auction.starts_at);
                }
                if(auction.status.toLowerCase() === "completed" && auction.id === auctionId ){
                    setIsRunning(false);
                    dispatch(getAuctionDetails(auctionId));
                }
                // calculating
                return null;
            })
        } else {
            dispatch(startUI());
        }
    }, [planDetails.auctions, biddingConfig.ends_in, auctionId, dispatch, selectedAuction.id]);

    useEffect(() => {
        if(planDetails.auctions && planDetails.auctions.length > 0 && isEmpty(selectedAuction)){
            let runningaAuctions = planDetails.auctions.filter((auction) => {
                return auction.status.toLowerCase() === "running"
            })
            let completedAuctions = planDetails.auctions.filter((auction) => {
                return auction.status.toLowerCase() === "completed"
            })
            let scheduledAuctions = planDetails.auctions.filter((auction) => {
                return auction.status.toLowerCase() === "scheduled"
            })
            // calculating
            let finalAuctionList = runningaAuctions.concat(completedAuctions);
            if(scheduledAuctions.length > 0){
                finalAuctionList[finalAuctionList.length]=scheduledAuctions[0];
            }
            setAuctions(finalAuctionList);
            if(runningaAuctions.length === 0){
               
                dispatch(setFirstAuctionId(finalAuctionList[finalAuctionList.length-1].id))
                setSelectedAuction(finalAuctionList[finalAuctionList.length-1]);
            } else {
                dispatch(setFirstAuctionId(finalAuctionList[0].id))
                setSelectedAuction(finalAuctionList[0]);
            }
            setKeyTimer(keyTimer => keyTimer + 1);
            dispatch(startUI())
        }
    }, [planDetails.auctions, dispatch, selectedAuction]);

    useEffect(() => {
        const secondsTimer = setInterval(() => {
            if(planDetails.auctions && planDetails.auctions.length > 0){
                planDetails.auctions.map((auction) => {
                    if(auction.status.toLowerCase() === "scheduled" && auction.id === auctionId){
                        let startTime = new Date(auction.starts_at);
                        let currentTime = new Date();
                        let diff = startTime-currentTime;
                        if (diff <= 60e3 && Math.floor(diff / 1e3) === -1) {
                            clearInterval(secondsTimer);
                            window.location.reload()
                        } 
                        let difference = startTime.getTime() - currentTime.getTime();
                        if(difference <= 24*60*60*1000 && (startTime.getTime() >= currentTime.getTime())){
                            setUpcomingText(`Next Auction starting in<br /><span className='dated'>${msToTime(difference)}</span>`)
                        } else {
                            setUpcomingText(`Next Auction Will be at <br /><span className='dated'>${moment(auction.starts_at).format("DD/MM/YYYY HH:mm:ss")}</span>`)
                        }
                    }
                    return null;
                })
            }
        }, 1000);
        return () => clearInterval(secondsTimer);
    }, [planDetails, auctionId]);

    useEffect(() => {
        if(auctionFinished){
            setIsRunning(false);
            setKeyTimer(keyTimer => keyTimer + 1);
        }
    }, [auctionFinished]);

    const msToTime = (duration) => {
        let seconds = Math.floor((duration / 1000) % 60);
        let minutes = Math.floor((duration / (1000 * 60)) % 60);
        let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
      
        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;
        return hours + ":" + minutes + ":" + seconds;
    }
    const handleClickOutside = (event) => {
        if(isListOpenPlan && event.target.className !== "taskDropItem" && event.target.className !== "planDropdown"){
            setIsListOpenPlan(false);
        }
        if(isExpandedOpen && event.target.className !== "menuDrop" && event.target.className !== "menu-item") {
            if(!(props.isGuide && props.guideStep === 10)){
                setIsExpandedOpen(false);
            }
        }
    }
    const isEmpty = (obj) => {
        for(var prop in obj) {
          if(Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
          }
        }
        return JSON.stringify(obj) === JSON.stringify({});
    }
    const toggleExpanded = () => {
        setIsExpandedOpen(!isExpandedOpen);
    }
    const selectMenuItem = (id) => {
        setIsExpandedOpen(false);
        if(id === 1){
            props.setIsRulesOpened(true)
        }
        if(id === 2){
            history.goBack();
        }
        if(id === 3){
            history.push(`/planDetails/${planId}`);
        }
    }
    
    const toggleListPlan = () => {
        setIsListOpenPlan(!isListOpenPlan);
    }
    const selectItem = (item) => {
        const {id} = item;
        if(item.id !== selectedAuction.id){
            auctions.map((auction) => {
                if(auction.id === id){
                    setSelectedAuction(auction);
                    dispatch(closeConnection());
                    if(auction.status.toLowerCase() === "running"){
                        dispatch(auctionStatusRunningOrScheduled());
                        dispatch(socketConnect(id));
                        props.setSelectedAuction(auction)
                        setIsScheduledAuction(false);
                    } else if(auction.status.toLowerCase() === "completed") {
                        dispatch(getAuctionDetails(id));
                        setIsRunning(false);
                        setKeyTimer(keyTimer => keyTimer + 1);
                        setIsScheduledAuction(false);
                    } else if(auction.status.toLowerCase() === "scheduled"){
                        dispatch(auctionStatusRunningOrScheduled());
                        setIsScheduledAuction(true);
                        dispatch(clearAuction(id));
                        dispatch(getPlanDetailsBidding(planId));
                        setIsRunning(false);
                        setKeyTimer(keyTimer => keyTimer + 1);
                        // setScheduledAuctionInfo(auction.starts_at);
                    }
                }
                return null;
            })
        }
        // setIsListOpenPlan(false);
    }
 
    return (
        loading ? <Loading /> :
        <section className="practiceBidding">
            <div className="fixedHeader">
                <div className="leftRowTop">
                    <img onClick={() => history.goBack()} src={backIcon} alt="icon" />
                    {selectedAuction && selectedAuction.status && selectedAuction.status.toLowerCase() === "running" && <span className='liveHeading'>Live</span>}
                </div>
                <div className="heading">
                    Auction Room
                </div>
                <div className="expandedMenu">
                    <div className="dropdown" data-tut="context">
                        <div onClick={toggleExpanded} className="menuDrop">
                            <img src={ellipsisOutlineBox} alt="icon"/>
                        </div>
                        {isExpandedOpen && (
                            <div role="list" className="dropdown-content-menu">
                                <span className="menu-item" onClick={() => selectMenuItem(3)}>Details</span>
                                <span className="menu-item" onClick={() => selectMenuItem(1)}>Rules</span>
                                <span className="menu-item" onClick={() => selectMenuItem(2)}>Exit</span>
                            </div>
                        )}
                    </div>
                </div>                 
                <div className="timerBarContainer">
                    <div className="timerBar">
                        <div className="left">
                            {auctions.length > 0 ? <div className="dropdown" onClick={()=> toggleListPlan()}>
                                <div style={{justifyContent: "center", fontSize: '12px'}} className="planDropdown">
                                    <span>{selectedAuction.title}</span>
                                    <img src={dropWhite} alt="icon"/>
                                </div>
                                {isListOpenPlan && (
                                    <div role="list" className="dropdown-content-task">
                                        {auctions.map((item) => {
                                            if(item){
                                                return (<span className="taskDropItem" key={item.id} onClick={() => selectItem(item)}>
                                                    {item.title}
                                                </span>)
                                            } else {
                                                return null
                                            }
                                        })}
                                    </div>
                                )}
                            </div>: <div style={{height: '30px'}}></div>}
                        </div>
                        
                        <div className="timer">
                            <TimerBidding 
                                biddingData={
                                    {
                                        biddingStarted: isRunning, 
                                        keyTimer: keyTimer,
                                        totalTime: planDetails.duration && isRunning ? planDetails.duration : 0,
                                        timer: biddingConfig.ends_in ? biddingConfig.ends_in : 0
                                    }
                                } 
                                />                            
                        </div>
                        {!props.demo && <div className="right">
                            <div style={{minHeight: '32px'}} className="dropdown">
                                <div onClick={() => props.openChatBox()} className="planDropdown dropRight live">
                                    <img src={chatIcon} alt="icon"/>
                                    <span>Chat</span>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="planTags">
                     {isSwiper && <Swiper  
                        spaceBetween={8}
                        direction={"horizontal"}
                        slidesPerView= "auto"
                        className="mySwiper">
                        <SwiperSlide>
                            <div className="tag">
                                <span className="title">Online:</span>
                                <span className="value">{isRunning && planDetails.members ? planDetails.members.length : '--'}</span>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="tag">
                                <span className="title">PTAP:</span>
                                <span className="value">{isRunning && planDetails.ptap ? `${planDetails.ptap.substring(0, planDetails.ptap.length - 3)}` : '$ ----'}</span>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="tag">
                                <span className="title">Forwent Members:</span>
                                <span className="value">{isRunning && forfeitCount ? forfeitCount : '--'}</span>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="tag">
                                <span className="title">Available Members:</span>
                                <span className="value">{isRunning && planDetails.available_to ? planDetails.available_to - forfeitCount : '--'}</span>
                            </div>
                        </SwiperSlide>
                    </Swiper>}
                </div>
                <img src={divider} alt="icon" className="divider"></img>
            </div>
           
            {auctions.length > 0 && !isScheduledAuction && !planInfoEnd && <div ref={messageContentRef} className="bidThreadsContainer">
                {bidData.length > 0 && bidData.map((data, index) => {
                        if(data.event_type.toLowerCase() === "system" && data.bid === null && data.message.toLowerCase() === "auction ended"){
                            return <div key={index} className="auctionEnded">Auction ended</div>
                        }
                        if(data.event_type.toLowerCase() === "bid"){
                            return <BidMessage 
                                planDetails={planDetails}
                                openUserInfoModal={props.openUserInfoModal} 
                                key={data.id} 
                                bidData={data} 
                            />
                        }
                        if(data.event_type.toLowerCase() === "flag"){
                            return <div key={data.id} className="forfeitThread">
                                    {localStorage.userId === data.user_flag.user.id ? "You" : data.user_flag.user.first_name} forwent
                                </div>
                        }
                        return <div key={data.id}></div>
                    }
                )}
            </div>}
           
            {auctions.length === 0 && !loading && <div className="infoContainer">
                {auctions.length === 0 &&<div className="nextBid">
                    <img src={upcomingAuction} alt="icon" />
                    <div className="title">Notification!</div>
                    <div className="info">The plan has not started yet. to learn more features about bid room, go to practice bid.</div>
                    <Link to="/practiceBidding"><button>Practice bid</button></Link>
                </div>}
            </div>}

            {planInfoEnd && <div className="planSummaryEnd">
                <div className='content'>
                    <div className="info">Plan has been ended</div>
                    <Link to={`/planDetails/${planId}`}><button>View Plan Summary</button></Link>
                </div>
            </div>}


            {isScheduledAuction && !loading && upcomingText && <div className="infoContainer">
                <div className="nextBid">
                    <img src={upcomingAuction} alt="icon" />
                    <div className="title">Upcoming Auction!</div>
                    <div className="info" dangerouslySetInnerHTML={{__html: upcomingText}}></div>
                </div>
            </div>}
        </section>
    )
}

export default LiveBiddingView