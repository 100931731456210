import React, {useState, useEffect} from 'react'
import { useSelector } from "react-redux";
import whiteFlag from './../../assets/images/icons/flag.svg';
import lockIcon from './../../assets/images/icons/lockBlack.svg';
import unlockIcon from './../../assets/images/icons/lockWhite.svg';
import divider from './../../assets/images/icons/divider.svg';

function BiddingFooter(props) {
    const liveBiddingState = useSelector((state) => state.liveBidding);
    const UI = useSelector((state) => state.UI);
    const [isRunning, setIsRunning] = useState(false);
    const [isForgoEnabled, setIsForgoEnabled] = useState(false);
    const [bidDisabled, setBidDisabled] = useState(false)
    const [unlockTime, setUnlockTime] = useState(0);
    const [auctionWinner, setAuctionWinner] = useState({})
    // const [hasUserBid, setHasUserBid] = useState(false);
    const { currentBid, planDetails, auctionId, bidData, biddingConfig, flagEnabledFromNetwork } = liveBiddingState;
    
    useEffect(() => {
        if(planDetails.auctions && planDetails.auctions.length > 0){
            planDetails.auctions.map((auction) => {
                if(auction.status.toLowerCase() === "running" && auction.id === auctionId){
                    setIsRunning(true);
                } else {
                    if(auction.id === auctionId){
                        setIsRunning(false);
                    }
                }
                return null;
            })
        }
    }, [planDetails.auctions, auctionId]);

    useEffect(() => {
        for(let i = 0; i<bidData.length; i++){
            if(bidData[i].event_type.toLowerCase() === "winner"){
                setAuctionWinner(bidData[i].auction_winner)
                break;
            }
        }
        if(bidData.length === 0){
            setAuctionWinner({})
        }
    }, [bidData]);
    useEffect(() => {
        if(props.lastAuctionData){
            setAuctionWinner(props.lastAuctionData)
        }
    }, [props.lastAuctionData]);

    useEffect(() => {
        if(biddingConfig.flag_enabled || flagEnabledFromNetwork){
            setIsForgoEnabled(true);
            if(bidData.length > 0){
                bidData.map((data)=> {
                    if(data.bid && data.bid.user && data.bid.user.id === localStorage.userId){
                        // setHasUserBid(true);
                    } 
                    return null
                })
            }
        } else {
            setIsForgoEnabled(false);
        }
        if(biddingConfig.bid_disabled){
            setBidDisabled(biddingConfig.bid_disabled)
        }
    }, [currentBid, bidData, biddingConfig, flagEnabledFromNetwork]);

    useEffect(() => {
        const secondsTimer = setInterval(() => {
            if(planDetails.auctions && planDetails.auctions.length > 0){
                planDetails.auctions.map((auction) => {
                    if(auction.status.toLowerCase() === "running" && auction.id === auctionId){
                        let startTime = new Date(auction.starts_at);
                        let hms =  planDetails.duration;
                        let a = hms.split(':');
                        let seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 
                        let secondsToCover = Math.round(seconds/3);
                        let unlockTime = new Date(startTime.getTime() + secondsToCover*1000);
                        let currentTime = new Date();
                        let diff = unlockTime-currentTime;
                        if (diff <= 60e3 && Math.floor(diff / 1e3) === -1) {
                            clearInterval(secondsTimer);
                        } 
                        let difference = unlockTime.getTime() - currentTime.getTime();
                        if(difference > 0){
                            setUnlockTime(msToTime(difference));
                        }
                    }
                    return null;
                })
            }
        }, 1000);
        return () => clearInterval(secondsTimer);
    }, [planDetails, auctionId]);
 
    const msToTime = (duration) => {
        let seconds = Math.floor((duration / 1000) % 60);
        let minutes = Math.floor((duration / (1000 * 60)) % 60);
        let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
      
        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;
        hours = (hours !== "00") ? hours + ":" : "";
        return hours + minutes + ":" + seconds;
    }

    const handleForfeit = () => {
        if(isForgoEnabled && !props.lockStatus) {
            if(currentBid.bid.user.id !== localStorage.userId ){
                props.handleForfeit();
            } else {
                props.currentBidderCantForgoModal();
            }
        }
    }

    const handleBidButton = () => {
        if(planDetails && planDetails.taken){
            props.cantBidModal();
        } else {
            if(!props.lockStatus){
                props.openBidSelectModal();
            } else {
                props.noBidAfterForwent();
            }
        }
    };
    const isEmpty = (obj) => {
        for(var prop in obj) {
          if(Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
          }
        }
        return JSON.stringify(obj) === JSON.stringify({});
    }
    return (
        !UI.loading ?
        <section className="bidValueSection">
            {isEmpty(auctionWinner) && <div className="bidValueRow">
                <img src={divider} alt="icon" className="divider"></img>
                <span className={(!props.lockStatus || !planDetails.taken) ? 'lockButton' : 'lockButton locked'}>
                     {(!props.lockStatus || !planDetails.taken) ?
                        <img src={unlockIcon} alt="icon" /> :
                        <img src={lockIcon} alt="icon" />
                        }
                </span>
                 
                <button onClick={()=> handleBidButton()} id="bidBtn">
                    {currentBid.bid && currentBid.bid.asking_amount 
                    ? `$${currentBid.bid.asking_amount}` 
                    : "$0"
                    }
                </button>
                <div className='flatButtonDiv' style={{marginBottom: isRunning && !isForgoEnabled && !bidDisabled ? '-32px' : '0px'}}>
                    <span className="flagButton" onClick={() => handleForfeit()} >
                        <img src={whiteFlag} alt="icon"/>
                    </span>
                    {isRunning && !isForgoEnabled && !bidDisabled && <div className='lockedText'>Unlocks in</div>}
                    {isRunning && !isForgoEnabled && !bidDisabled && <div className='lockTimer'>{unlockTime}</div>}
                </div>
            </div>}
            {(props.lockStatus || planDetails.taken) && <div className="overlay"></div>}

            {!isEmpty(auctionWinner) && <div className='auctionWinner'>
                <img src={divider} alt="icon" className="divider"></img>
                <div className='userWon'>{auctionWinner.winner.first_name} has won the auction</div>
                <div className='amountTaken'>
                    <span className='title'>Amount Taken:</span>
                    <span className='value'>${auctionWinner.total_amount_received}</span>
                </div>
                <button onClick={() => props.openPlanEndModal()}>View Summary</button>
            </div>}
            {currentBid 
                && currentBid.bid 
                && currentBid.bid.user 
                && isEmpty(auctionWinner) &&
                <div className="bidCurrentText">
                    {isRunning && (currentBid.bid.user.id === localStorage.userId ? 'You have the bid' : `${currentBid.bid.user.first_name} have the bid`)}
                    {!isRunning && (currentBid.bid.user.id === localStorage.userId ? 'You are the winner' : `${currentBid.bid.user.first_name} is the winner`)}
                </div>}
            {(isEmpty(auctionWinner) && (currentBid && !currentBid.bid)) && <div style={{minHeight: '17.33px'}} className="bidCurrentText"></div>}
        </section> : <></>
    )
}

export default BiddingFooter