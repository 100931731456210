import {
    GET_BALANCE,
    GET_ALL_TRANSACTIONS,
    DUE_BALANCE,
    ADD_TO_TRANSACTIONS,
    SHOULD_FETCH_MORE_TRANSACTIONS
  } from '../types';
  
  const initialState = {
    balance: "",
    dueBalance: "",
    allTransactions: [],
    fetchMore: true
  };
  
  export default function walletReducer(state = initialState, action) {
    switch (action.type) {
        case GET_BALANCE:
            return {
              ...state,
              balance: action.payload
            };
        case DUE_BALANCE:
            return {
              ...state,
              dueBalance: action.payload
            };
        case GET_ALL_TRANSACTIONS:
            return {
              ...state,
              allTransactions: action.payload
            };
        case ADD_TO_TRANSACTIONS:
          let final = [];
          final.push(...state.allTransactions)
          final.push(...action.payload)
          return {
            ...state,
            allTransactions:  final
          };
        case SHOULD_FETCH_MORE_TRANSACTIONS:
          return {
            ...state,
            fetchMore: action.payload
          }
        default:
            return state;
    }
  }