import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useSelector } from "react-redux";
import userIconWhite from './../../assets/images/icons/userIconWhite.svg';
import userIconBlack from './../../assets/images/icons/userIconBlack.svg';
const BASE_URL = `http://${process.env.REACT_APP_WHEEZ_SERVER_URL}`;

export default function BidMessage(props) {
    const contacts = useSelector((state) => state.contacts);
    const { userAvatars } = contacts;
    const userId = localStorage.userId;
    const bidData = props.bidData;

    const [avatar, setAvatar] = useState(null)

    useEffect(() => {
        if(bidData.bid && bidData.bid.user && bidData.bid.user.avatar){
            const userAvatar = userAvatars.filter(u => u.id === bidData.bid.user.avatar)
            if(userAvatar.length > 0){
                setAvatar(userAvatar[0])
            }
        }
    },[bidData, userAvatars])

    return (
        <div className={userId === bidData.bid.user.id ? "bidThread bidThreadRight" : "bidThread bidThreadLeft"}>
            <span className={bidData.bid.user.id === userId ? 'userIcon ownUser' : 'userIcon'} onClick={() => props.openUserInfoModal(bidData)} > 
                {bidData.bid.user.id === userId ?
                (
                    avatar ? <img className='avatar' src={`${BASE_URL}${avatar.image}`} alt="icon" /> 
                    : ( bidData.bid.user && bidData.bid.user.profile_image ? 
                        <img className='avatar' src={`${bidData.bid.user.profile_image}`} alt="icon" /> 
                        :
                        <img src={userIconWhite} alt="icon" />
                    )
                )
                : 
                (
                    avatar ? <img className='avatar' src={`${BASE_URL}${avatar.image}`} alt="icon" /> 
                    : ( bidData.bid.user && bidData.bid.user.profile_image ? 
                        <img className='avatar' src={`${bidData.bid.user.profile_image}`} alt="icon" /> 
                        :
                        <img src={userIconBlack} alt="icon" />
                    )

                )}
            </span>
            <div className="threadContainer">
                {bidData.bid.bid_at && <span className="time">{moment(bidData.bid.bid_at).format('h:mm:ss A')}</span>}
                <div className="bidText">{bidData.bid.user.id === userId ? 'You' : bidData.bid.user.first_name} have asked for</div>
                <div className="value">
                    <span>${bidData.bid.bid_against ? bidData.bid.bid_against.asking_amount : (props.planDetails.total_amount ? props.planDetails.total_amount : 0)}</span>
                    <span>&nbsp;- ${bidData.bid.deducted_amount}</span>
                </div>
                <div className="finalValue">${bidData.bid.asking_amount}</div>
            </div>
        </div> 
    )
}