import React from 'react'
import { useSelector } from "react-redux";
import { useTour } from '@reactour/tour'
import whiteFlag from './../../assets/images/icons/flag.svg';
import lockIcon from './../../assets/images/icons/lockBlack.svg';
import unlockIcon from './../../assets/images/icons/lockWhite.svg';
import divider from './../../assets/images/icons/divider.svg';

function BiddingFooter(props) {
    const biddingData = useSelector((state) => state.practiceBidding);
    const { isOpen, setIsOpen, setCurrentStep } = useTour()

    const handleForfeit = () => {
        if(biddingData.biddingStarted){
            if(isOpen){
                setIsOpen(false)
            }
            if(!props.lockStatus && biddingData.currentBidUser && biddingData.currentBidUser.id && biddingData.currentBidUser.id !== 1 ){
                let diUserBidYet = false;
                for (var i = 0; i < biddingData.bidLog.length; i++) {
                    if (biddingData.bidLog[i].id === 1) {
                        diUserBidYet = true;
                        break;
                    }
                }
                if(diUserBidYet){
                    props.handleForfeit();
                } else {
                    props.openAlertNotBid();
                }
            } else {
                let diUserForgoYet = false;
                for (var j = 0; j < biddingData.bidLog.length; j++) {
                    if (biddingData.bidLog[j].id === 1 && biddingData.bidLog[j].type === "self_forfeit") {
                        diUserForgoYet = true;
                        break;
                    }
                }
                if(diUserForgoYet){
                    props.openLockedAlert();
                }
            }
            if(props.isTourOpenTaskForfeit){
                props.setIsTourOpenTaskForfeit(false)
            }
        } else {
            setIsOpen(true);
            setCurrentStep(9)
        }
    }
    const handleLock = () => {
        if(!biddingData.biddingStarted){
            setIsOpen(true);
            setCurrentStep(8)
        }
    }
    const handleBidButton = () => {
        if(biddingData.biddingStarted){
            props.openBidSelectModal();
            if(props.isTourOpenTask){
                props.setCurrentTaskTourId(2)
            }
        } else {
            setIsOpen(true);
            setCurrentStep(7)
        }
    }
    return (
        <section className="bidValueSection">
            <div className="bidValueRow" style={{pointerEvents: props.lockStatus ? 'none':'auto'}}>
                <img src={divider} alt="icon" className="divider"></img>
                <span data-tut="lock" onClick={() => handleLock()} className={!props.lockStatus ? 'lockButton' : 'lockButton locked'}>
                     {!props.lockStatus ?
                        <img src={unlockIcon} alt="icon" /> :
                        <img src={lockIcon} alt="icon" />
                        }
                </span>
                {biddingData.currentBidUser && biddingData.currentBidUser.id ? 
                    <button data-tut="placeBidTask" onClick={()=> handleBidButton()} id="bidBtn">
                        ${biddingData.currentBidUser.bidAmount}
                    </button>
                    :
                    <button data-tut="placeBid" onClick={()=> handleBidButton()} id="bidBtn">
                        $8700
                    </button>
                }
                <div className='flatButtonDiv' style={{marginBottom: !props.lockStatus && biddingData.currentBidUser && biddingData.currentBidUser.id && biddingData.currentBidUser.id === 1 ? '-36px' : '4px'}}>
                    <span data-tut="forgo" className='flagButton' onClick={() => handleForfeit()}>
                        <img src={whiteFlag} alt="icon"/>
                    </span>
                    {!props.lockStatus && biddingData.currentBidUser && biddingData.currentBidUser.id && biddingData.currentBidUser.id === 1 && <span className='lockedText'>Locked</span>}
                </div>
            </div>
            {props.lockStatus && <div className="overlay"></div>}
            {biddingData.currentBidUser && biddingData.currentBidUser.id ? 
                <div className="bidCurrentText">{biddingData.currentBidUser.name} has the bid</div>
                :
                <div style={{minHeight: '13.33px'}} className="bidCurrentText"></div>
            }
        </section>
    )
}

export default BiddingFooter