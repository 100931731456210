import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Headroom from 'react-headroom'
import { PieChart } from 'react-minimal-pie-chart';
import moment from 'moment'
import Footer2 from '../components/Footer2';
import Loading from '../components/Loading';
// import PlanNotification from '../components/PlanNotification';
import AccountSetup from '../components/AccountSetup';
import AmountTakenNotfication from '../components/AmountTakenNotfication';
// import JoinPlanNotification from '../components/JoinPlanNotification';
import AppliedMCLNotification from '../components/AppliedMCLNotification';
import PlanApplicationNotification from '../components/PlanApplicationNotification';

import { 
    getUserPlans, 
    setCurrentPlan, 
    simpleGetUserPlans, 
    getPlanDetails,
    getNextAuctionDate,
    getCurrentAuction, 
    getPrevAuction,
    getPlanPortfolio,
    getPlanAuctionWinningInfo,
    simpleGetRecommendedPlans,
    simpleUserPlans
} from '../redux/actions/planActions';
import practiceIntroIcon from './../assets/images/icons/practiceIntroIcon.svg';
import closeIcon from './../assets/images/icons/closeIcon.svg';
import alertIcon from './../assets/images/icons/alertIcon.svg';
import recentIcon from './../assets/images/icons/recentIcon.svg';
import divider from './../assets/images/icons/divider.svg';
import noActivity from './../assets/images/noActivity.svg';
import dashboardOutline from './../assets/images/icons/dashboardOutline.svg';
import plansOutline from './../assets/images/icons/plansOutline.svg';
import walletOutline from './../assets/images/icons/walletOutline.svg';
import friendOutline from './../assets/images/icons/friendOutline.svg';
import auctionRoomIcon from './../assets/images/icons/auctionRoomIcon.svg';
import chatRoomIcon from './../assets/images/icons/chatRoomIcon.svg';
import './../styles/dashboard.scss';
var ordinal = require('ordinal')
class dashboard extends Component {
    constructor(props) {
        super(props);
        this.infoRef = React.createRef();
        this.dropIconRef = React.createRef();
        this.planRef = React.createRef();
        this.noPlansModal = React.createRef();
        this.floaterRef = React.createRef();
        this.state ={
            userPlans: [],
            currentPlan: {},
            isListOpen: false,
            isDelayList: false,
            prevAuction: {},
            isLive: false,
            nextAuctionDate: null,
            segmentOne: true,
            segmentTwo: true,
            anchorEl: null
        }
    }

    componentDidUpdate(prevProps){
        const { isListOpen } = this.state;
        setTimeout(() => {
            if(isListOpen){
            window.addEventListener('click', this.closeDropDown)
            }
            else{
            window.removeEventListener('click', this.closeDropDown)
            }
        }, 0);
        if(prevProps.plans.currentSelectedPlan !== this.props.plans.currentSelectedPlan){
            this.props.getNextAuctionDate(this.state.currentPlan.id).then(next => {
                if(next){
                    this.setState({
                        nextAuctionDate : next.data.starts_at
                    })
                } else {
                    this.setState({
                        nextAuctionDate : null
                    })
                }
            })
        }
    }
   
    componentDidMount(){
        this.props.simpleGetRecommendedPlans()
        this.props.simpleUserPlans()
        this.props.simpleGetUserPlans().then((res) => {
            if(res && res.payload && res.payload.length > 0){
                this.setState({
                    userPlans: res.payload,
                    currentPlan: res.payload[0]
                })
                this.props.setCurrentPlan(res.payload[0])
                this.props.getPrevAuction(res.payload[0].id).then(prevData => {
                    if(prevData){
                        this.setState({
                            prevAuction: prevData.data
                        })
                    }
                })
                this.props.getCurrentAuction().then(showedAuction => {
                    if(showedAuction){
                        this.props.getPlanPortfolio(showedAuction.data.plan.id)
                        if(showedAuction && showedAuction.data && showedAuction.data.status && (showedAuction.data.status.toLowerCase() === "running" || showedAuction.data.status.toLowerCase() === "completed")){
                            if(showedAuction.data.status.toLowerCase() === "running"){
                                this.setState({
                                    isLive: true
                                })
                            }
                            let getPlanFromId = res.payload.filter((plan) => {
                                return showedAuction.data.plan.id === plan.id
                            })
                            if(getPlanFromId.length > 0){
                                this.props.setCurrentPlan(getPlanFromId[0])
                                this.props.getPrevAuction(getPlanFromId[0].id).then(prevData => {
                                    if(prevData){
                                        this.setState({
                                            prevAuction: prevData.data
                                        })
                                    }
                                })
                                this.setState({
                                    currentPlan: getPlanFromId[0]
                                })
                                this.props.getNextAuctionDate(getPlanFromId[0].id).then(next => {
                                    if(next){
                                        this.setState({
                                            nextAuctionDate : next.data.starts_at
                                        })
                                    }
                                })

                            }
                        }
                    }
                })
            }
        });
       
    }
    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };
    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    };
    toggleList = () => {
        if(!this.state.isListOpen){
            setTimeout(() => {
                this.setState(prevState => ({
                    isDelayList : !prevState.isDelayList,
                }))
            }, 500)
            this.setState(prevState => ({
                isListOpen : !prevState.isListOpen,
                // isDelayList : !prevState.isDelayList,
            }))
        }
    }
    selectItem = (item) => {
        this.setState({
            currentPlan: item
        })
        this.props.setCurrentPlan(item)
        this.props.getPlanPortfolio(item.id)
        this.props.getPrevAuction(item.id).then(prevData => {
            if(prevData){
                this.setState({
                    prevAuction: prevData.data
                })
            } else {
                this.setState({
                    prevAuction: {}
                })
            }
        })
    }
    
    closeInfo = () => {
        this.infoRef.current.style.display = "none";
    };
    
    closeDropDown = () => {
        setTimeout(() => {
            this.setState({
                isListOpen: false
            });
        }, 300)
        
        this.setState({
            isDelayList: false,
        });
    }
    closeModal = () => {
        this.noPlansModal.current.style.display = "none";
    }
    handleAuctionRoom = () => {
        if(this.props.plans.simpleUserPlans.length === 0){
            this.noPlansModal.current.style.display = "block";
        } else{
            this.props.history.push(`/bidding/${this.state.currentPlan.id}`)
        }
    }
    handleChatRoom = () => {
        if(this.props.plans.simpleUserPlans.length === 0){
            this.noPlansModal.current.style.display = "block";
        } else{
            this.props.history.push(`/chatRoom/${this.state.currentPlan.id}`)
        }
    }
    handlePlanDetails = () => {
        if(this.props.plans.simpleUserPlans.length === 0){
            this.noPlansModal.current.style.display = "block";
        } else{
            this.props.history.push(`/planDetails/${this.state.currentPlan.id}`)
        }
    }
    render() {
        const { UI : { loading} } = this.props;
        return (
            loading ? <Loading /> :
            <div className="dashboardContainer">
                <Headroom style={{
                    WebkitTransition: 'all .5s ease-in-out',
                    MozTransition: 'all .5s ease-in-out',
                    OTransition: 'all .5s ease-in-out',
                    transition: 'all .5s ease-in-out',
                    }}
                    disableInlineStyles
                >
                     <div className="stickyMenu">
                         <div className='flex'>
                            <Link to="/dashboard">
                                <div className="icon active">
                                    <img src={dashboardOutline} alt="icon" />
                                </div>
                            </Link>
                            <Link to="/friends/1">
                                <div className="icon">
                                    <img src={friendOutline} alt="icon" />
                                </div>
                            </Link>
                            <Link to="/wallet/1">
                                <div className="icon">
                                    <img src={walletOutline} alt="icon" />
                                </div>
                            </Link>
                            <Link to="/plans/1">
                                <div className="icon">
                                    <img src={plansOutline} alt="icon" />
                                </div>
                            </Link>
                         </div>
                        <img className="divider" src={divider} alt="icon" />
                    </div>
                </Headroom>

                <div className="noPlansModal" ref={this.noPlansModal}>
                    <div className='content'>
                        <img src={alertIcon} alt="icon" />
                        <span>No Plans Yet</span>
                        <img className='close' onClick={() => this.closeModal()} src={closeIcon} alt="icon" />
                    </div>
                </div>
                <AccountSetup />
                <div className="planInfo">
                    {/* {this.props.plans.simpleUserPlans && this.props.plans.simpleUserPlans.length === 0 && <div className='floater floaterCircle'>
                        <span className='number'>0</span>
                        <span className='title'>Plans</span>
                    </div>
                    } */}
                    {this.props.plans.simpleUserPlans && this.props.plans.simpleUserPlans &&
                    <div className={`floater ${this.state.isListOpen ? 'floaterList' : 'floaterCircle'}`} ref={this.floaterRef} onClick={() => this.toggleList()}>
                        {!this.state.isListOpen && <>
                            <span className="number">{this.props.plans.simpleUserPlans.length}</span>
                            <span className='title'>Plans</span>
                        </>}
                        {this.state.isDelayList && <div className='headerTitle'>Your Plans</div>}
                        {this.state.isDelayList &&  this.props.plans.simpleUserPlans.length === 0 && <div className='noPlansInfo'>
                            <div className='info'>No Plans Yet, Join In Demo Plan</div>
                            <button>View Now</button>
                        </div>}

                        {this.state.isDelayList && this.props.plans.simpleUserPlans.length > 0 &&
                            this.props.plans.simpleUserPlans.map((plan) =>
                            <div key={plan.id} className='planCard' onClick={() => this.selectItem(plan)}>
                                <span className='memberCount'>{plan.no_of_members}</span>
                                <div className='planDetails'>
                                    <div className='planName'>
                                        {plan.title}
                                    </div>
                                    <div className='planType'>
                                        x${plan.amount_to_contribute.substring(0, plan.amount_to_contribute.length - 3)}&nbsp; 
                                        Per&nbsp;
                                        {plan.type.interval_period === 1 && 'Day'}
                                        {plan.type.interval_period === 7 && 'Week'}
                                        {plan.type.interval_period === 30 && 'Month'}&nbsp;
                                        For {plan.no_of_auctions}&nbsp;
                                        {plan.type.interval_period === 1 && 'Day'}
                                        {plan.type.interval_period === 7 && 'Week'}
                                        {plan.type.interval_period === 30 && 'Month'}s
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>}
                </div>
                <section className="firstPortfolio">
                    <div className="header">
                        <div className="title">
                            Portfolio
                        </div>
                        <div className="tabs">
                            <span className="one">Chart</span>
                            <span onClick={() => this.handlePlanDetails()} className="two">Lists</span>
                        </div>
                    </div>
                    <div className="row">
                        {this.props.plans.currentPlanPortfolio 
                        && this.props.plans.currentPlanPortfolio.total_deposited_excluding_surplus 
                        && this.props.plans.currentPlanPortfolio.total_surplus_gain 
                        && this.props.plans.currentPlanPortfolio.total_surplus_gain > 0
                        && this.props.plans.currentPlanPortfolio.total_deposited_excluding_surplus > 0
                        ? <div className='pieChart'>
                               <PieChart
                                    animate
                                    radius={40}
                                    label={(labelProps) => {
                                        let deductX = 0;
                                        if(labelProps.x+labelProps.dx +37 > 100){
                                            deductX = 100 - (labelProps.x+labelProps.dx +37) + 15;
                                        }
                                        if(this.state.segmentOne && labelProps.dataIndex === 0) {
                                            return (
                                                <g key={labelProps.dataIndex}>
                                                    <rect x={labelProps.x + labelProps.dx - deductX} y={labelProps.y + labelProps.dy} width="37" height="18" rx="4" ry="4" fill="#EBEBEB"></rect>
                                                    <text x={labelProps.x} y={labelProps.y} dx={labelProps.dx + 12 - deductX} dy={labelProps.dy + 9} fontWeight="bold" fill="black" fontSize={7}>${labelProps.dataEntry.value}</text>
                                                    <text x={labelProps.x} y={labelProps.y} dx={labelProps.dx + 8 - deductX} dy={labelProps.dy + 15} fill="black" fontSize={6}>{labelProps.dataEntry.title}</text>
                                                </g>
                                            )
                                        }
                                        if(this.state.segmentTwo && labelProps.dataIndex === 1) {
                                            return (
                                                <g key={labelProps.dataIndex}>
                                                    <rect x={labelProps.x + labelProps.dx - deductX} y={labelProps.y + labelProps.dy} width="37" height="18" rx="4" ry="4" fill="#EBEBEB"></rect>
                                                    <text x={labelProps.x} y={labelProps.y} dx={labelProps.dx + 12 - deductX} dy={labelProps.dy + 9} fontWeight="bold" fill="black" fontSize={7}>${labelProps.dataEntry.value}</text>
                                                    <text x={labelProps.x} y={labelProps.y} dx={labelProps.dx + 8 - deductX} dy={labelProps.dy + 15} fill="black" fontSize={6}>{labelProps.dataEntry.title}</text>
                                                </g>
                                            )
                                        }
                                    }}
                                    onClick={(e, segmentIndex) => {
                                        this.setState({
                                            segmentOne: !this.state.segmentOne,
                                            segmentTwo: !this.state.segmentTwo
                                        })
                                        
                                    }}
                                    data={[
                                        { title: 'Deposit', value: this.props.plans.currentPlanPortfolio.total_deposited_excluding_surplus ? this.props.plans.currentPlanPortfolio.total_deposited_excluding_surplus : 0, key: 1, color: 'rgba(191, 188, 210, 0.7)' },
                                        { title: 'Surplus', value: this.props.plans.currentPlanPortfolio.total_surplus_gain ? this.props.plans.currentPlanPortfolio.total_surplus_gain : 0, key: 2, color: '#52498E' },
                                    ]}
                                />
                        </div> :<div className="circleMetric">No Data</div> }
                        <ul style={{width: !(this.props.plans.currentPlanPortfolio 
                            && this.props.plans.currentPlanPortfolio.total_deposited_excluding_surplus  
                            && this.props.plans.currentPlanPortfolio.total_surplus_gain 
                            && this.props.plans.currentPlanPortfolio.total_surplus_gain > 0
                            && this.props.plans.currentPlanPortfolio.total_deposited_excluding_surplus > 0) ? 'auto' : '250px'}}>
                            <li>
                                <div className="surplus indicator"></div><span>Surplus Gain</span>
                            </li>
                            <li>
                                <div className="deposit indicator"></div><span>Deposit Amount</span>
                            </li>
                        </ul>
                    </div>
                </section>
                {/* <section className="practiceBiddingCTA">
                    <div className="header">
                        <span className="icon">
                            <img src={plansOutline} alt="icon"/>
                        </span>
                        <h2>Explore Wheez Plans</h2>
                    </div>
                    <h3>
                        This is where you can get trained before you participate in actual bidding. Here you can take a tour and learn how to place a bid, what metrics to see before bidding & other options like forgo, timer, etc.
                    </h3>
                    <div className="button">
                        <button onClick={() => this.props.history.push("/plans/1")}>Explore Now!</button>
                    </div>
                </section> */}
                <section className="roomsCTA">
                    <div className='div'>
                        <div className='imageContainer auctionRoom'>
                            <div className='top'>
                                <img src={auctionRoomIcon} alt="icon" />
                                <span>Auction Room</span>
                                {this.props.plans.currentSelectedPlan && this.props.plans.currentSelectedPlan.auction_running && <span className='live'>LIVE</span>}
                            </div>
                            <div className='content'>
                                <div className='title'>Last Winning Amount</div>
                                {this.state.prevAuction && this.state.prevAuction.winner ? <div className='title'>
                                    <span className='value'>${this.state.prevAuction.total_amount_received} by </span>
                                    <span className='amount'>{this.state.prevAuction.winner.first_name}</span>
                                </div> : <div className='title' style={{marginTop: '3px'}}>N/A</div>}
                                <button onClick={() => this.handleAuctionRoom()}>Enter Auction Room</button>
                            </div>
                        </div>
                        {/* <div onClick={() => this.handleAuctionRoom()} className="imageContainer auctionRoom">
                            {this.props.plans.currentSelectedPlan && this.props.plans.currentSelectedPlan.auction_running && <span className='floatText'>Live</span>}
                            <img src={bidRoomImage} alt="icon"/>
                            <div className="title">Auction Room</div>
                        </div> */}
                    </div>
                    <div className='div'>
                        <div className='imageContainer chatRoom'>
                            <div className='top'>
                                <img src={chatRoomIcon} alt="icon" />
                                <span>Chat Room</span>
                            </div>
                            <div className='content'>
                                <div className='content-grid'>
                                    <span className='value'>0</span>
                                    <span className='title'>New Messages</span>
                                    <span className='value'>0</span>
                                    <span className='title'>Announcement</span>
                                </div>
                                <button onClick={() => this.handleChatRoom()}>Enter Chat Room</button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="practiceBiddingCTA">
                    <div className="header">
                        <span className="icon">
                            <img src={practiceIntroIcon} alt="icon"/>
                        </span>
                        <h2>Practice Bidding</h2>
                    </div>
                    <h3>
                        This is where you can get trained before you participate in actual bidding. Here you can take a tour and learn how to place a bid, what metrics to see before bidding & other options like forgo, timer, etc.
                    </h3>
                    <div className="button">
                        <Link to="/practiceBidding"><button type="submit" >Practice Now!</button></Link>
                    </div>
                </section>
                
                <section className="bidHistory">
                    <div className="header">
                        <img src={recentIcon} alt="icon" />
                        <div className="heading">Last Auction Summary</div>
                        <img className="divider" src={divider} alt="icon" />
                    </div>
                    {this.state.prevAuction && !this.state.prevAuction.winner && <>
                        <div className="noActivity">
                            <img className="image" src={noActivity} alt="icon" />
                            {/* <img className="divider" src={divider} alt="icon" /> */}
                            <div className="info">No history yet</div>
                        </div>
                    </>}
                    {this.state.prevAuction && this.state.prevAuction.winner && <>
                        <div className="historyDetails">
                            <div className="grid">
                                <span className="headerTitle">{this.state.prevAuction.winner.first_name} Won The Auction</span>
                                <span className="headerValue">Auction: {this.state.prevAuction.auction.serial_no ? ordinal(this.state.prevAuction.auction.serial_no) : 0}/{this.state.currentPlan.no_of_auctions}</span>
                                <span className="title">Amount Taken By Winner</span>
                                <span className="value">${this.state.prevAuction.total_amount_received}</span>
                                <span className="title">Total Surplus</span>
                                <span className="value">${this.state.prevAuction.shared_surplus}</span>
                                <span className="title">Individual Surplus</span>
                                <span className="value">${this.state.prevAuction.individual_surplus}</span>
                                <span className="title">Amount Paid By Members</span>
                                <span className="value">${this.state.prevAuction.amount_contributed}</span>
                            </div>
                        </div>
                        {this.state.nextAuctionDate && <div className="nextAuction">
                            <span>Next auction at</span>
                            <span className="value">&nbsp;{this.state.nextAuctionDate !== "" ? moment(this.state.nextAuctionDate).format('D MMMM YYYY') : ''}</span>
                        </div>}
                    </>}
                </section>
                <AmountTakenNotfication /> 
                <AppliedMCLNotification /> 
                <PlanApplicationNotification /> 
                {/* <JoinPlanNotification /> */}
                <Footer2 />
            </div>
        )
    }
}

dashboard.propTypes = {
    getUserPlans: PropTypes.func.isRequired,
    simpleGetUserPlans: PropTypes.func.isRequired,
    getPlanAuctionWinningInfo : PropTypes.func.isRequired,
    setCurrentPlan: PropTypes.func.isRequired,
    getPrevAuction: PropTypes.func.isRequired,
    getNextAuctionDate: PropTypes.func.isRequired,
    getPlanDetails: PropTypes.func.isRequired,
    getCurrentAuction: PropTypes.func.isRequired,
    getPlanPortfolio: PropTypes.func.isRequired,
    simpleGetRecommendedPlans: PropTypes.func.isRequired,
    simpleUserPlans: PropTypes.func.isRequired
}
const mapStateToProps = (state) => ({
   plans: state.plans,
   UI: state.UI
});
const mapActionsToProps = {
    getUserPlans,
    setCurrentPlan,
    getNextAuctionDate,
    getCurrentAuction,
    getPlanAuctionWinningInfo ,
    getPlanPortfolio,
    getPrevAuction,
    getPlanDetails,
    simpleGetUserPlans,
    simpleGetRecommendedPlans,
    simpleUserPlans
}

export default connect(mapStateToProps, mapActionsToProps)(dashboard);