import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Headroom from 'react-headroom'
import moment from 'moment'
import Loading from '../components/Loading';
import { 
    getGoogleContactsAll, 
    linkGoogleAccount,
    getInviteLink,
    inviteGoogleAccount,
    getWheezContacts,
    getSearchedUser,
    deleteWheezContact,
    getCurrentRequests,
    handleIncomingRequest,
    getAllAvatars,
} from '../redux/actions/contactActions';

import profileIconBlue from './../assets/images/icons/userIconBlack.svg';
import fbMessenger from './../assets/images/icons/messengerIcon.svg';
import chatMessenger from './../assets/images/icons/messageIcon.svg';
import whatsApp from './../assets/images/icons/whatsApp.svg';
import searchFriends from './../assets/images/icons/searchFriends.svg';
import noFriendsImage from './../assets/images/noFriends.svg';
import googleIcon from './../assets/images/icons/googleIcon.svg';
import dashboardOutline from './../assets/images/icons/dashboardOutline.svg';
import backIcon from './../assets/images/icons/backIcon.svg';
import plansOutline from './../assets/images/icons/plansOutline.svg';
import searchIcon from './../assets/images/icons/searchIcon.svg';
import noIncoming from './../assets/images/noIncoming.svg';
import locIcon from './../assets/images/icons/locIcon.svg';
import closeIcon from './../assets/images/icons/closeCircle.svg';
import inviteFriends from './../assets/images/icons/inviteFriends.svg';
import ellipsisHorizontal from './../assets/images/icons/ellipsisHorizontal.svg';
import friendsGroup from './../assets/images/icons/friendsGroup.svg';
import divider from './../assets/images/icons/divider.svg';
import userIconBlack from './../assets/images/icons/userIconBlack.svg';
import pendingInvites from './../assets/images/pendingInvites.svg';
import walletOutline from './../assets/images/icons/walletOutline.svg';
import friendOutline from './../assets/images/icons/friendOutline.svg';
import dp from './../assets/images/DP.png';
import './../styles/friends.scss';
const BASE_URL = `http://${process.env.REACT_APP_WHEEZ_SERVER_URL}`;

class friends extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isListOpen: false,
            currentListId: 0,
            isIncomingRequestOpened: false,
            currentGoogleContactId: 0,
            currentGooglePhoneNumber: 0,
            isOutgoingRequestOpened: false,
            currentGoogleInviteName: '',
            searchActivated: false,
        }
        this.inviteGoogle = React.createRef();
        this.searchRef = React.createRef();
        this.searchFlex = React.createRef();
    }
    componentDidMount(){
        this.props.getInviteLink();
        this.props.getGoogleContactsAll();
        this.props.getWheezContacts();
        this.props.getAllAvatars();
        this.props.getCurrentRequests();
        document.addEventListener('mousedown', this.handleClickOutside);
        
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    componentDidUpdate(){
        const { isListOpen } = this.state;
        setTimeout(() => {
            if(isListOpen){
                window.addEventListener('click', this.closeDropDown)
            }
            else{
                window.removeEventListener('click', this.closeDropDown)
            }
        }, 0);
    }
    selectTab = (tab) => {
        this.props.history.push(`/friends/${tab}`)
    }
    shareLink = () => {
        if (navigator.clipboard && window.isSecureContext) {
            navigator
            .share({
                title: document.title,
                text: `Hey ${this.state.currentGoogleInviteName} - You should join us on Wheez. Here is the link!`,
                url: this.props.contacts.inviteLink.link ? this.props.contacts.inviteLink.link : ''
            })
            .then(() => console.log('Successful share! 🎉'))
            .catch(err => console.error(err));
        }
    }
    shareLinkGeneral = () => {
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(this.props.contacts.inviteLink.link ? this.props.contacts.inviteLink.link : '');
            navigator
            .share({
                title: document.title,
                text: 'Hey Krishna - You should join us on Wheez. Here is the link!',
                url: this.props.contacts.inviteLink.link ? this.props.contacts.inviteLink.link : ''
            })
        } 
    }
    toggleList = (id) => {
        this.setState(prevState => ({
            currentListId: id,
            isListOpen : !prevState.isListOpen
        }))
    }
    closeDropDown = () => {
        this.setState({
          isListOpen: false,
        });
    }
    removeWheezContact = (id) => {
        this.props.deleteWheezContact(id);
    }
    handleGoogleLink = () => {
        this.props.linkGoogleAccount().then((url)=> {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        });
    }
    handleGoogleInvite = (contact) => {
        this.setState({
            currentGoogleContactId: contact.contact_id,
            currentGooglePhoneNumber: contact.phone_numbers[0],
            currentGoogleInviteName: contact.first_name
        })
        this.inviteGoogle.current.style.display = "block";
    }
    closeInvite = () => {
        this.setState({
            currentGoogleContactId: null,
            currentGooglePhoneNumber: null
        })
        this.inviteGoogle.current.style.display = "none";
    }
    sendGoogleInviteThroughWheez = () => {
        // let userData = {
        //     "linked_account_id": this.props.contacts.googleContacts.results[0].linked_account_id,
        //     "contact_id": this.state.currentGoogleContactId
        // }
        // this.props.inviteGoogleAccount(userData);
    }
    handleSearch = (e) => {
        if(this.props.match.params.id === '2' && this.props.contacts.googleContacts.length > 0 && !this.state.isIncomingRequestOpened && !this.state.isOutgoingRequestOpened ){
            this.props.getSearchedUser(e.target.value, this.props.contacts.googleContacts, this.props.match.params.id)
        }
    }
    handleRequest = (type, id) => {
        let userData;
        if(type === "accept"){
           userData = {
              "contact_request_id": id,
              "action": "accept"
           } 
        } 
        if(type === "ignore"){
            userData = {
                "contact_request_id": id,
                "action": "reject"
            }
        }
        if(type === "delete"){
            userData = {
                "contact_request_id": id,
                "action": "delete"
            }
        }
        this.props.handleIncomingRequest(userData);
    }
    handleWhatsappInvite = () => {
        let textToSend = "Hey Krishna - You should join us on Clubhouse. Here is the link!";
        let encodedMessage = encodeURI(textToSend);
        let link = this.props.contacts.inviteLink.link ? this.props.contacts.inviteLink.link : '';

        let phoneNumber = this.state.currentGooglePhoneNumber;
        phoneNumber = "91" + phoneNumber.substr(phoneNumber.length - 10);
        let encodedURL = encodeURI(link);
        const newWindow = window.open(
            `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${encodedMessage}. ${encodedURL}`,
             '_blank', 
             'noopener,noreferrer'
             )
        if (newWindow) newWindow.opener = null
    }
    backNavigate = () => {
        if(this.state.isIncomingRequestOpened){
            this.setState({
                isIncomingRequestOpened: false
            })
        } else if(this.state.isOutgoingRequestOpened) {
            this.setState({
                isOutgoingRequestOpened: false
            })
        }
        //  else {
        //     this.props.history.goBack();
        // }
    }
    changeDummy = () => {

    }
    enableSearch = () => {
        this.setState({
            searchActivated: true
        })
        this.searchFlex.current.style.display = "flex";
        setTimeout(() => {
            this.searchFlex.current.style.opacity = 1;
            this.searchFlex.current.style.width = "100%";
            this.searchRef.current.focus();
        }, 200)
    }
    closeSearch = () => {
        this.searchFlex.current.style.opacity = 0;
        this.searchFlex.current.style.width = "0";
        setTimeout(() => {
            this.searchFlex.current.style.display = "none";
            this.setState({
                searchActivated: false
            })
        }, 500)
    }
    timeDifference(date1) {
        var a = moment(date1);
        var b = moment(new Date());
        return b.diff(a, 'days')
    }
   
    render() {
        const tab = this.props.match.params.id;
        const { UI : { loading } } = this.props;
        return (
            loading ? 
            <Loading /> : 
            <div className="friendsContainer">
                <Headroom style={{
                    WebkitTransition: 'all .5s ease-in-out',
                    MozTransition: 'all .5s ease-in-out',
                    OTransition: 'all .5s ease-in-out',
                    transition: 'all .5s ease-in-out',
                    }}
                    disableInlineStyles
                >
                    <div className="stickyMenu">
                         <div className='flex'>
                            <Link to="/dashboard">
                                <div className="icon">
                                    <img src={dashboardOutline} alt="icon" />
                                </div>
                            </Link>
                            <Link to="/friends/1">
                                <div className="icon active">
                                    <img src={friendOutline} alt="icon" />
                                </div>
                            </Link>
                            <Link to="/wallet/1">
                                <div className="icon">
                                    <img src={walletOutline} alt="icon" />
                                </div>
                            </Link>
                            <Link to="/plans/1">
                                <div className="icon">
                                    <img src={plansOutline} alt="icon" />
                                </div>
                            </Link>
                         </div>
                        <img className="divider" src={divider} alt="icon" />
                    </div>
                </Headroom>
                <div ref={this.inviteGoogle} className="modal">
                    <div className="modal-content">
                        <img src={closeIcon} onClick={() => this.closeInvite()} className='close' alt="icon" />
                        <div className="header">
                            <div className="heading">Send invitation link</div>
                            <div className="info">Through wheez:</div>
                            <button onClick={() => this.sendGoogleInviteThroughWheez()}>Send invite</button>
                        </div>
                        <div className="bottom">
                            <div className="heading">Share Link Via:</div>
                            <div className="flexShare">
                                <img src={whatsApp} alt="icon" onClick={() => this.handleWhatsappInvite()} />
                                <img src={chatMessenger} alt="icon"  onClick={() => this.shareLink()} />
                                <img src={fbMessenger} alt="icon"  onClick={() => this.shareLink()} />
                            </div>
                        </div>
                    </div>
                </div>
                {!this.state.isIncomingRequestOpened && !this.state.isOutgoingRequestOpened && <div className="tabSelect">
                    <input onChange={this.changeDummy} checked={tab === '1'} type="radio" name="toggle" id="contactTab" value="contact" />
                    <label className="toggle toggle-yes contact" onClick={() => this.selectTab(1)}>
                        <img src={friendsGroup} alt="icon" />
                        <div>Friends</div>
                    </label>
                    <input onChange={this.changeDummy} checked={tab === '2'} type="radio" name="toggle" id="inviteTab" value="invite" />
                    <label className="toggle toggle-yes" onClick={() => this.selectTab(2)}>
                        <img src={inviteFriends} alt="icon" />
                        <div className='inviteText'>Invite Contacts</div>
                    </label>
                    <span className='spanner'></span>  
                </div>}
           
                {tab === '1' && !this.state.isIncomingRequestOpened && <div className="requestOthers">
                    <div 
                        onClick={() => this.setState({isIncomingRequestOpened: true})} 
                        className="link">
                            <span>See Pending Requests</span>
                            <span style={{fontWeight: 'bold', marginLeft: '2px'}}>({this.props.contacts.incomingRequests.length})</span>
                    </div>
                </div>}
                
                {tab === '2' && !this.state.isOutgoingRequestOpened && <div className="requestOthers">
                    {!this.state.searchActivated && <div 
                        onClick={() => this.setState({isOutgoingRequestOpened: true})} 
                        className="link">
                            <span>See Pending Invites</span>
                            <span style={{fontWeight: 'bold', marginLeft: '2px'}}>({this.props.contacts.outgoingRequests.length})</span>
                    </div>}
                    <div className="searchFlex" ref={this.searchFlex}>
                        <div className='inputFlex'>
                            <img src={searchIcon} alt="icon"  />
                            <input onChange={(e) => this.handleSearch(e)} ref={this.searchRef} type="text" placeholder="Search" />
                            <img onClick={() => this.closeSearch()} className="closeSearch" src={closeIcon} alt="icon" />
                        </div>
                    </div>
                    {!this.state.searchActivated && <img onClick={() => this.enableSearch()} className='searchFriendsIcon' src={searchFriends} alt="icon" />}
                </div>}


                {this.state.isIncomingRequestOpened &&
                    <div className="incomingRequests">
                        <div className="heading">
                            <img className="backIcon" onClick={() => this.backNavigate()} src={backIcon} alt="icon"/>
                            <span>Pending Requests</span>
                        </div>
                        {this.props.contacts.incomingRequests.map((request) => {
                            let avatarImage = null
                            if(request.sender.avatar){
                                avatarImage = this.props.userAvatars.filter((u) => u.id === request.sender.avatar)
                            }
                            return (
                                <div key={request.id} className="card">
                                    <div className="midFlex">
                                        {
                                            request.sender.avatar && avatarImage.length > 0 ? <img className='avatar' src={`${BASE_URL}${avatarImage[0].image}`} alt="icon" /> 
                                            : (
                                                request.sender.profile_image ? <img className='avatar' src={`${BASE_URL}${request.sender.profile_image}`} alt="icon" />
                                                :
                                                <img src={userIconBlack} alt="icon" />
                                            ) 
                                        }
                                        {/* <img src={dp} alt="icon" /> */}
                                        <div className='textFlex'>
                                            <span className="name">{request.sender.first_name} {request.sender.last_name}</span>
                                            <span className="planInfo">You both are in same plan</span>
                                        </div>
                                    </div>
                                    <div className="endFlex">
                                        <button className="accept" onClick={() => this.handleRequest("accept", request.id)}>Accept</button>
                                        <button className="ignore" onClick={() => this.handleRequest("ignore", request.id)}>Ignore</button>
                                    </div>
                                </div>
                            )
                        }
                        )}
                        {
                            this.props.contacts.incomingRequests.length === 0 && 
                            <div className='noRequests'>
                                <img src={noIncoming} alt="icon" />
                                <div>No Requests Yet</div>
                            </div>
                        }
                    </div>
                }

                {this.state.isOutgoingRequestOpened &&
                    <div className="outgoingRequests">
                        <div className="heading">
                            <img className="backIcon" onClick={() => this.backNavigate()} src={backIcon} alt="icon"/>
                            <span>Pending Invites</span>
                        </div>
                        {this.props.contacts.outgoingRequests.map((request) => {
                            let avatarImage = null
                            if(request.receiver.avatar){
                                avatarImage = this.props.userAvatars.filter((u) => u.id === request.receiver.avatar)
                            }
                                return (
                                    <div key={request.id} className="card">
                                        <div className='profileImage'>
                                            {
                                                request.receiver.avatar && avatarImage.length > 0 ? <img className='avatar' src={`${BASE_URL}${avatarImage[0].image}`} alt="icon" /> 
                                                : (
                                                    request.receiver.profile_image ? <img className='avatar' src={request.receiver.profile_image} alt="icon" />
                                                    :
                                                    <img src={userIconBlack} alt="icon" />
                                                ) 
                                            }
                                        </div>
                                        <div className="midFlex">
                                            <span className="name">{request.receiver.first_name} {request.receiver.last_name}</span>
                                            <span className="planInfo">From Wheez Contact</span>
                                            {/* <span className="totalFriends">25 friends on Wheez</span> */}
                                        </div>
                                        <button>Pending</button>
                                    </div>
                                )
                            }
                        )}
                        {
                            this.props.contacts.outgoingRequests.length === 0 && 
                            <div className='noRequests'>
                                <img src={pendingInvites} alt="icon" />
                                <div>No Invites Yet</div>
                            </div>
                        }
                    </div>
                }

                {tab === '1' && !this.state.isIncomingRequestOpened && <div id="contacts" className="contacts">
                    {this.props.contacts.wheezContacts && this.props.contacts.wheezContacts.count === 0 && <div className="noFriends">
                        <img className='image' src={noFriendsImage} alt="icon" />
                        <img className='divider' src={divider} alt="icon" />
                        <div className="text">Oops, No Friends Here!</div>
                        <button onClick={() => this.props.history.push('/friends/2')} >Let's Add Friends</button>
                    </div>}

                    <ul>
                    {this.props.contacts.wheezContacts && this.props.contacts.wheezContacts.results && this.props.contacts.wheezContacts.results.map((friend) => {
                        let profileLink = `/profile/${friend.user.id}` ;
                        let isNew = true
                        if(this.timeDifference(friend.user.friend_request_status.created_at) > 10){
                            isNew = false
                        }
                        let avatarImage = null
                        if(friend.user.avatar){
                            avatarImage = this.props.userAvatars.filter((u) => u.id === friend.user.avatar)
                        }
                        return <li key={friend.id} className="userCard">
                            <div>
                            {
                                friend.user.avatar && avatarImage.length > 0 ? <img className='dp' src={`${BASE_URL}${avatarImage[0].image}`} alt="icon" /> 
                                : (
                                    friend.user.profile_image ? <img className='dp' src={friend.user.profile_image} alt="icon" />
                                    :
                                    <img className='dp' src={dp} alt="icon" />
                                ) 
                            }
                            </div>
                            {isNew && <div className='dummyContainer'>
                                <div className='planTypeInfo'>
                                    <span className='planText'>NEW</span>
                                </div>
                            </div>}
                            <div className='content'>
                                <div>
                                    <span className='name'>{friend.user.first_name} {friend.user.last_name}</span>
                                    <span className='memberSince'>• Member since {moment(friend.user.member_since).format("YYYY")}</span>
                                </div>
                                <div className='rowFlex'>
                                    <div>
                                        {friend.user.country && <div className='locFlex'>
                                            <img src={locIcon} alt="icon" />
                                            <span>{friend.user.country}</span>
                                        </div>}
                                        {friend.user.plans.length > 0 && <div className='planFlex'>
                                            <span className='title'>Plans:</span>
                                            <span className='value'>{friend.user.plans.length}</span>
                                        </div>}
                                    </div>
                                    <Link to={profileLink}><button>View Profile</button></Link>
                                </div>
                            </div>
                            <div className="fixedMenu">
                                <div className="dropdown">
                                    <div onClick={() => this.toggleList(friend.id)}>
                                        <img src={ellipsisHorizontal} alt="icon"/>
                                    </div>
                                    {this.state.isListOpen && this.state.currentListId === friend.id && (
                                        <div role="list" className="dropdown-content">
                                            <div onClick={() => this.removeWheezContact(friend.id)}>Remove Profile</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </li>
                    })}
                    </ul>
                </div>}
        
                {tab === '2' && !this.state.isOutgoingRequestOpened && <div id="invites" className="invites">
                    {this.props.contacts.inviteLink && this.props.contacts.inviteLink.link && <div className="inviteFriends">
                        <div className="heading">Invite your friends</div>
                        <div className="linkCopy">
                            <span className='link'>{this.props.contacts.inviteLink ? this.props.contacts.inviteLink.link : ''}</span>
                            <span className='copy' onClick={() => this.shareLinkGeneral()}>Copy</span>
                        </div>
                    </div>}
                    {this.props.contacts.googleContacts 
                            && !this.props.contacts.googleContacts.results && <div className="accountLink">
                        <div className="heading">Account linking</div>
                        <div className="intro">You can link your google contacts and see who all your friends are in Wheez. And you can connect with them into a plan or invite them to a plan, and you can see if you both are in the same plans also, see what they are doing in wheez, ask their experience about wheez plans and a lot more.</div>
                        <div className="card">
                            <div className="title">Link google contacts</div>
                            <div className="buttonRow">
                                <img src={googleIcon} alt="icon" />
                                <span>Google</span>
                                <button onClick={() => this.handleGoogleLink()}>Connect</button>
                            </div>
                        </div>
                    </div>}
                    <ul>
                        {this.props.contacts.googleContacts 
                            && this.props.contacts.googleContacts.contacts
                            && this.props.contacts.googleContacts.contacts.map(contact => {
                                    if(contact.invited === false || contact.wheez_account === null){
                                        return <li key={contact.contact_id} className="inviteCard">
                                            <span className="icon">
                                                <img src={profileIconBlue} alt="icon" />
                                            </span>
                                            <div className="content">
                                                <span className="name">{contact.full_name}</span>
                                                <span className="from">From google contacts</span>
                                                {/* <span className="friendsCount">25 friends on Wheez</span> */}
                                            </div>
                                            <button onClick={() => this.handleGoogleInvite(contact)}>Invite</button>
                                        </li>
                                    } else {
                                        return null
                                    }
                                }
                            )}
                    
                    </ul>
                </div>}
            </div>
        )
    }
}

friends.propTypes = {
    getGoogleContactsAll: PropTypes.func.isRequired,
    getWheezContacts: PropTypes.func.isRequired,
    linkGoogleAccount: PropTypes.func.isRequired,
    deleteWheezContact: PropTypes.func.isRequired,
    handleIncomingRequest: PropTypes.func.isRequired,
    getCurrentRequests: PropTypes.func.isRequired,
    getSearchedUser: PropTypes.func.isRequired,
    getInviteLink : PropTypes.func.isRequired,
    inviteGoogleAccount : PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    userAvatars: PropTypes.array.isRequired,
    contacts: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => ({
   user: state.user,
   UI: state.UI,
   contacts: state.contacts,
   userAvatars: state.contacts.userAvatars
});
const mapActionsToProps = {
    getGoogleContactsAll,
    linkGoogleAccount,
    getInviteLink,
    inviteGoogleAccount,
    getWheezContacts,
    getAllAvatars,
    getSearchedUser,
    deleteWheezContact,
    getCurrentRequests,
    handleIncomingRequest
}

export default connect(mapStateToProps, mapActionsToProps)(friends);