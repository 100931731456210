import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DateRangePicker } from 'react-date-range';
import Headroom from 'react-headroom'
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment'
import Loading from '../components/Loading';
import { getBalance, getAllTransactions, getMoreTransactions, handleRevalidate, getLimitFromServer } from '../redux/actions/walletActions';
import { getAllTypePlans, clearAllTypePlans } from '../redux/actions/planActions';
import dashboardOutline from './../assets/images/icons/dashboardOutline.svg';
import friendOutline from './../assets/images/icons/friendOutline.svg';
import dropIcon from './../assets/images/icons/dropIcon.svg';
import plansOutline from './../assets/images/icons/plansOutline.svg';
import divider from './../assets/images/icons/divider.svg';
import walletFilled from './../assets/images/icons/walletFilled.svg';
import backIcon from './../assets/images/icons/backIcon.svg';
import bankIcon from './../assets/images/icons/bankIcon.svg';
import sortByIcon from './../assets/images/icons/sortByIcon.svg';
import chevronDown from './../assets/images/icons/dropIcon.svg';
import noTransaction from './../assets/images/noTransaction.png';
// import plusIcon from './../assets/images/icons/plusIcon.svg';
import calendarSortIcon from './../assets/images/icons/calendarSortIcon.svg';
import transFilled from './../assets/images/icons/transFilled.svg';
import walletOutline from './../assets/images/icons/walletOutline.svg';
import closeIcon from './../assets/images/icons/closeCircle.svg';
import mclApplySuccess from './../assets/images/mclApplySuccess.svg';
import mclApply from './../assets/images/mclApply.svg';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 
import './../styles/wallet.scss';
var ordinal = require('ordinal')
function Wallet() {
    const { id } = useParams();
    const tab = id;
    const history = useHistory();
    const dispatch = useDispatch();

    const uiState = useSelector((state) => state.UI);
    const user = useSelector((state) => state.user);

    const plans = useSelector((state) => state.plans);
    const wallet = useSelector((state) => state.wallet);

    const modalRef = useRef();
    const chevronDownMonth = useRef();
    const chevronDownPlans = useRef();
    const startDateModalRef = useRef();
    const revalidateModalRef = useRef();
    
    const [detailsOpened, setDetailsOpened] = useState(false);
    const [sortByOptions, setSortByOptions] = useState(false);
    const [currentExpandedId, setCurrentExpandedId] = useState(0);
    const [planSortOpened, setPlanSortOpened] = useState(false);
    const [currentPageId, seCurrentPageId] = useState(1);
    const [monthSortOpened, setMonthSortOpened] = useState(false);
    const [isStartDateOpened, setIsStartDateOpened] = useState(false);
    const [allTransactions, setAllTransactions] = useState({});
    const [displayedTransactions, setDisplayedTransactions] = useState([]);
    const [startDateString, setStartDateString] = useState('Start Date');
    const [endDateString, setEndDateString] = useState('End Date');
    const [disableValidate, setDisableValidate] = useState(false)
    const [limitSuccess, setLimitSuccess] = useState(false)
    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
    ]);

    useEffect(() => {
        dispatch(getBalance());
        dispatch(getAllTransactions());
        dispatch(getAllTypePlans());
        return () => {
            dispatch(clearAllTypePlans())
        }
    }, [dispatch])

    useEffect(() => {
        if(wallet.allTransactions){
            setAllTransactions(wallet.allTransactions)
            setDisplayedTransactions(wallet.allTransactions)
        }
    },[wallet])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    })
    
    const handleClickOutside = (event) => {
        if(sortByOptions && !event.target.className.includes("sorter") && !isStartDateOpened){
            setSortByOptions(false)
        }
        if(isStartDateOpened){
            if(event.target.className.toLowerCase().includes("modal") && event.target.className !== "modal-content"){
                setIsStartDateOpened(false)
                startDateModalRef.current.style.display = "none"
            }
        }
    }
    const openWithdrawModal = () => {
        modalRef.current.style.display = "block";
    }

    const changeDummy = () => {}

    const closeWithdrawModal = () => {
        modalRef.current.style.display = "none";
    }
    const selectTab = (tab) => {
        if(tab === 1) {
            history.push('/wallet/1')
        }
        if(tab === 2) {
            history.push('/wallet/2')
        }
        if(tab === 3) {
            history.push('/wallet/3')
        }
    }
    const openDetails = (id) => {
        setDetailsOpened(!detailsOpened)
        setCurrentExpandedId(id)
    }
    const openSortByOptions = () => {
        setSortByOptions(true)
    }
    const openPlansSort = () => {
        setPlanSortOpened(!planSortOpened)
        if(!planSortOpened){
            chevronDownPlans.current.style.transform = 'rotate(180deg)'
        } else {
            chevronDownPlans.current.style.transform = 'rotate(0deg)'
        }
    }
    const openMonthsSort = () => {
        setMonthSortOpened(!monthSortOpened)
        if(!monthSortOpened){
            chevronDownMonth.current.style.transform = 'rotate(180deg)'
        } else {
            chevronDownMonth.current.style.transform = 'rotate(0deg)'
        }
    }
    const sortUsingThisPlan = (plan) => {
        if(allTransactions.length > 0){
            let results = allTransactions.filter((transaction) => {
                if(transaction.auction_return){
                    return transaction.auction_return.auction.plan.id === plan.id
                } else if(transaction.auction_deposit){
                    return transaction.auction_deposit.auction.plan.id === plan.id
                } else {
                    return null
                }
            })
            setDisplayedTransactions(results)
        } else {
            setDisplayedTransactions([])
        }
        setMonthSortOpened(false)
        setPlanSortOpened(false)
        setSortByOptions(false)
    } 
   
    const chooseStartingDate = () => {
        setIsStartDateOpened(true)
        startDateModalRef.current.style.display = "block"
    }
    const onChangeDate = (item) => {
        setState([item.selection])
        let compareDate = new Date();
        compareDate = compareDate.setHours(0,0,0,0)
        if(compareDate.valueOf() !== item.selection.startDate.valueOf()){
            let startAtDate = moment(item.selection.startDate).format("D MMM YYYY, ddd");
            setStartDateString(startAtDate)
        }
        if(compareDate.valueOf() !== item.selection.endDate.valueOf()){
            let endAtDate = moment(item.selection.endDate).format("D MMM YYYY, ddd");
            setEndDateString(endAtDate)
        }
    }
    const disableDay = (item) => {
        let todaysDate =  new Date();
        if(item > todaysDate){
            return true
        }
    }
    const applyDateFilter = () => {
        if(allTransactions.length > 0){
            let results = allTransactions.filter((transaction) => {
                let transactionDate = (new Date(transaction.created_at)).setHours(0,0,0,0)
                return (transactionDate.valueOf() <= state[0].endDate.valueOf()) && (transactionDate.valueOf() >= state[0].startDate.valueOf())
            })
            setDisplayedTransactions(results)
        }
        setMonthSortOpened(false)
        setPlanSortOpened(false)
        setSortByOptions(false)
    }
    const fetchMoreTransactions = () => {
        seCurrentPageId(currentPageId + 1)
        dispatch(getMoreTransactions(currentPageId + 1))
    }

    const openRevalidateModal = () => {
        revalidateModalRef.current.style.display = "block"
    }
    const closeRevalidateModal = () => {
        revalidateModalRef.current.style.display = "none"
    }
    
    const revalidate = () => {
        dispatch(handleRevalidate()).then((res) => {
            if(res === "success"){
                revalidateModalRef.current.style.display = "block"
                setDisableValidate(true)
            }
        })
    }
    const getLimit = () => {
        dispatch(getLimitFromServer()).then((res) => {
            if(res === "success"){
                setLimitSuccess(true)
            }
        })
    }
    const tempArray = [];
    return (
        uiState.loading ? 
        <Loading /> : 
        <div className="walletPageContainer">
            {tab === '3' ? 
            <div className='viewLimitContainer'>
                <div className='header'>
                    <img onClick={() => history.goBack()} src={backIcon} alt="icon" />
                    <span>Account Limits</span>
                </div>
                {user.userProfile.limit > 0 ? <>
                    <div className='lastUpdate'><span className="title">Last Update:</span><span className='value'>{user.userProfile.limit_last_updated_at ? moment(user.userProfile.limit_last_updated_at).format("Do MMM") : 'N/A'}</span></div>
                    <div className='content'>
                        <div className='box'>
                            <div className='maxCont'>
                                <span className='title'>Max Contribution Limit</span>
                                <span className='value'> (Per Month)</span>
                            </div>
                            <div className='limitValue'>${user.userProfile.limit}</div>
                            <div className='utilized'>
                                <span className='title'>Utilized</span>
                                <span className='hyphen'>-</span>
                                <span className='value'>${user.userProfile.limit - user.userProfile.available_limit}</span>
                            </div>
                            <div className='utilized available'>
                                <span className='title'>Available</span>
                                <span className='hyphen'>-</span>
                                <span className='value'>${user.userProfile.available_limit}</span>
                            </div>
                        </div>
                        <div className='box box2'>
                            <div className='maxCont'><span className='title'>Plan Limit</span></div>
                            <img src={divider} alt="icon"/>
                            {user.userProfile.level === 0 && <span className='na'>N/A</span>}
                            {user.userProfile.level > 0 && <div className='grid'>
                                {user.userProfile.level === 1  && "Weekly Plans"}
                                {user.userProfile.level === 2  && "Weekly/Monthly upto 10 months"}
                                {user.userProfile.level === 3  && "Weekly/Monthly upto 20 months"}
                                {user.userProfile.level === 4  && "Full Access"}
                            </div>}
                        </div>
                    </div>
                    <div className='buttonFlex'>
                        <button className={`${disableValidate && 'disabled'}`} disabled={disableValidate} onClick={() => openRevalidateModal()}>Re-Validate</button>
                    </div>
                </>
                :
                (user.userProfile.set_limit && (
                    !limitSuccess ? 
                    <div className='applyMCL'>
                        <img src={mclApply} alt="icon" />
                        <p>
                            This is the max monthly-contribution that you can make in the wheez plans, you can have multiple plans with different goals, but you can't contribute more than this limit. we provide this based on your financials and monthly budget, credit score, experience and activities in wheez, etc... We check and update limits actively. Link your bank accounts here and get a monthly contribution limit from wheez.
                        </p>
                        <button onClick={() => getLimit()}>Get My Limit</button>
                    </div> 
                    :
                    <div className='applyMCL'>
                        <img src={mclApplySuccess} alt="icon" />
                        <p>
                        Thank you for applying, we are calculating your limits. we will get back to you soon.
                        </p>
                    </div>
                ))
                }
            </div> 
            :
            <>
                <Headroom style={{
                    WebkitTransition: 'all .5s ease-in-out',
                    MozTransition: 'all .5s ease-in-out',
                    OTransition: 'all .5s ease-in-out',
                    transition: 'all .5s ease-in-out',
                    }}
                    disableInlineStyles
                >
                    <div className="stickyMenu">
                            <div className='flex'>
                            <Link to="/dashboard">
                                <div className="icon">
                                    <img src={dashboardOutline} alt="icon" />
                                </div>
                            </Link>
                            <Link to="/friends/1">
                                <div className="icon">
                                    <img src={friendOutline} alt="icon" />
                                </div>
                            </Link>
                            <div className="icon active">
                                <img src={walletOutline} alt="icon" />
                            </div>
                            <Link to="/plans/1">
                                <div className="icon">
                                    <img src={plansOutline} alt="icon" />
                                </div>
                            </Link>
                            </div>
                        <img className="divider" src={divider} alt="icon" />
                    </div>
                </Headroom>
                <div className="walletPage">
                    <div className="tabSelect">
                        <input onChange={changeDummy} checked={tab === '1'} type="radio" name="toggle" id="walletTab" value="wallet" />
                        <label className="toggle toggle-yes contact" onClick={() => selectTab(1)}>
                            <img src={walletFilled} alt="icon" />
                            <div>Wallet</div>
                        </label>
                        <input onChange={changeDummy} checked={tab === '2'} type="radio" name="toggle" id="transactionTab" value="transaction" />
                        <label className="toggle toggle-yes" onClick={() => selectTab(2)}>
                            <img src={transFilled} alt="icon" />
                            <div className='inviteText'>Transactions</div>
                        </label>
                        <span className='spanner'></span>  
                    </div>
                    {tab === '1' && <div>
                        <section className='balanceContent'>
                            <div className='topContent'>
                                <span className="title">Your Avl Limit:</span>
                                <span className="value">{user.userProfile && user.userProfile.limit && parseInt(user.userProfile.limit) > 0 ? user.userProfile.available_limit : 'N/A'}</span>
                                <span className='link' onClick={() => selectTab(3)}>View</span>
                            </div>
                            <div className='bottomContent'>
                                <div className='flexCol'>
                                    <span className='title'>Your Balance</span>
                                    <span className='value'>${wallet.balance ? wallet.balance : 0}</span>
                                </div>
                                <button onClick={openWithdrawModal}>Withdraw</button>
                            </div>
                        </section>
                        <section className="addAccount">
                            <div className='header'>Accounts</div>
                            <div className='bankContent'>
                                <span className='icon'><img src={bankIcon} alt="icon" /></span>
                                <div className='col'>
                                    <span className='bankName'>Bank Name</span>
                                    <span className='accountNo'>123 456 789 999</span>
                                </div>
                            </div>
                            {/* <div className='addBank'>
                                <img src={plusIcon} alt="icon" />
                                <span className='title'>Add more account</span>
                            </div> */}
                            <div className='info'>Note: we use plaid to connect your bank account </div>
                        </section>
                    
                        <section className="dueAmount">
                            <span className="due">Due</span>
                            <span className="value">${wallet.dueBalance ? wallet.dueBalance : "N/A"}</span>
                            <button type="submit">Pay Now</button>
                        </section>
                    </div>}
                    
                    {tab === '2' && <div className="transactionContainer">
                        {(displayedTransactions.length === 0) && <div className='noTransaction'>
                            <img src={noTransaction} alt="icon" />
                            <div className='title'>No Transaction</div>
                        </div>}
                        <div className='sortByContainer' style={{transform: (displayedTransactions.length === 0) ? "translateY(-64px)" : "translateY(0px)"}}>
                            <div onClick={() => openSortByOptions()} className='main'>
                                <span>Filter By</span>
                                <img src={sortByIcon} alt="icon" />
                            </div>
                            {sortByOptions && (
                                <div className="sortByOptions sorter">
                                    <div className='heading sorter'>Filter By</div>
                                    <div className='groupHeader sorter' onClick={() => openPlansSort()}>
                                        <span className='sorter'>Plans</span>
                                        <img className='sorter' ref={chevronDownPlans} src={chevronDown} alt="icon" />
                                    </div>
                                    {planSortOpened && 
                                        <div className="sorter">
                                            {plans.allTypePlans.length > 0 && plans.allTypePlans.map((userPlan) => {
                                                return <div key={userPlan.id} className='sortOption sorter' onClick={() => sortUsingThisPlan(userPlan)}>{userPlan.title}</div>
                                            })}
                                    </div>}
                                    <div className='groupHeader sorter' style={{marginTop: '10px'}} onClick={() => openMonthsSort()}>
                                        <span className='sorter'>Month</span>
                                        <img className='sorter' ref={chevronDownMonth} src={chevronDown} alt="icon" />
                                    </div>
                                    {monthSortOpened && 
                                        <div className="sorter">
                                            <div className='sortOption sorter' onClick={() => chooseStartingDate()}>
                                                <span className={startDateString.toLowerCase() === 'start date' ? 'sorter title' : 'sorter title titleWhite'}>{startDateString}</span>
                                                <img className='sorter' src={calendarSortIcon} alt="icon" />
                                            </div>
                                            <div className='sortOption sorter' onClick={() => chooseStartingDate()}>
                                                <span className={endDateString.toLowerCase() === 'end date' ? 'sorter title' : 'sorter title titleWhite'}>{endDateString}</span>
                                                <img className='sorter' src={calendarSortIcon} alt="icon" />
                                            </div>
                                    </div>}
                                    <div className='dateHeader sorter' onClick={() => applyDateFilter()}>
                                        Apply
                                        {/* <img className='sorter' src={searchIcon} alt="icon" />
                                        <input className='sorter' type="text" placeholder='Search...'></input> */}
                                    </div>
                                </div>
                            )}
                        </div>
                        
                        <InfiniteScroll
                            dataLength={displayedTransactions && displayedTransactions.length ? displayedTransactions.length : 0}
                            next={fetchMoreTransactions}
                            hasMore={wallet.fetchMore}
                            scrollThreshold={0.7}
                            loader={<Loading />}
                        >
                            {
                                displayedTransactions && displayedTransactions.length > 0 && displayedTransactions.map((transaction) => {
                                    let classType;
                                    if(transaction.type === "AUCTION_WINNER_RETURN" || "AUCTION_SURPLUS_RETURN"){
                                        classType = "value surplus"
                                    }
                                    if(transaction.type === "AUCTION_DEPOSIT"){
                                        classType = "value deposit"
                                    }
                                    var show = null;
                                    let dateString = moment(transaction.created_at).format("DD-MM-YYYY");
                                    let i = 0;
                                    tempArray.map((compareData) => {
                                        if(compareData && compareData === dateString){
                                            i++;
                                        } 
                                        return null
                                    })
                                    if(i === 0){
                                        show=true;
                                        tempArray.push(dateString)
                                    }
                                    // if(type === "withdraw"){
                                    //     classType = "value withdraw"
                                    // }
                                    return  <section key={transaction.id} className="transactCard">
                                        {show && <div className="dated">
                                            <span className='date'>{moment(transaction.created_at).format("MMMM Do YYYY")}</span>
                                            <span className='time'>{moment(transaction.created_at).format("h:mm:ss a")}</span>
                                        </div>}
                                        <div className="card">
                                            <div className='main' onClick={() => openDetails(transaction.id)}>
                                                <div className="bidDetails">
                                                    <span className="title">
                                                        {transaction.type === "AUCTION_WINNER_RETURN" && "Auction Amount"}
                                                        {transaction.type === "AUCTION_SURPLUS_RETURN" && "Surplus"}
                                                        {transaction.type === "AUCTION_DEPOSIT" && "Deposit"}
                                                        {transaction.type === "AUCTION_WINNER_RETURN" && <span className='fee'>Fee 3%</span>}
                                                    </span>
                                                    {transaction.auction_return && <span className="plan">{transaction.auction_return.auction.plan.title} ({ordinal(transaction.auction_return.auction.serial_no)} Auction)</span>}

                                                    {transaction.auction_deposit && <span className="plan">{transaction.auction_deposit.auction.plan.title} ({ordinal(transaction.auction_deposit.auction.serial_no)} Auction)</span>}
                                                </div>
                                                <div className="bidValue">
                                                    <span className={classType}>
                                                        {classType === "value surplus" && '+'}
                                                        {classType === "value withdraw" && '-'}
                                                        ${transaction.amount.substring(0, transaction.amount.length - 3)}
                                                    </span>
                                                    <div className='dropdown'>
                                                        {(detailsOpened && currentExpandedId === transaction.id) ?
                                                            <img className='dropIconReverse' src={dropIcon} alt="icon" /> :
                                                            <img src={dropIcon} alt="icon" />
                                                            }
                                                    </div>
                                                </div>
                                            </div>
                                            {detailsOpened && currentExpandedId === transaction.id && (
                                                <div className='transactDetails'>
                                                    <div className='grid'>
                                                        <span className='title'>From</span>
                                                        <span className='value'>{classType.includes("deposit") ? "You" : ""}{classType.includes("surplus") ? "Wheez" : ""}</span>
                                                        <span className='title'>To</span>
                                                        <span className='value'>{classType.includes("deposit") ? "Wheez" : ""}{classType.includes("surplus") ? "You" : ""}</span>
                                                        <span className='title'>Transaction ID</span>
                                                        <span className='value id'>{transaction.id}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </section>
                                })
                            }
                        </InfiniteScroll>
                    </div>}
                </div>
            </>}
            <div ref={startDateModalRef} className="modal calenderModal">
                <div className="modal-content">
                    <DateRangePicker
                        onChange={item => onChangeDate(item)}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        disabledDay={item => disableDay(item)}
                        showMonthAndYearPickers={false}
                        className='dateRangerPicker'
                        ranges={state}
                        direction="horizontal"
                    />
                </div>
            </div>
            <div ref={modalRef} className="modal">
                <div className="modal-content">
                    <img onClick={() => closeWithdrawModal()} className='close' src={closeIcon} alt="icon" />
                    <div className="heading">
                        Withdraw
                    </div>
                    <div className="inputContent">
                        <span className="icon">$</span>
                        <input type="number" placeholder="Enter Amount" />
                    </div>
                    <div className="inputContent">
                        <input type="number" placeholder="Bank Account" />
                        <img className="iconDrop" src={dropIcon} alt="icon"/>
                    </div>
                    <div className="button">Withdraw Now!</div>
                </div>
            </div>
            <div ref={revalidateModalRef} className="modal">
                <div className="modal-content">
                    <img onClick={() => closeRevalidateModal()} className='close' src={closeIcon} alt="icon" />
                    <div className="heading">
                        Re-Validate
                    </div>
                    {!disableValidate && <div className='para'>
                        Re-validate, Limit will be given based on your financials, credit score, experience and activities in wheez, etc... We check and update limits actively. If you what us to re- check now, You can request for re-validation.
                    </div>}
                    {!disableValidate && <div className='para'>
                        Note: Like more accounts to get more accqurate limits "Add accounts link"
                    </div>}
                    {disableValidate && <div className='para' style={{textAlign: 'center', color: '#7AA8FF'}}>
                        Applied Successfully
                    </div>}
                    {!disableValidate && <div className='button' onClick={() => revalidate()}>Apply For Re-Validate</div>}
                </div>
            </div>
        </div>
    )
}

export default Wallet;
