import React from "react";
import { isMobile } from "react-device-detect";
import { Box, Container, Grid, Typography, Button } from "@material-ui/core";
import confusedIconTow from "../../assets/images/landingPage/Group 1320.png";
import confusedIcon from "../../assets/images/landingPage/Group 1321.png";
import { Filter, Filters } from "../../pages/landingPage"

const Confused = (props) => {
  return (
    <div>
      <Box bgcolor={"#1C0941"} zIndex={1} position={"relative"} padding={"80px 0 70px 0"} style={{overflow: "hidden"}}>
        <Box>
          <Container
            sx={{
              maxWidth: {
                sm: "sm",
                md: "xl",
                lg: "lg",
                xl: "xl",
              },
              position: "relative",
              zIndex: "5",
            }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              padding={"50px 0px"}>
              <Grid item xs={12} md={12}>
                <Box textAlign={"center"}>
                  <Typography
                    className="resp-h3"
                    variant="h3"
                   >
                    So, are you still confused? That’s ok you
                    <br /> can learn it just by doing it.
                  </Typography>
                  <Box>
                    <Typography
                      className="resp-confused-p"
                     >
                      because we have tools that can help you to learn and
                      understand. And there is no hurries, you can just grow on
                      your own pace. So, let’s start your wheez journey to make
                      your financial life better.
                    </Typography>
                  </Box>
                  <Box marginTop={"30px"}>
                    <img src={confusedIcon} />
                  </Box>
                  <Typography
                    style={{color: "#fff", textAlign: 'center', marginTop: "10px", textTransform: "capitalize"}}
                    >
                    By the way, I am Wheezy, and{isMobile && <br/>} I’ll meet you inside.
                  </Typography>
                  <Box
                    marginTop={"30px"}
                    justifyContent="center"
                    display={"flex"}
                    flexDirection={isMobile ? "column" : "row"}
                    alignItems="center"
                    style={{gap: "20px"}}
                    >
                    <Button
                      style={{
                        textTransform: "capitalize",
                        borderRadius: isMobile ? "12px" : "5px",
                        padding: "10px 0px",
                        backgroundColor: "#FFFFFF",
                        fontWeight: "700",
                        cursor: "pointer",
                        width: isMobile ? "80%" : "190px",
                        color: "#6D61C2",
                      }}
                      onClick={() => props.getStarted()}
                      >
                      Sign-up for free
                    </Button>
                    <Button
                      style={{
                        textTransform: "capitalize",
                        borderRadius: isMobile ? "12px" : "5px",
                        width: isMobile ? "80%" : "190px",
                        cursor: "pointer",
                        padding: "10px 0px",
                        border: "1px solid #ffff",
                        fontWeight: "700",
                        color: "#fff",
                        "&:hover": {
                          background: "#fff",
                        },
                      }}
                      >
                      Learn More
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>

          <Box bottom="0px" zIndex={-1} style={{scale: isMobile ? "1.5" : "2", position: 'relative'}}>
            <Box position={"absolute"} zIndex={-1} right={isMobile ? "0" : "45%"} bottom={isMobile ? "20px" : "200px"}>
              <Filters />
            </Box>
            <Box position={"absolute"} zIndex={-1} left={isMobile ? "25%" : "45%"} bottom={isMobile ? "20px" : "200px"}>
              <Filter />
            </Box>
          </Box>
          <Box position={"absolute"} zIndex={-1} bottom={isMobile ? "85%" : "30px"} left={"-80px"}>
            <img src={confusedIconTow} />
          </Box>
          <Box position={"absolute"} bottom={isMobile ? "93%" : "200px"} left={isMobile ? "10px" : "-60px"}>
            <img src={confusedIconTow} />
          </Box>
          {!isMobile && <Box position={"absolute"} top="40px" left={"0px"}>
            <img src={confusedIconTow} />
          </Box>}
          <Box position={"absolute"} top={isMobile ? "-10px" : "0px"} left={isMobile ? "40%" : "30%"}>
            <img src={confusedIconTow} />
          </Box>
          <Box position={"absolute"} top={isMobile ? "40px" : "10px"} left={isMobile ? "72%" : "60%"}>
            <img src={confusedIconTow} />
          </Box>
          {!isMobile && <Box position={"absolute"} top="25px" right={"30px"}>
            <img src={confusedIconTow} />
          </Box>}
          {!isMobile && <Box position={"absolute"} top="50%" right={"10px"}>
            <img src={confusedIconTow} />
          </Box>}
          {!isMobile && <Box position={"absolute"} bottom="5%" right={"20px"}>
            <img src={confusedIconTow} />
          </Box>}
        </Box>
      </Box>
    </div>
  );
};

export default Confused;
