import React from 'react'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles({
  icon: {
    borderRadius: '50%',
    width: 20,
    height: 20,
    boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
    backgroundColor: '#898989'
  },
  checkedIcon: {
    backgroundColor: '#A0C1FF',
    '&:before': {
      display: 'block',
      width: 10,
      height: 10,
      top: 0,
      left:0,
      transform: 'translate(50%, 50%)',
      borderRadius: '50%',
      backgroundColor: '#4283FF',
      content: '""',
    },
  },
});

function BpRadio(props) {
    const classes = useStyles();
    return (
      <Radio
        sx={{
          '&:hover': {
            bgcolor: 'transparent',
          },
        }}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
}


export default function RadioWithLabel(props) {
  return (
      <FormControlLabel 
        className="formLabel" 
        value={props.value} 
        control={props.type === "checkbox" ? <Checkbox color="#000"/> : <BpRadio />} 
        label={props.label} 
      />
  )
}